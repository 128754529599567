import { Component, OnInit, OnChanges } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { HttpService } from '@core/services/http.service';

@Component({
  selector: 'im-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit, OnChanges {

  leftSide: any = false;
  rightSide: any = false;

  loginUser: any;

  constructor(private authService: AuthService, private http: HttpService) { }

  ngOnInit() {
    this.loginUser = this.authService.loginUser;

  }
  ngOnChanges() {
    this.loginUser = this.authService.loginUser;
  }

}
