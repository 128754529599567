import { Routes } from '@angular/router';
import { ProfileComponent } from './profile.component';
import { AuthGuardService as AuthGuard } from '@core/services/guards/auth-guard.service';
import { AboutComponent } from './about/about.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { TimelineComponent } from './profile-page/timeline/timeline.component';
import { SummaryComponent } from './profile-page/summary/summary.component';
import { PublicComponent } from './public/public.component';
import { PSummaryComponent } from './public/summary/summary.component';
import { MyComponent } from './my/my.component';

export const profileRoutes: Routes = [
  {
    path: 'public',
    canActivate: [AuthGuard],
    children: [
      {
        path: ':slug',
        component: PublicComponent,
        children: [
          { path: '', component: PSummaryComponent },
          { path: 'about', component: PSummaryComponent },
          { path: 'feed', component: TimelineComponent },
        ],
      },
    ],
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProfilePageComponent,
        children: [
          { path: '', component: SummaryComponent },
          { path: 'feed', component: TimelineComponent },
        ],
      },
      {
        path: 'edit',
        component: ProfileComponent,
        children: [
          { path: '', component: AboutComponent },
          { path: 'change-password', component: ChangePasswordComponent },
          { path: 'transactions', component: TransactionsComponent },
          { path: 'integrations', component: IntegrationsComponent },
          { path: 'my-courses', component: MyComponent },
        ],
      },
      { path: ':slug', component: ProfilePageComponent },
    ],
  },
];
