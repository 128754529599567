<div class="full-event-section-wrapper">
  <ng-container *ngIf="!processing && notEnrolled">
    <im-event-pay [data]="event"></im-event-pay>
  </ng-container>

  <ng-container *ngIf="!processing && !notEnrolled">
    <mat-drawer-container class="example-container" autosize>
      <mat-drawer #drawer class="example-sidenav" mode="over" position="end">
        <div style="width: 60vw">
          <mat-tab-group
            dynamicHeight
            (selectedTabChange)="mainTabChange($event)"
            class="main-tab"
          >
            <mat-tab>
              <ng-template mat-tab-label>{{ "Projects" }}</ng-template>
              <div class="practical-section">
                <mat-tab-group
                  (selectedTabChange)="
                    ResourceItemTabChange($event, 'projects')
                  "
                  [selectedIndex]="selectedProjectIndex"
                >
                  <ng-container
                    *ngFor="let practical of weekBasedProjects; let i = index"
                  >
                    <mat-tab>
                      <ng-template mat-tab-label>
                        <span
                          class="mr-1 border px-1 font-semi-bold border-radius-md"
                          >P{{ i + 1 }}</span
                        >
                        {{ practical.title }}
                      </ng-template>
                      <div class="ml-5 mt-5 mr-5">
                        <h3 class="flex justify-between mb-4">
                          {{ practical.title }}
                          <span
                            class="border px-2 py-1 font-semi-bold font-sm mat-color-primary border-dashed border-radius-md"
                            >Project {{ i + 1 }}</span
                          >
                        </h3>
                        <div class="im-content chapter-section">
                          <div id="editorjsP{{ practical.id }}"></div>
                        </div>
                      </div>
                    </mat-tab>
                  </ng-container>
                </mat-tab-group>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>{{ "Assignments" }}</ng-template>
              <div class="practical-section">
                <mat-tab-group
                  (selectedTabChange)="
                    ResourceItemTabChange($event, 'assignments')
                  "
                  [selectedIndex]="selectedProjectIndex"
                >
                  <ng-container
                    *ngFor="
                      let practical of weekBasedAssignments;
                      let i = index
                    "
                  >
                    <mat-tab>
                      <ng-template mat-tab-label>
                        <span
                          class="mr-1 border px-1 font-semi-bold border-radius-md"
                          >A{{ i + 1 }}</span
                        >
                        {{ practical.title }}
                      </ng-template>
                      <div class="ml-5 mt-5 mr-5">
                        <h3 class="flex justify-between mb-4">
                          {{ practical.title }}
                          <span
                            class="border px-2 py-1 font-semi-bold font-sm mat-color-primary border-dashed border-radius-md"
                            >Assignment {{ i + 1 }}</span
                          >
                        </h3>
                        <div class="im-content chapter-section">
                          <div id="editorjsA{{ practical.id }}"></div>
                        </div>
                      </div>
                    </mat-tab>
                  </ng-container>
                </mat-tab-group>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </mat-drawer>

      <div
        class="grid has-8-columns full-event-section h-screen"
        style="max-width: 75%"
      >
        <div class="column-start-1 row-span-1 column-end-8 left">
          <div class="ml-4 mr-4 mt-4 flex items-center justify-between">
            <div class="flex items-center">
              <button mat-icon-button class="mr-2" (click)="back()">
                <mat-icon>keyboard_backspace</mat-icon>
              </button>
              <h3 style="letter-spacing: 1.2px">{{ event?.name }}</h3>
            </div>
            <div *ngIf="tabIndex > 0 && tabIndex <= eventData.weeks.length">
              <ng-container *ngIf="weekBasedResources[tabIndex]">
                <button
                  mat-raised-button
                  class="letter-spacing-15 text-uppercase mr-8"
                  color="primary"
                  (click)="openResources(drawer)"
                >
                  <mat-icon class="material-symbols-outlined">
                    arrow_back_ios </mat-icon
                  >Resources
                </button>
              </ng-container>
            </div>
          </div>
          <div [ngSwitch]="tabIndex">
            <div
              class="intro-section im-content ml-8 mt-4 mb-4 mr-12 overflow-y-auto"
              style="height: calc(100vh - 90px)"
              *ngSwitchCase="0"
            >
              <div id="editorjs"></div>
            </div>
            <ng-container *ngFor="let eventd of eventData.weeks; let i = index">
              <div
                *ngSwitchCase="i + 1"
                style="position: relative; height: calc(100vh - 114px)"
                class="overflow-y-auto"
              >
                <div class="ml-8 mt-4">
                  <h2 class="mb-1">{{ eventd.title + " - " + eventd.name }}</h2>
                  <p style="color: rgba(0, 0, 0, 0.54)"></p>
                </div>
                <div
                  class="md:display-block lg:display-block xl:display-none w-94p"
                >
                  <ul class="mr-0 mt-4 p-0 ml-8 flex justify-between">
                    <li class="flex mb-2 align-items-center">
                      <mat-icon class="mr-4" color="primary"
                        >calendar_today</mat-icon
                      >
                      <div class="flex flex-column">
                        <p class="mb-1 font-semi-bold font-xs">
                          {{ event.event_start_date | date : "mediumDate" }}
                        </p>
                        <p class="font-xss">Start Date</p>
                      </div>
                    </li>
                    <li class="flex mb-2 align-items-center">
                      <mat-icon class="mr-4" color="primary"
                        >calendar_today</mat-icon
                      >
                      <div class="flex flex-column">
                        <p class="mb-1 font-semi-bold font-xs">
                          {{ event.event_end_date | date : "mediumDate" }}
                        </p>
                        <p class="font-xss">End Date</p>
                      </div>
                    </li>
                    <li class="flex mb-2 align-items-center">
                      <mat-icon class="mr-4" color="primary">schedule</mat-icon>
                      <div class="flex flex-column">
                        <p class="mb-1 font-semi-bold font-xs">
                          {{
                            event.event_start_date +
                              "
                                                  " +
                              event.event_start_time | date : "shortTime"
                          }}
                          -
                          {{
                            event.event_start_date + " " + event.event_end_time
                              | date : "shortTime"
                          }}
                          IST
                        </p>
                        <p class="font-xss">Time</p>
                      </div>
                    </li>
                    <li class="flex mb-2 align-items-center">
                      <mat-icon class="mr-4" color="primary">timer</mat-icon>
                      <div class="flex flex-column">
                        <p class="mb-1 font-semi-bold font-xs">
                          {{ event.duration }}
                        </p>
                        <p class="font-xss">Duration</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="ml-0 mt-4 flex">
                  <div class="w-70p md:w-96p">
                    <div class="flex flex-wrap ml-4">
                      <ng-container *ngFor="let day of eventd.days">
                        <div class="w-1/2 mb-4">
                          <mat-card
                            class="ml-4"
                            [ngStyle]="{ 'background-color': day.color }"
                          >
                            <mat-card-header
                              class="justify-between items-center"
                            >
                              <mat-card-title
                                class="font-sm text-uppercase letter-spacing-20 mb-0"
                                style="margin-left: -16px"
                              >
                                {{ day.title }}</mat-card-title
                              >
                              <div *ngIf="day.videoID">
                                <button
                                  mat-icon-button
                                  (click)="openRecordedVideo(day.videoID)"
                                >
                                  <div
                                    class="flex items-center flex-column mat-color-primary"
                                  >
                                    <mat-icon class=""
                                      >play_circle_outline</mat-icon
                                    >
                                    <span
                                      style="
                                        font-size: 0.87em;
                                        padding: 0;
                                        margin: 0;
                                        line-height: 20px;
                                      "
                                      >Recorded</span
                                    >
                                  </div>
                                </button>
                              </div>
                            </mat-card-header>

                            <mat-card-content>
                              <ul
                                class="color-primary pl-0 pt-3 line-height-15rem font-base"
                              >
                                <li
                                  class="list-unstyled flex align-items mb-1 font-xss"
                                  *ngFor="let topic of day.topics"
                                >
                                  <mat-icon
                                    class="mat-icon material-icons-round material-icons mr-2"
                                  >
                                    task_alt
                                  </mat-icon>
                                  {{ topic }}
                                </li>
                              </ul>
                            </mat-card-content>
                          </mat-card>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div
                    class="md:display-none lg:display-none xl:display-block"
                    style="width: 30%"
                  >
                    <ul class="mr-0 pr-0 float-right1">
                      <li class="flex mb-8 align-items-center">
                        <mat-icon class="mr-4" color="primary"
                          >calendar_today</mat-icon
                        >
                        <div class="flex flex-column">
                          <p class="mb-1 font-semi-bold font-xs">
                            {{ event.event_start_date | date : "mediumDate" }}
                          </p>
                          <p class="font-xss">Start Date</p>
                        </div>
                      </li>
                      <li class="flex mb-8 align-items-center">
                        <mat-icon class="mr-4" color="primary"
                          >calendar_today</mat-icon
                        >
                        <div class="flex flex-column">
                          <p class="mb-1 font-semi-bold font-xs">
                            {{ event.event_end_date | date : "mediumDate" }}
                          </p>
                          <p class="font-xss">End Date</p>
                        </div>
                      </li>
                      <li class="flex mb-8 align-items-center">
                        <mat-icon class="mr-4" color="primary"
                          >schedule</mat-icon
                        >
                        <div class="flex flex-column">
                          <p class="mb-1 font-semi-bold font-xs">
                            {{
                              event.event_start_date +
                                "
                                                  " +
                                event.event_start_time | date : "shortTime"
                            }}
                            -
                            {{
                              event.event_start_date +
                                " " +
                                event.event_end_time | date : "shortTime"
                            }}
                            IST
                          </p>
                          <p class="font-xss">Time</p>
                        </div>
                      </li>
                      <li class="flex mb-4 align-items-center">
                        <mat-icon class="mr-4" color="primary">timer</mat-icon>
                        <div class="flex flex-column font-xs">
                          <p class="mb-1 font-semi-bold">
                            {{ event.duration }}
                          </p>
                          <p class="font-xss">Duration</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="mr-8 mt-6">
                  <ng-container *ngIf="eventStart">
                    <ng-container *ngIf="joinStatus[currentActiveDayIndex]">
                      <ng-container *ngIf="startEnableJoin">
                        <button
                          mat-raised-button
                          color="primary"
                          class="letter-spacing-15 text-uppercase mt-2 fixed bottom-0 border-radius-none"
                          style="height: 3.6rem; width: 75% !important"
                          (click)="join()"
                          type="button"
                        >
                          JOIN SESSION NOW
                        </button>
                      </ng-container>
                      <ng-container *ngIf="!startEnableJoin">
                        <button
                          mat-raised-button
                          class="letter-spacing-15 text-uppercase mt-2 fixed bottom-0 border-radius-none"
                          style="
                            height: 3.6rem;
                            opacity: 1;
                            width: 75% !important;
                            color: #000;
                          "
                          type="button"
                          disabled
                        >
                          <span class="flex items=center justify-center"
                            >JOIN SESSION
                            <span
                              class="font-xs ml-4"
                              style="
                                color: rgba(0, 0, 0, 0.6);
                                text-transform: initial;
                              "
                              >(Bridge will open 10 mins before the scheduled
                              time)</span
                            ></span
                          >
                        </button>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!eventStart"> </ng-container>
                  <ng-container *ngIf="!joinStatus[currentActiveDayIndex]">
                    <button
                      mat-raised-button
                      class="letter-spacing-15 text-uppercase mt-2 w-full fixed bottom-0 border-radius-none"
                      style="
                        height: 3.6rem;
                        opacity: 1;
                        color: #000;
                        width: 75% !important;
                      "
                      disabled
                      type="button"
                    >
                      CLOSED
                    </button>
                  </ng-container>
                </div>
              </div>
            </ng-container>

            <div
              *ngSwitchCase="eventData.weeks.length + 1"
              style="position: relative"
            >
              <im-event-certificate [event]="event"></im-event-certificate>
            </div>
          </div>
        </div>
        <div
          class="column-start-9 row-span-1 column-end-12 right-side-nav pt-4 fixed h-screen overflow-hidden mr-2"
          style="right: 0; max-width: 25%; background-color: #fafafa"
        >
          <mat-vertical-stepper
            linear="false"
            [selectedIndex]="selectedIndex"
            #stepper
            (selectionChange)="tabChange($event)"
            disableRipple="false"
            style="background-color: #fafafa"
          >
            <!-- Icon overrides. -->
            <ng-template matStepperIcon="edit" let-index="index">
              <mat-icon>{{ maticons[index] }}</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="videos">
              <mat-icon>play_circle_outline</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="intro">
              <mat-icon>lightbulb</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="certification">
              <mat-icon>card_membership</mat-icon>
            </ng-template>

            <ng-template matStepperIcon="event_available">
              <mat-icon>event_available</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="event">
              <mat-icon>event</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="event_not_available">
              <mat-icon>event_busy</mat-icon>
            </ng-template>
            <mat-step state="intro" completed="true">
              <ng-template matStepLabel>
                <p
                  class="active-step"
                  [ngClass]="{ 'completed-step': surveySubmitted }"
                >
                  Introduction
                </p>
                <p
                  class="mr-12 font-xs"
                  style="color: rgba(0, 0, 0, 0.54); white-space: initial"
                >
                  Let’s have a look at the ground rules for the program
                </p>
              </ng-template>
            </mat-step>

            <ng-container *ngFor="let eventd of eventData.weeks; let i = index">
              <mat-step state="event_available">
                <ng-template matStepLabel>
                  <p [ngStyle]="{ color: eventd.color }">
                    {{ eventd.title }} - {{ eventd.name }}
                  </p>
                  <p
                    class="mr-12 font-xs"
                    style="color: rgba(0, 0, 0, 0.54); white-space: initial"
                  >
                    {{ eventd.tagline }}
                  </p>
                </ng-template>
              </mat-step>
            </ng-container>

            <mat-step
              state="certification"
              [aria-labelledby]="
                event.user_event.properties?.event_program?.day3 == 1
                  ? ''
                  : 'disabled_Resi'
              "
            >
              <ng-template matStepLabel>
                <p
                  [ngClass]="{
                    'active-step':
                      event.user_event.properties?.event_program?.day3 == 1
                  }"
                >
                  Certification
                </p>
                <p
                  class="mr-12 font-xs"
                  style="color: rgba(0, 0, 0, 0.54); white-space: initial"
                >
                  Please click link to download your certificate
                </p>
              </ng-template>
            </mat-step>
          </mat-vertical-stepper>
        </div>
      </div>
    </mat-drawer-container>
  </ng-container>
  <ng-container *ngIf="!processing && notEnrolled && detailsShow">
    <div class="flex items-center justify-center h-screen flex-column">
      <mat-icon style="font-size: 48px; height: 48px; width: 48px" color="warn"
        >error_outline</mat-icon
      >
      <h2>
        Enrollments are closed. Please try for the another event programs.
      </h2>
      <button
        mat-raised-button
        color="primary"
        class="mt-2"
        routerLink="/schedule"
      >
        Explore
      </button>
    </div>
  </ng-container>
</div>
