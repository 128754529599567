import { Component, OnInit, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';

import { UrlSerializer, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'im-iframe-join',
  templateUrl: './iframe-join.component.html',
  styleUrls: ['./iframe-join.component.scss'],
})
export class IframeJoinComponent implements OnInit {
  eventUrl: any;

  isItEventPage: boolean = false;

  constructor(
    protected sanitizer: DomSanitizer,
    private _bottomSheetRef: MatBottomSheetRef<IframeJoinComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private router: Router,
    @Inject(DOCUMENT) private document
  ) {}

  ngOnInit(): void {
    if (this.document.location.href.includes('event')) {
      this.isItEventPage = true;
    }

    document.body.className += 'online-zoom-full-mode-open';
    this.eventUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.data.link
    );

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url.includes('event') && this.isItEventPage) {
        } else {
          document.body.classList.remove('online-zoom-full-mode-open');
          this._bottomSheetRef.dismiss();
        }
      });
  }
}
