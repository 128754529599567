import { Component, OnInit, AfterViewInit } from '@angular/core';
import { environment } from '@environment/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'im-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, AfterViewInit {

  coderUrl: any;

  processing: boolean = true;

  constructor(private route: ActivatedRoute, private router: Router, protected sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      this.coderUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.scratchUrl+'#'+id);
    });
    
  }

  ngAfterViewInit(): void {
    const that = this;
      (<any>document.querySelector('#scratch-iframe')).onload = () => {
        that.processing = false;
      };
  }

}
