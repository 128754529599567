import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorComponent } from './editor/editor.component';
import { GuideComponent } from './guide/guide.component';
import { WrapperComponent } from './wrapper/wrapper.component';
import { InputComponent } from './input/input.component';
import { ListComponent } from './list/list.component';
import { RouterModule } from '@angular/router';

import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { AllComponent } from './list/all/all.component';
import { MyComponent } from './list/my/my.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DynamicFormsModule } from '@core/ui/dynamic-forms';
import {MatChipsModule} from '@angular/material/chips';
import {MatTableModule} from '@angular/material/table';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import { TeachableComponent } from './teachable/teachable.component';
import { PlayComponent } from './teachable/play/play.component';
import { CreateComponent } from './teachable/create/create.component';
import { PlayzoneComponent } from './teachable/playzone/playzone.component';
import { GamesComponent } from './teachable/games/games.component';
import { ImageComponent } from './teachable/play/image/image.component';
import { AudioComponent } from './teachable/play/audio/audio.component';

@NgModule({
  declarations: [EditorComponent, GuideComponent, WrapperComponent, InputComponent, ListComponent, AllComponent, MyComponent, TeachableComponent, PlayComponent, CreateComponent, PlayzoneComponent, GamesComponent, ImageComponent, AudioComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule, 
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatTabsModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressBarModule,
    RouterModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    DynamicFormsModule,
    MatChipsModule,
    MatTableModule,
    MatToolbarModule,
    MatSidenavModule
  ]
})
export class AiEditor { }
