import { Component, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import * as tf from '@tensorflow/tfjs';
import * as tmImage from '@teachablemachine/image';
import { environment } from '@environment/environment';

@Component({
  selector: 'im-play-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit, OnDestroy, AfterViewInit {

  
  outputImg: any;
  outputLabel: any;
  model: any;
  webcam: any; 
  labelContainer: any = [];
  maxPredictions: any;
  isIos: boolean = false;
  @Input() game:any;

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if(this.webcam && this.webcam.canvas) this.webcam.stop();
  }

  ngAfterViewInit() {
    //this._ngZone.runOutsideAngular(() => {
        // fix when running demo in ios, video will be frozen;
        if (window.navigator.userAgent.indexOf('iPhone') > -1 || window.navigator.userAgent.indexOf('iPad') > -1) {
          this.isIos = true;
        }
        this.init();
    //});

  }

    // Load the image model and setup the webcam
    async init() {
      const modelURL = environment.storageUrl+'ai_games/models/'+this.game.key+'/model.json';
      const metadataURL =  environment.storageUrl+'ai_games/models/'+this.game.key+'/metadata.json';

      // load the model and metadata
      // Refer to tmImage.loadFromFiles() in the API to support files from a file picker
      // or files from your local hard drive
      this.model = await tmImage.load(modelURL, metadataURL);
      this.maxPredictions = this.model.getTotalClasses();

      // Convenience function to setup a webcam
      const flip = true; // whether to flip the webcam
      const width = 265;
      const height = 265;
      this.webcam = new tmImage.Webcam(width, height, flip);
      await this.webcam.setup(); // request access to the webcam

      if (this.isIos) {
          document.getElementById('webcam-container').appendChild(this.webcam.webcam); // webcam object needs to be added in any case to make this work on iOS
          // grab video-object in any way you want and set the attributes
          const webCamVideo:any = document.getElementsByTagName('video')[0];
          webCamVideo.setAttribute("playsinline", true); // written with "setAttribute" bc. iOS buggs otherwise
          webCamVideo.muted = "true";
          webCamVideo.style.width = width + 'px';
          webCamVideo.style.height = height + 'px';
      } else {
          document.getElementById("webcam-container").appendChild(this.webcam.canvas);
      }
      for (let i = 0; i < this.maxPredictions; i++) { // and class labels
          this.labelContainer.push({label:'', i: i, value:0});
      }
      this.webcam.play();
      this.outputImg = document.querySelector('#output-player-img');
      this.outputLabel = document.querySelector('#output-player-label');

      window.requestAnimationFrame(this.loop.bind(this));
  }

  async loop() {
      this.webcam.update(); // update the webcam frame
      await this.predict();
      window.requestAnimationFrame(this.loop.bind(this));
  }

  // run the webcam image through the image model
  async predict() {
      // predict can take in an image, video or canvas html element
      let prediction;
      if (this.isIos) {
          prediction = await this.model.predict(this.webcam.webcam);
      } else {
          prediction = await this.model.predict(this.webcam.canvas);
      }

      for (let i = 0; i < this.maxPredictions; i++) {
          //const classPrediction = prediction[i].className + ': ' + prediction[i].probability.toFixed(2);
          this.labelContainer[i].value = prediction[i].probability.toFixed(2);
          this.labelContainer[i].label = prediction[i].className;
          if (prediction[i].probability.toFixed(2) > 0.5 && this.game.properties && this.game.properties.actions) {
            this.outputImg.src=environment.storageUrl+'ai_games/models/'+this.game.key+"/actions/"+this.game.properties.actions[prediction[i].className];
            if(this.game.properties.action_label) this.outputLabel.innerText = this.game.properties.action_label[prediction[i].className]
          }
      }
  }
   

}
