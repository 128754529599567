<mat-sidenav-container autosize style="background: white;">
  <mat-sidenav-content>

<div class=" 
    md:grid 
    has-12-columns 
    has-1-rows
    justify-items-stretch h-screen relative" *ngIf="model">
	<!-- <div class="main column-start-1  row-span-1 column-end-4">
        <im-ai-guide [model]="model"></im-ai-guide>
    </div> -->
	<div class="result column-start-1  row-span-1 column-end-12 overflow-hidden">
    <div class="flex px-8 py-1 items-center header justify-between">
      <div class="flex items-center">
        <button mat-icon-button class="mr-2" (click)="back()"><mat-icon>keyboard_backspace</mat-icon></button>
        <h2>{{model.name}}</h2>
        <mat-chip-list aria-label="model tags" class="ml-3"> 
          <mat-chip color="primary" selected class="font-xs cursor-pointer" routerLink="/ai-coder/category/{{model.category.slug}}" style="cursor: pointer;">{{model.category.name}}</mat-chip>
          <!-- <mat-chip color="primary" selected class="font-xs">{{model.properties.tags}}</mat-chip> -->
        </mat-chip-list>

      </div>
      <div class="icon-info"> 
        <div class="border-radius-full"><img [src]="model.icon_p" width="32" height="32" /></div>
      </div>
    </div>
		<div class=" 
          md:grid 
          has-1-columns 
          has-1-rows
          justify-items-stretch h-screen row-gap-20">
			<!-- <div class="row-start-1   row-span-1">
            <im-ai-editor></im-ai-editor>
            </div>
            <div class="row-start-2   row-span-2">
                <im-ai-input></im-ai-input>
            </div> -->
            <ng-container *ngIf="modelConfig">
              <mat-horizontal-stepper #stepper labelPosition="bottom" linear="false">
                <mat-step state="about">
                  <ng-template matStepLabel> 
                    <p class="font-xs"> About the model </p>
                  </ng-template>
                  <im-ai-guide [model]="model"></im-ai-guide>
                  <div class="flex justify-end shadow-2 absolute bottom-0 left-0 w-full px-4 py-2 z-5" style="background-color:whitesmoke;">
                    <button mat-button matStepperNext mat-stroked-button class="letter-spacing-15">NEXT</button>
                  </div>
                </mat-step>
                <ng-template matStepperIcon="about">
                  <mat-icon>downloading</mat-icon>
                </ng-template>
                <ng-container *ngFor="let steps of modelConfig; let mi = index">
                  <mat-step [stepControl]="" errorMessage="required." >
                    <ng-template matStepLabel> <strong>{{steps.name}}</strong>
                      <br />
                      <p class="font-xs" [innerHtml]="steps.short_description"> </p>
                    </ng-template>
                    <div class="p-0 overflow-y-auto" style="height: calc(100vh - 180px);">
                      <p [innerHtml]="steps.description" class="px-8 pt-4 font-xs line-height-15rem"> </p>
                      <ng-container *ngIf="!steps?.is_it_deployment">
                        <div class="flex mt-6 xs:flex-column w-full justify-between h-full px-8">
                          <ng-container *ngFor="let subStep of steps.steps; let si = index">
                            <div class="{{(steps.steps.length > 1)? 'w-48 xs:w-full': 'w-full'}} pb-12" [ngClass]="{'noshadow': (subStep.datasetTableConfig && steps.steps.length ==1  && subStep.type == 'output')}">
                              <mat-card class="shadow-1 border-radius-lg xs:w-full im-ai-card" style="height: 82%;">
                                <h3 class="mb-3">
                                  {{subStep.name}}
  
                                  <button mat-icon-button (click)="openSubInfo(drawer, subStep)" *ngIf="subStep?.info?.enabled">
                                    <mat-icon class="material-icons-outlined">info</mat-icon>
                                  </button>
  
                                </h3>
                                <ng-container *ngIf="subStep.type == 'input'"> 
                                  <app-dynamic-form [questions]="subStep.refConfig.properties" [refId]="model.id" [buttonLabel]="subStep.buttonLabel" (submitEvent)="submitStep($event, subStep, mi, si)" [patchValues]="getPatchValues(subStep.id, mi, si, imDynamicForm)" [replaceVlaues]="replaceVlaues"  #imDynamicForm></app-dynamic-form>
                                </ng-container>
                                <ng-container *ngIf="subStep.type == 'output'"> 
                                  <ng-container *ngIf="subStep.datasetTableConfig">
                                    <div class="overflow-y-auto mat-elevation-z8" style="max-height: 90%;">
                                      <table mat-table [dataSource]="subStep.datasetTableConfig" class="w-full" style="border: 1px solid rgba(0, 0, 0, 0.12);">
                                        <ng-container [matColumnDef]="column" *ngFor="let column of Object.keys(subStep.datasetTableConfig[0])">
                                          <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                                          <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                                        </ng-container>
                                      
                                        <tr mat-header-row *matHeaderRowDef="Object.keys(subStep.datasetTableConfig[0])"></tr>
                                        <tr mat-row *matRowDef="let row; columns: Object.keys(subStep.datasetTableConfig[0]);"></tr>
                                      </table>
                                    </div>
                                   
                                  </ng-container>
                                <ng-container *ngIf="outputs[subStep.ref]">
                                   <div *ngIf="isString(outputs[subStep.ref])">
                                      <p [innerHtml]="outputs[subStep.ref]"></p>
                                    </div>
                                    <div  [ngTemplateOutlet]="isObject(outputs[subStep.ref])?outputObject: ''" [ngTemplateOutletContext]="{data:outputs[subStep.ref]}">
                                      <!-- <ul class="list-unstyled">
                                        <ng-container *ngFor="let key1 of Object.keys(outputs[subStep.ref])">
                                          <li>
                                            <p class="title">{{key1.replace('_',' ')}}</p>
                                            <p class="value font-4xl my-2 font-bold" [innerHtml]="outputs[subStep.ref][key1]"></p>
                                          </li>
                                        </ng-container>
                                      </ul> -->
                                    </div>
                                    <div [ngTemplateOutlet]="isArray(outputs[subStep.ref])? outputArray: ''"  [ngTemplateOutletContext]="{data:outputs[subStep.ref]}">
                                      
                                    </div>
                                </ng-container>
            
                                </ng-container>
                              </mat-card>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="steps?.is_it_deployment">
                        <div class="flex mt-6 xs:flex-column w-full px-8">
                            <div class="flex flex-column my-6 mx-auto justify-center border border-radius-lg items-center">
                              <h3 class="mt-2">DataSet & Source Code</h3>
                              <p>
                                
                              </p>
                              <ul class="flex list-unstyled items-center">
                                <ng-container *ngFor="let file of steps.files">
                                  <li *ngIf="file.enable" class="p-8">
                                    <a [href]="((file.provider == 'github')?file.url: (storageUrl + file.url))" target="_blank">
                                        <img src="assets/logos/{{file.provider}}.png"  width="60" alt="{{file.provider}}"/>
                                    </a>
                                  </li>
                                </ng-container>
                            </ul>
                            </div>
                            
                        </div>
                      </ng-container>
                      
                      <div class="flex justify-end shadow-2 absolute bottom-0 left-0 w-full px-4 py-2" style="background-color:whitesmoke;">
                        <button mat-button matStepperPrevious mat-stroked-button class="mr-4">Back</button>
                        <ng-container *ngIf="mi+1 != modelConfig.length">
                          <button mat-button mat-stroked-button class="letter-spacing-15" (click)="next(steps, mi)">NEXT</button>
                        </ng-container>
                      </div>
                    </div>
                  </mat-step>
                </ng-container>
                <ng-template matStepperIcon="about">
                  <mat-icon>info</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="edit">
                  <mat-icon>done_all</mat-icon>
                </ng-template>
              </mat-horizontal-stepper>        
            </ng-container>
		</div>
	</div>
</div>

<ng-template #outputObject let-data="data">
  <ng-container [ngTemplateOutlet]="(data.type && data.type == 'multi_object') ? '':outputSingleObject" [ngTemplateOutletContext]="{data:data}">
      <ng-container *ngFor="let child of data.children">
        <ng-container [ngTemplateOutlet]="child.type? '' : outputSingleObject" [ngTemplateOutletContext]="{data:child}"> 

        </ng-container>
      </ng-container>
  </ng-container>
</ng-template>
<ng-template #outputSingleObject let-data="data">
  <ul class="list-unstyled">
    <ng-container *ngFor="let key1 of Object.keys(data)">
      <li>
        <p class="title">{{key1.replace('_',' ')}}</p>
        <ng-container *ngIf="isString(data[key1])">
          <p class="value font-4xl my-2 font-bold" [innerHtml]="data[key1]"></p>
        </ng-container>
        <ng-container *ngIf="isObject(data[key1])">
          <ng-container [ngTemplateOutlet]="outputSingleObject" [ngTemplateOutletContext]="{data:data[key1]}"> 
            </ng-container>
        </ng-container>
      </li>
    </ng-container>
  </ul>
</ng-template>
<ng-template #outputArray let-data="data">
  <div class="overflow-y-auto mat-elevation-z8" style="max-height: 40vh;">
    <table mat-table [dataSource]="data" class="w-full" style="border: 1px solid rgba(0, 0, 0, 0.12);">
      <ng-container [matColumnDef]="column" *ngFor="let column of Object.keys(data[0])">
        <th mat-header-cell *matHeaderCellDef> {{column}} </th>
        <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="Object.keys(data[0])"></tr>
      <tr mat-row *matRowDef="let row; columns: Object.keys(data[0]);"></tr>
    </table>
  </div>
</ng-template>

</mat-sidenav-content>

<mat-sidenav #drawer  mode="over" position="end" style="width: 45%;">
      <ng-container *ngIf="activeSubStepInfo">
        <iframe [src]="activeSubStepInfo.iframeUrl" frameborder="0" style="width: 100%;height: calc(100vh);"></iframe>
      </ng-container>
</mat-sidenav>

</mat-sidenav-container>