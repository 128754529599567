<div class="ml-8 h-full">

    <div class="shadow-1 flex  border-radius-lg mb-8 p-4 flex-column">
        <h4>My Courses</h4>
        <div class="empty-state flex items-center px-6 flex-column justify-center"  style="height: 150px;">
            <p class="text-center mt-2 font-xs color-secondary">
                You did not enroll for any courses yet. It need not be like that. <br />Please explore the available course and pick one.
            </p>
            <button mat-raised-button color="primary" class="mt-2" routerLink="/dashboard">Buy Now</button>
        </div>
    </div>

    <div class="shadow-1 flex border-radius-lg mb-8 p-4 flex-column">
        <h4>Upcoming Schedules</h4>
        <div class="empty-state flex items-center px-6 flex-column justify-center" style="height: 150px;">
            <p class="text-center mt-2 font-xs color-secondary">
                Your upcoming schedules would appear here. As you have not enrolled for any course you don’t have any schedule as of today. Please commit to a course.
            </p>
            <button mat-raised-button color="primary" class="mt-2" routerLink="/dashboard">Buy Now</button>
        </div>
    </div>

    <div class="shadow-1 flex border-radius-lg mb-8 p-4 flex-column">
        <h4>My Tasks</h4>
        <div class="empty-state flex items-center px-6 flex-column justify-center" style="height: 150px;">
            <p class="text-center mt-2 font-xs color-secondary">
                Your assignments and commitments related to your course would appear here. As you have not enrolled for any course you don’t have any tasks. Please explore our course options to decide on one.
            </p>
            <button mat-raised-button color="primary" class="mt-2" routerLink="/dashboard">Buy Now</button>
        </div>

    </div>

</div>
