<div class="teachable-machine">
    <div class="grid 
    has-12-columns 
    has-1-rows
    justify-items-stretch h-screen relative" *ngIf="!childExist" style="background-image: url('assets/images/SFAI_App_Gaming section.png');background-repeat: no-repeat;background-size: 100%;">
        <div class="column-start-1  row-span-1 column-end-6 overflow-hidden" style="position: relative;">
            <div style="position: absolute;bottom: 20%;left: 5%; width: 40%;">
                <button mat-raised-button color="primary" routerLink="create" style="text-transform: uppercase;letter-spacing: 1.5px;background-color: #2c3e50;">Build Game</button>
                <p class="mt-2" style="color: #d50000;">Teach a machine using your camera, live in the browser. No coding required</p>
            </div> 
        </div>
        <div class="column-start-7  row-span-1 column-end-12 overflow-hidden" style="position: relative;">
            <div style="position: absolute; top: 23%; left: 30%; width: 40%;">
                <button mat-raised-button color="primary" routerLink="games" style="text-transform: uppercase;letter-spacing: 1.5px;">Explore Games</button>
                <p class="mt-2 color-secondary">Enjoy the games built using the pre-trained models. You could contribute too. </p>
            </div>
        </div>
    </div>
    <router-outlet> </router-outlet>        
</div>

