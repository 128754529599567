import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../../../core/services/http.service';
import { AuthService } from '@core/services/auth.service';
//import { ProviderDetector } from '@core/utillities/email-provider';
@Component({
  selector: 'im-auth-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  @Input() isItComonent = false;
  @Input() compEmail = '';
  @Output() verifyCallback = new EventEmitter<any>();

  passwordFormControl = new FormControl('', [
    Validators.required
  ]);
  nameFormControl = new FormControl('', [
    Validators.required
  ]);
  referalControl = new FormControl('', []);

  otp: any;

  hide: boolean = true;

  processing: boolean = false;

  email: string = '';

  otpConfig = {
    length: 4,
    allowNumbersOnly: true,
    isPasswordInput: true
  }

  otpError: boolean = false;

  resendProcessing: boolean = false;

  termsCheckError: boolean = false;

  mobileNumber = '';

  grade = '';

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpService, private authService: AuthService) { }

  ngOnInit() {
    if (!this.isItComonent) {
      if (this.route.snapshot.paramMap.get('email')) {
        this.email = atob(this.route.snapshot.paramMap.get('email'));
        //   this.emailProvider.get(this.email)
        // .then( service => {
        //   console.log(service.name); // Gmail
        //   console.log(service.url); // https://googlemail.com
        // })
        // .catch( error => {
        //   console.log('Email provider was not reached:', error);
        // });
      }
    } else {
      this.email = this.compEmail;
    }

    if (this.route.snapshot.queryParamMap.get('name')) {
      this.nameFormControl.setValue(this.route.snapshot.queryParamMap.get('name'));
    }
    if (this.route.snapshot.queryParamMap.get('mobile')) {
      this.mobileNumber = this.route.snapshot.queryParamMap.get('mobile');
    }
    if (this.route.snapshot.queryParamMap.get('grade')) {
      this.grade = this.route.snapshot.queryParamMap.get('grade');
    }

  }

  next(termsCheckbox) {
    if (!termsCheckbox) {
      this.termsCheckError = true;
      return;
    }
    if (this.otp && this.otp.length === this.otpConfig.length) {
      this.otpError = false;
      if (!this.passwordFormControl.valid) {
        return;
      }
      this.processing = true;
      this.http.post('login', { email_otp: this.otp, email: this.email, new_password: this.passwordFormControl.value, referal_code: this.referalControl.value, name: this.nameFormControl.value, mobileNumber: this.mobileNumber, grade: this.grade }, { showErrorMessage: false }).subscribe((res) => {
        if (res && res.success) {
          this.authService.setToken(res.data.accessToken);
          if (this.isItComonent) {
            this.verifyCallback.emit({ next: 'done' });
          } else {
            this.authService.redirectUrl();
          }

        } else {
          this.processing = false;
        }
      }, (err) => {
        this.processing = false;
      });
    } else {
      this.otpError = true;
    }
  }

  otpChange($event) {
    this.otp = $event;
    if (this.otp && this.otp.length === this.otpConfig.length) {
      this.otpError = false;
    }
  }

  resendOtp() {
    if (this.resendProcessing) return;
    this.resendProcessing = true;
    this.http.post('exist/user', { email: this.email }, { showErrorMessage: false }).subscribe((res) => {
      if (res && res.success) {
        this.router.navigate(['auth/password', btoa(this.email)]);
      } else {
        this.processing = false;
      }
    }, (err) => {
      if (err.status === 404) {
        //this.router.navigate(['auth/verify', btoa(this.email)]);
      } else {
        this.processing = false;
      }
      this.resendProcessing = false;
    })
  }

}
