import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotesComponent, NoteCreate } from './notes.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import { DetailsComponent, DeleteNoteComponent } from './details/details.component';
import { RouterModule } from '@angular/router';
import {MatSidenavModule} from '@angular/material/sidenav';


@NgModule({
    declarations: [NotesComponent, NoteCreate, DetailsComponent, DeleteNoteComponent],
    imports: [
      CommonModule,
      MatButtonModule,
      MatIconModule,
      MatDividerModule,
      MatDialogModule,
      MatInputModule,
      MatFormFieldModule,
      FormsModule,
      ReactiveFormsModule,
      MatTooltipModule,
      RouterModule,
      MatSidenavModule
    ],
    exports: [],
    entryComponents: [NoteCreate, DeleteNoteComponent]
  })
export class NoteModule { }
  
