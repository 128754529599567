import { Component, OnInit } from '@angular/core';
import { AiService } from '../ai.service';
import { HttpService } from '../../../core/services/http.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'im-ai-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  activeModel : string = '';

  checkingUserModels: boolean = true;

  modelCategories: any = [];

  navLinks = [
    // {
    //   path: '/ai-coder',
    //   label: 'My Models'
    // },
    {
      path: '/ai-coder/all',
      label: 'All Models'
    },
   
  ];

  constructor(private aiService: AiService, private http: HttpService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.checkingUserModels = true;
    this.http.get('ai_model_categories?recursive=-1&order_by=order').subscribe((res)=>{
      this.modelCategories = res.data;
      this.modelCategories.forEach(category => {
            this.navLinks.push({
              path: '/ai-coder/category/'+category.slug,
              label: category.name
            })
      });
      this.navLinks.push({
          path: '/ai-coder/teachable-machine',
          label: 'Gaming Lab'
      });
      //this.router.navigate(['all'], {relativeTo: this.route});
      // if (res.data.length == 0 ){
      //     this.navLinks.splice(0, 1);
      //     this.router.navigate(['all'], {relativeTo: this.route});
      // }
      this.checkingUserModels = false;
    });
    this.aiService.activeModel.subscribe((res)=> {
        this.activeModel = res;
    });
  }

}
