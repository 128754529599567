import { Component, OnInit } from '@angular/core';
import { HttpService } from '@core/services/http.service';

@Component({
  selector: 'im-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {

  apps = [
    {
      "name": "Meeting",
      "children": [
        {
          "logo": "/assets/images/zoom.png",
          "name": "Zoom",
          "staus": 1
        }
      ]
    }
    
  ]

  constructor(private http: HttpService) { }

  ngOnInit() {
  }

  link(integration) {

  }

}
