import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'im-teachable',
  templateUrl: './teachable.component.html',
  styleUrls: ['./teachable.component.scss']
})
export class TeachableComponent implements OnInit {

  childExist: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router) {
      this.router.events.subscribe((res)=>{
        if(this.router.url !== '/ai-coder/teachable-machine') {
          this.childExist = true;
        } else {
          this.childExist = false;
        }
      });
  }

  ngOnInit() {
      if(this.router.url !== '/ai-coder/teachable-machine') {
          this.childExist = true;
      }
  }



}
