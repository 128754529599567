<div class="pages box shadow-1 border-radius-lg">
    <div class="intro-title mb-4">
      <h3>Leader board</h3>
      <!-- <button class="intro-menu"></button> -->
    </div>
    <div class="user" *ngFor="let user of usersList">
      <img [src]="user.user.profile_image" alt="" class="user-img">
      <div class="username">
        <span class="font-medium">{{user.user.name}}</span>
        <div class="total-points">Points: {{user.total}}</div>
      </div>
      <div class="rank">
          {{user.rank}}
      </div>
    </div>
  </div>