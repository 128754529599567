<!-- <ng-container *ngIf="!processing && products.length == 0">
  <div class="empty-state flex items-center px-6 py-12 my-12 flex-column">
    <mat-icon class="border-radius-full">search</mat-icon>
    <p class="text-center mt-2 font-base color-secondary">
      No Events available. <br />Please explore the by click on the below
      button.
    </p>
    <button
      mat-raised-button
      color="primary"
      class="mt-2"
      routerLink="/our-offering"
    >
      Explore
    </button>
  </div>
</ng-container> -->
<div class="im-bar-coder">
  <h3
    class="ml-6 letter-spacing-10 font-semi-bold flex items-center"
    style="color: #8b4324"
  >
    <mat-icon class="mr-2 material-symbols-outlined">rocket_launch</mat-icon>
    Events
  </h3>
</div>
<div class="aitinker-section my-4" *ngIf="!processing">
  <h4
    class="mt-2 mb-3 text-uppercase letter-spacing-10"
    *ngIf="productList.length > 0"
  >
    Active Events
  </h4>
  <div
    class="grid has-4-columns xs:has-1-columns column-gap-25 lg:column-gap-25 row-gap-25 justify-items-stretch"
  >
    <ng-container *ngFor="let product of productList">
      <div
        class="relative flex flex-column overflow-hidden border-radius-xlg cursor-pointer"
        style="background: linear-gradient(#faf7f4, #eceef1); height: 200px"
        routerLink="/event/{{ product.slug }}"
      >
        <div class="flex flex-column p-4">
          <p
            style="color: #747d8c"
            class="text-uppercase letter-spacing-15 font-xsss"
          >
            {{ product.properties?.tag_line }}
          </p>
          <h3 class="font-semi-bold letter-spacing-15">
            <span style="color: #06669f" class="font-xl poppins-font">
              {{ product.name }}
            </span>
          </h3>

          <!-- <p class="">
            {{ product.short_description }}
          </p> -->
          <div class="flex flex-column mt-4">
            <mat-chip-list aria-label="Product Tags">
              <mat-chip
                color="accent"
                selected
                *ngFor="let tag of product.properties?.tags"
                >{{ tag }}</mat-chip
              >
            </mat-chip-list>
          </div>
        </div>
        <div class="absolute left-20 bottom-20">
          <mat-icon>east</mat-icon>
        </div>
        <div style="position: absolute; right: 10px; bottom: 10px">
          <img
            alt=""
            class=""
            [src]="product.cover_image_p"
            style="height: 100px"
          />
        </div>
      </div>
    </ng-container>
  </div>
  <h4 class="mt-6 mb-3 text-uppercase letter-spacing-10">Completed Events</h4>
  <div
    class="grid has-4-columns xs:has-1-columns column-gap-25 lg:column-gap-25 row-gap-25 justify-items-stretch"
  >
    <ng-container *ngFor="let product of oldProductsList">
      <ng-container *ngIf="product.user_event">
        <div
          class="relative flex flex-column overflow-hidden border-radius-xlg cursor-pointer"
          style="background: linear-gradient(#faf7f4, #eceef1); height: 200px"
          routerLink="/event/{{ product.slug }}"
        >
          <div class="flex flex-column p-4">
            <p
              style="color: #747d8c"
              class="text-uppercase letter-spacing-15 font-xsss"
            >
              {{ product.properties?.tag_line }}
            </p>
            <h3 class="font-semi-bold letter-spacing-15">
              <span style="color: #06669f" class="font-xl poppins-font">
                {{ product.name }}
              </span>
            </h3>

            <!-- <p class="">
          {{ product.short_description }}
        </p> -->
            <div class="flex flex-column mt-4">
              <mat-chip-list aria-label="Product Tags">
                <mat-chip
                  color="accent"
                  selected
                  *ngFor="let tag of product.properties?.tags"
                  >{{ tag }}</mat-chip
                >
              </mat-chip-list>
            </div>
          </div>
          <div class="absolute left-20 bottom-20">
            <mat-icon>east</mat-icon>
          </div>
          <div style="position: absolute; right: 10px; bottom: 10px">
            <img
              alt=""
              class=""
              [src]="product.cover_image_p"
              style="height: 100px"
            />
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!product.user_event">
        <div
          class="relative flex flex-column overflow-hidden border-radius-xlg"
          style="background: linear-gradient(#faf7f4, #eceef1); height: 200px"
        >
          <div class="flex flex-column p-4">
            <p
              style="color: #747d8c"
              class="text-uppercase letter-spacing-15 font-xsss"
            >
              {{ product.properties?.tag_line }}
            </p>
            <h3 class="font-semi-bold letter-spacing-15">
              <span style="color: #06669f" class="font-xl poppins-font">
                {{ product.name }}
              </span>
            </h3>

            <!-- <p class="">
          {{ product.short_description }}
        </p> -->
            <div class="flex flex-column mt-4">
              <mat-chip-list aria-label="Product Tags">
                <mat-chip
                  color="accent"
                  selected
                  *ngFor="let tag of product.properties?.tags"
                  >{{ tag }}</mat-chip
                >
              </mat-chip-list>
            </div>
          </div>

          <div style="position: absolute; right: 10px; bottom: 10px">
            <img
              alt=""
              class=""
              [src]="product.cover_image_p"
              style="height: 100px"
            />
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
