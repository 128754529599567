
<ng-template [cdkPortalOutlet]="_calendarHeaderPortal"></ng-template>

<div class="mat-calendar-content" [ngSwitch]="currentView" cdkMonitorSubtreeFocus tabindex="-1">
  <mat-week-view
      *ngSwitchCase="'week'"
      [(activeDate)]="activeDate"
      [selected]="selected"
      [dateFilter]="dateFilter"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [dateClass]="dateClass"
      (selectedChange)="_dateSelected($event)"
      (_userSelection)="_userSelected()">
  </mat-week-view>

    <mat-month-view
        *ngSwitchCase="'month'"
        [(activeDate)]="activeDate"
        [selected]="selected"
        [dateFilter]="dateFilter"
        [maxDate]="maxDate"
        [minDate]="minDate"
        [dateClass]="dateClass"
        (selectedChange)="_dateSelected($event)"
        (_userSelection)="_userSelected()">
    </mat-month-view>

  <mat-year-view
      *ngSwitchCase="'year'"
      [(activeDate)]="activeDate"
      [selected]="selected"
      [dateFilter]="dateFilter"
      [maxDate]="maxDate"
      [minDate]="minDate"
      (monthSelected)="_monthSelectedInYearView($event)"
      (selectedChange)="_goToDateInView($event, 'month')">
  </mat-year-view>

  <mat-multi-year-view
      *ngSwitchCase="'multi-year'"
      [(activeDate)]="activeDate"
      [selected]="selected"
      [dateFilter]="dateFilter"
      [maxDate]="maxDate"
      [minDate]="minDate"
      (yearSelected)="_yearSelectedInMultiYearView($event)"
      (selectedChange)="_goToDateInView($event, 'year')">
  </mat-multi-year-view>
</div>
