import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CourseComponent,
  ChapterLockStatusSheet,
  CourseIntroModal,
} from './course.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TrialComponent, BookComponent } from './trial/trial.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  ChapterComponent,
  TopicLockStatusSheet,
} from './chapter/chapter.component';
import { RouterModule } from '@angular/router';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { TextFieldModule } from '@angular/cdk/text-field';
import { PictureVideoComponent } from '../../themes/default/picture-video/picture-video.component';
import { ListComponent } from './list/list.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AllComponent } from './list/all/all.component';
import { MyComponent } from './list/my/my.component';
import { CompletedComponent } from './list/completed/completed.component';
import { CertificatesComponent } from './list/certificates/certificates.component';
import { FreeComponent } from './list/free/free.component';
import { HybridComponent } from './list/hybrid/hybrid.component';
import { AiTinkerComponent } from './list/aitinker/aitinker.component';
import { TopicComponent, ImageZoom } from './topic/topic.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ProductListComponent } from './list/products/product-list.component';
import { MatChipsModule } from '@angular/material/chips';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDatepickerModule } from '@core/ui/calendar';
import { AuthModule } from '@modules/auth';
import { MiniKitsComponent } from './list/mini-kits/mini-kits.component';
import { MinikitDetailsComponent } from './minikit-details/minikit-details.component';
import { MiniKitTopicComponent } from './mini-kit-topic/mini-kit-topic.component';

@NgModule({
  declarations: [
    ProductListComponent,
    ImageZoom,
    TopicComponent,
    AiTinkerComponent,
    PictureVideoComponent,
    CourseComponent,
    TrialComponent,
    BookComponent,
    ChapterComponent,
    ChapterLockStatusSheet,
    TopicLockStatusSheet,
    CourseIntroModal,
    ListComponent,
    AllComponent,
    MyComponent,
    CompletedComponent,
    CertificatesComponent,
    FreeComponent,
    HybridComponent,
    ProductDetailsComponent,
    MiniKitsComponent,
    MinikitDetailsComponent,
    MiniKitTopicComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatTabsModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressBarModule,
    MatToolbarModule,
    RouterModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
    TextFieldModule,
    MatExpansionModule,
    MatChipsModule,
    MatSidenavModule,
    MatDatepickerModule,
    AuthModule,
  ],
  exports: [TrialComponent],
  entryComponents: [
    ImageZoom,
    PictureVideoComponent,
    BookComponent,
    ChapterLockStatusSheet,
    TopicLockStatusSheet,
    CourseIntroModal,
  ],
})
export class CourseModule {}
