import { Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from '@core/services/guards/auth-guard.service';
import { CommunityComponent } from './community.component';


export const communityRoutes: Routes = [
    { path: 'community', component: CommunityComponent, canActivate: [AuthGuard]},
];


