import { Component, OnInit } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { PaymentService } from '@modules/payment/payment.service';

@Component({
  selector: 'im-mini-kits',
  templateUrl: './mini-kits.component.html',
  styleUrls: ['./mini-kits.component.scss'],
})
export class MiniKitsComponent implements OnInit {
  products: any = [];

  productList: any = [];

  processing: boolean = false;

  activeFilter = 'all';

  constructor(
    private http: HttpService,
    private paymentService: PaymentService
  ) {}

  ngOnInit() {
    this.processing = true;
    this.getProducts();
  }

  getProducts() {
    this.http
      .get(
        'na/mini-kits?order_by=order&order=asc&fields=id,name,icon_image,slug,properties,short_description'
      )
      .subscribe((res) => {
        this.products = res.data;
        this.productList = [...this.products];
        this.processing = false;
      });
  }

  buyNow(course: any) {
    course.packageType = 'course';
    const dialogRef = this.paymentService.initPayment(course);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.status === 'Success') {
        this.getProducts();
      }
    });
  }

  filetrList(searchTerm: any) {
    this.activeFilter = searchTerm;
    if (searchTerm == 'all') {
      this.productList = [...this.products];
    } else {
      this.productList = this.products.filter(
        (product) => product.properties.tag_line == searchTerm
      );
    }
  }
}
