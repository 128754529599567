import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuizQuestion } from '../model/QuizQuestion';
import { HttpService } from '../../../core/services/http.service';
import Header from '@editorjs/header'; 
import List from '@editorjs/list'; 
import RawTool from '@editorjs/raw';
import Checklist  from '@editorjs/checklist';
import Quote from '@editorjs/quote';
import ImageTool from '@editorjs/image';
import CodeTool from '@editorjs/code';
import LinkTool from '@editorjs/link';
import Marker from '@editorjs/marker';
import Warning from '@editorjs/warning';
import Paragraph from '@editorjs/paragraph';
import InlineCode from '@editorjs/inline-code';
import EditorJS from '@editorjs/editorjs';

@Component({
  selector: 'codelab-quiz-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
  @Input() answer: string;
  @Input() question: QuizQuestion;
  totalQuestions: number;
  allQuestions: QuizQuestion[];
  correctAnswersCount: number;
  percentage: number;
  completionTime: number;
  elapsedMinutes: number;
  elapsedSeconds: number;

  CONGRATULATIONS = 'assets/images/Congratulations.png';
  NOT_BAD = 'assets/images/Not_bad.png';
  TRY_AGAIN = 'assets/images/Try_again.png';

  course: any;
  module: any;
  chapter: any;
  topic: any;
  nextTopicData: any;
  quizId: any;
  stateData: any;
  
  constructor(private route: ActivatedRoute, private router: Router, private http: HttpService) {
    this.route.paramMap.subscribe(params => {
      this.stateData = history.state;
      this.quizId = atob(params.get('quizId'));
      if (!this.quizId && (!this.stateData || !this.stateData.course)) {
        this.router.navigate(['../intro'], {relativeTo: this.route});
      }
      this.init();
    });
    
  }

  init() {   
    if (this.stateData && this.stateData.course) {
        this.stateProcessing();
    } else if(this.quizId) {
       this.http.get('user_quiz/'+this.quizId+'?recursive=0').subscribe((res)=>{
            this.stateData = res.data.properties;
            this.stateData.course = res.data.course;
            this.stateData.module = res.data.module;
            this.stateData.chapter = res.data.chapter;
            this.stateData.topic = res.data.topic;
            console.log(this.stateData);
            this.stateProcessing();
       });

    }
  }

  stateProcessing() {
      this.totalQuestions = this.stateData.totalQuestions;
      this.correctAnswersCount = this.stateData.correctAnswersCount;
      this.completionTime = this.stateData.completionTime;
      this.allQuestions = this.stateData.allQuestions;
      this.course = this.stateData.course;
      this.module = this.stateData.module;
      this.chapter = this.stateData.chapter;
      this.topic = this.stateData.topic;
      this.nextTopicData = this.stateData.nextTopic?this.stateData.nextTopic: null;
  
      this.elapsedMinutes = Math.floor(this.completionTime / 60);
      this.elapsedSeconds = this.completionTime % 60;
      this.percentage = Math.round(100 * this.correctAnswersCount / this.totalQuestions);
      setTimeout((e)=>{
        this.allQuestions.forEach((item) => {
          console.log(item);
          this.questionRender(item.questionText, 'question'+item.questionId)
        })
      }, -1);
     
  }

  ngOnInit() {
    //this.init();
  }

  restart() {
    this.router.navigate(['../../intro'], {relativeTo: this.route});
  }

  backToTopic() {
    this.router.navigate(['/course', this.course.slug, this.module.slug, this.chapter.slug], {queryParams: {topic: this.topic.slug}});

  }

  nextTopic() {
    if(this.nextTopicData) {
        this.router.navigate(['/course', this.course.slug, this.nextTopicData.module.slug, this.nextTopicData.chapter.slug], {queryParams: {topic: this.nextTopicData.topic.slug}});
    } else {
      this.router.navigate(['/course', this.course.slug, this.module.slug, this.chapter.slug]);
    }
    
  }

  questionRender(questionText, holder) {
    new EditorJS({ data: questionText, readOnly: true, holder: holder,  tools:{
      header: Header, 
      list: List,
      paragraph: {
       class: Paragraph,
       inlineToolbar: true,
     },
      //image: SimpleImage,
      checklist: Checklist,
      
      quote: Quote,
      raw: RawTool,
      code: CodeTool,
      linkTool: LinkTool,
      marker: Marker,
      warning: Warning, 
      inlineCode: {
         class: InlineCode,
         shortcut: 'CMD+SHIFT+M',
      },
      image: {
        class: ImageTool
      } 
    },
    onReady: () => {  
      },
    });
  }
}
