import { Component, OnInit } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { PaymentService } from '@modules/payment/payment.service';

@Component({
  selector: 'im-free',
  templateUrl: './free.component.html',
  styleUrls: ['./free.component.scss'],
})
export class FreeComponent implements OnInit {
  coursesAll: any = [];
  courses: any = [];

  processing: boolean = false;

  activeFilter = 'all';

  constructor(
    private http: HttpService,
    private paymentService: PaymentService
  ) {}

  ngOnInit() {
    this.processing = true;
    this.getCourses();
  }

  getCourses() {
    this.http
      .get(
        "na/course?fields=id,name,icon,properties,short_description,slug,status,cover_image,group_course&filter=category eq 'videos'"
      )
      .subscribe((res) => {
        this.coursesAll = res.data;
        this.courses = res.data;
        this.processing = false;
      });
  }

  buyNow(course: any) {
    course.packageType = 'course';
    const dialogRef = this.paymentService.initPayment(course);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.status === 'Success') {
        this.getCourses();
      }
    });
  }

  upgrade(course: any) {
    course.packageType = 'course';
    const dialogRef = this.paymentService.initPayment(course, true);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.status === 'Success') {
        this.getCourses();
      }
    });
  }

  filetrList(searchTerm: any) {
    this.activeFilter = searchTerm;
    if (searchTerm == 'all') {
      this.courses = [...this.coursesAll];
    } else {
      this.courses = this.coursesAll.filter(
        (product) => product.properties.category == searchTerm
      );
    }
  }
}
