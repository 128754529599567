<div class="im-bar-coder">
  <h3
    class="ml-6 letter-spacing-10 font-semi-bold flex items-center"
    style="color: #8b4324"
  >
    <mat-icon class="mr-2 material-symbols-outlined">communities</mat-icon>
    Community
  </h3>
</div>
<div class="community-wrapper">
  <div class="flex justify-between mr-4">
    <div class="w-1/2 mt-2 pl-4 xs:w-full">
      <im-community-list></im-community-list>
    </div>
    <div class="w-1/4 xs:display-none">
      <im-leaderboard></im-leaderboard>
    </div>
    <div class="w-1/5 xs:display-none">
      <div class="shadow-1 border-radius-lg h-auto mt-4 flex">
        <img src="assets/images/DS_Bootcamp.png" class="border-radius-lg" />
      </div>
    </div>
  </div>
</div>
