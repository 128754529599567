// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseUrl: 'app.aitinkr.com',
  cookieUrl: 'aitinkr.com',
  ApiUrl: 'https://app.aitinkr.com/api/im/v1/',
  coderUrl: 'https://app.aitinkr.com/coding/',
  scratchUrl: 'https://blocks.schoolforai.com',
  zoomUrl: 'https://app.aitinkr.com/zoom/',
  storageUrl: 'https://app.aitinkr.com/static/',
  mysqlEditorUrl: 'https://app.aitinkr.com/sqlpad/',
  razorpay: 'rzp_live_R4dF8aAtZnqmGM',
  aiUrl: 'https://app.aitinkr.com/ai/',
  playerUrl: 'https://app.aitinkr.com/player/index.html',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
