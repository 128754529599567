 /**
 * @license
 * Copyright IM All Rights Reserved.
 *
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthAvatarComponent } from './auth-avatar.component';

@NgModule({
    declarations: [AuthAvatarComponent],
    imports: [
      CommonModule
    ],
    exports: [AuthAvatarComponent]
  })
export class AuthAvatarModule { }