import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { PageComponent } from './page/page.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuardService as AuthGuard } from '@core/services/guards/auth-guard.service';
import { UserCheckGuardService } from '@core/services/guards/user-check.service';

import { courseRoutes } from '@modules/course/routes';
import { profileRoutes } from '@modules/profile/routes';
import { scheduleRoutes } from '@modules/schedule/routes';
import { aiEditorRoutes } from '@modules/ai-editor/routes';
import { quizRoutes } from '@modules/quiz/routes';
import { noteRoutes } from '@modules/notes/routes';
import { communityRoutes } from '@modules/community/routes';

import { coderRoutes } from './coder/routes';
import { eventRoutes } from '@modules/event/routes';
import { NotFoundComponent } from '../../src/app/not-found/not-found.component';
import { PathResolveService } from '@core/services/path-resolve.service';

import { FeedbackComponent } from './feedback/feedback.component';
import { ContactComponent } from './contact/contact.component';
import { ScratchComponent } from './scratch/scratch.component';
import { CreateComponent } from './scratch/create/create.component';
import { ListComponent } from './scratch/list/list.component';
import { DetailsComponent } from './scratch/details/details.component';
import { ProductRedirectComponent } from './product-redirect/product-redirect.component';

const routes: Routes = [
  {
    path: 'feedback',
    component: FeedbackComponent,
  },
  { path: 'aitinker', redirectTo: 'our-offering', pathMatch: 'full' },
  { path: 'beatle', component: ProductRedirectComponent },
  {
    path: '',
    component: PageComponent,
    canActivate: [UserCheckGuardService],
    children: [
      // {
      //   path: "", component: DashboardComponent,
      // },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'contact',
        component: ContactComponent,
      },
      {
        path: 'scratch',
        component: ScratchComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'create',
            component: CreateComponent,
          },
          {
            path: '',
            component: ListComponent,
          },
          {
            path: 'beginner',
            component: ListComponent,
            data: { type: 'Beginner' },
          },
          {
            path: 'intermediate',
            component: ListComponent,
            data: { type: 'Intermediate' },
          },
          {
            path: 'advanced',
            component: ListComponent,
            data: { type: 'Advanced' },
          },
          {
            path: 'my-workspace',
            component: ListComponent,
            data: { type: 'user' },
          },
          {
            path: 'details/:id',
            component: DetailsComponent,
          },
        ],
      },
      ...courseRoutes,
      ...profileRoutes,
      ...scheduleRoutes,
      ...aiEditorRoutes,
      ...coderRoutes,
      ...quizRoutes,
      ...noteRoutes,
      ...communityRoutes,
      ...eventRoutes,
      {
        path: '**',
        resolve: {
          path: PathResolveService,
        },
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RoutesModule {}
