<div
  class="flex"
  style="
    background-color: #ffe9e3;
    color: var(--primary-color);
    align-items: center;
    padding: 0.5rem 4rem;
    justify-content: flex-end;
    font-weight: 600;
  "
>
  <div class="mr-8">
    Teach a machine using your camera, live in the browser. No coding required
  </div>
  <button
    mat-raised-button
    color="primary"
    routerLink="../create"
    style="
      text-transform: uppercase;
      letter-spacing: 1.5px;
      background-color: #2c3e50;
      margin-right: 1rem;
    "
  >
    Build Game
  </button>
</div>
<div
  class="flex flex-column px-8 py-4 games-list-wrapper overflow-y-auto"
  *ngIf="games.length > 0"
>
  <div class="main-blogs">
    <ng-container *ngFor="let game of games">
      <div
        class="main-blog anim cursor-pointer"
        style="--delay: 0.1s"
        [ngStyle]="{
          'background-image': 'url(' + game.cover_image_p + ')',
          'background-color': game.properties.color
        }"
        routerLink="../play/{{ game.slug }}"
      >
        <div class="main-blog__title">{{ game.name }}</div>
        <div class="main-blog__author">
          <div class="author-img__wrapper">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check"
            >
              <path d="M20 6L9 17l-5-5" />
            </svg>
            <img class="author-img" src="assets/images/icon1.png" />
          </div>
          <div class="author-detail">
            <div class="author-name">
              {{ game.is_system_game == 1 ? "SchoolForAI" : "" }}
            </div>
            <div class="author-info">
              <!-- {{ game.views }} views <span class="seperate"></span
              > -->
              {{ moment(game.created_at).fromNow() }}
            </div>
          </div>
        </div>
        <div class="main-blog__time">{{ game?.properties?.read_time }}</div>
      </div>
    </ng-container>
  </div>
</div>
