import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../../../core/services/http.service';
import { PaymentService } from '@modules/payment/payment.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@environment/environment';

import Header from '@editorjs/header';
import List from '@editorjs/list';
import RawTool from '@editorjs/raw';
import SimpleImage from '@editorjs/simple-image';
import Checklist from '@editorjs/checklist';
import Embed from '../../../core/ui/editor/embed';
import Quote from '@editorjs/quote';
import Table from '@editorjs/table';
import ImageTool from '@editorjs/image';
// import CodeTool from '../../../core/ui/editor/code';
import LinkTool from '@editorjs/link';
import Marker from '@editorjs/marker';
import Warning from '@editorjs/warning';
import Paragraph from '@editorjs/paragraph';
import InlineCode from '@editorjs/inline-code';
import EditorJS from '@editorjs/editorjs';
import AttachesTool from '../../../core/ui/editor/attaches';

import {
  MatBottomSheet,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { PictureVideoComponent } from '../../../themes/default/picture-video/picture-video.component';

@Component({
  selector: 'im-chapter-topic',
  templateUrl: './topic.component.html',
  styleUrls: [
    './topic.component.scss',
    '../../../core/ui/editor/attaches/index.scss',
    '../../../core/ui/editor/embed/index.scss',
  ],
})
export class TopicComponent implements OnInit {
  courseSlug: string;
  moduleSlug: string;
  chapterSlug: string;
  topicSlug: string;
  course: any;
  processing: boolean = false;
  processingTopics: boolean = true;
  module: any;
  chapter: any;
  chapterTopics: any = [];
  editor: any;
  practicalEditors: any = {};
  activeTopic: any;

  enableQuizButton: boolean = false;

  coursePurchased: boolean = false;

  chapterLoked: boolean = false;

  processActions: boolean = false;

  courseModules: any = [];

  activeCourseModuleId: number;

  activeModuleIndex = 0;

  practicals: any = [];

  selectedIndex = 0;

  selectedProjectIndex = 0;

  constructor(
    public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService,
    private paymentService: PaymentService,
    protected sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('slug')) {
      this.courseSlug = this.route.snapshot.paramMap.get('slug');
      this.moduleSlug = this.route.snapshot.paramMap.get('moduleSlug');
      this.topicSlug = this.route.snapshot.paramMap.get('topicSlug');
      if (
        this.route.snapshot.queryParamMap.get('t') &&
        this.route.snapshot.queryParamMap.get('t') == 'practical'
      ) {
        this.selectedIndex = 1;
      }
      if (this.route.snapshot.queryParamMap.get('p')) {
        let p = parseInt(this.route.snapshot.queryParamMap.get('p'));
        this.selectedProjectIndex = p - 1;
      }
      this.getCourse();
    }
  }

  getCourse() {
    this.processing = true;
    this.http
      .get('course/' + this.courseSlug + '?fields=id,name,properties,icon,slug')
      .subscribe(
        (res) => {
          this.course = res.data;
          if (this.course.user_course) {
            this.coursePurchased = true;
          }
          //this.course.cover_image = this.sanitizer.bypassSecurityTrustStyle(`linear-gradient(to bottom,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)), url(${this.course.cover_image})`);
          //this.processing = false;
          this.getModules();
          //this.getModule();
        },
        (err) => {
          this.processing = false;
        }
      );
  }

  getModules() {
    let uri = 'course/' + this.course.id + '/modules?nch=1';
    this.http.get(uri).subscribe(
      (res) => {
        this.courseModules = res.data;
        if (this.moduleSlug) {
          this.activeModuleIndex = this.courseModules.findIndex(
            (m) => m.slug == this.moduleSlug
          );
          console.log(this.activeModuleIndex);
          this.module = this.courseModules[this.activeModuleIndex];
          this.getChapterTopics();
          this.processing = false;
        } else {
        }
      },
      (err) => {
        this.processing = false;
      }
    );
  }

  getModule() {
    this.processing = true;
    this.http
      .get(
        'course_module/' +
          this.moduleSlug +
          '?fields=id,name,properties,slug,icon'
      )
      .subscribe(
        (res) => {
          this.module = res.data;
          this.getChapterTopics();
          this.processing = false;
        },
        (err) => {
          this.processing = false;
        }
      );
  }

  makeActiveTopic(topic: any, module: any) {
    this.module = module;
    this.enableQuizButton = false;
    this.processActions = false;
    this.activeTopic = topic;

    let uriQuery =
      'course_topic?recursive=0&filter="course_id eq ' +
      this.course.id +
      " and slug eq '" +
      this.activeTopic.slug +
      "' and course_module_id eq " +
      this.module.id;

    this.http.get(uriQuery).subscribe((res) => {
      this.activeTopic = res.data[0];
      this.practicalEditors = {};
      this.practicals = this.activeTopic.practicals;
      if (this.selectedIndex == 1) {
        this.selectedProjectIndex = 0;
        setTimeout(() => {
          this.practicalTabChange({ index: this.selectedProjectIndex });
        }, 100);
        this.router.navigate(
          ['../../../', this.module.slug, 'nch', this.activeTopic.slug],
          {
            relativeTo: this.route,
            queryParamsHandling: 'merge',
            queryParams: { p: this.selectedProjectIndex + 1 },
          }
        );
      } else {
        if (this.editor) {
          this.editor.render(this.activeTopic.description);
          this.processActions = true;
          this.router.navigate(
            ['../../../', this.module.slug, 'nch', this.activeTopic.slug],
            {
              relativeTo: this.route,
              queryParams: {},
            }
          );
          setTimeout(() => {
            document
              .querySelectorAll('.image-tool__image-picture')
              .forEach((element) => {
                element.addEventListener('click', ($event: any) => {
                  console.log($event);
                  const imagePopup = this._bottomSheet.open(ImageZoom, {
                    data: { source: $event.srcElement.currentSrc },
                  });
                });
              });
          }, 800);
        } else {
          this.processEditor();
        }
      }
    });
  }

  processEditorPractical(id, practical) {
    if (id in this.practicalEditors && this.practicalEditors[id]) {
      this.practicalEditors[id].clear();
      this.practicalEditors[id].render(practical.description);
      setTimeout(() => {
        document
          .querySelectorAll('.image-tool__image-picture')
          .forEach((element) => {
            element.addEventListener('click', ($event: any) => {
              console.log($event);
              const imagePopup = this._bottomSheet.open(ImageZoom, {
                data: { source: $event.srcElement.currentSrc },
              });
            });
          });
      }, 800);
      return false;
    }
    const that = this;
    const editorTools = {
      header: Header,
      list: List,
      paragraph: {
        class: Paragraph,
      },
      //image: SimpleImage,
      checklist: Checklist,
      embed: {
        class: Embed,
        config: {
          services: {
            youtube: true,
            vimeo: {
              regex:
                /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
              embedUrl: environment.playerUrl + '<%= remote_id %>',
              html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allow='autoplay;fullscreen' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
              height: 300,
              width: 600,
              id: ([id]) => {
                const params = [
                  'autoplay=false',
                  'autopause=true',
                  'muted=false',
                  'loop=false',
                  `url=https%3A%2F%2Fvimeo.com%2F${id}`,
                  'poster=',
                  'time=true',
                  'progressBar=true',
                  'overlay=true',
                  'muteButton=true',
                  'fullscreenButton=true',
                  'style=light',
                  'quality=auto',
                  'playButton=true',
                  'pip=true',
                ];
                return '?' + params.join('&');
              },
            },
            schoolforai: {
              regex:
                /https?:\/\/(?:s\.)?schoolforai.com\/static\/videos\/(\w+.(mp4))/,
              embedUrl:
                environment.playerUrl +
                '?autoplay=false&autopause=false&muted=false&loop=true&url=https%3A%2F%2Fdev.schoolforai.com%2Fstatic%2Fvideos%2F<%= remote_id %>&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true',
              html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
              height: 300,
              width: 600,
            },
          },
        },
      },
      attaches: {
        class: AttachesTool,
        config: {
          //   endpoint: environment.mediaUrl+'/upload',
          //   field: 'file[0]',
          //   additionalRequestData: {
          //     upload_path: '/content',
          //     custom_attrs: [],
          //     random_names: false,
          //     modify_output: true
          //   },
          //   additionalRequestHeaders: {
          //    Authorization: `Bearer ${this.authService.getToken()}`
          //  },
        },
      },
      quote: Quote,
      table: Table,
      raw: RawTool,
      code: (<any>window).editorjsCodeflask,
      linkTool: LinkTool,
      marker: Marker,
      warning: Warning,
      inlineCode: {
        class: InlineCode,
        shortcut: 'CMD+SHIFT+M',
      },
      image: {
        class: ImageTool,
      },
    };
    this.practicalEditors[id] = new EditorJS({
      holder: id,
      data: practical.description,
      readOnly: true,
      tools: {
        ...editorTools,
        twoColumns: {
          class: window['EditorJSLayout'].LayoutBlockTool,
          config: {
            EditorJS,
            editorJSConfig: {
              tools: editorTools,
              readOnly: true,
            },
            enableLayoutEditing: true,
            enableLayoutSaving: true,
            readOnly: true,
            initialData: {
              itemContent: {
                1: {
                  blocks: [],
                },
                2: {
                  blocks: [],
                },
              },
              layout: {
                type: 'container',
                id: '',
                className: '',
                style: 'border: none;display:flex; padding:0',
                children: [
                  {
                    type: 'item',
                    id: '',
                    className: '',
                    style: 'border: none; padding:0',
                    itemContentId: '1',
                  },
                  {
                    type: 'item',
                    id: '',
                    className: '',
                    style: 'border: none;',
                    itemContentId: '2',
                  },
                ],
              },
            },
          },
          shortcut: 'CMD+2',
          toolbox: {
            icon: `
                <svg xmlns='http://www.w3.org/2000/svg' width="16" height="16" viewBox='0 0 512 512'>
                  <rect x='128' y='128' width='336' height='336' rx='57' ry='57' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/>
                  <path d='M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/>
                </svg>
              `,
            title: '2 columns',
          },
        },
      },
      onChange: () => {},
      onReady: () => {
        var elements = document.querySelectorAll('[contenteditable=true]');
        elements.forEach((element) => {
          element.setAttribute('contenteditable', 'false');
        });
        setTimeout(() => {
          document
            .querySelectorAll('.image-tool__image-picture')
            .forEach((element) => {
              element.addEventListener('click', ($event: any) => {
                console.log($event);
                const imagePopup = this._bottomSheet.open(ImageZoom, {
                  data: { source: $event.srcElement.currentSrc },
                });
              });
            });
        }, 800);

        window.addEventListener('message', function (e) {
          if (e.data) {
            try {
              let data = JSON.parse(e.data);
              console.log(data);
              let iframe = document.querySelector(
                'iframe[src="' + data.url + '"]'
              );
              if (iframe) {
                if (data.ratio) {
                  iframe.parentElement.style.paddingTop = data.ratio + '%';
                } else if (data.event && data.event == 'pip') {
                  //data.player.pause();
                  const dialogRef = that.dialog.open(PictureVideoComponent, {
                    data: { url: data.url },
                    panelClass: 'pip-model',
                    disableClose: true,
                    hasBackdrop: false,
                  });
                }
              }
            } catch (error) {}
          }
        });
        this.processActions = true;
      },
    });
  }

  processEditor() {
    const that = this;
    const editorTools = {
      header: Header,
      list: List,
      paragraph: {
        class: Paragraph,
      },
      //image: SimpleImage,
      checklist: Checklist,
      embed: {
        class: Embed,
        config: {
          services: {
            youtube: true,
            vimeo: {
              regex:
                /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
              embedUrl: environment.playerUrl + '<%= remote_id %>',
              html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allow='autoplay;fullscreen' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
              height: 300,
              width: 600,
              id: ([id]) => {
                const params = [
                  'autoplay=false',
                  'autopause=true',
                  'muted=false',
                  'loop=false',
                  `url=https%3A%2F%2Fvimeo.com%2F${id}`,
                  'poster=',
                  'time=true',
                  'progressBar=true',
                  'overlay=true',
                  'muteButton=true',
                  'fullscreenButton=true',
                  'style=light',
                  'quality=auto',
                  'playButton=true',
                  'pip=true',
                ];
                return '?' + params.join('&');
              },
            },
            schoolforai: {
              regex:
                /https?:\/\/(?:s\.)?schoolforai.com\/static\/videos\/(\w+.(mp4))/,
              embedUrl:
                environment.playerUrl +
                '?autoplay=false&autopause=false&muted=false&loop=true&url=https%3A%2F%2Fdev.schoolforai.com%2Fstatic%2Fvideos%2F<%= remote_id %>&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true',
              html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
              height: 300,
              width: 600,
            },
          },
        },
      },
      attaches: {
        class: AttachesTool,
        config: {
          //   endpoint: environment.mediaUrl+'/upload',
          //   field: 'file[0]',
          //   additionalRequestData: {
          //     upload_path: '/content',
          //     custom_attrs: [],
          //     random_names: false,
          //     modify_output: true
          //   },
          //   additionalRequestHeaders: {
          //    Authorization: `Bearer ${this.authService.getToken()}`
          //  },
        },
      },
      quote: Quote,
      table: Table,
      raw: RawTool,
      code: (<any>window).editorjsCodeflask,
      linkTool: LinkTool,
      marker: Marker,
      warning: Warning,
      inlineCode: {
        class: InlineCode,
        shortcut: 'CMD+SHIFT+M',
      },
      image: {
        class: ImageTool,
      },
    };
    this.editor = new EditorJS({
      data: this.activeTopic.description,
      readOnly: true,
      tools: {
        ...editorTools,
        twoColumns: {
          class: window['EditorJSLayout'].LayoutBlockTool,
          config: {
            EditorJS,
            editorJSConfig: {
              tools: editorTools,
              readOnly: true,
            },
            enableLayoutEditing: true,
            enableLayoutSaving: true,
            readOnly: true,
            initialData: {
              itemContent: {
                1: {
                  blocks: [],
                },
                2: {
                  blocks: [],
                },
              },
              layout: {
                type: 'container',
                id: '',
                className: '',
                style: 'border: none;display:flex; padding:0',
                children: [
                  {
                    type: 'item',
                    id: '',
                    className: '',
                    style: 'border: none; padding:0',
                    itemContentId: '1',
                  },
                  {
                    type: 'item',
                    id: '',
                    className: '',
                    style: 'border: none;',
                    itemContentId: '2',
                  },
                ],
              },
            },
          },
          shortcut: 'CMD+2',
          toolbox: {
            icon: `
                <svg xmlns='http://www.w3.org/2000/svg' width="16" height="16" viewBox='0 0 512 512'>
                  <rect x='128' y='128' width='336' height='336' rx='57' ry='57' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/>
                  <path d='M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/>
                </svg>
              `,
            title: '2 columns',
          },
        },
      },
      onChange: () => {},
      onReady: () => {
        var elements = document.querySelectorAll('[contenteditable=true]');
        elements.forEach((element) => {
          element.setAttribute('contenteditable', 'false');
        });
        setTimeout(() => {
          document
            .querySelectorAll('.image-tool__image-picture')
            .forEach((element) => {
              element.addEventListener('click', ($event: any) => {
                console.log($event);
                const imagePopup = this._bottomSheet.open(ImageZoom, {
                  data: { source: $event.srcElement.currentSrc },
                });
              });
            });
        }, 800);
        window.addEventListener('message', function (e) {
          if (e.data) {
            try {
              let data = JSON.parse(e.data);
              console.log(data);
              let iframe = document.querySelector(
                'iframe[src="' + data.url + '"]'
              );
              if (iframe) {
                if (data.ratio) {
                  iframe.parentElement.style.paddingTop = data.ratio + '%';
                } else if (data.event && data.event == 'pip') {
                  //data.player.pause();
                  const dialogRef = that.dialog.open(PictureVideoComponent, {
                    data: { url: data.url },
                    panelClass: 'pip-model',
                    disableClose: true,
                    hasBackdrop: false,
                  });
                }
              }
            } catch (error) {}
          }
        });
        this.processActions = true;
      },
    });
  }

  checkQuizExist() {
    this.http
      .post('checkexist/course_questions', {
        refId: this.activeTopic.id,
        ref: 'ChapterTopic',
        status: 1,
      })
      .subscribe((res) => {
        if (res.data.exist) {
          this.enableQuizButton = true;
        }
        console.log(res);
      });
  }

  getChapterTopics() {
    this.processingTopics = true;
    const that = this;
    let uriQuery =
      'course_topic?recursive=0&filter="course_id eq ' +
      this.course.id +
      ' and order eq 1"';
    if (this.topicSlug) {
      uriQuery =
        'course_topic?recursive=0&filter="course_id eq ' +
        this.course.id +
        " and slug eq '" +
        this.topicSlug +
        "' and course_module_id eq " +
        this.module.id +
        '"';
    }

    this.http.get(uriQuery).subscribe(
      (res) => {
        this.activeTopic = res.data[0];
        this.practicals = this.activeTopic.practicals;
        this.activeCourseModuleId = this.activeTopic.course_module_id;
        this.activeModuleIndex = this.courseModules.findIndex(
          (m) => m.id == this.activeTopic.course_module_id
        );
        this.processingTopics = false;
        this.processing = false;
        //this.checkQuizExist();
        if (this.selectedIndex == 1) {
          setTimeout(() => {
            this.practicalTabChange({ index: this.selectedProjectIndex });
          }, 100);
        } else {
          setTimeout(() => {
            this.processEditor();
          }, -1);
        }
      },
      (err) => {
        this.processingTopics = false;
      }
    );
  }

  quiz() {
    this.router.navigate([
      '/quiz',
      this.course.slug,
      this.module.slug,
      this.chapter.slug,
      this.activeTopic.slug,
    ]);
  }

  next() {
    const currentIndex = this.module.chapter.findIndex(
      (item) => item.id == this.activeTopic.id
    );
    if (currentIndex == this.module.chapter.length - 1) {
      if (this.activeModuleIndex == this.courseModules.length - 1) {
      } else {
        const nextTopic =
          this.courseModules[this.activeModuleIndex + 1].chapter[0];
        this.module = this.courseModules[this.activeModuleIndex + 1];
        this.activeCourseModuleId = this.module.id;
        this.makeActiveTopic(nextTopic, this.module);
      }
    } else {
      const nextTopic = this.module.chapter[currentIndex + 1];
      this.makeActiveTopic(nextTopic, this.module);
    }
  }

  mainTabChange($event) {
    this.selectedIndex = $event.index;
    console.log($event);
    if ($event.index == 1) {
      this.processEditorPractical(
        'editorjs' + this.practicals[this.selectedProjectIndex].id,
        this.practicals[this.selectedProjectIndex]
      );
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { t: 'practical', p: this.selectedProjectIndex + 1 },
      });
    } else {
      if (this.editor) {
        this.editor.render(this.activeTopic.description);
        this.processActions = true;
      } else {
        setTimeout(() => {
          this.processEditor();
        }, -1);
      }
      this.router.navigate([], { relativeTo: this.route, queryParams: {} });
    }
  }

  practicalTabChange($event) {
    this.selectedProjectIndex = $event.index;
    this.processEditorPractical(
      'editorjs' + this.practicals[$event.index].id,
      this.practicals[$event.index]
    );

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { t: 'practical', p: $event.index + 1 },
    });
  }
}

@Component({
  selector: 'image-zoom',
  templateUrl: 'image-zoom.html',
})
export class ImageZoom {
  source: any = '';

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<ImageZoom>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data
  ) {
    this.source = this.data.source;
  }
}

// @Component({
//   selector: 'im-topic-lock',
//   templateUrl: 'topic.lock.html',
// })
// export class TopicLockStatusSheet {
//   constructor(private _bottomSheetRef: MatBottomSheetRef<TopicLockStatusSheet>) {}

//   buyNow(event: MouseEvent): void {
//     this._bottomSheetRef.dismiss({event: 'buyNow'});
//     event.preventDefault();
//   }

//   close(event: MouseEvent): void {
//     this._bottomSheetRef.dismiss({event: 'close'});
//     event.preventDefault();
//   }
// }
