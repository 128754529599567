<div
  class="flex flex-column items-center justify-center h-full"
  *ngIf="processingTopics"
>
  <mat-spinner strokeWidth="3"></mat-spinner>
</div>
<ng-container *ngIf="course && !processingTopics">
  <div
    class="grid has-12-columns has-1-rows justify-items-stretch h-screen column-gap-20 chapter-topic-view-section"
  >
    <div class="main column-start-1 row-span-1 column-end-8 xxl:column-end-8">
      <mat-tab-group
        dynamicHeight
        (selectedTabChange)="mainTabChange($event)"
        class="main-tab"
        [selectedIndex]="selectedIndex"
      >
        <mat-tab>
          <ng-template mat-tab-label>{{
            activeTopic?.properties?.title
              ? activeTopic?.properties?.title
              : "Theory"
          }}</ng-template>
          <div style="height: calc(100vh - 49px); overflow-y: auto">
            <div
              class="chapter-section im-content overflow-y-auto"
              [ngClass]="{ 'display-none': !activeTopic }"
            >
              <div id="editorjs"></div>
              <ng-container *ngIf="processActions">
                <div
                  class="flex justify-end pb-8 mr-8"
                  *ngIf="enableQuizButton"
                >
                  <button mat-raised-button color="primary" (click)="quiz()">
                    Go for Quiz
                  </button>
                </div>
                <div
                  class="flex justify-end pb-8 mr-8 mt-8"
                  *ngIf="!enableQuizButton"
                >
                  <button mat-raised-button color="primary" (click)="next()">
                    Next
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-tab>
        <ng-container *ngIf="practicals.length > 0">
          <mat-tab>
            <ng-template mat-tab-label>Practical</ng-template>
            <div class="practical-section">
              <mat-tab-group
                (selectedTabChange)="practicalTabChange($event)"
                [selectedIndex]="selectedProjectIndex"
              >
                <ng-container
                  *ngFor="let practical of practicals; let i = index"
                >
                  <mat-tab>
                    <ng-template mat-tab-label>
                      <span
                        class="mr-1 border px-1 font-semi-bold border-radius-md"
                        >P{{ i + 1 }}</span
                      >
                      {{ practical.name }}
                    </ng-template>
                    <div class="ml-5 mt-5 mr-5">
                      <h3 class="flex justify-between">
                        {{ practical.name }}
                        <span
                          class="border px-2 py-1 font-semi-bold font-sm mat-color-primary border-dashed border-radius-md"
                          >Project {{ i + 1 }}</span
                        >
                      </h3>
                      <div class="im-content chapter-section">
                        <div id="editorjs{{ practical.id }}"></div>
                      </div>
                    </div>
                  </mat-tab>
                </ng-container>
              </mat-tab-group>
            </div>
          </mat-tab>
        </ng-container>
      </mat-tab-group>

      <ng-container *ngIf="!activeTopic">
        <div class="chapter-section flex justify-center placeholder-section">
          <img src="assets/images/Placeholder.png" />
        </div>
      </ng-container>
    </div>

    <div
      class="topics-section column-start-9  row-span-1 column-end-12 mb-4 course{{
        course.id
      }}-section level{{
        course.id
      }} fixed h-screen overflow-hidden right-0 relative"
      *ngIf="!processing"
      [attr.data-level]="course.properties.level"
    >
      <button
        mat-icon-button
        class="back_button absolute shadow-2"
        routerLink="/mini-kit/{{ course.slug }}"
        style="top: 14px; background: #fff; left: 6px"
      >
        <mat-icon>keyboard_backspace</mat-icon>
      </button>
      <div class="ml-6 shadow-1">
        <div class="flex pt-4 pb-4 pl-4 course-title">
          <h2 class="flex justify-between items-center" style="width: 94%">
            <a routerLink="/mini-kit/{{ course.slug }}" class="ml-2">{{
              course.name
            }}</a>
            <span
              style="
                font-size: 0.9rem;
                letter-spacing: 3px;
                color: var(--primary-color);
                text-transform: uppercase;
              "
              >{{ course.properties.tag }}</span
            >
          </h2>
        </div>
        <div
          class="steps"
          style="height: calc(100vh - 62px); background: #fff; overflow-y: auto"
        >
          <mat-accordion multi="true">
            <mat-expansion-panel
              *ngFor="let module of courseModules; let i = index"
              [expanded]="module.id == activeCourseModuleId"
              style="border-radius: 0 !important"
              class="mat-elevation-z0"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ module.title }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ol>
                <li
                  *ngFor="let topic of module.topics"
                  [ngClass]="{ active: activeTopic?.id == topic.id }"
                >
                  <button
                    (click)="makeActiveTopic(topic, module)"
                    [disabled]="
                      !topic?.properties?.is_it_free &&
                      course.user_course == null
                    "
                  >
                    <span class="step">{{ topic.name }}</span>
                    <ng-container
                      *ngIf="topic?.user_course_topic?.is_completed == 1"
                    >
                      <span class="completed">
                        <mat-icon>done</mat-icon>
                      </span>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        !topic?.properties?.is_it_free &&
                        course.user_course == null
                      "
                    >
                      <span>
                        <mat-icon>lock_outline</mat-icon>
                      </span>
                    </ng-container>
                  </button>
                </li>
              </ol>
            </mat-expansion-panel>
          </mat-accordion>
          <!-- <ol>
                        <li *ngFor="let topic of chapterTopics" [ngClass]="{'active': (activeTopic.id == topic.id)}">
                            <button (click)="makeActiveTopic(topic)"
                                [disabled]="topic?.user_course_topic?.is_completed == 0 && topic?.user_course_topic?.status == 0">
                                <span class="step">{{topic.name}}</span>
                                <ng-container *ngIf="topic?.user_course_topic?.is_completed == 1">
                                    <span class="completed">
                                        <mat-icon>done</mat-icon>
                                    </span>
                                </ng-container>
                                <ng-container
                                    *ngIf="(topic?.user_course_topic?.is_completed == 0 && topic?.user_course_topic?.status == 0) || (!topic.user_course_topic && !chapter?.properties?.is_it_free && (!course?.access?.top.includes(topic.id)))">
                                    <span>
                                        <mat-icon>lock_outline</mat-icon>
                                    </span>
                                </ng-container>
                            </button>
                        </li>
                    </ol> -->
        </div>
      </div>
    </div>
  </div>
</ng-container>
