<div
  class=" 
    md:grid 
    has-12-columns 
    has-1-rows
    justify-items-stretch h-screen sm:overflow-y-auto im-bar-h-screen">

    <div class="column-start-1  row-span-1 column-end-6">

        <form [formGroup]="secondFormGroup" *ngIf="showForm">
    
            <div class="flex flex-column px-12 pt-10">
                <mat-form-field  class="w-3/5 xs:w-full" appearance="outline">
                  <mat-label>Current Password</mat-label>
                    <input matInput placeholder="" formControlName="currentPassword" required [type]="hide ? 'password' : 'text'"  >
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" type="button">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                      </button>

                      <mat-error *ngIf="secondFormGroup.get('currentPassword').hasError('required')" class="mb-2">
                        Password is <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="secondFormGroup.get('currentPassword').hasError('incorrect')">
                        Password is worng. Please try again
                      </mat-error>


                </mat-form-field>
        
                <mat-form-field class="w-3/5 xs:w-full" appearance="outline">
                    <mat-label>New Password</mat-label>
                      <input matInput placeholder="" formControlName="newPassword" required [type]="newHide ? 'password' : 'text'" >
                      <button mat-icon-button matSuffix (click)="newHide = !newHide" [attr.aria-label]="'Hide password'" type="button">
                        <mat-icon>{{newHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                      </button>
                      <mat-hint class="font-xs">Use at least 8 characters. Don’t use a password from another site, or something too obvious like your pet’s name.</mat-hint>
                      <mat-error *ngIf="secondFormGroup.get('newPassword').hasError('required')" class="mb-2">
                        New Password is <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="secondFormGroup.get('newPassword').hasError('incorrect')">
                        New password should not be the current password.
                      </mat-error>
                  </mat-form-field>
        
                  <mat-form-field class="w-3/5 mt-12 xs:w-full" appearance="outline">
                    <mat-label>Confirm New Password</mat-label>
                      <input matInput placeholder="" formControlName="confirmNewPassword" required [type]="confirmHide ? 'password' : 'text'" >
                      <button mat-icon-button matSuffix (click)="confirmHide = !confirmHide" [attr.aria-label]="'Hide password'" type="button">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                      </button>
                      <mat-error *ngIf="secondFormGroup.get('confirmNewPassword').hasError('required')" class="mb-2">
                        Confirm New Password is <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="secondFormGroup.get('confirmNewPassword').hasError('incorrect')">
                        Confirm New password didn't match with New Password.
                      </mat-error>
                  </mat-form-field>
               
        
                  <div class="flex justify-center flex-row w-3/5 py-4 xs:w-full">
                    <button mat-raised-button color="primary" class="letter-spacing-15" type="submit" (click)="process()">CHANGE PASSWORD</button>
                </div>
                
               
            </div>
        
            
          </form>

        </div>

        <div class="column-start-7  row-span-1 column-end-10 overflow-y-auto pt-10 content-section im-content xs:pl-12">

            <h3 class="mt-0"><strong>Meet password requirements</strong></h3>
            <p>Create your password using 12 characters or more. It can be any combination of letters, numbers, and symbols (ASCII-standard characters only). Accents and accented characters aren't supported.
            </p>
            <p>
                You can't use a password that:
            </p>
            <ul>
                <li>Is particularly weak. Example: "password123"</li>
                <li>You've used before on your account</li>
                <li>Starts or ends with a blank space</li>
            </ul>

        </div>


</div>

