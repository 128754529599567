import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../../../core/services/http.service';
import { PaymentService } from '@modules/payment/payment.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@environment/environment';
import moment from 'moment';

import Header from '@editorjs/header';
import List from '@editorjs/list';
import RawTool from '@editorjs/raw';
import SimpleImage from '@editorjs/simple-image';
import Checklist from '@editorjs/checklist';
import Embed from '../../../core/ui/editor/embed';
import Quote from '@editorjs/quote';
import Table from '@editorjs/table';
import ImageTool from '@editorjs/image';
// import CodeTool from '../../../core/ui/editor/code';
import LinkTool from '@editorjs/link';
import Marker from '@editorjs/marker';
import Warning from '@editorjs/warning';
import Paragraph from '@editorjs/paragraph';
import InlineCode from '@editorjs/inline-code';
import EditorJS from '@editorjs/editorjs';
import AttachesTool from '../../../core/ui/editor/attaches';
import { AuthService } from '@core/services/auth.service';

import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'im-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit, AfterViewInit {
  processing: boolean = false;
  product: any;

  slug = '';

  editor: any;

  products: any = [];

  productCodes: any = [];

  practicalEditors: any = {};

  vidoesEditors: any = {};

  selectedIndex = 0;

  selectedProjectIndex = 0;

  loginUser: any;

  showProductBanner = false;

  packages: any = [];

  schematicUrl: any;

  selfLearnVideos: any = [];

  selectedVideoIndex = 0;

  getthePackages: boolean = false;

  key: string = '';

  @ViewChild(MatDrawer, { static: false }) sidenav: MatDrawer;

  productTabs: any = {
    about: 0,
    schematic: 1,
    projects: 2,
    'self-learn': 3,
    'free-sessions': 4,
  };

  userProduct: any;

  selectedDay: any;

  todayDay: any;

  maxDay: any;

  errorProductKeyMessage = '';

  history: any = [];

  sidNavType = 1;

  timeSlots = {
    Wed: [
      {
        title: '5:00 PM',
        value: '17:00:00',
      },
    ],
    Sat: [
      {
        title: '5:00 PM',
        value: '17:00:00',
      },
    ],
  };

  activetimeSlot = [];

  selectedTimeSlot: any;

  userProductSessions: any = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService,
    private auth: AuthService,
    protected sanitizer: DomSanitizer
  ) {
    this.route.params.subscribe((params) => {
      if (params.slug) {
        this.getthePackages = false;
        this.slug = params.slug;
        this.getProduct();
        //this.getProducts();
      }
    });
  }

  ngOnInit(): void {
    this.loginUser = this.auth.loginUser;
    this.selectedDay = moment();
    this.todayDay = moment();
    this.maxDay = moment().add('days', 90);
    // if (this.route.snapshot.paramMap.get('slug')) {
    //   this.slug = this.route.snapshot.paramMap.get('slug');
    //   this.getProduct();
    // }
    // this.getProducts();
  }

  ngAfterViewInit(): void {}

  getProducts() {
    this.http.get('na/products?limit=4').subscribe((res) => {
      this.products = res.data;
      this.processing = false;
    });
  }

  getProduct() {
    this.processing = true;
    this.http.get('na/products/' + this.slug).subscribe(
      (res) => {
        this.product = res.data;
        if (this.loginUser) {
          this.isBotPurchased();
        }
        this.getPrice();
        this.showProductBanner = true;
        if (this.product.properties && this.product.properties.schematic) {
          this.schematicUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.product.properties.schematic
          );
        }
        let isTabSelected = this.route.snapshot.queryParamMap.get('t');
        if (isTabSelected) {
          this.mainTabChange({ index: this.productTabs[isTabSelected] });
        } else {
          setTimeout(() => {
            this.processEditor();
            let activationQuery = this.route.snapshot.queryParamMap.get('ac');
            if (activationQuery) {
              this.sidenav.open();
            }
          }, -1);
        }
      },
      (err) => {
        this.processing = false;
      }
    );
  }

  getPrice() {
    let filter =
      'refId eq ' + this.product.id + " and ref eq 'product' and status eq 1";
    this.http.get('na/packages?filter=' + filter).subscribe((res) => {
      this.packages = res.data;
      this.getthePackages = true;
    });
  }

  getProductCode() {
    this.http
      .get('product-codes?filter=product_id eq ' + this.product.id)
      .subscribe(
        (res) => {
          this.productCodes = res.data;
          this.practicalEditors = {};
          if (this.productCodes.length > 0) {
            let isTabSelected = this.route.snapshot.queryParamMap.get('p');
            if (isTabSelected) {
              this.selectedProjectIndex = parseInt(isTabSelected) - 1;
            } else {
              this.selectedProjectIndex = 0;
            }
            setTimeout(() => {
              this.processEditorPractical(
                'editorjs' + this.productCodes[this.selectedProjectIndex].id,
                this.productCodes[this.selectedProjectIndex]
              );
            }, 100);
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: { t: 'projects', p: this.selectedProjectIndex + 1 },
            });
          }
        },
        (err) => {
          this.processing = false;
        }
      );
  }

  getProductVideos() {
    this.http
      .get('product_videos?filter=product_id eq ' + this.product.id)
      .subscribe(
        (res) => {
          this.selfLearnVideos = res.data;
          this.vidoesEditors = {};
          if (this.selfLearnVideos.length > 0) {
            let isTabSelected = this.route.snapshot.queryParamMap.get('v');
            if (isTabSelected) {
              this.selectedVideoIndex = parseInt(isTabSelected) - 1;
            } else {
              this.selectedVideoIndex = 0;
            }
            //this.selectedVideoIndex = 0;
            setTimeout(() => {
              this.processEditorVideo(
                'videoEditorjs' +
                  this.selfLearnVideos[this.selectedVideoIndex].id,
                this.selfLearnVideos[this.selectedVideoIndex]
              );
            }, 100);
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: { t: 'self-learn', v: this.selectedVideoIndex + 1 },
            });
          }
        },
        (err) => {
          this.processing = false;
        }
      );
  }

  mainTabChange($event) {
    this.selectedIndex = $event.index;
    console.log($event);
    if ($event.index == 2) {
      this.showProductBanner = false;
      this.getProductCode();
    } else if ($event.index == 3) {
      this.showProductBanner = false;
      this.getProductVideos();
    } else if ($event.index == 4) {
      this.showProductBanner = false;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { t: 'free-sessions' },
      });
    } else if ($event.index == 0) {
      this.showProductBanner = true;
      if (this.editor && 'clear' in this.editor) {
        this.editor.clear();
        setTimeout(() => {
          this.editor.render(this.product.description);
        }, 100);
      } else {
        setTimeout(() => {
          this.processEditor();
        }, -1);
      }
      this.router.navigate([], { relativeTo: this.route, queryParams: {} });
    } else if ($event.index == 1) {
      this.showProductBanner = false;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { t: 'schematic' },
      });
    }
  }

  practicalTabChange($event) {
    this.selectedProjectIndex = $event.index;
    setTimeout(() => {
      this.processEditorPractical(
        'editorjs' + this.productCodes[$event.index].id,
        this.productCodes[$event.index]
      );
    }, -1);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { t: 'projects', p: $event.index + 1 },
    });
  }

  videoTabChange($event) {
    this.selectedVideoIndex = $event.index;
    setTimeout(() => {
      this.processEditorVideo(
        'videoEditorjs' + this.selfLearnVideos[$event.index].id,
        this.selfLearnVideos[$event.index]
      );
    }, -1);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { t: 'self-learn', v: $event.index + 1 },
    });
  }

  processEditorVideo(id, practical) {
    if (id in this.vidoesEditors && this.vidoesEditors[id]) {
      setTimeout(() => {
        if (document.getElementById(id)) {
          this.vidoesEditors[id].clear();
          this.vidoesEditors[id].render(practical.description);
        }
      }, 100);
      return false;
    }
    const that = this;
    const editorTools = {
      header: Header,
      list: List,
      paragraph: {
        class: Paragraph,
      },
      //image: SimpleImage,
      checklist: Checklist,
      embed: {
        class: Embed,
        config: {
          services: {
            youtube: true,
            vimeo: {
              regex:
                /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
              embedUrl: environment.playerUrl + '<%= remote_id %>',
              html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allow='autoplay;fullscreen' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
              height: 300,
              width: 600,
              id: ([id]) => {
                const params = [
                  'autoplay=false',
                  'autopause=true',
                  'muted=false',
                  'loop=false',
                  `url=https%3A%2F%2Fvimeo.com%2F${id}`,
                  'poster=',
                  'time=true',
                  'progressBar=true',
                  'overlay=true',
                  'muteButton=true',
                  'fullscreenButton=true',
                  'style=light',
                  'quality=auto',
                  'playButton=true',
                  'pip=true',
                ];
                return '?' + params.join('&');
              },
            },
            schoolforai: {
              regex:
                /https?:\/\/(?:s\.)?schoolforai.com\/static\/videos\/(\w+.(mp4))/,
              embedUrl:
                environment.playerUrl +
                '?autoplay=false&autopause=false&muted=false&loop=true&url=https%3A%2F%2Fdev.schoolforai.com%2Fstatic%2Fvideos%2F<%= remote_id %>&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true',
              html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
              height: 300,
              width: 600,
            },
          },
        },
      },
      attaches: {
        class: AttachesTool,
        config: {
          //   endpoint: environment.mediaUrl+'/upload',
          //   field: 'file[0]',
          //   additionalRequestData: {
          //     upload_path: '/content',
          //     custom_attrs: [],
          //     random_names: false,
          //     modify_output: true
          //   },
          //   additionalRequestHeaders: {
          //    Authorization: `Bearer ${this.authService.getToken()}`
          //  },
        },
      },
      quote: Quote,
      table: Table,
      raw: RawTool,
      code: (<any>window).editorjsCodeflask,
      linkTool: LinkTool,
      marker: Marker,
      warning: Warning,
      inlineCode: {
        class: InlineCode,
        shortcut: 'CMD+SHIFT+M',
      },
      image: {
        class: ImageTool,
      },
    };
    this.vidoesEditors[id] = new EditorJS({
      holder: id,
      data: practical.description,
      readOnly: true,
      tools: {
        ...editorTools,
        twoColumns: {
          class: window['EditorJSLayout'].LayoutBlockTool,
          config: {
            EditorJS,
            editorJSConfig: {
              tools: editorTools,
              readOnly: true,
            },
            enableLayoutEditing: true,
            enableLayoutSaving: true,
            readOnly: true,
            initialData: {
              itemContent: {
                1: {
                  blocks: [],
                },
                2: {
                  blocks: [],
                },
              },
              layout: {
                type: 'container',
                id: '',
                className: '',
                style: 'border: none;display:flex; padding:0',
                children: [
                  {
                    type: 'item',
                    id: '',
                    className: '',
                    style: 'border: none; padding:0',
                    itemContentId: '1',
                  },
                  {
                    type: 'item',
                    id: '',
                    className: '',
                    style: 'border: none;',
                    itemContentId: '2',
                  },
                ],
              },
            },
          },
          shortcut: 'CMD+2',
          toolbox: {
            icon: `
                <svg xmlns='http://www.w3.org/2000/svg' width="16" height="16" viewBox='0 0 512 512'>
                  <rect x='128' y='128' width='336' height='336' rx='57' ry='57' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/>
                  <path d='M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/>
                </svg>
              `,
            title: '2 columns',
          },
        },
      },
      onChange: () => {},
      onReady: () => {
        var elements = document.querySelectorAll('[contenteditable=true]');
        elements.forEach((element) => {
          element.setAttribute('contenteditable', 'false');
        });
        // setTimeout(()=>{
        //   document.querySelectorAll('.image-tool__image-picture').forEach(element => {
        //     element.addEventListener('click', ($event: any)=>{
        //           console.log($event);
        //           const imagePopup = this._bottomSheet.open(ImageZoom , {data: {source: $event.srcElement.currentSrc}});

        //     });
        //   });
        // }, 800)

        window.addEventListener('message', function (e) {
          if (e.data) {
            try {
              let data = JSON.parse(e.data);
              console.log(data);
              let iframe = document.querySelector(
                'iframe[src="' + data.url + '"]'
              );
              if (iframe) {
                if (data.ratio) {
                  iframe.parentElement.style.paddingTop = data.ratio + '%';
                } else if (data.event && data.event == 'pip') {
                  //data.player.pause();
                  // const dialogRef = that.dialog.open(PictureVideoComponent, {
                  //   data: { url: data.url },
                  //   panelClass: 'pip-model',
                  //   disableClose: true,
                  //   hasBackdrop: false,
                  // });
                }
              }
            } catch (error) {}
          }
        });
      },
    });
  }

  processEditorPractical(id, practical) {
    if (id in this.practicalEditors && this.practicalEditors[id]) {
      setTimeout(() => {
        if (document.getElementById(id)) {
          this.practicalEditors[id].clear();
          this.practicalEditors[id].render(practical.code);
        }
      }, 100);
      return false;
    }
    const that = this;
    const editorTools = {
      header: Header,
      list: List,
      paragraph: {
        class: Paragraph,
      },
      //image: SimpleImage,
      checklist: Checklist,
      embed: {
        class: Embed,
        config: {
          services: {
            youtube: true,
            vimeo: {
              regex:
                /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
              embedUrl: environment.playerUrl + '<%= remote_id %>',
              html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allow='autoplay;fullscreen' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
              height: 300,
              width: 600,
              id: ([id]) => {
                const params = [
                  'autoplay=false',
                  'autopause=true',
                  'muted=false',
                  'loop=false',
                  `url=https%3A%2F%2Fvimeo.com%2F${id}`,
                  'poster=',
                  'time=true',
                  'progressBar=true',
                  'overlay=true',
                  'muteButton=true',
                  'fullscreenButton=true',
                  'style=light',
                  'quality=auto',
                  'playButton=true',
                  'pip=true',
                ];
                return '?' + params.join('&');
              },
            },
            schoolforai: {
              regex:
                /https?:\/\/(?:s\.)?schoolforai.com\/static\/videos\/(\w+.(mp4))/,
              embedUrl:
                environment.playerUrl +
                '?autoplay=false&autopause=false&muted=false&loop=true&url=https%3A%2F%2Fdev.schoolforai.com%2Fstatic%2Fvideos%2F<%= remote_id %>&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true',
              html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
              height: 300,
              width: 600,
            },
          },
        },
      },
      attaches: {
        class: AttachesTool,
        config: {
          //   endpoint: environment.mediaUrl+'/upload',
          //   field: 'file[0]',
          //   additionalRequestData: {
          //     upload_path: '/content',
          //     custom_attrs: [],
          //     random_names: false,
          //     modify_output: true
          //   },
          //   additionalRequestHeaders: {
          //    Authorization: `Bearer ${this.authService.getToken()}`
          //  },
        },
      },
      quote: Quote,
      table: Table,
      raw: RawTool,
      code: (<any>window).editorjsCodeflask,
      linkTool: LinkTool,
      marker: Marker,
      warning: Warning,
      inlineCode: {
        class: InlineCode,
        shortcut: 'CMD+SHIFT+M',
      },
      image: {
        class: ImageTool,
      },
    };
    this.practicalEditors[id] = new EditorJS({
      holder: id,
      data: practical.code,
      readOnly: true,
      tools: {
        ...editorTools,
        twoColumns: {
          class: window['EditorJSLayout'].LayoutBlockTool,
          config: {
            EditorJS,
            editorJSConfig: {
              tools: editorTools,
              readOnly: true,
            },
            enableLayoutEditing: true,
            enableLayoutSaving: true,
            readOnly: true,
            initialData: {
              itemContent: {
                1: {
                  blocks: [],
                },
                2: {
                  blocks: [],
                },
              },
              layout: {
                type: 'container',
                id: '',
                className: '',
                style: 'border: none;display:flex; padding:0',
                children: [
                  {
                    type: 'item',
                    id: '',
                    className: '',
                    style: 'border: none; padding:0',
                    itemContentId: '1',
                  },
                  {
                    type: 'item',
                    id: '',
                    className: '',
                    style: 'border: none;',
                    itemContentId: '2',
                  },
                ],
              },
            },
          },
          shortcut: 'CMD+2',
          toolbox: {
            icon: `
                <svg xmlns='http://www.w3.org/2000/svg' width="16" height="16" viewBox='0 0 512 512'>
                  <rect x='128' y='128' width='336' height='336' rx='57' ry='57' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/>
                  <path d='M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/>
                </svg>
              `,
            title: '2 columns',
          },
        },
      },
      onChange: () => {},
      onReady: () => {
        var elements = document.querySelectorAll('[contenteditable=true]');
        elements.forEach((element) => {
          element.setAttribute('contenteditable', 'false');
        });
        // setTimeout(()=>{
        //   document.querySelectorAll('.image-tool__image-picture').forEach(element => {
        //     element.addEventListener('click', ($event: any)=>{
        //           console.log($event);
        //           const imagePopup = this._bottomSheet.open(ImageZoom , {data: {source: $event.srcElement.currentSrc}});

        //     });
        //   });
        // }, 800)

        window.addEventListener('message', function (e) {
          if (e.data) {
            try {
              let data = JSON.parse(e.data);
              console.log(data);
              let iframe = document.querySelector(
                'iframe[src="' + data.url + '"]'
              );
              if (iframe) {
                if (data.ratio) {
                  iframe.parentElement.style.paddingTop = data.ratio + '%';
                } else if (data.event && data.event == 'pip') {
                  //data.player.pause();
                  // const dialogRef = that.dialog.open(PictureVideoComponent, {
                  //   data: { url: data.url },
                  //   panelClass: 'pip-model',
                  //   disableClose: true,
                  //   hasBackdrop: false,
                  // });
                }
              }
            } catch (error) {}
          }
        });
      },
    });
  }

  processEditor() {
    const that = this;
    const editorTools = {
      header: Header,
      list: List,
      paragraph: {
        class: Paragraph,
      },
      //image: SimpleImage,
      checklist: Checklist,
      embed: {
        class: Embed,
        config: {
          services: {
            youtube: true,
            vimeo: {
              regex:
                /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
              embedUrl: environment.playerUrl + '<%= remote_id %>',
              html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allow='autoplay;fullscreen' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
              height: 300,
              width: 600,
              id: ([id]) => {
                const params = [
                  'autoplay=false',
                  'autopause=true',
                  'muted=false',
                  'loop=false',
                  `url=https%3A%2F%2Fvimeo.com%2F${id}`,
                  'poster=',
                  'time=true',
                  'progressBar=true',
                  'overlay=true',
                  'muteButton=true',
                  'fullscreenButton=true',
                  'style=light',
                  'quality=auto',
                  'playButton=true',
                  'pip=true',
                ];
                return '?' + params.join('&');
              },
            },
            schoolforai: {
              regex:
                /https?:\/\/(?:s\.)?schoolforai.com\/static\/videos\/(\w+.(mp4))/,
              embedUrl:
                environment.playerUrl +
                '?autoplay=false&autopause=false&muted=false&loop=true&url=https%3A%2F%2Fdev.schoolforai.com%2Fstatic%2Fvideos%2F<%= remote_id %>&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true',
              html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
              height: 300,
              width: 600,
            },
          },
        },
      },
      attaches: {
        class: AttachesTool,
        config: {
          //   endpoint: environment.mediaUrl+'/upload',
          //   field: 'file[0]',
          //   additionalRequestData: {
          //     upload_path: '/content',
          //     custom_attrs: [],
          //     random_names: false,
          //     modify_output: true
          //   },
          //   additionalRequestHeaders: {
          //    Authorization: `Bearer ${this.authService.getToken()}`
          //  },
        },
      },
      quote: Quote,
      table: Table,
      raw: RawTool,
      code: (<any>window).editorjsCodeflask,
      linkTool: LinkTool,
      marker: Marker,
      warning: Warning,
      inlineCode: {
        class: InlineCode,
        shortcut: 'CMD+SHIFT+M',
      },
      image: {
        class: ImageTool,
      },
    };
    this.editor = new EditorJS({
      data: this.product.description,
      readOnly: true,
      tools: {
        ...editorTools,
        twoColumns: {
          class: window['EditorJSLayout'].LayoutBlockTool,
          config: {
            EditorJS,
            editorJSConfig: {
              tools: editorTools,
              readOnly: true,
            },
            enableLayoutEditing: true,
            enableLayoutSaving: true,
            readOnly: true,
            initialData: {
              itemContent: {
                1: {
                  blocks: [],
                },
                2: {
                  blocks: [],
                },
              },
              layout: {
                type: 'container',
                id: '',
                className: '',
                style: 'border: none;display:flex; padding:0',
                children: [
                  {
                    type: 'item',
                    id: '',
                    className: '',
                    style: 'border: none; padding:0',
                    itemContentId: '1',
                  },
                  {
                    type: 'item',
                    id: '',
                    className: '',
                    style: 'border: none;',
                    itemContentId: '2',
                  },
                ],
              },
            },
          },
          shortcut: 'CMD+2',
          toolbox: {
            icon: `
                <svg xmlns='http://www.w3.org/2000/svg' width="16" height="16" viewBox='0 0 512 512'>
                  <rect x='128' y='128' width='336' height='336' rx='57' ry='57' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/>
                  <path d='M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/>
                </svg>
              `,
            title: '2 columns',
          },
        },
      },
      onChange: () => {},
      onReady: () => {
        var elements = document.querySelectorAll('[contenteditable=true]');
        elements.forEach((element) => {
          element.setAttribute('contenteditable', 'false');
        });
        // setTimeout(()=>{
        //   document.querySelectorAll('.image-tool__image-picture').forEach(element => {
        //     element.addEventListener('click', ($event: any)=>{
        //           console.log($event);
        //           const imagePopup = this._bottomSheet.open(ImageZoom , {data: {source: $event.srcElement.currentSrc}});

        //     });
        //   });
        // }, 800)
        window.addEventListener('message', function (e) {
          if (e.data) {
            try {
              let data = JSON.parse(e.data);
              console.log(data);
              let iframe = document.querySelector(
                'iframe[src="' + data.url + '"]'
              );
              if (iframe) {
                if (data.ratio) {
                  iframe.parentElement.style.paddingTop = data.ratio + '%';
                } else if (data.event && data.event == 'pip') {
                  //data.player.pause();
                  // const dialogRef = that.dialog.open(PictureVideoComponent, {
                  //   data: { url: data.url },
                  //   panelClass: 'pip-model',
                  //   disableClose: true,
                  //   hasBackdrop: false,
                  // });
                }
              }
            } catch (error) {}
          }
        });
      },
    });
  }

  onKeyChange() {
    this.key = this.key.toUpperCase();
    this.key = this.key.replace(/-/g, '').slice(0, 19);
    const formattedKey = this.key.replace(/(\w{4})(?=\w)/g, '$1-');
    this.key = formattedKey;
  }

  validateActivationCode() {
    if (!this.key) return;
    this.http
      .post('user_products', {
        key: this.key,
        product_id: this.product.id,
      })
      .subscribe((res: any) => {
        if (res.success) {
          this.errorProductKeyMessage = '';
          window.location.reload();
        } else {
          this.key = '';
          this.errorProductKeyMessage = res.message;
        }
      });
  }
  isBotPurchased() {
    this.http
      .get(
        'user_products?filter=product_id eq ' +
          this.product.id +
          ' and status eq 1&limit=1'
      )
      .subscribe((res: any) => {
        this.history = res.data;
        if (res.data.length == 1) {
          this.userProduct = res.data[0];
          this.getProductSessionsData();
        }
      });
  }
  getProductSessionsData() {
    this.http
      .get('user_product_events?filter=product_id eq ' + this.product.id)
      .subscribe((res: any) => {
        this.userProductSessions = res.data;
      });
  }
  onCalendarSelectedChange($event: any) {
    this.selectedDay = $event;
    let key = $event.format('ddd');
    if (this.userProductSessions.length >= 2) {
      this.activetimeSlot = [];
    } else {
      this.activetimeSlot = this.timeSlots[key];
    }
    this.processing = true;
  }

  loginSuccess(user, drawer, sidnavClose = false) {
    this.loginUser = user;
    if (sidnavClose) {
      drawer.close();
      this.initiatePayment();
    }
  }

  loginInitiateForBuy(drawer) {
    this.sidNavType = 2;
    drawer.open();
  }

  initiatePayment() {
    window
      .open(
        'https://checkout.schoolforai.com/?d=aitinkr.com&type=product&refId=' +
          this.product.slug +
          '&c=' +
          this.auth.getToken(),
        '_blank'
      )
      .focus();
  }

  appointmentDateSelectionFilter(date: any) {
    return date.format('ddd') == 'Wed' || date.format('ddd') == 'Sat';
  }

  checkSlotAlreadyBooked(slot) {
    let that = this;
    if (this.userProductSessions.length == 0) return null;
    let exist = this.userProductSessions.find(
      (se) =>
        se.event_time == slot.value &&
        se.event_date == that.selectedDay.format('YYYY-MM-DD')
    );
    if (exist) {
      return true;
    } else {
      return null;
    }
  }

  bookSlot() {
    if (!this.selectedTimeSlot) return;
    if (this.userProductSessions.length >= 2) return;
    this.http
      .post('user_product_events', {
        product_id: this.product.id,
        event_date: this.selectedDay.format('YYYY-MM-DD'),
        event_time: this.selectedTimeSlot.value,
      })
      .subscribe((res: any) => {
        this.userProductSessions.push(res.data);
        this.selectedTimeSlot = '';
        this.activetimeSlot = [];
      });
  }
}
