<ng-container *ngIf="activeEvent">
    <img [src]="activeEvent.cover_image_p || 'assets/images/event-cover-image.jpg'" class="h-1/4 w-full cover-image"/> 
    <div class="px-8 py-4 event-details-popup" >
        <h3 class="font-xl course{{activeEvent.refId}}-text">{{activeEvent.name}}</h3>
        <p class="mt-1">{{activeEvent.description || activeEvent.short_description}}</p>
        <!-- <p class="mt-8">Participants</p> -->
        <ul class="mt-8 p-0">
            <li class="flex mb-5 align-items-center">
                <mat-icon class="mr-4 ">calendar_today</mat-icon>
                <div class="flex flex-column">
                    <p class="mb-1 font-semi-bold">{{activeEvent.event_start_date | date: "mediumDate" }}</p>
                    <p class="font-xs">Date</p>
                </div>    
            </li>
            <li class="flex mb-5 align-items-center">
                <mat-icon class="mr-4">schedule</mat-icon>
                <div class="flex flex-column">
                    <p class="mb-1 font-semi-bold">{{ (activeEvent.event_start_date+" "+activeEvent.event_start_time) | date: 'shortTime'}} - {{ (activeEvent.event_start_date+" "+activeEvent.event_end_time) | date: 'shortTime'}}</p>
                    <p class="font-xs">Time</p>
                </div>    
            </li>
            <li class="flex mb-4 align-items-center">
                <mat-icon class="mr-4">timer</mat-icon>
                <div class="flex flex-column">
                    <p class="mb-1 font-semi-bold">{{activeEvent.duration}}</p>
                    <p class="font-xs">Duration</p>
                </div>    
            </li>
        </ul>

        

    </div>
    <ng-container *ngIf="activeEvent.user_id">
        <ng-container *ngIf="joinStatus">
            <ng-container *ngIf="startEnableJoin">
                <button mat-raised-button color="primary" class="letter-spacing-15 text-uppercase mt-2 w-full absolute bottom-0 border-radius-none" style="height: 3.6rem;" (click)="join()" type="button" >JOIN  SESSION NOW</button>
            </ng-container>
            <ng-container *ngIf="!startEnableJoin">
                <button mat-raised-button color="course{{activeEvent.refId}}-button" class="letter-spacing-15 text-uppercase mt-2 w-full absolute bottom-0 border-radius-none" style="height: 3.6rem;opacity: 1;" type="button" disabled>JOIN SESSION</button>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!joinStatus">
            <button mat-raised-button color="course{{activeEvent.refId}}-button" class="letter-spacing-15 text-uppercase mt-2 w-full absolute bottom-0 border-radius-none" style="height: 3.6rem;opacity: 1;" disabled type="button">CLOSED</button>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="!activeEvent.user_id">
        <button mat-raised-button color="primary" class="letter-spacing-15 text-uppercase mt-2 w-full absolute bottom-0 border-radius-none" style="height: 3.6rem;" (click)="register()" type="button">REGISTER</button>
    </ng-container>
</ng-container>