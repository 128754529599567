<h1 mat-dialog-title>Create a Note</h1>
<div mat-dialog-content>
  <mat-form-field appearance="outline" hintLabel="Min 3 characters and Max 200 characters" class="w-full">
    <input matInput [(ngModel)]="data.name" #input maxlength="200" >
    <mat-hint align="end">{{input.value?.length || 0}}/200</mat-hint>
  </mat-form-field>
</div>
<div mat-dialog-actions class="mt-4 mb-2" align="end">
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="data.name" [disabled]="input.value.length < 3">Create</button>
</div>