<h2 class="px-4 pt-6 xs:px-4">My Schedule</h2>
<div class="flex im-weekly-wrapper-screen">
	<div class="h-full flex im-calendar flex-column left">
		<div class="btn-group btn-group pl-3 mt-4">
			<button class="tag" (click)="onCalendarSelectedChange(moment())">Today</button>
			<button class="tag" (click)="onCalendarSelectedChange(moment().add(1, 'days'))">Tomorrow</button>
			<button class="tag" (click)="onCalendarSelectedChange(moment().add(2, 'days'))">In 2 days</button>
		</div>
		<mat-calendar (selectedChange)="onCalendarSelectedChange($event)" [selected]="selectedDay" startView="month"
			class="w-full" [startAt]="selectedDay"></mat-calendar>
	</div>
	<div class="flex justify-center right">
		<ng-container *ngIf="processing">
			<div class="flex flex-column items-center justify-center relative" style="height: 60vh;">
				<mat-spinner strokeWidth="3"></mat-spinner> <img src="assets/images/icon.png" class="absolute"
					width="64">
			</div>
		</ng-container>
		<div class="md:grid 
        has-1-columns 
        column-gap-10 
        lg:column-gap-15 
        justify-items-stretch w-full" *ngIf="!processing && schedules.length">
			<div class="ml-4 mb-4 xs:mx-2 xs:w-auto events" *ngFor="let schedule of schedules" style="width: 90%;">
				<a (click)="eventDetails(schedule)" class="cursor-pointer">
					<mat-card class="mat-card xborder-radius-lg course{{schedule.refId}} event-item">
						<mat-card-title class="font-xl"> {{schedule.name}} </mat-card-title>
						<mat-card-content class="font-sm">
							<p class="text2">{{schedule.short_description}}</p>
							<p class="flex mt-2 timing">
								<mat-icon aria-hidden="false" aria-label="Schedule time" class="mr-2 font-base">
									schedule </mat-icon> {{ (schedule.event_start_date+" "+schedule.event_start_time) |
								date: 'shortTime'}} - {{ (schedule.event_start_date+" "+schedule.event_end_time) | date:
								'shortTime'}}
							</p>
						</mat-card-content>
					</mat-card>
				</a>
			</div>
		</div>
		<div class="md:grid 
        has-1-columns 
        justify-items-stretch" *ngIf="!schedules.length && !processing">
			<div class="empty-state flex items-center px-6 py-12 my-12 flex-column">
				<mat-icon class="border-radius-full">search</mat-icon>
				<p class="text-center mt-2 font-base font-normal color-secondary"> No Schedules found.
					<br />Please explore the courses and start learning.
				</p>
			</div>
		</div>
	</div>
</div>