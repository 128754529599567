<div class="teachable-machine-play shadow-1">
    <!-- <div class="header">
        <span class="title">Model Title</span>
    </div> -->
    <div class="run">
        <div id='webcam-container'></div>
        <div id='label-container'>
            <div class="prediction" *ngFor="let prediction of labelContainer">
                <label>{{prediction.label}}</label>
                <mat-progress-bar
                    class="example-margin"
                    mode="determinate"
                    [value]="prediction.value*100">
                </mat-progress-bar>
            </div>
        </div>    
    </div>
</div>