    <div class="flex">
        <div class="flex flex-column w-2/5 course-info mr-8">
            <img src= "assets/images/ML-Modules.png" height="200" style="height: 240px"/>
            <h3 class="mt-3 px-4 pt-4">ONE STEP CLOSER TO AI</h3>
            <p class="mt-2 px-4 pb-8 color-secondary font-xs">Experience our pedagogy and learn about project based learning through a fruitful interaction with our trainers to take informed decision.</p>
            
        </div>
        <div class="flex flex-column justify-center w-1/2 p-4">
            <form [formGroup]="secondFormGroup">

            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Name</mat-label>
                  <input matInput placeholder="" formControlName="name" required>
              </mat-form-field>
            
                <mat-form-field class="w-full" appearance="outline">
                  <mat-label>10-digit mobile number</mat-label>
                    <input matInput placeholder="" formControlName="mobile_number" required>
                </mat-form-field>
      
                  <mat-form-field class="w-full" appearance="outline">
                      <mat-label>Email</mat-label>
                        <input matInput placeholder="" formControlName="email" required type="email">
                    </mat-form-field> 
                    <div class="flex justify-end pt-6">
                        <button color="primary" mat-button mat-stroked-button (click)="save()" class="letter-spacing-15">BOOK FREE TRAIL</button>
                      </div>
                    </form>   
        </div>
           
      
    </div>
    
