import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IntroductionComponent } from './introduction/introduction.component';
import { QuestionComponent } from './question/question.component';
import { ResultsComponent } from './results/results.component';
import * as QuestionComponent2 from './question.component';

import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatChipsModule} from '@angular/material/chips';
import {MatSidenavModule} from '@angular/material/sidenav';


@NgModule({
    declarations: [IntroductionComponent,
        QuestionComponent,
        QuestionComponent2.QuestionComponent,
        ResultsComponent],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      MatCardModule,
      MatRadioModule,
      MatIconModule,
      MatButtonModule,
      MatProgressBarModule,
      MatChipsModule,
      MatSidenavModule  
    ],
    providers: [{
        provide: MAT_RADIO_DEFAULT_OPTIONS,
        useValue: { color: 'accent' },
      }],
    exports: [],
    entryComponents: [],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
      ]
  })
  export class QuizModule { }
  