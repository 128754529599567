import { Component, OnInit } from '@angular/core';
import { HttpService } from '@core/services/http.service';

@Component({
  selector: 'im-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  constructor(private http: HttpService) { }

  ngOnInit() {
  }

}
