import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'im-event-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss']
})
export class PayComponent implements OnInit {

  @Input('data') event;

  constructor() { }

  ngOnInit() {
  }

}
