import { Component, OnInit } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'im-free',
  templateUrl: './aitinker.component.html',
  styleUrls: ['./aitinker.component.scss'],
})
export class AiTinkerComponent implements OnInit {
  courses: any = [];

  processing: boolean = false;

  constructor(private http: HttpService, private auth: AuthService) {}

  ngOnInit() {
    this.processing = true;
    this.getCourses();
  }

  getCourses() {
    this.http
      .get(
        "na/course?fields=id,name,icon,properties,short_description,slug,status,cover_image,group_course&filter=category eq 'aitinker'"
      )
      .subscribe((res) => {
        this.courses = res.data;
        this.processing = false;
      });
  }

  buyNow(course: any) {
    window
      .open(
        'https://checkout.schoolforai.com/?d=aitinkr.com&type=course&refId=' +
          course.slug +
          '&c=' +
          this.auth.getToken(),
        '_blank'
      )
      .focus();
  }

  initiatePayment() {
    // if (!this.loginUser) {
    //   this.loginInitiateForBuy(this.sidenav);
    //   return false;
    // }
    // if (this.couponNumber) {
    //   window
    //     .open(
    //       'https://checkout.schoolforai.com/?d=aitinkr.com&type=course&refId=' +
    //         this.course.slug +
    //         '&coupon=' +
    //         this.couponNumber +
    //         '&pack=' +
    //         this.activePackage.id +
    //         '&c=' +
    //         this.auth.getToken(),
    //       '_blank'
    //     )
    //     .focus();
    // } else {
    //   window
    //     .open(
    //       'https://checkout.schoolforai.com/?d=aitinkr.com&type=course&refId=' +
    //         this.course.slug +
    //         '&pack=' +
    //         this.activePackage.id +
    //         '&c=' +
    //         this.auth.getToken(),
    //       '_blank'
    //     )
    //     .focus();
    // }
  }
}
