import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OnlineComponent } from '../online/online.component';
import { MatSidenav } from '@angular/material/sidenav';
import { HttpService } from '@core/services/http.service';
import { ScheduleService } from '../schedule.service'
import moment from 'moment';
import { AuthService } from '@core/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'im-schedule-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnChanges {

  @Input('activeEvent') activeEvent: any;

  @Input('drawer') drawer: MatSidenav;

  joinStatus: boolean = true;

  enrollStatus: boolean = false;

  startEnableJoin: boolean = false;

  meetingWindow: any;

  loginUser: any;

  constructor(private authService: AuthService, public dialog: MatDialog, public http: HttpService, private scheduleService: ScheduleService, private router: Router) { }

  ngOnInit() {
    this.loginUser = this.authService.loginUser;

  }

  ngOnChanges() {
    if (this.activeEvent) {
      if (moment().diff(moment(this.activeEvent.event_end_date + ' ' + this.activeEvent.event_end_time)) > 0) {
        this.joinStatus = false;
      } else {
        this.joinStatus = true;
      }
      console.log("Remaing Time: ", moment().diff(moment(this.activeEvent.event_start_date + ' ' + this.activeEvent.event_start_time)));
      if (moment().diff(moment(this.activeEvent.event_start_date + ' ' + this.activeEvent.event_start_time)) > -300000) {
        this.startEnableJoin = true;
      } else {
        this.startEnableJoin = false;
      }
    }
  }

  googleLink() {
    if (this.startEnableJoin) {
      if (this.meetingWindow == null || this.meetingWindow.closed) {
        this.meetingWindow = window.open(this.activeEvent.link, 'Google Meet - SchoolforAI', 'directories=0,titlebar=0,toolbar=0,location=0,status=0,width=650,height=350,scrollbars=1')
      } else {
        this.meetingWindow.focus();
      }
    }
  }

  join() {
    if (this.activeEvent.link.includes('meet.google.com')) {
      this.googleLink();
      this.drawer.close();
      return;
    } else {
      const dialogRef = this.dialog.open(OnlineComponent, {
        width: 'calc(100vw - 84px)',
        maxWidth: 'calc(100vw - 84px)',
        minHeight: '100vh',
        data: this.activeEvent,
        panelClass: 'online-section',
        disableClose: true,
        hasBackdrop: false,
      });
      this.drawer.close();
      // let role = 0;
      // if (this.loginUser.user_type == 'trainer') {
      //   role = 1;
      // }
      // const that = this;
      // this.http.post('extra/zoom/signature', { 'meeting_number': this.activeEvent.meetingId, role }).subscribe((res) => {
      //   let meetingConfig = {
      //     mn: that.activeEvent.meetingId,
      //     name: that.loginUser.name,
      //     role,
      //     signature: res.data.signature,
      //     apiKey: res.data.api_key,
      //     pwd: that.activeEvent.link_pwd,
      //     email: '',
      //     //lu: this.b64EncodeUnicode(this.document.location.href)
      //   }

      // const rootElement = document.getElementById('ZoomEmbeddedApp');
      // const zmClient: any = (<any>window).ZoomMtgEmbedded.createClient();

      // zmClient.init({
      //   debug: true,
      //   zoomAppRoot: rootElement,
      //   // assetPath: 'https://websdk.zoomdev.us/2.0.0/lib/av', //default
      //   webEndpoint: 'zoom.us',
      //   language: 'en-US',
      //   customize: {
      //     meetingInfo: ['topic'],
      //     toolbar: {
      //       buttons: [
      //         {
      //           text: 'CustomizeButton',
      //           className: 'CustomizeButton',
      //           onClick: () => {
      //             console.log('click Customer Button');
      //           }
      //         }
      //       ]
      //     }
      //   }
      // }).then((e) => {
      //   console.log('success', e);
      // }).catch((e) => {
      //   console.log('error', e);
      // });

      // // WebSDK Embedded join 
      // zmClient.join({
      //   apiKey: meetingConfig.apiKey,
      //   signature: meetingConfig.signature,
      //   meetingNumber: meetingConfig.mn,
      //   userName: meetingConfig.name,
      //   password: meetingConfig.pwd,
      //   userEmail: meetingConfig.email,
      // }).then((e) => {
      //   console.log('success', e);
      // }).catch((e) => {
      //   console.log('error', e);
      // });
      //this.drawer.close();

      //});

    }
  }

  register() {
    if (this.activeEvent.is_it_paid == 1) {
      this.drawer.close();
      this.router.navigate(['/event', this.activeEvent.slug]);
    } else {
      this.http.post("workshop", { workshop: this.activeEvent.id }).subscribe((res) => {
        this.scheduleService.dashboardRefresh.next({ refresh: true });
        this.drawer.close();
      });
    }
  }

}
