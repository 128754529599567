import { Injectable } from '@angular/core';
import { Subject  } from 'rxjs'; 

@Injectable({
  providedIn: 'root'
})
export class AiService {

  activeModel= new Subject<any>();

  constructor() { }
}
