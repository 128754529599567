<div class="im-bar-coder">
  <nav mat-tab-nav-bar>
    <a mat-tab-link *ngFor="let link of navLinks" [routerLink]="link.path" routerLinkActive #rla="routerLinkActive"
      [active]="rla.isActive" class="letter-spacing-15" [routerLinkActiveOptions]="{exact:
          true}">
      {{link.label}}
    </a>
  </nav>
  <img src="assets/images/logotag2.png" style="object-fit: contain;" width="160" alt="SchoolForAI Logo" class="mr-4">

</div>

<div class="overflow-y-auto display-block">
  <router-outlet></router-outlet>
</div>