<ng-container *ngIf="processing">
	<div class="flex flex-column items-center justify-center relative" style="height: 70vh;">
		<mat-spinner strokeWidth="3"></mat-spinner> <img src="assets/images/icon.png" class="absolute" width="64"> </div>
</ng-container>
<ng-container *ngIf="!processing">
	<ng-container *ngFor="let category of modelCategories">
    <ng-container *ngIf="category.ai_models.length">
      <h4 class="mx-8 mt-8 text-uppercase">{{category.name}}</h4>
      <div class="models-section flex mx-8 flex-wrap">
        <ng-container *ngFor="let model of category.ai_models">
          <div class="item">
            <mat-card class="mat-card border-radius-lg ">
              <mat-card-content>
                <div class="absolute right-10-px top-10-px">
                  <mat-chip-list aria-label="model tags">
                    <mat-chip color="primary" selected>{{model.properties.tags}}</mat-chip>
                  </mat-chip-list>
                </div>
                <div class="icon-info"> 
                  <div class="border-radius-full"><img [src]="model.icon_p" width="32" height="32" /></div>
                </div>
                <h3>{{model.name}}</h3>
                <p class="mt-1 line-clamp-3 color-secondary font-xs" style="height: 4.2em;">{{model.short_description}}</p>
                <button mat-mini-fab class="mt-3 float-right mat-elevation-z1" routerLink="../{{model.slug}}">
                  <mat-icon>east</mat-icon>
                </button>
              </mat-card-content>
            </mat-card>
          </div>
        </ng-container>
      </div>
      
    </ng-container>
		
	</ng-container>
</ng-container>