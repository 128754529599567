import {
  Component,
  OnInit,
  Inject,
  NgZone,
  Input,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '@core/services/http.service';
import { AuthService } from '@core/services/auth.service';
import { environment } from '@environment/environment';

import Header from '@editorjs/header';
import List from '@editorjs/list';
import RawTool from '@editorjs/raw';
import SimpleImage from '@editorjs/simple-image';
import Checklist from '@editorjs/checklist';
import Embed from '../../../core/ui/editor/embed';
import Quote from '@editorjs/quote';
import Table from '@editorjs/table';
import ImageTool from '@editorjs/image';
import CodeTool from '@editorjs/code';
import LinkTool from '@editorjs/link';
import Marker from '@editorjs/marker';
import Warning from '@editorjs/warning';
import Paragraph from '@editorjs/paragraph';
import InlineCode from '@editorjs/inline-code';
import EditorJS from '@editorjs/editorjs';
import AttachesTool from '../../../core/ui/editor/attaches';

import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'im-epackages',
  templateUrl: './epackages.component.html',
  styleUrls: ['./epackages.component.scss'],
})
export class EpackagesComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  activePackage: any;

  packages: any = {};

  loginUser: any;

  paymentStatus: String;

  paymentOrder: String;

  processing: boolean = false;

  currentStep = 0;

  isLinear = true;

  object = Object;

  checkingCoupon: boolean = false;

  couponInfoApply: any;

  packageCategoryTitles = {
    hybrid_package: 'Hybrid Packages',
    e_learning: 'E-learning Packages',
    others: 'Other Packages',
  };

  @Input() data: any = {};

  paymentData: any;

  couponNumber: string;

  isItFree: boolean = false;

  editor: any;

  sidNavType = 1;

  @ViewChild(MatDrawer, { static: false }) sidenav: MatDrawer;

  constructor(
    private _formBuilder: FormBuilder,
    private http: HttpService,
    private authService: AuthService,
    private _ngZone: NgZone
  ) {}

  ngOnInit() {
    this.loginUser = this.authService.loginUser;
    this.getPackages();
    this.processEditor();
  }

  getPackages() {
    this.processing = true;
    let filter =
      'refId eq ' + this.data.id + " and ref eq 'event' and status eq 1";
    if (this.data.isItUpgrade) {
      filter += ' and upgrade_package eq 1';
    } else {
      filter += ' and upgrade_package eq 0';
    }
    this.http.get('na/packages?filter=' + filter).subscribe((res) => {
      res.data.forEach((item) => {
        if (item.properties.category) {
          if (!this.packages[item.properties.category])
            this.packages[item.properties.category] = [];
          this.packages[item.properties.category].push(item);
        } else {
          if (!this.packages['other']) this.packages['other'] = [];
          this.packages['other'].push(item);
        }
      });
      //this.packages = res.data;
      if (Object.keys(this.packages).length > 0) {
        this.activePackage = res.data[0];
        this.selectPackage(this.activePackage);
      }
      this.processing = false;
    });
  }

  selectPackage(packageItem: any) {
    this.activePackage = packageItem;
    this.firstFormGroup.controls['activePackage'].setValue(packageItem);
  }

  processEditor() {
    const that = this;
    const editorTools = {
      header: Header,
      list: List,
      paragraph: {
        class: Paragraph,
      },
      //image: SimpleImage,
      checklist: Checklist,
      embed: {
        class: Embed,
        config: {
          services: {
            youtube: true,
            vimeo: {
              regex:
                /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
              embedUrl: environment.playerUrl + '<%= remote_id %>',
              html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allow='autoplay;fullscreen' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
              height: 300,
              width: 600,
              id: ([id]) => {
                const params = [
                  'autoplay=false',
                  'autopause=true',
                  'muted=false',
                  'loop=false',
                  `url=https%3A%2F%2Fvimeo.com%2F${id}`,
                  'poster=',
                  'time=true',
                  'progressBar=true',
                  'overlay=true',
                  'muteButton=true',
                  'fullscreenButton=true',
                  'style=light',
                  'quality=auto',
                  'playButton=true',
                  'pip=true',
                ];
                return '?' + params.join('&');
              },
            },
            schoolforai: {
              regex:
                /https?:\/\/(?:s\.)?schoolforai.com\/static\/videos\/(\w+.(mp4))/,
              embedUrl:
                environment.playerUrl +
                '?autoplay=false&autopause=false&muted=false&loop=true&url=https%3A%2F%2Fdev.schoolforai.com%2Fstatic%2Fvideos%2F<%= remote_id %>&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true',
              html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
              height: 300,
              width: 600,
            },
          },
        },
      },
      attaches: {
        class: AttachesTool,
        config: {
          //   endpoint: environment.mediaUrl+'/upload',
          //   field: 'file[0]',
          //   additionalRequestData: {
          //     upload_path: '/content',
          //     custom_attrs: [],
          //     random_names: false,
          //     modify_output: true
          //   },
          //   additionalRequestHeaders: {
          //    Authorization: `Bearer ${this.authService.getToken()}`
          //  },
        },
      },
      quote: Quote,
      table: Table,
      raw: RawTool,
      code: (<any>window).editorjsCodeflask,
      linkTool: LinkTool,
      marker: Marker,
      warning: Warning,
      inlineCode: {
        class: InlineCode,
        shortcut: 'CMD+SHIFT+M',
      },
      image: {
        class: ImageTool,
      },
    };
    this.editor = new EditorJS({
      data: this.data.intro,
      readOnly: true,
      tools: {
        ...editorTools,
        twoColumns: {
          class: window['EditorJSLayout'].LayoutBlockTool,
          config: {
            EditorJS,
            editorJSConfig: {
              tools: editorTools,
              readOnly: true,
            },
            enableLayoutEditing: true,
            enableLayoutSaving: true,
            readOnly: true,
            initialData: {
              itemContent: {
                1: {
                  blocks: [],
                },
                2: {
                  blocks: [],
                },
              },
              layout: {
                type: 'container',
                id: '',
                className: '',
                style: 'border: none;display:flex; padding:0',
                children: [
                  {
                    type: 'item',
                    id: '',
                    className: '',
                    style: 'border: none; padding:0',
                    itemContentId: '1',
                  },
                  {
                    type: 'item',
                    id: '',
                    className: '',
                    style: 'border: none;',
                    itemContentId: '2',
                  },
                ],
              },
            },
          },
          shortcut: 'CMD+2',
          toolbox: {
            icon: `
                <svg xmlns='http://www.w3.org/2000/svg' width="16" height="16" viewBox='0 0 512 512'>
                  <rect x='128' y='128' width='336' height='336' rx='57' ry='57' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/>
                  <path d='M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/>
                </svg>
              `,
            title: '2 columns',
          },
        },
      },
      onChange: () => {},
      onReady: () => {
        window.addEventListener('message', function (e) {
          if (e.data) {
            try {
              let data = JSON.parse(e.data);
              console.log(data);
              let iframe = document.querySelector(
                'iframe[src="' + data.url + '"]'
              );
              if (iframe) {
                if (data.ratio) {
                  iframe.parentElement.style.paddingTop = data.ratio + '%';
                } else if (data.event && data.event == 'pip') {
                  //data.player.pause();
                }
              }
            } catch (error) {}
          }
        });
      },
    });
  }

  loginSuccess(user, drawer, sidnavClose = false) {
    this.loginUser = user;
    if (sidnavClose) {
      drawer.close();
      this.initiatePayment();
    }
  }

  loginInitiateForBuy(drawer) {
    this.sidNavType = 2;
    drawer.open();
  }

  initiatePayment() {
    window
      .open(
        'https://checkout.schoolforai.com/?d=aitinkr.com&type=event&refId=' +
          this.data.slug +
          '&c=' +
          this.authService.getToken(),
        '_blank'
      )
      .focus();
  }
}
