<ng-container *ngIf="!process">
	<section [attr.disabled]="disabled? true : null">
		<div id="drop_zone" class="drop-zone" (drop)="dropHandler($event);" (dragover)="onDragOver($event)" (click)="galleryFile.click()">
			<input type="file" name="files[]" id="file" [attr.multiple]="field.multiple?'':null" style="display: none" #galleryFile (change)="uploadSource($event)" />	
		
			<ng-container *ngIf="!field.multiple && (previewImage || field.value)">
				<img [src]="previewImage || (storageUrl+field.upload_path+customPath+'/'+field.value)" class="exist-source">
				<div class="exist-source-change-wrapper"> <strong>Change Image</strong> or drag it here.</div>
			</ng-container>
			<ng-container *ngIf="(!previewImage && !field.value) || field.multiple">
					<p> <strong>Choose File<ng-container *ngIf="field.multiple">s</ng-container></strong> or drag it here.</p>
			</ng-container>
			
		</div>
		<div class="uploadedList" *ngIf="field.multiple && sourceList">
		<ul class="list-unstyled">
			<li *ngFor = "let list of sourceList; let i = index" class="flex justify-between p-2 items-center">
				<div class="flex items-center" *ngIf="!list?.uploading">
					<img [src]="storageUrl+field.upload_path+customPath+'/'+list.name" width="32" height="32" style="width: 32px;height: 32px;object-fit: cover;border-radius: 4px;border: 1px solid rgba(0, 0, 0, 0.04);">
					<span  class="ml-2">{{list.name}}</span>	
				</div>
				<div *ngIf="list?.uploading">
					<mat-progress-bar mode="buffer"></mat-progress-bar>
				</div>
				<button mat-icon-button aria-label="Delete image" type="button" (click)="removeImage(list, i)">
					<mat-icon>remove_circle</mat-icon>
				</button>
			</li>
		</ul>
		</div>
	</section>
</ng-container>

