<mat-card class="mat-elevation-z0 h-screen relative">
  <mat-card-header *ngIf="stateData && stateData.topic" class="flex items-center justify-between">
    <div class="flex items-center">
      <div mat-card-avatar class="header-image mr-4"><img [src]="stateData.course.icon_p" width="64"></div>
      <div class="mat-card-container flex flex-column">
        <mat-card-title>{{stateData.topic.name}}</mat-card-title>
        <mat-card-subtitle class="mb-0">
          {{stateData.module.name}}
        </mat-card-subtitle>
      </div>
    </div>
    <div class="course{{stateData.course.id}}-text">
      <mat-card-title>{{stateData.course.name}}</mat-card-title>
    </div>
  </mat-card-header>

  <mat-card-content>
    <section class="scoreboard">
      <div class="row">
        <div class="score">
          <span class="leader">Score</span>
          <span class="scoreboard">
            {{ correctAnswersCount }}/{{ totalQuestions }}
          </span>
        </div>
        <div class="badge" *ngIf="question && question.questionId <= totalQuestions">
          <span>Question {{ question.questionId }} of {{ totalQuestions }}</span>
        </div>
        <div class="time-left">
          <span class="leader">Time</span>
          <span class="scoreboard">
            <!-- 0:<span *ngIf="timeLeft < 10">0</span>{{ timeLeft }} -->
            {{displayTimer}}
          </span>
        </div>
      </div>
    </section>

    <section id="question"></section>

    <codelab-quiz-question [question]="question"></codelab-quiz-question>
  </mat-card-content>

  <mat-card-footer class="absolute bottom-0-em w-full left-0-em m-0">
    <section class="paging flex justify-center">
      <mat-card-actions class="p-3">
        <!-- <div class="previousQuestionNav" *ngIf="question && question.questionId !== 1">
          <button type="button" (click)="navigateToPreviousQuestion()">
            &laquo; Previous
          </button>
        </div> -->
        <div class="nextQuestionNav" *ngIf="question && question.questionId !== totalQuestions">
          <button type="button" [disabled]="disabled" (click)="navigateToNextQuestion()" mat-raised-button>
            Next &raquo;
          </button>
        </div>
        <!-- <div class="showScoreNav" *ngIf="question && question.questionId === totalQuestions && hasAnswer">
          <button type="button" (click)="navigateToResults()" mat-raised-button>
            Show Your Score
          </button>
        </div> -->
      </mat-card-actions>
    </section>

    <section class="progress-bar">
      <mat-progress-bar *ngIf="(progressValue >= 0 && progressValue <= 100) &&
                              question && question.questionId <= totalQuestions"
                      [value]="progressValue">
        <strong>{{ progressValue }}%</strong>
      </mat-progress-bar>
    </section>
  </mat-card-footer>
</mat-card>
