<mat-card *ngIf="topic" class="mx-4 xs:m-0 mt-4 relative overflow-y-auto" style="height: calc(100vh - 32px);">
   <section class="pb-4 flex justify-between">
    <div class="flex items-center">
      <div class="mat-card-container">
        <mat-card-title>{{topic.name}}</mat-card-title>
        <mat-card-subtitle class="mb-0">
          <span class="subtitle1"> {{course.name}}</span>, 
          <span class="subtitle2">{{module.name}}</span>, 
          <span class="subtitle2">{{chapter.title}}</span>
        </mat-card-subtitle>
      </div>
    </div>
    <div>
        <img [src]="course.icon_p" width="42" />
    </div>
  </section>

  <mat-card-content>
    <section class="results flex mt-4">
      <section class="flex flex-column mr-12">
        <section class="statistics">
          <h3 class="mb-2">RESULTS</h3>
  
          <div class="quiz-feedback">
            <div *ngIf="percentage >= 80">
              <img mat-card-image [src]="CONGRATULATIONS" alt="Photo of Congratulations">
              <h3>Great job! </h3>
            </div>
            <div *ngIf="percentage >= 60 && percentage < 80">
              <img mat-card-image [src]="NOT_BAD" alt="Photo of Not Bad">
              <h3>Not bad! </h3>
            </div>
            <div *ngIf="percentage < 60">
              <img mat-card-image [src]="TRY_AGAIN" alt="Photo of Try Again">
              <h3>Try again! </h3>
            </div>
            <span>You scored <strong>{{ correctAnswersCount }}</strong> out of <strong>{{ totalQuestions }}</strong> questions correctly.</span>
            <span>You completed the quiz in <strong>{{ elapsedMinutes }}</strong> minutes and <strong>{{ elapsedSeconds }}</strong> seconds.</span>  
            <span *ngIf="elapsedMinutes < 1">You scored <strong>{{ percentage }}%</strong> correctly (and quickly)!</span>
            <span *ngIf="elapsedMinutes > 1">You scored <strong>{{ percentage }}%</strong> correct.</span>
          </div>
        </section>
  
        <section class="return mt-6">
          <mat-card-actions>
            <button mat-raised-button type="button" disableRipple="false" (click)="restart()" color="warn">Restart Quiz</button>
    
            <button mat-raised-button type="button" disableRipple="false" (click)="backToTopic()" color="course{{course.id}}-button">Back to Topic</button>
            <ng-container *ngIf="nextTopicData">
              <button mat-raised-button type="button" disableRipple="false" (click)="nextTopic()" color="course{{course.id}}-button">Next Topic</button>
            </ng-container>
          </mat-card-actions>
        </section>
      </section>
     

      <section class="quizSummary">
        <details open>
          <summary>View a more detailed summary of your quiz</summary>

            <ng-container *ngFor="let question of allQuestions">
              <div class="quiz-summary-question">
                <div class="quiz-summary-field">
                  <span>
                    <span class="leader">Question #{{ question.questionId }}: </span>
                    <div id="question{{ question.questionId }}"></div>
                  </span>
                </div>
                <div class="quiz-summary-field">
                  <span>
                    <span class="leader">Your Answer: </span>
                    Option {{ question.selectedOption }} &mdash; {{ question.selectedOptionObject.optionText }}
                    <mat-icon class="correct" *ngIf="question.answer === question.selectedOption">done</mat-icon>
                    <mat-icon class="incorrect" *ngIf="question.answer !== question.selectedOption">clear</mat-icon>
                    <span *ngIf="question.selectedOption === ''"> (no answer provided)</span>
                  </span>
                </div>
                <div class="quiz-summary-field">
                  <span>
                    <span class="leader">Correct Answer: </span>
                    Option {{ question.answer }} &mdash; {{ question.answerObject.optionText }}
                  </span>
                </div>
                <!-- <div class="quiz-summary-field">
                  <span>
                    <span class="leader">Explanation: </span>
                    Option {{ question.answer }} was correct because {{ question.explanation }}.
                  </span>
                </div> -->
              </div>
            </ng-container>
        </details>
      </section>
    </section>

    

    <!-- <hr /> -->

    <!-- <section class="challenge-social">
      <h4>Challenge your friends!</h4>
      <div class="social-buttons">
        <a class="btn email"
           href="mailto:?subject=Try to beat my quiz score!&amp;body=I scored {{ percentage }}% on this awesome Angular quiz. Try to beat my score at {{ codelabUrl }}">Email a friend</a>
        <a class="btn twitter" target="_blank"
           href="https://www.twitter.com/share?text=I scored {{ percentage }}% on this awesome Angular quiz. Try to beat my score at &amp;hashtag=quiz&amp;url={{ codelabUrl }}">Tweet your score</a>
      </div>
    </section> -->
  </mat-card-content>
</mat-card>
