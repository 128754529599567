import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'im-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  navLinks = [
    // {
    //   path: '/our-offering',
    //   label: 'All',
    // },
    {
      path: '/our-offering',
      label: 'Stage Courses',
    },
    {
      path: '/our-offering/bot-based-courses',
      label: 'Bot-based Courses',
    },
    {
      path: '/our-offering/mini-kits',
      label: 'Mini Kits',
    },
    {
      path: '/our-offering/short-courses',
      label: 'Short Courses',
    },
  ];

  constructor() {}

  ngOnInit() {}
}
