import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute , Router} from '@angular/router';
import { HttpService } from '../../../core/services/http.service';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'im-auth-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  @Input() isItComonent = false;
  @Input() compEmail = '';
  @Output() resetCallback = new EventEmitter<any>();

  passwordFormControl = new FormControl('', [
    Validators.required
  ]);	

  otp: any;

  hide: boolean = true;

  processing: boolean = false;

  email: string = '';

  otpConfig = {
    length : 4,
    allowNumbersOnly: true,
    isPasswordInput: true
  }

  otpError: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpService, private authService: AuthService) { }

  ngOnInit() {
    if(!this.isItComonent) {
      if (this.route.snapshot.paramMap.get('email')) {
        this.email = atob(this.route.snapshot.paramMap.get('email'));
      }
    } else {
      this.email = this.compEmail;
    }
  }

  next() {
    if (this.otp && this.otp.length === this.otpConfig.length) {
      this.otpError = false;
      if (!this.passwordFormControl.valid) {
        return;
      }
      this.processing = true;
      this.http.post('login',{email_otp:this.otp, email: this.email, new_password: this.passwordFormControl.value}, {showErrorMessage: false}).subscribe((res)=>{
        if(res && res.success){
          this.authService.setToken(res.data.accessToken);
          if(this.isItComonent) {
              this.resetCallback.emit({next:'done'});
          } else {
            this.authService.redirectUrl();
          }
        } else {
          this.processing = false;
        }
      }, (err) => {
          this.processing = false;
      });
    } else {
      this.otpError = true;
    }
  }

  otpChange($event) {
    this.otp = $event;
    if (this.otp && this.otp.length === this.otpConfig.length) {
      this.otpError = false;
    }
  }
}
