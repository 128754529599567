<div class="flex flex-column border-radius-lg shadow-1 m-4 onboarding-section" *ngIf="!processing">
    <div class="flex items-center justify-between p-4 relative">
        <img src="assets/images/logotag2.png" style="width: 120px;object-fit: contain;">
        <a routerLink="/dashboard" class="letter-spacing-10 text-underline" style="position: absolute;right: 12px;top: 12px;">SKIP</a>
    </div>
    <div class="flex items-center justify-center flex-column">
        <h2>Available Courses</h2>
        <p>select one that applies to you</p>
    </div>
    <div class="py-0 px-4">
        <div class="grid has-3-columns column-gap-20 px-12 py-6 im-courses mt-4 mx-8">
            <ng-container *ngFor="let course of courses" >
                <mat-card class="relative p-3 mat-card shadow-2 border-radius-lg course{{course.id}}-text course-border mb-3 xxl:mb-8">
                    <mat-card-title class="font-xl mb-1 font-normal">
                        <div class="flex justify-between course-card-title course-card-title{{course.properties.level}}" [attr.data-level]="course.properties.level">
                          <a routerLink="/course/{{course.slug}}" class="flex items-center font-base"><img [src]="course.icon_p" width="32" class="mr-1 course-icon"/> {{course.name}}</a>
                          <!-- <ng-container *ngIf="!course.user_course">
                            <div *ngIf="course.properties && course.properties.childCourses" class="flex child-course">
                              <span *ngFor="let childCourse of course.properties.childCourses" class="flex items-center">
                                +&nbsp;<img [src]="childCourse.icon_p" width="24" height="24"/>
                              </span>
                            </div>
                          </ng-container> -->
                        </div>
                    </mat-card-title>
                    <mat-card-content>
                      <!-- <ng-container *ngIf="!course.user_course">
                        <div class="flex mb-1 mt-1">
                          <span class="font-semi-bold color-primary font-xs">{{course.properties.duration}} {{course.properties.durationUnit}}</span> 
                          <span class="dot-separator color-primary">.</span>
                          <span class="font-semi-bold color-primary font-xs">{{course.properties.sessions}} Sessions</span> 
                          <span class="dot-separator color-primary">.</span>
                          <span class="font-semi-bold color-primary font-xs">{{course.properties.projectSessions}} Hr. Project</span>
                        </div>
                      </ng-container> -->
                      <!-- <ng-container *ngIf="course.user_course">  -->
                        <div class="flex mb-1 items-center justify-evenly mt-4 mb-2">
                          <div class="flex flex-column items-center justify-center">
                            <span class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex" style="width: 32px;height: 32px;">{{course.properties.duration}}</span> 
                            <span class="color-primary font-xss mt-2">{{course.properties.durationUnit}}</span>
                          </div>
                          <div class="flex flex-column items-center justify-center">
                            <span class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex" style="width: 32px;height: 32px;">{{course.properties.sessions}}</span> 
                            <span class="color-primary font-xss mt-2">Sessions</span> 
                          </div>
                          <div class="flex flex-column items-center justify-center">
                            <span class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex" style="width: 32px;height: 32px;">{{course.properties.projectSessions}}</span>
                            <span class="color-primary font-xss mt-2">Hr. Project</span>
        
                          </div>
                        </div>
                     <!-- </ng-container> --> 
                      
                        <p class="font-xs color-primary line-clamp-2" *ngIf="!course.user_course">{{course.short_description}}</p>
                    </mat-card-content>
                    <mat-card-actions class="flex justify-between mat-card-actions" [ngClass]="{'flex-column': course.user_course}">
                      <ng-container *ngIf="!course.user_course">
                      <button mat-raised-button color="course{{course.id}}-button" class="letter-spacing-15 text-uppercase font-xss" routerLink="/course/{{course.slug}}" style="height: 32px;line-height: 32px;">Explore</button>
                      <ng-container *ngIf="subscriptionExist">
                        <button mat-stroked-button class="letter-spacing-15 text-uppercase font-xss" (click)="upgrade(course)" style="height: 32px;line-height: 32px;">Upgrade</button>
                      </ng-container>
                      <ng-container *ngIf="!subscriptionExist">
                        <button mat-stroked-button class="letter-spacing-15 text-uppercase font-xss" (click)="buyNow(course)" style="height: 32px;line-height: 32px;">Buy Now</button>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="course.user_course">
                      <div class="w-full">
                        <span class="float-right" style="margin-top: -1em;">0%</span>
                        <mat-progress-bar
                        class="mt-4 w-full"
                        color="course{{course.id}}"
                        mode="determinate"
                        value="0" style="bottom: 0.75em;"
                        ></mat-progress-bar>
                      </div>
                      <div class="w-full">
                        <button mat-mini-fab class="mt-3 float-right mat-elevation-z1" routerLink="/course/{{course.slug}}" style="background: #ffbd32;color: #565050;">
                          <mat-icon>east</mat-icon>
                        </button>
                      </div>
        
                    </ng-container>
                     
                    </mat-card-actions>
        
                </mat-card>
              </ng-container>
        
        </div>
    </div>
    

</div>