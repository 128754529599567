import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from './page/page.component';
import { HeaderComponent } from './includes/header/header.component';
import { FooterComponent } from './includes/footer/footer.component';
import { RoutesModule } from './routes';

import {
  DashboardComponent,
  VimeoModal,
} from './dashboard/dashboard.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';

import { PaymentModule } from '@modules/payment';
import { CourseModule } from '@modules/course';
import { ScheduleModule } from '@modules/schedule';
import { AiEditor } from '@modules/ai-editor';
import { CoderModule } from './coder';
import { QuizModule } from '@modules/quiz';
import { NoteModule } from '@modules/notes';
import { CommunityModule } from '@modules/community';
import { FeedbackComponent } from './feedback/feedback.component';
import { ContactComponent } from './contact/contact.component';
import { EventModule } from '@modules/event';
import { ScratchComponent } from './scratch/scratch.component';
import { ListComponent } from './scratch/list/list.component';
import { DetailsComponent } from './scratch/details/details.component';
import { CreateComponent } from './scratch/create/create.component';

import { MatTooltipModule } from '@angular/material/tooltip';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductRedirectComponent } from './product-redirect/product-redirect.component';

@NgModule({
  declarations: [
    CreateComponent,
    VimeoModal,
    PageComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    FeedbackComponent,
    ContactComponent,
    ScratchComponent,
    ListComponent,
    DetailsComponent,
    ProductRedirectComponent,
  ],
  imports: [
    CommonModule,
    RoutesModule,
    MatCardModule,
    MatIconModule,
    MatSidenavModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    TextFieldModule,
    PaymentModule,
    MatInputModule,
    CourseModule,
    MatProgressBarModule,
    MatDialogModule,
    ScheduleModule,
    QuizModule,
    AiEditor,
    NoteModule,
    CommunityModule,
    MatProgressSpinnerModule,
    CoderModule,
    EventModule,
    MatListModule,
    MatTooltipModule,
    MatTabsModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  entryComponents: [VimeoModal, FeedbackComponent],
  bootstrap: [PageComponent],
})
export class DefaultModule {}
