import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { init } from '../machine';
import { AiService } from '../../ai.service';

@Component({
  selector: 'im-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(private aiService: AiService) { }

  ngOnInit() {
    this.aiService.activeModel.next('create-ai-game');
  }

  ngAfterViewInit() {
    init();
  }

  ngOnDestroy() {
    this.aiService.activeModel.next('');
    const videoObject = (<any>document.querySelector('.input__camera-video')).srcObject;
    if(videoObject) {
      videoObject.getTracks().forEach((track)=>{track.stop()});
    }
  }

}
