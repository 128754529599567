import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../../../core/services/http.service';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'im-auth-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  @Input() isItComonent = false;
  @Input() compEmail = '';
  @Output() passwordCallback = new EventEmitter<any>();

  passwordFormControl = new FormControl('', [Validators.required]);

  @ViewChild('passwordInput', { static: true })
  private passwordInput: ElementRef;

  processing: boolean = false;

  resetProcessing: boolean = false;

  hide: boolean = true;

  email: string = '';

  encryptedEmail: string = '';
  otp: any;

  otpConfig = {
    length: 4,
    allowNumbersOnly: true,
    isPasswordInput: true,
  };

  otpError: boolean = false;

  resendProcessing: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    if (!this.isItComonent) {
      if (this.route.snapshot.paramMap.get('email')) {
        this.encryptedEmail = this.route.snapshot.paramMap.get('email');
        this.email = atob(this.route.snapshot.paramMap.get('email'));
      } else {
        this.router.navigate(['auth', '']);
      }
    } else {
      this.email = this.compEmail;
    }
  }

  changeUser() {
    if (this.isItComonent) {
      this.passwordCallback.emit({ next: 'login' });
    } else {
      this.router.navigate(['auth/start']);
    }
  }

  public ngAfterViewInit(): void {
    // setTimeout(() => {
    //   this.passwordInput.nativeElement.focus();
    // }, 0)
  }

  otpChange($event) {
    this.otp = $event;
    if (this.otp && this.otp.length === this.otpConfig.length) {
      this.otpError = false;
    }
  }

  next() {
    if (this.passwordFormControl.valid) {
      if (this.processing) return;
      this.processing = true;
      this.http
        .post(
          'login',
          {
            email: this.email,
            password: this.passwordFormControl.value,
          },
          { showErrorMessage: false, showSuccessMessage: false }
        )
        .subscribe(
          (res) => {
            if (res && res.success) {
              this.authService.setToken(res.data.accessToken);
              if (this.isItComonent) {
                this.passwordCallback.emit({ next: 'done' });
              } else {
                this.authService.redirectUrl();
              }
            } else {
              this.passwordFormControl.markAsDirty();
              this.passwordFormControl.markAsTouched();
              this.passwordFormControl.setErrors({ incorrect: true });
              console.log(this.passwordFormControl);
              this.processing = false;
            }
          },
          (err) => {
            this.processing = false;
          }
        );
    }
  }

  resetPassword() {
    this.resetProcessing = true;
    this.http
      .post('reset', { email: this.email }, { showErrorMessage: true })
      .subscribe(
        (res) => {
          if (this.isItComonent) {
            this.passwordCallback.emit({ next: 'reset', email: this.email });
          } else {
            this.router.navigate(['auth/reset', btoa(this.email)]);
          }
        },
        (err) => {
          this.resetProcessing = false;
        }
      );
  }

  resendOtp() {
    if (this.resendProcessing) return;
    this.resendProcessing = true;
    this.http
      .post('exist/user', { email: this.email }, { showErrorMessage: false })
      .subscribe(
        (res) => {
          if (res && res.success) {
            this.router.navigate(['auth/password', btoa(this.email)]);
          } else {
            this.processing = false;
          }
        },
        (err) => {
          if (err.status === 404) {
            //this.router.navigate(['auth/verify', btoa(this.email)]);
          } else {
            this.processing = false;
          }
          this.resendProcessing = false;
        }
      );
  }
}
