<!-- <div class="flex flex-column items-center justify-center h-screen relative" *ngIf="!loginUser">
    <mat-spinner strokeWidth="3">
    </mat-spinner>
    <img src="assets/images/icon.png" class="absolute" width="64" alt="Processing"> 

  </div> -->
<div class="h-screen flex xs:flex-column">
  <div
    class="h-screen flex flex-column relative xs:h-auto xs:w-full xs:flex-row xs:fixed xs:bottom-0 z-10 im-side-nav justify-between xs:shadow-2"
  >
    <div class="xs:w-full">
      <div class="mb-2 mt-2 xs:w-1/5 logo-branding xs:display-none">
        <a routerLink="/" class="w-full text-center display-block">
          <img
            src="assets/images/SFAI_75px.png"
            alt="AITINKR Logo"
            width="50"
          />
        </a>
      </div>
      <nav
        class="flex flex-column items-center primary-navigation xs:flex-row xs:w-full"
      >
        <a
          class="flex flex-column text-none md:mb-2 md:border-left border-width-2 xs:border-top"
          [matTooltip]="link.label"
          [matTooltipPosition]="'after'"
          *ngFor="let link of navLinks"
          [routerLink]="link.path"
          routerLinkActive="active"
          #rla="routerLinkActive"
        >
          <img
            [src]="rla.isActive ? link.iconS : link.icon"
            alt="{{ link.label }}"
            class="mb-0"
            width="24"
            height="24"
          />
          <!-- {{link.label}} -->
        </a>
      </nav>
    </div>

    <div
      class="w-full md:flex justify-content-center flex-column align-items-center xs:display-none user-profile-nav pb-4"
      *ngIf="loginUser"
    >
      <a class="cursor-pointer profile-image" routerLink="/profile">
        <img
          [src]="loginUser.avatar"
          width="40"
          height="40"
          alt="User Avatar Image"
        />
      </a>
      <a class="mt-6 cursor-pointer xs:display-none" routerLink="/profile/edit">
        <mat-icon class="material-icons-outlined">settings</mat-icon>
      </a>
      <a class="mt-6 cursor-pointer xs:display-none" (click)="logout()">
        <mat-icon>power_settings_new</mat-icon>
      </a>
    </div>
  </div>
  <div
    class="h-screen xs:w-full im-route-content"
    [ngClass]="{ 'xs:z-20': scQueryParam }"
  ></div>
</div>
