import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { HttpService } from '@core/services/http.service';

@Component({
  selector: 'im-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss']
})
export class CommunityComponent implements OnInit {
  loginUser: any;


  constructor(private authService: AuthService, private http: HttpService) { }

  ngOnInit() {
    this.loginUser = this.authService.loginUser;

  }

  
}
