<ng-container *ngIf="processing">
  <div class="flex flex-column items-center justify-center relative" style="height: 65vh;">
    <mat-spinner strokeWidth="3"></mat-spinner>
    <img src="assets/images/icon.png" class="absolute" width="64">

  </div>
</ng-container>

<div class=" 
    grid 
    has-12-columns 
    has-1-rows
    justify-items-stretch sm:overflow-y-auto im-bar-h-screen overflow-hidden"
  *ngIf="transactions.length && !processing">

  <div class="column-start-1  row-span-1 column-end-4 p-8 transaction-list xs:column-end-12 overflow-y-auto"
    style="height: calc(100vh - 110px)">

    <ng-container *ngFor="let transaction of transactions">
      <div class="p-4 border border-radius-lg item mb-4 flex justify-between cursor-pointer"
        (click)="active(transaction)">

        <div class="flex flex-column">
          <p class="font-xs sub-title">{{transaction.modified_at | date: 'medium'}}</p>
          <h3>{{transaction.package.course?.name}}</h3>
          <p class="font-sm"><span class="sub-title">Package: </span> {{transaction.package.name}}</p>
          <p class="font-sm"><span class="sub-title">Order Number: </span> {{transaction.order_number}}</p>

        </div>
        <div class="flex flex-column items-center justify-center" style="gap: 8px;">
          <p class="currency font-semi-bold">{{transaction.price | currency: 'INR':'symbol':'2.0-0'}}</p>
          <div class="border-radius-full failure-icon flex items-center justify-center"
            *ngIf="transaction.status === 'Canceled'">
            <div class="border-radius-full flex items-center justify-center">
              <mat-icon aria-hidden="false" aria-label="Canceled icon">close</mat-icon>
            </div>
          </div>
          <div class="border-radius-full flex items-center justify-center success-icon"
            *ngIf="transaction.status === 'Success'">
            <div class="border-radius-full flex items-center justify-center">
              <mat-icon aria-hidden="false" aria-label="Success icon">check</mat-icon>
            </div>
          </div>

        </div>


      </div>
    </ng-container>
  </div>

  <div class="column-start-5  row-span-1 column-end-12 p-8 active-transaction xs:column-end-12 overflow-y-auto"
    style="height: calc(100vh - 110px)">
    <ng-container *ngIf="activeTransaction">
      <div class="invoice-wrapper">
        <div class="invoice-top">
          <div class="w-full flex justify-between">
            <div class="w-2/5">
              <div class="invoice-top-left">
                <img src="assets/images/logotag2.png" class="img-responsive logo">
                <h3 class="ml-2">CEDURA Testsol Pvt. Ltd.</h3>
              </div>
            </div>
            <div class="w-2/5">
              <div class="invoice-top-right">
                <h4>INVOICE</h4>
                <h6>#{{activeTransaction.invoice_number}}</h6>
                <h3>{{activeTransaction.modified_at | date: 'medium'}}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="invoice-bottom ml-3">
          <div class="w-full flex justify-between">
            <ng-container *ngIf="activeTransaction.package.course">
              <div style="width: 6.66667%;">
                <img src="{{activeTransaction.package.course?.icon_p}}">
              </div>
              <div class="w-3/5 pl-4">
                <h2 class="service-title">{{activeTransaction.package.course?.name}}</h2>
                <div class="flex mt-1 course-more-info">
                  <span class="font-xss">{{activeTransaction.package.course?.properties.duration}}
                    {{activeTransaction.package.course?.properties.durationUnit}}</span>
                  <span class="dot-separator">.</span>
                  <span class="font-xss">{{activeTransaction.package.course?.properties.sessions}} Sessions</span>
                  <span class="dot-separator">.</span>
                  <span class="font-xss">{{activeTransaction.package.course?.properties.projectSessions}}Hr.
                    Project</span>
                </div>
                <h5 class="service-subtitle mt-1">{{activeTransaction.package.course?.short_description}}</h5>
              </div>
            </ng-container>
            <div class="w-1/6">
              <h3 class="service-price mat-color-primary">{{activeTransaction.package.name}}</h3>
            </div>
            <div class="w-1/6">
              <h3 class="service-price mat-color-primary currency">{{activeTransaction.package.price | currency:
                'INR':'symbol':'2.0-0'}}</h3>
            </div>
          </div>
          <div class="w-full flex flex-column">
            <div class="flex mb-4">
              <div class="w-4/5">
                <h4 class="sub-total">SUB TOTAL</h4>
              </div>
              <div class="w-1/5">
                <h3 class="sub-total-price currency">{{activeTransaction.package.price | currency:
                  'INR':'symbol':'2.0-0'}}</h3>
              </div>
            </div>
            <div class="flex mb-4" *ngIf="activeTransaction.discounted_price">
              <div class="w-4/5">
                <h4 class="discount">DISCOUNT</h4>
              </div>
              <div class="w-1/5">
                <h3 class="discount-price currency">-{{activeTransaction.discounted_price | currency:
                  'INR':'symbol':'2.0-0'}}</h3>
              </div>
            </div>
            <div class="flex mb-6">
              <div class="w-4/5">
                <h3 class="total-due">TOTAL</h3>
              </div>
              <div class="w-1/5">
                <h3 class="total-due-price mat-color-primary currency">{{activeTransaction.price | currency:
                  'INR':'symbol':'2.0-0'}}</h3>
              </div>
            </div>

          </div>
        </div>
        <div class="footer">
          <div class="w-full flex ml-3">
            <div class="w-1/3">
              <div class="client-address">
                <h6>TO</h6>
                <h2>{{activeTransaction.user_billing?.name}}</h2>
                <h4>
                  {{activeTransaction.user_billing?.address}} <br>
                  {{activeTransaction.user_billing?.city_district_town}} <br>
                  {{activeTransaction.user_billing?.state}} - {{activeTransaction.user_billing?.pincode}} <br>
                  {{activeTransaction.user_billing?.mobile_number}}
                </h4>
              </div>
            </div>
            <div class="w-1/3">
              <div class="our-address">
                <h6>FROM</h6>
                <h2>CEDURA Testsol Pvt Ltd.</h2>
                <h4>
                  Hitech City Road <br>
                  Madhapur <br>
                  Hyderabad - 81 <br>
                  hello@schoolforai.com
                </h4>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="payment">
            <div class="row">
              <div class="col-xs-12">
                <h3 class="text-center">
                  <a href="#">PAY NOW</a>
                </h3>
              </div>
            </div>
          </div> -->
      </div>
    </ng-container>
  </div>


</div>

<div class="md:grid 
has-1-columns 
justify-items-stretch" *ngIf="!transactions.length && !processing">
  <div class="empty-state flex items-center px-6 py-12 my-12 flex-column">
    <mat-icon class="border-radius-full">search</mat-icon>
    <p class="text-center mt-2 font-base color-secondary">
      You didn't transactioned yet. <br />Please explore the by click on the below button.
    </p>
    <button mat-raised-button color="primary" class="mt-2" routerLink="/dashboard">Explore</button>
  </div>

</div>