<ng-container *ngFor="let category of apps">
<h4 class="mx-8 mt-8 text-uppercase">{{category.name}}</h4>
<div class="integration-items-section flex mx-8 flex-wrap">
    <ng-container *ngFor="let integration of category.children">
      <div class="item">
        <mat-card>
            <img mat-card-image [src]="integration.logo" alt="Zoom Authentication">
            <mat-card-content>
                <button mat-stroked-button color="primary" class="mt-3 letter-spacing-10" (click)="link(integration)">Link Account</button>
            </mat-card-content>
        </mat-card>
        </div>
    </ng-container>   
</div>       
</ng-container>  