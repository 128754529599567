import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs'; 

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  dashboardRefresh = new Subject<any>();

  openDetailsEvent = new Subject<any>();

  closeDetailsEvent = new Subject<any>();

  constructor() { }
}
