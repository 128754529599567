<ng-container *ngIf="!processing && courses.length == 0">
  <div class="empty-state flex items-center px-6 py-12 my-12 flex-column">
    <mat-icon class="border-radius-full">search</mat-icon>
    <p class="text-center mt-2 font-base color-secondary">
      You did not enroll for any courses yet. It need not be like that.
      <br />Please explore the available course and pick one.
    </p>
    <button
      mat-raised-button
      color="primary"
      class="mt-2"
      routerLink="/courses"
    >
      Explore
    </button>
  </div>
</ng-container>

<div
  class="courses-list-section flex mx-8 flex-wrap mt-6"
  *ngIf="!processing && courses.length > 0"
>
  <ng-container *ngFor="let course of courses">
    <div class="item">
      <ng-container *ngIf="course.course.category == 'aitinker'">
        <mat-card
          class="relative p-3 mat-card shadow-2 border-radius-lg course{{
            course.course.id
          }}-text course-border mb-3 xxl:mb-4"
        >
          <mat-card-title class="font-xl mb-1 font-normal">
            <div
              class="flex justify-between course-card-title course-card-title{{
                course.course.properties.level
              }}"
              [attr.data-level]="course.course.properties.level"
              [ngClass]="{
                'course-card-title-group': course.course.group_course
              }"
            >
              <a
                routerLink="/course/{{ course.course.slug }}"
                class="flex items-center font-base"
              >
                <div
                  class="shadow-1 border-radius-full mr-2"
                  style="width: 48px; height: 48px; background: azure"
                >
                  <img
                    [src]="course.course.icon_p"
                    width="48"
                    style="width: 48px; height: 48px"
                    class="course-icon"
                  />
                </div>
                {{ course.course.name }}</a
              >
              <!-- <ng-container *ngIf="!course.user_course">
                    <div *ngIf="course.properties && course.properties.childCourses" class="flex child-course">
                    <span *ngFor="let childCourse of course.properties.childCourses" class="flex items-center">
                        +&nbsp;<img [src]="childCourse.icon_p" width="24" height="24"/>
                    </span>
                    </div>
                </ng-container> -->
            </div>
          </mat-card-title>
          <mat-card-content>
            <!-- <ng-container *ngIf="!course.user_course">
                <div class="flex mb-1 mt-1">
                <span class="font-semi-bold color-primary font-xs">{{course.properties.duration}} {{course.properties.durationUnit}}</span> 
                <span class="dot-separator color-primary">.</span>
                <span class="font-semi-bold color-primary font-xs">{{course.properties.sessions}} Sessions</span> 
                <span class="dot-separator color-primary">.</span>
                <span class="font-semi-bold color-primary font-xs">{{course.properties.projectSessions}} Hr. Project</span>
                </div>
            </ng-container> -->
            <!-- <ng-container *ngIf="course.user_course">  -->
            <div class="flex mb-1 items-center justify-evenly mt-4 mb-2">
              <div class="flex flex-column items-center justify-center">
                <span
                  class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                  style="width: 32px; height: 32px"
                  >{{ course.course.properties.stage }}</span
                >
                <span
                  class="color-primary highlight-item-title mt-2 font-semi-bold"
                  >Stage</span
                >
              </div>
              <div class="flex flex-column items-center justify-center">
                <span
                  class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                  style="width: 32px; height: 32px"
                  >{{ course.course.properties.engagement }}</span
                >
                <span
                  class="color-primary highlight-item-title mt-2 font-semi-bold"
                  >Engagement</span
                >
              </div>
            </div>
            <!-- </ng-container> -->

            <!-- <p class="font-xs color-secondary line-clamp-2" *ngIf="!course.user_course">
                        {{course.course.short_description}}</p> -->
          </mat-card-content>
          <mat-card-actions
            class="flex justify-between mat-card-actions flex-column"
          >
            <div class="w-full mt-2">
              <span class="package_info opacity-background">{{
                course.package_properties?.name
              }}</span>
              <button
                mat-mini-fab
                class="float-right mat-elevation-z1"
                routerLink="/course/{{ course.course.slug }}"
                style="background: #ffbd32; color: #565050"
              >
                <mat-icon>east</mat-icon>
              </button>
            </div>
          </mat-card-actions>
        </mat-card>
      </ng-container>
      <ng-container *ngIf="course.course.category != 'aitinker'">
        <mat-card
          class="relative p-3 mat-card shadow-2 border-radius-lg course{{
            course.course.id
          }}-text course-border mb-3 xxl:mb-4"
        >
          <mat-card-title class="font-xl mb-1 font-normal">
            <div
              class="flex justify-between course-card-title course-card-title{{
                course.course.properties.level
              }}"
              [attr.data-level]="course.course.properties.level"
              [ngClass]="{
                'course-card-title-group': course.course.group_course
              }"
            >
              <a
                routerLink="/course/{{ course.course.slug }}"
                class="flex items-center font-base"
                ><img
                  [src]="course.course.icon_p"
                  width="32"
                  class="mr-1 course-icon"
                />
                {{ course.course.name }}</a
              >
              <!-- <ng-container *ngIf="!course.user_course">
                    <div *ngIf="course.properties && course.properties.childCourses" class="flex child-course">
                    <span *ngFor="let childCourse of course.properties.childCourses" class="flex items-center">
                        +&nbsp;<img [src]="childCourse.icon_p" width="24" height="24"/>
                    </span>
                    </div>
                </ng-container> -->
            </div>
          </mat-card-title>
          <mat-card-content>
            <!-- <ng-container *ngIf="!course.user_course">
                <div class="flex mb-1 mt-1">
                <span class="font-semi-bold color-primary font-xs">{{course.properties.duration}} {{course.properties.durationUnit}}</span> 
                <span class="dot-separator color-primary">.</span>
                <span class="font-semi-bold color-primary font-xs">{{course.properties.sessions}} Sessions</span> 
                <span class="dot-separator color-primary">.</span>
                <span class="font-semi-bold color-primary font-xs">{{course.properties.projectSessions}} Hr. Project</span>
                </div>
            </ng-container> -->
            <!-- <ng-container *ngIf="course.user_course">  -->
            <div class="flex mb-1 items-center justify-evenly mt-4 mb-2">
              <div class="flex flex-column items-center justify-center">
                <span
                  class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                  style="width: 32px; height: 32px"
                  >{{ course.course.properties.duration }}</span
                >
                <span
                  class="color-primary highlight-item-title mt-2 font-semi-bold"
                  >{{ course.course.properties.durationUnit }}</span
                >
              </div>
              <div class="flex flex-column items-center justify-center">
                <span
                  class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                  style="width: 32px; height: 32px"
                  >{{ course.course.properties.sessions }}</span
                >
                <span
                  class="color-primary highlight-item-title mt-2 font-semi-bold"
                  >Sessions</span
                >
              </div>
              <div class="flex flex-column items-center justify-center">
                <span
                  class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                  style="width: 32px; height: 32px"
                  >{{ course.course.properties.projectSessions }}</span
                >
                <span
                  class="color-primary highlight-item-title mt-2 font-semi-bold"
                  >Projects</span
                >
              </div>
            </div>
            <!-- </ng-container> -->

            <!-- <p class="font-xs color-secondary line-clamp-2" *ngIf="!course.user_course">
                        {{course.course.short_description}}</p> -->
          </mat-card-content>
          <mat-card-actions
            class="flex justify-between mat-card-actions flex-column"
          >
            <div class="w-full">
              <span class="float-right" style="margin-top: -1em">0%</span>
              <mat-progress-bar
                class="mt-4 w-full"
                color="course{{ course.id }}"
                mode="determinate"
                value="0"
                style="bottom: 0.75em"
              ></mat-progress-bar>
            </div>
            <div class="w-full mt-2">
              <span class="package_info opacity-background">{{
                course.package_properties?.name
              }}</span>
              <button
                mat-mini-fab
                class="float-right mat-elevation-z1"
                routerLink="/course/{{ course.course.slug }}"
                style="background: #ffbd32; color: #565050"
              >
                <mat-icon>east</mat-icon>
              </button>
            </div>
          </mat-card-actions>
        </mat-card>
      </ng-container>
    </div>
  </ng-container>
</div>
