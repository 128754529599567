<div class="ml-8 h-full">

    <div class="shadow-1 flex  border-radius-lg mb-8 p-4 flex-column">
        <h3>Courses</h3>
        <p style="color: rgba(0,0,0,.5);">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vel.</p>
        <!-- <div class="empty-state flex items-center px-6 flex-column justify-center" style="height: 150px;">
            <p class="text-center mt-2 font-xs color-secondary">
                You did not enroll for any courses yet. It need not be like that. <br />Please explore the available
                course and pick one.
            </p>
        </div> -->
        <section class="my-im-courses">

            <article class="card">
                <div class="card-inner">
                    <span class="card-pin"></span>
                    <div class="card-image">
                        <img src="https://assets.codepen.io/285131/illustration-hand-with-cigarette-icon.jpg" />
                    </div>
                    <div class="card-content">
                        <div class="card-meta">
                            <span class="card-meta-number">5 Modules</span>
                            <button class="card-meta-button">
                                <i class="ai-circle-triangle-right-fill"></i>
                            </button>
                        </div>
                        <h2 class="card-title">Course 1</h2>
                    </div>
                </div>
            </article>

            <article class="card">
                <div class="card-inner">
                    <span class="card-pin"></span>
                    <div class="card-image">
                        <img src="https://assets.codepen.io/285131/hand-drawn-monster-milkshake.jpg" />
                    </div>
                    <div class="card-content">
                        <div class="card-meta">
                            <span class="card-meta-number">5 Modules</span>
                            <button class="card-meta-button">
                                <i class="ai-circle-triangle-right-fill"></i>
                            </button>
                        </div>
                        <h2 class="card-title">Course 2</h2>
                    </div>
                </div>
            </article>
        </section>
    </div>

    <div class="shadow-1 flex  border-radius-lg mb-8 p-4 flex-column">
        <h4>Achievements</h4>
        <div class="empty-state flex items-center px-6 flex-column justify-center" style="height: 150px;">
            <p class="text-center mt-2 font-xs color-secondary">
                You did not enroll for any courses yet. It need not be like that. <br />Please explore the available
                course and pick one.
            </p>
        </div>
    </div>




</div>