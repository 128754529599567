<div class="teachable-machine-play shadow-1">
    <!-- <div class="header">
        <span class="title">Model Title</span>
    </div> -->
    <div class="run">
        <div class="audio_wave">
            <svg preserveAspectRatio="none" id="visualizer" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
    
                    <mask id="mask">
                        <g id="maskGroup">
                      </g>
                    </mask>
                    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" style="stop-color:#ff0a0a;stop-opacity:1" />
                        <stop offset="20%" style="stop-color:#f1ff0a;stop-opacity:1" />
                        <stop offset="90%" style="stop-color:#d923b9;stop-opacity:1" />
                        <stop offset="100%" style="stop-color:#050d61;stop-opacity:1" />
                    </linearGradient>
                </defs>
                <rect x="0" y="0" width="100%" height="100%" fill="url(#gradient)" mask="url(#mask)"></rect>
            </svg>
        </div>
        <div id='label-container'>
            <div class="prediction" *ngFor="let prediction of labelContainer">
                <label>{{prediction.label}}</label>
                <mat-progress-bar
                    class="example-margin"
                    mode="determinate"
                    [value]="prediction.value*100">
                </mat-progress-bar>
            </div>
        </div>    
    </div>
</div>