<ng-container *ngIf="!process">
  <mat-sidenav-container autosize>
    <mat-sidenav-content>
      <div
        class="md:grid has-12-columns has-1-rows justify-items-stretch h-screen sm:overflow-y-auto mobile-h-screen overflow-x-hidden"
      >
        <div
          class="main column-start-1 row-span-1 column-end-9 pb-0 md:column-end-12 xl:column-end-9"
        >
          <div class="px-4 xs:px-3">
            <div class="flex justify-between items-center my-4">
              <h2>
                <span class="font-normal">👋 Hello,</span> {{ loginUser?.name }}
              </h2>
              <img
                src="assets/images/logotag2.png"
                style="object-fit: contain"
                width="160"
                alt="AITINKR Logo"
              />
            </div>
            <im-trial></im-trial>
            <!-- <ng-container
              *ngIf="
                !subscriptionExist &&
                !freeTrailRequested &&
                loginUser.user_type == 'student'
              "
            >
              <im-trial></im-trial>
            </ng-container> -->
            <!-- <ng-container
              *ngIf="
                (subscriptionExist || freeTrailRequested) &&
                loginUser.user_type == 'student'
              "
            >
              <div class="mb-4">
                <div class="banner-1"></div>
              </div>
            </ng-container> -->

            <div class="grid has-4-columns column-gap-20 mb-4 xs:has-2-columns">
              <mat-card
                class="user-stats green mat-elevation-z0 cursor-pointer"
                routerLink="/profile/edit/my-courses"
              >
                <div class="flex flex-column">
                  <div>
                    <span class="float-right">
                      <a routerLink="/profile/edit/my-courses">
                        <mat-icon>east</mat-icon>
                      </a>
                    </span>
                  </div>
                  <span class="font-4xl number">{{ onGoingCourses }}</span>
                  <span>My Courses</span>
                </div>
              </mat-card>
              <mat-card
                class="user-stats yellow mat-elevation-z0 cursor-pointer"
                routerLink="/our-offering/bot-based-courses"
              >
                <div class="flex flex-column">
                  <div>
                    <span class="float-right">
                      <a routerLink="/our-offering/bot-based-courses">
                        <mat-icon>east</mat-icon>
                      </a>
                    </span>
                  </div>
                  <span class="font-4xl number">{{ onGoingBots }}</span>
                  <span>My Bots</span>
                </div>
              </mat-card>
              <!-- <mat-card
                class="user-stats blue mat-elevation-z0 cursor-pointer"
                routerLink="/courses/completed"
              >
                <div class="flex flex-column">
                  <div>
                    <span class="float-right">
                      <a routerLink="/courses/completed">
                        <mat-icon>east</mat-icon>
                      </a>
                    </span>
                  </div>
                  <span class="font-4xl number">0</span>
                  <span>Courses Completed</span>
                </div>
              </mat-card>
              <mat-card
                class="user-stats red mat-elevation-z0 cursor-pointer"
                routerLink="/courses/certificates"
              >
                <div class="flex flex-column">
                  <div>
                    <span class="float-right">
                      <a routerLink="/courses/certificates">
                        <mat-icon>east</mat-icon>
                      </a>
                    </span>
                  </div>
                  <span class="font-4xl number">0</span>
                  <span>Certificate earned</span>
                </div>
              </mat-card> -->
            </div>
            <div class="xs:display-none">
              <div
                class="flex free-stuff border-radius-lg shadow-1 p-4 mb-4 items-center"
                style="height: 10vh; background-color: white; font-weight: 500"
              >
                <div
                  class="w-1/4 flex items-center cursor-pointer"
                  (click)="openBigBang()"
                >
                  <img
                    src="assets/images/animation_200_kt0lmc5r.gif"
                    width="60"
                    style="width: 60px"
                  />
                  AI Big bang theory
                </div>
                <div
                  class="w-1/4 flex items-center cursor-pointer"
                  (click)="book417()"
                >
                  <img
                    src="assets/images/Booklet.png"
                    style="margin-right: 8px"
                  />
                  CBSE AI Course Books <br />(Code - 417)
                </div>
                <div
                  class="w-1/4 flex items-center cursor-pointer mr-4"
                  style="justify-content: end"
                >
                  <a
                    href="https://sfai.cc/0J"
                    target="_blank"
                    class="flex items-center"
                  >
                    <img src="assets/images/AI_Booklet.png" />
                    Booklet for Parents.
                  </a>
                </div>
                <div
                  class="w-1/4 flex items-center cursor-pointer mr-4"
                  style="justify-content: end"
                >
                  <a
                    href="https://app.schoolforai.com/books/?file=/static/schoolforai-aitinkr-brochure-latest.pdf"
                    target="_blank"
                    class="flex items-center"
                    style="margin-top: 10px"
                  >
                    <img
                      src="https://app.schoolforai.com/static/download_pdf.png"
                      style="width: 70px; margin-right: 8px; margin-top: -16px"
                    />
                    Brochure
                  </a>
                </div>
              </div>
            </div>
            <!-- <div class="flex flex-row items-center mb-4">
              <h2 class="mr-4">Available Courses</h2>
              <button
                mat-button
                color="primary"
                routerLink="/courses"
                style="letter-spacing: 1.5px; font-weight: 400"
                class="font-sm"
              >
                VIEW ALL
              </button>
            </div> -->
            <!-- <h2 class="pb-2">Courses</h2> -->
            <!-- <ng-container *ngIf="processing">
              <div
                class="flex flex-column items-center justify-center relative w-full mb-4"
                style="min-height: 175px"
              >
                <mat-spinner strokeWidth="3"></mat-spinner>
                <img
                  src="assets/images/icon.png"
                  class="absolute"
                  width="64"
                  alt="Processing"
                />
              </div>
            </ng-container> -->
            <div class="grid has-3-columns column-gap-20 xs:">
              <div class="flex flex-column column-span-3">
                <div class="flex flex-row items-center mb-4">
                  <h2 class="mr-4 font-lg">Available Courses:</h2>
                </div>
              </div>

              <!--
              <mat-card
                class="relative p-3 mat-card shadow-1 border-radius-lg course course-border mb-3 xxl:mb-4 cursor-pointer"
                routerLink="/courses/aitinker"
                style="background-color: azure"
              >
                <div class="flex w-full">
                  <div class="w-full">
                    <img src="assets/images/aitinker.png" />
                    <div
                      class="flex mt-1 mb-3 flex-wrap justify-center items-center px-8"
                    >
                      <div
                        class="shadow-1 border-radius-full p-2 mx-2 aitinker-stage-icons-wrapper"
                      >
                        <img
                          src="assets/images/aitinker_icons/icon1.png"
                          width="32"
                        />
                      </div>
                      <div
                        class="shadow-1 border-radius-full p-2 mx-2 aitinker-stage-icons-wrapper"
                      >
                        <img
                          src="assets/images/aitinker_icons/icon2.png"
                          width="32"
                        />
                      </div>
                      <div
                        class="shadow-1 border-radius-full p-2 mx-2 aitinker-stage-icons-wrapper"
                      >
                        <img
                          src="assets/images/aitinker_icons/icon3.png"
                          width="32"
                        />
                      </div>
                      <div
                        class="shadow-1 border-radius-full p-2 mx-2 aitinker-stage-icons-wrapper"
                      >
                        <img
                          src="assets/images/aitinker_icons/icon4.png"
                          width="32"
                        />
                      </div>
                      <div
                        class="shadow-1 border-radius-full p-2 mx-2 aitinker-stage-icons-wrapper"
                      >
                        <img
                          src="assets/images/aitinker_icons/icon5.png"
                          width="32"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="w-1/2">

                                    </div>
                </div>
                <div class="flex w-full items-center justify-center">
                  <button
                    mat-raised-button
                    color="primary"
                    class="letter-spacing-15 text-uppercase font-xss"
                    routerLink="/courses/aitinker"
                    style="height: 32px; line-height: 32px"
                  >
                    Explore
                  </button>
                </div>
              </mat-card> -->
            </div>
            <div class="grid has-3-columns column-gap-20 xs:has-1-columns">
              <mat-card
                class="relative p-3 mat-card shadow-1 border-radius-lg course mb-3 xxl:mb-4 cursor-pointer"
                routerLink="/our-offering/stage-courses"
                style="background-color: #1ab69d12"
              >
                <div>
                  <h2 class="font-base mb-1">Stage Courses</h2>
                  <div class="flex mb-1 items-center justify-evenly mt-4 mb-2">
                    <div class="flex flex-column items-center justify-center">
                      <span
                        class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                        style="width: 42px; height: 42px; background: #fff"
                        >5</span
                      >
                      <span
                        class="color-primary highlight-item-title mt-2 font-semi-bold"
                        >STAGES</span
                      >
                    </div>
                    <div class="flex flex-column items-center justify-center">
                      <span
                        class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                        style="width: 42px; height: 42px; background: #fff"
                        >9+</span
                      >
                      <span
                        class="color-primary highlight-item-title mt-2 font-semi-bold"
                        >Age</span
                      >
                    </div>
                    <div class="flex flex-column items-center justify-center">
                      <span
                        class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                        style="width: 42px; height: 42px; background: #fff"
                        >200+</span
                      >
                      <span
                        class="color-primary highlight-item-title mt-2 font-semi-bold"
                        >PROJECTS</span
                      >
                    </div>
                  </div>
                  <p class="font-xs color-secondary line-clamp-2 mt-2">
                    Launch your curiosity today and build products. A guided
                    learning program with Kits for Students of Grades 6 to 12.
                  </p>
                </div>
                <div class="flex w-full items-center justify-center mt-4">
                  <button
                    mat-raised-button
                    color="primary"
                    class="letter-spacing-15 text-uppercase font-xss"
                    routerLink="/our-offering/stage-courses"
                    style="height: 32px; line-height: 32px"
                  >
                    Explore
                  </button>
                </div>
              </mat-card>
              <mat-card
                class="relative p-3 mat-card shadow-1 border-radius-lg course mb-3 xxl:mb-4 cursor-pointer"
                routerLink="/our-offering/bot-based-courses"
                style="background-color: #ee4a6212"
              >
                <div>
                  <h2 class="font-base mb-1">Bot-based Courses</h2>
                  <div class="flex mb-1 items-center justify-evenly mt-4 mb-2">
                    <div class="flex flex-column items-center justify-center">
                      <span
                        class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                        style="width: 42px; height: 42px; background: #fff"
                        >15</span
                      >
                      <span
                        class="color-primary highlight-item-title mt-2 font-semi-bold"
                        >Bots</span
                      >
                    </div>
                    <div class="flex flex-column items-center justify-center">
                      <span
                        class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                        style="width: 42px; height: 42px; background: #fff"
                        >9+</span
                      >
                      <span
                        class="color-primary highlight-item-title mt-2 font-semi-bold"
                        >Age</span
                      >
                    </div>
                    <div class="flex flex-column items-center justify-center">
                      <span
                        class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                        style="width: 42px; height: 42px; background: #fff"
                        >200+</span
                      >
                      <span
                        class="color-primary highlight-item-title mt-2 font-semi-bold"
                        >PROJECTS</span
                      >
                    </div>
                  </div>
                  <p class="font-xs color-secondary line-clamp-2 mt-2">
                    Innovation at work and striving for excellence in the domain
                    of Embedded AI. Simple to Advanced products to support
                    individual learning and enable businesses to solve real-time
                    business problems.
                  </p>
                </div>
                <div class="flex w-full items-center justify-center mt-4">
                  <button
                    mat-raised-button
                    color="primary"
                    class="letter-spacing-15 text-uppercase font-xss"
                    routerLink="/our-offering/bot-based-courses"
                    style="height: 32px; line-height: 32px"
                  >
                    Explore
                  </button>
                </div>
              </mat-card>
              <mat-card
                class="relative p-3 mat-card shadow-1 border-radius-lg course mb-3 xxl:mb-4 cursor-pointer"
                routerLink="/our-offering/short-courses"
                style="background-color: #4664e412"
              >
                <div>
                  <h2 class="font-base mb-1">Short Courses</h2>
                  <div class="flex mb-1 items-center justify-evenly mt-4 mb-2">
                    <div class="flex flex-column items-center justify-center">
                      <span
                        class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                        style="width: 42px; height: 42px; background: #fff"
                        >4</span
                      >
                      <span
                        class="color-primary highlight-item-title mt-2 font-semi-bold"
                        >Must for All</span
                      >
                    </div>
                    <div class="flex flex-column items-center justify-center">
                      <span
                        class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                        style="width: 42px; height: 42px; background: #fff"
                        >3</span
                      >
                      <span
                        class="color-primary highlight-item-title mt-2 font-semi-bold"
                        >Age 10-13</span
                      >
                    </div>
                    <div class="flex flex-column items-center justify-center">
                      <span
                        class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                        style="width: 42px; height: 42px; background: #fff"
                        >4</span
                      >
                      <span
                        class="color-primary highlight-item-title mt-2 font-semi-bold"
                        >Age 13+</span
                      >
                    </div>
                  </div>
                  <p class="font-xs color-secondary line-clamp-2 mt-2">
                    AI is powerful and AI is future, irrespective of your career
                    choice. Hence, we have carefully designed courses to lead
                    the students step-by-step towards the ultimate goal of being
                    a AI-Practitioner.
                  </p>
                </div>
                <div class="flex w-full items-center justify-center mt-4">
                  <button
                    mat-raised-button
                    color="primary"
                    class="letter-spacing-15 text-uppercase font-xss"
                    routerLink="/our-offering/short-courses"
                    style="height: 32px; line-height: 32px"
                  >
                    Explore
                  </button>
                </div>
              </mat-card>
            </div>
          </div>
        </div>

        <div
          class="courses-section column-start-10 row-span-1 column-end-12 xs:display-none md:display-none xl:display-block"
        >
          <div class="py-4 pr-4">
            <mat-accordion multi="true">
              <!-- <mat-expansion-panel
                expanded="true"
                hideToggle
                class="today-schedule m-0 xs:mb-4 mb-6 mat-elevation-z0"
                disabled
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span class="font-medium font-sm"
                      >Your Upcoming Schedule</span
                    >
                  </mat-panel-title>
                  <mat-panel-description>

                  </mat-panel-description>
                </mat-expansion-panel-header>

                <ng-container *ngIf="schedulesProcessing">
                  <div
                    class="flex flex-column items-center justify-center h-full relative"
                  >
                    <mat-spinner strokeWidth="3"></mat-spinner>
                    <img
                      src="assets/images/icon.png"
                      class="absolute"
                      width="64"
                    />
                  </div>
                </ng-container>

                <div class="flex flex-column">
                  <div
                    *ngFor="let schedule of schedules"
                    class="flex mt-2 px-0 py-2 cursor-pointer items-center"
                    (click)="openEvent(schedule)"
                  >
                    <div
                      class="mr-4 course1-text course-border-top border-radius-lg shadow-1 flex items-center flex-column"
                      style="min-width: 3.2rem; border: 0"
                    >
                      <div
                        style="
                          font-size: 11px;
                          border-radius: 8px 8px 0 0;
                          background-color: currentColor;
                          width: 100%;
                        "
                        class="text-center"
                      >
                        <span style="color: #fff"
                          >{{ schedule.event_start_date | date : "MMM" }}
                          {{ schedule.event_start_date | date : "dd" }}
                        </span>
                      </div>
                      <div
                        class="flex flex-column items-center"
                        style="padding: 3px 2px 0 2px"
                      >
                        <p class="font-bolder text-center">
                          {{
                            schedule.event_start_date +
                              "
                                                    " +
                              schedule.event_start_time | date : "h:mm"
                          }}
                        </p>
                        <p class="font-xs time-prefix">
                          {{
                            schedule.event_start_date +
                              "
                                                    " +
                              schedule.event_start_time | date : "a"
                          }}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p class="font-medium">{{ schedule.name }}</p>
                      <p class="font-xs line-clamp-1 font-light mt-1">
                        {{ schedule.short_description }}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="empty-state flex items-center flex-column justify-center h-full"
                  *ngIf="!schedules.length && !schedulesProcessing"
                >
                  <mat-icon class="border-radius-full">search</mat-icon>
                  <p class="text-center mt-2 color-secondary">
                    No Schedules found. <br />Please explore the courses and
                    start learning.
                  </p>
                </div>
              </mat-expansion-panel> -->

              <!-- <mat-expansion-panel
                expanded="true"
                hideToggle
                class="start-practicing m-0 xs:display-none mb-2 mat-elevation-z0"
                disabled
              >
                <div class="start-practicing-empty">
                  <div class="start-practicing-empty-wrapper">
                    <div class="text-center mb-3" routerLink="/coder">
                      <img src="assets/images/1.webp" style="width: 65%" />
                      <h2 class="letter-spacing-15 text-uppercase">CODER</h2>
                      <p
                        class="font-sm mt-2 mx-4"
                        style="color: rgb(95 99 104 / 60%)"
                      >
                        Practice your coding skills in Python and MySQL
                        supported by code snippets
                      </p>
                    </div>
                    <button
                      mat-raised-button
                      color="primary"
                      class="letter-spacing-15 text-uppercase mb-3"
                      routerLink="/coder"
                    >
                      GO CODING
                    </button>
                  </div>
                </div>
              </mat-expansion-panel> -->

              <!-- <mat-expansion-panel expanded="true" hideToggle class="start-practicing m-0 xs:display-none" disabled>
                  <div class="start-practicing-empty ai">
                    <div class="start-practicing-empty-wrapper">
                      <div class="text-center mb-3">
                        <h2 class="letter-spacing-15 text-uppercase">AI Practice</h2>
                        <p class="font-sm mt-2 color-secondary-white">One of a kind AI practice modules in Data Science
                          and AI for skill building</p>
                      </div>
                      <button mat-raised-button color="primary" class="letter-spacing-15 text-uppercase"
                        routerLink="/ai-coder">START BUILDING</button>

                    </div>
                  </div>

                </mat-expansion-panel> -->

              <mat-expansion-panel
                expanded="true"
                hideToggle
                class="stage-wise-approach-to-ai m-0 xs:mb-4 mb-6 xs:display-none"
                disabled
                id="1"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span class="font-medium font-sm"
                      >Stage-wise approach to AI</span
                    >
                  </mat-panel-title>
                  <mat-panel-description> </mat-panel-description>
                </mat-expansion-panel-header>
                <div
                  class="border-radius-lg h-auto flex items-center justify-center"
                >
                  <img
                    src="assets/images/stage-1-path.png"
                    class="border-radius-lg"
                    style="width: 90%; object-fit: cover; object-position: top"
                  />
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                expanded="true"
                hideToggle
                class="start-practicing m-0 xs:display-none"
                disabled
                id="2"
              >
                <div class="border-radius-lg h-auto flex">
                  <img
                    src="assets/images/haas-1.png"
                    class="border-radius-lg"
                    style="width: 100%; object-fit: cover; object-position: top"
                  />
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
    <mat-sidenav #drawer mode="over" position="end" class="w-1/3">
      <ng-container *ngIf="activeEvent">
        <im-schedule-details
          [activeEvent]="activeEvent"
          [drawer]="drawer"
        ></im-schedule-details>
      </ng-container>
    </mat-sidenav>
  </mat-sidenav-container>
</ng-container>
