import { Component, OnInit } from '@angular/core';
import { environment } from '@environment/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'im-python',
  templateUrl: './python.component.html',
  styleUrls: ['./python.component.scss']
})
export class PythonComponent implements OnInit {

  coderUrl: any;


  constructor(protected sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.coderUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.coderUrl);
  }

}
