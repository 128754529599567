<mat-drawer-container hasBackdrop="false">
  <mat-drawer #drawer mode="over" position="end">
    <ng-container *ngIf="sidNavType == 1">
      <ng-container *ngIf="!loginUser">
        <div class="auth-wrapper-product xs:w-screen">
          <button
            mat-icon-button
            color="warn"
            class="absolute right-2-rem top-1-rem cursor-pointer"
            (click)="drawer.close()"
            style="z-index: 2"
          >
            <mat-icon>close</mat-icon>
          </button>
          <im-auth-comp
            isItComonent="true"
            (onSuccess)="loginSuccess($event, drawer, false)"
          ></im-auth-comp>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="sidNavType == 2">
      <div style="width: 33vw" class="auth-wrapper-product">
        <button
          mat-icon-button
          color="warn"
          class="absolute right-2-rem top-1-rem cursor-pointer"
          (click)="drawer.close()"
          style="z-index: 2"
        >
          <mat-icon>close</mat-icon>
        </button>
        <im-auth-comp
          isItComonent="true"
          (onSuccess)="loginSuccess($event, drawer, true)"
        ></im-auth-comp>
      </div>
    </ng-container>
  </mat-drawer>
  <mat-drawer-content>
    <ng-container *ngIf="data">
      <div
        class="flex justify-between xs:flex-column"
        style="overflow-y: auto; height: 100vh"
      >
        <div class="flex flex-column w-2/5 course-info xs:w-full">
          <img
            [src]="data?.properties?.payment_image || data.cover_image_p"
            height="200"
          />
          <div class="flex items-center justify-between mt-3 mb-2 ml-4">
            <h2>{{ data?.name }}</h2>
          </div>

          <p
            class="mt-2 color-secondary event-description ml-4"
            [innerHtml]="'<pre>' + data?.description + '</pre>'"
          ></p>
          <div class="grid has-3-columns items-center my-4">
            <div class="flex flex-column items-center justify-center">
              <a
                target="_blank"
                class="transition duration-500 ease-in-out transform hover:scale-105"
                download=""
                href="https://aitinkr.com/SchoolforAI_Summer_Camp_2024_Brochure.pdf"
              >
                <img
                  alt=""
                  src="https://aitinkr.azureedge.net/_next/static/media/pdf.49b33085.png"
                  width="48"
                  loading="lazy"
                />
              </a>
              <p class="font-display text-base mt-1">Curriculum</p>
            </div>
            <div class="flex flex-column items-center">
              <div
                class="transition duration-500 ease-in-out transform hover:scale-105 items-center rounded-lg border-2 border-dashed border-blue-600 p-2"
              >
                <button
                  class="relative overflow-hidden bg-cover bg-center px-6 py-2 mt-1 flex items-center justify-center"
                  style="
                    background-image: url('https://aitinkr.com/thumb-summer.png');
                    background-size: cover;
                  "
                >
                  <div class="flex animate-pulse">
                    <span
                      style="
                        box-sizing: border-box;
                        display: inline-block;
                        overflow: hidden;
                        width: initial;
                        height: initial;
                        background: none;
                        opacity: 1;
                        border: 0;
                        margin: 0;
                        padding: 0;
                        position: relative;
                        max-width: 100%;
                      "
                      ><span
                        style="
                          box-sizing: border-box;
                          display: block;
                          width: initial;
                          height: initial;
                          background: none;
                          opacity: 1;
                          border: 0;
                          margin: 0;
                          padding: 0;
                          max-width: 100%;
                        "
                        ><img
                          style="
                            display: block;
                            max-width: 100%;
                            width: initial;
                            height: initial;
                            background: none;
                            opacity: 1;
                            border: 0;
                            margin: 0;
                            padding: 0;
                          "
                          alt=""
                          aria-hidden="true"
                          src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2725%27%20height=%2725%27/%3e" /></span
                      ><img
                        alt="video"
                        src="https://aitinkr.azureedge.net/_next/static/media/playbutton-blue.41279e6b.png"
                        decoding="async"
                        data-nimg="intrinsic"
                        style="
                          position: absolute;
                          top: 0;
                          left: 0;
                          bottom: 0;
                          right: 0;
                          box-sizing: border-box;
                          padding: 0;
                          border: none;
                          margin: auto;
                          display: block;
                          width: 0;
                          height: 0;
                          min-width: 100%;
                          max-width: 100%;
                          min-height: 100%;
                          max-height: 100%;
                          object-fit: contain;
                        " /><noscript
                        ><img
                          alt="video"
                          src="https://aitinkr.azureedge.net/_next/static/media/playbutton-blue.41279e6b.png"
                          decoding="async"
                          data-nimg="intrinsic"
                          style="
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            box-sizing: border-box;
                            padding: 0;
                            border: none;
                            margin: auto;
                            display: block;
                            width: 0;
                            height: 0;
                            min-width: 100%;
                            max-width: 100%;
                            min-height: 100%;
                            max-height: 100%;
                            object-fit: contain;
                          "
                          loading="lazy" /></noscript
                    ></span>
                  </div>
                </button>
              </div>
              <p class="font-display text-base mt-2">Watch Intro</p>
            </div>
            <div class="flex flex-column items-center">
              <a>
                <img
                  src="https://aitinkr.azureedge.net/_next/static/media/summer-location.fe2f34e7.png"
                  width="48"
                />
              </a>
              <div class="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                  class="ml-2 h-5 w-5"
                  style="color: #eb5c38; width: 1.25rem; height: 1.25rem"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                  ></path>
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                  ></path>
                </svg>
                <p>Madhapur</p>
              </div>
            </div>
          </div>
          <div class="flex justify-end mt-4">
            <ng-container *ngIf="loginUser">
              <button
                mat-raised-button
                (click)="initiatePayment()"
                class="mat-focus-indicator letter-spacing-15 text-uppercase font-xss mat-button-base mat-primary mr-8 cursor-pointer"
                target="_blank"
              >
                BUY NOW
              </button>
            </ng-container>
            <ng-container *ngIf="!loginUser">
              <button
                mat-raised-button
                (click)="loginInitiateForBuy(drawer)"
                class="mat-focus-indicator letter-spacing-15 text-uppercase font-xss mat-button-base mat-primary mr-8 cursor-pointer"
              >
                BUY NOW
              </button>
            </ng-container>
          </div>
        </div>

        <div class="w-3/5 xs:w-full">
          <div class="intro-section im-content mx-8">
            <div id="editorjs"></div>
          </div>
        </div>
      </div>
    </ng-container>
  </mat-drawer-content>
</mat-drawer-container>
