import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { QuestionBase }     from './interface/question-base';
import { QuestionControlService }    from './service/question-control.service';

@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form.scss']
})
export class DynamicFormQuestionComponent implements OnInit {
  @Input() question: QuestionBase<any>;
  @Input() questions: QuestionBase<any>[];
  @Input() index: number;
  @Input() form: FormGroup;
  get isValid() { return this.form.controls[this.question.key].valid; }

  constructor(private qcs: QuestionControlService) {  }

  compareFn: ((f1: any, f2: any) => boolean)|null = this.compareByValue;

  compareByValue(f1: any, f2: any) { 
    return f1 && f2 && f1 == f2; 
  }

  excuteCondition(conditions, value:any) {
    conditions.forEach(conditionParam => {
      if (conditionParam.type == 'disabled') {
        if (eval(conditionParam.condition)) {
            this[conditionParam.type] = true;
        } else {
            this[conditionParam.type] = false;
        }
      } else if (conditionParam.type == 'value') {
          let tempValue = eval(conditionParam.condition);
          this.form.controls[conditionParam.key].setValue(tempValue);
      }
      else if (conditionParam.type == 'option') {
        console.log(conditionParam);
        let coptions = [];
        for(let i =0; i < conditionParam.options.length; i++) {
          let element = {...conditionParam.options[i]};
          if (element.disabled) { 
            element.disabled = eval(element.disabled);
          }
          coptions.push(element);
        }
        let questionIndex = this.questions.findIndex((it) => it.key == conditionParam.key);
        if(questionIndex > -1) { 
          this.questions[questionIndex].options = coptions;
          this.form.controls[conditionParam.key].setValue('');
        }
      }
     
    });
  }

  ngOnInit() {
      this.question.label = this.question.label.replace(/_/gi,' ');
      this.form.controls[this.question.key].valueChanges.subscribe(value=> {
          this.excuteCondition(this.question.conditions, value);
      })
  }

  fileSuccess($event) {
    //this.form.controls[this.question.key].setValue($event.file_name);
  }

  createField(form: any, key, childQuestions: any) {
    const formrArray:FormArray = form.get(key);
    let temp = {}
    childQuestions.forEach(childQuestion => {
      temp[childQuestion.key] = this.qcs.createControl(childQuestion, false);
    })
    formrArray.push(new FormGroup(temp));
  }

  removeField(form: any, key, index) {
    const formrArray:FormArray = form.get(key);
    formrArray.removeAt(index);
  }

  setValue(form, key, $event) {
    form.controls[key].setValue($event.value)
  }



}