<ng-container *ngIf="!processing && products.length == 0">
  <div class="empty-state flex items-center px-6 py-12 my-12 flex-column">
    <mat-icon class="border-radius-full">search</mat-icon>
    <p class="text-center mt-2 font-base color-secondary">
      No Mini Kits available. <br />Please explore the by click on the below
      button.
    </p>
    <button
      mat-raised-button
      color="primary"
      class="mt-2"
      routerLink="/our-offering"
    >
      Explore
    </button>
  </div>
</ng-container>
<div class="grid has-4-columns xs:has-1-columns aitinker-section">
  <div
    class="column-start-1 column-end-3 xs:column-end-4 md:column-end-4 xl:column-end-3 mr-6 ml-4 xs:mr-4"
  >
    <p class="w-full column-span-3 mb-6 mt-2" style="line-height: 1.5rem">
      Interested in short topic-specific courses? Then, Multi-Volume mini kits
      are for you. These kit-based short-duration courses teach essential
      concepts economically. Pick any of these volumes and collect all to create
      your learning library.
    </p>
    <div
      class="grid has-3-columns xs:has-1-columns column-gap-20 lg:column-gap-20 row-gap-20 justify-items-stretch"
    >
      <ng-container *ngFor="let product of productList">
        <div
          class="relative flex flex-column overflow-hidden border-radius-xlg cursor-pointer"
          style="background: linear-gradient(#faf7f4, #eceef1); height: 200px"
          routerLink="/mini-kit/{{ product.slug }}"
        >
          <div class="flex flex-column p-4">
            <p
              style="color: #747d8c"
              class="text-uppercase letter-spacing-15 font-xsss"
            >
              {{ product.properties?.tag_line }}
            </p>
            <h3 class="font-semi-bold letter-spacing-5">
              <span style="color: #06669f" class="font-base poppins-font">
                {{ product.name }}
              </span>
            </h3>

            <p class="font-xss mt-1" style="line-height: 1.2rem">
              {{ product.short_description }}
            </p>
            <!-- <div class="flex flex-column mt-4">
            <mat-chip-list aria-label="Product Tags">
              <mat-chip
                color="accent"
                selected
                *ngFor="let tag of product.properties?.tags"
                >{{ tag }}</mat-chip
              >
            </mat-chip-list>
          </div> -->
          </div>
          <div class="absolute left-20 bottom-20">
            <mat-icon>east</mat-icon>
          </div>
          <div style="position: absolute; right: 5px; bottom: 0px">
            <img
              alt=""
              class=""
              [src]="product.icon_image_p"
              style="height: 100px"
            />
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div
    class="h-auto mt-8 pr-4 column-start-4 column-end-4 xs:display-none md:display-none xl:display-block"
  >
    <!-- <img
      src="assets/images/haas.jpeg"
      class="border-radius-lg"
      style="height: 98%; object-fit: contain; object-position: top"
    /> -->
    <div
      class="flex border-radius-lg flex-column py-6"
      style="line-height: 1.8rem; background: antiquewhite"
    >
      <h2 class="mb-2 pl-6">What will you learn?</h2>
      <ul class="pl-10 pr-4 font-xs">
        <li class="mb-2">
          A systematic multi-stage approach to engage students with emerging
          technologies. Students would master the topics and convert the
          knowledge into skill through project-based learning.
        </li>
        <li class="mb-2">
          Encouraging students to showcase their learning by solving real-life
          problems using emerging technologies such as AI.
        </li>
        <li class="mb-2">
          The pedagogy would reduce cognitive load and increase student
          engagement through online, offline, and self-learning modules.
        </li>
        <li>
          Students could select Kit based programs right from electronics,
          robotics and IoT.
        </li>
      </ul>
    </div>
  </div>
</div>
