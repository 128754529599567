<div class="notes-routing">
	<section class="notes-section">
		<!-- <mat-drawer-container class="example-container" autosize> -->

		<div class="right xs:w-full">
			<div [ngClass]="{'display-none': !activeId}">
				<router-outlet></router-outlet>
			</div>
			<div *ngIf="!activeId" class="p-8">
				<h2 class="mb-6">Get Started</h2>
				<div style="width: 65%;margin: 0 auto;" class="xs:w-full">
					<div style="padding-top: 74%; position: relative; overflow: hidden;"><iframe frameborder="0"
							allowfullscreen="" scrolling="no" allow="autoplay;fullscreen" [src]="url"
							style="position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;border-radius: 8px;"></iframe>
					</div>

				</div>

			</div>
		</div>
		<!-- <mat-drawer #drawer class="example-sidenav" mode="side"> -->
		<div class="left">
			<div class="h-3/5">
				<div class="head">
					<h2 style="letter-spacing: 1.5px;">Notes</h2>
					<div class="actions">
						<button mat-button mat-icon-button matTooltip="Create a note" (click)="openDialog()"><img
								src="assets/images/add-new.svg"></button>
					</div>
				</div>

				<div class="content mt-4">
					<ng-container *ngFor="let note of notes">
						<button (click)="goToNote(note.id)" [ngClass]="{'active': (activeId == note.id)}">
							<div class="note-item w-full">
								<mat-icon>numbers</mat-icon>
								<span class="text-left">{{note.name}}</span>
								<span style="margin-left: auto;"
									class="font-xss mr-4 color-secondary">{{moment(note.modified_at).fromNow()}}</span>
							</div>
						</button>
					</ng-container>
				</div>
			</div>

			<div class="h-2/5">
				<div class="head">
					<h4 style="letter-spacing: 1.5px;font-weight: 300;">Built-in Notes</h4>
				</div>
				<div class="content mt-4  overflow-y-auto" style="height: calc(50vh - 28px)">
					<ng-container *ngFor="let note of globalNotes">
						<button (click)="goToNote(note.id)" [ngClass]="{'active': (activeId == note.id)}">
							<div class="note-item w-full">
								<mat-icon>description</mat-icon>
								<span class="text-left">{{note.name}}</span>
								<span style="margin-left: auto;"
									class="font-xss mr-4 color-secondary">{{moment(note.modified_at).fromNow()}}</span>
							</div>
						</button>
					</ng-container>


				</div>
			</div>

		</div>
		<!-- </mat-drawer> -->

		<!-- </mat-drawer-container> -->

	</section>
</div>