<div class="h-screen mx-auto my-auto lg:w-32vw xs:w-full md:w-full">
  <div
    class="flex flex-column p-12 justify-center h-full"
    [ngClass]="{ 'im-login-center': isItComonent }"
  >
    <div class="mb-8 flex flex-column justify-center items-center">
      <h3 class="mb-4">Verification</h3>
      <p class="px-4 py-1 xs:text-center">
        We sent the otp to the below email address
      </p>
      <p>
        <strong>{{ email }}</strong>
      </p>
    </div>
    <form>
      <div class="mb-4">
        <im-otp-field
          class="text-center"
          (onInputChange)="otpChange($event)"
          [config]="otpConfig"
        ></im-otp-field>
        <mat-error class="text-center display-block pt-2 font-xs">
          <ng-container *ngIf="otpError && !otp"
            ><strong>Please enter a otp</strong></ng-container
          >
          <ng-container *ngIf="otpError && otp"
            ><strong>Please enter a valid otp</strong></ng-container
          >
          &nbsp;
        </mat-error>
      </div>
      <mat-form-field class="w-full" appearance="outline" floatLabel="always">
        <mat-label>PASSWORD</mat-label>
        <input
          matInput
          placeholder="Set the password for your account"
          [formControl]="passwordFormControl"
          color="#fff"
          [type]="hide ? 'password' : 'text'"
          id="authPassword"
          (keyup.enter)="next()"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>

        <mat-error
          *ngIf="passwordFormControl.hasError('required')"
          class="mb-2"
        >
          Password is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <div class="action-items flex flex-column pt-12">
        <button
          mat-stroked-button
          color="primary"
          (click)="next()"
          [disabled]="processing"
          class="w-full im-button"
        >
          <div class="flex justify-between items-center">
            <span>{{ processing ? "Processing..." : "CONTINUE" }}</span>
            <mat-icon>east</mat-icon>
          </div>
        </button>
      </div>
    </form>
  </div>
</div>
