import { Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from '@core/services/guards/auth-guard.service';
import { WrapperComponent } from './wrapper/wrapper.component';
import { ListComponent } from './list/list.component';
import { AllComponent } from './list/all/all.component';
import { MyComponent } from './list/my/my.component';
import { TeachableComponent } from './teachable/teachable.component';
import { PlayComponent } from './teachable/play/play.component';
import { CreateComponent } from './teachable/create/create.component';
import { PlayzoneComponent } from './teachable/playzone/playzone.component';
import { GamesComponent } from './teachable/games/games.component';

export const aiEditorRoutes: Routes = [
    {
       path: "ai-coder", component: ListComponent, children: [
        // {
        //     path: "", component: AllComponent, 
        // },
        { path: '',   redirectTo: 'all', pathMatch: 'full' },
        {
            path: "all", component: AllComponent 
        },
        {
            path: "teachable-machine", component: TeachableComponent,
            children: [
                {
                    path: 'create', component: CreateComponent, canActivate: [AuthGuard]
                },
                {
                    path: 'games', component: GamesComponent
                },
                {
                    path: 'play/:id', component: PlayzoneComponent, canActivate: [AuthGuard]
                }
            ]
        },
        {
            path: "category/:slug", component: MyComponent
        }
       
       ]
    },
    {
        path: "ai-coder/:slug", component: WrapperComponent, canActivate: [AuthGuard]        
    }
    
];
