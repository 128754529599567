<div class="flex flex-column items-center justify-center h-full" *ngIf="processingTopics">
    <mat-spinner strokeWidth="3"></mat-spinner>
  </div>
<ng-container *ngIf="course && !processingTopics">


<div
  class=" 
    grid 
    has-11-columns 
    has-1-rows
    justify-items-stretch h-screen overflow-y-auto column-gap-20 chapter-topic-view-section">

    <div class="main column-start-1  row-span-1 column-end-8 xxl:column-end-7">
        <div class="chapter-section im-content" [ngClass]="{'display-none': !activeTopic}">
            <div id="editorjs"></div>
            <ng-container *ngIf="processActions">
                <div class="flex justify-end pb-8 mr-8" *ngIf="enableQuizButton">
                    <button mat-raised-button color="primary" (click)="quiz()">Go for Quiz</button>
                </div>
                <div class="flex justify-end pb-8 mr-8" *ngIf="!enableQuizButton">
                    <button mat-raised-button color="primary" (click)="next()">Next</button>
                </div>    
            </ng-container>
        </div>
        <ng-container *ngIf="!activeTopic">
            <div class="chapter-section flex justify-center placeholder-section">
                <img src="assets/images/Placeholder.png" />
            </div>
        </ng-container>
        

    </div>

    <div class="topics-section column-start-9  row-span-1 column-end-12 my-4 mr-4 course{{course.id}}-section level{{course.id}} fixed right-0" *ngIf="!processing && chapter"  [attr.data-level]="course.properties.level" >
        <button mat-icon-button class="back_button" routerLink="/course/{{course.slug}}">
            <mat-icon>keyboard_backspace</mat-icon>
        </button>
        <div class="flex flex-column">
            <span class="course{{course.id}}-text"><a routerLink="/course/{{course.slug}}">{{course.name }}</a></span> 
            <!-- <span class="mx-2"><mat-icon>double_arrow</mat-icon> </span>  -->
            <span class="mat-color-primary mt-2"><a routerLink="/course/{{course.slug}}">{{module.name}}</a></span>
        </div>
        <!-- <h2 class="ml-3 flex items-center"><span class="mr-2"><img [src]="course.icon_p" width="32" height="32"></span> <span>{{course.name }}</span> </h2>
        <h4 class="ml-3 mt-2 font-normal flex items-center"><span class="mr-2 module-icon mat-course{{course.id}}-button"><img [src]="module.icon_p" width="18" height="18"></span> <span>{{module.name}}</span></h4> -->
        <h2 class="mt-2">{{chapter.title}}</h2>
        <div class="steps">
            <ol>
                <li *ngFor="let topic of chapterTopics" [ngClass]="{'active': (activeTopic.id == topic.id)}">
                    <button (click)="makeActiveTopic(topic)" [disabled]="topic?.user_course_topic?.is_completed == 0 && topic?.user_course_topic?.status == 0">
                        <span class="step">{{topic.name}}</span>
                        <ng-container *ngIf="topic?.user_course_topic?.is_completed == 1">
                            <span class="completed"><mat-icon>done</mat-icon></span>
                        </ng-container>
                        <ng-container *ngIf="(topic?.user_course_topic?.is_completed == 0 && topic?.user_course_topic?.status == 0) || (!topic.user_course_topic && !chapter?.properties?.is_it_free && (!course?.access?.top.includes(topic.id)))">
                            <span><mat-icon>lock_outline</mat-icon></span>
                        </ng-container>
                    </button>
                </li>
            </ol>
        </div>
    </div>

</div>    
</ng-container>