<ng-container *ngIf="!processing">
  <ng-container *ngIf="!alreadyMysqlSetupDone">
    <ng-container *ngIf="!startInstallationFlag">
        <div class="px-8 py-4 get-start-section">
              <div>
                  <img src="assets/images/mysql-logo.svg" width="120">
                  <p class="mt-4 mr-12">
                    A relational database organizes data into one or more data tables in which data types may be related to each other; these relations help structure the data. SQL is a language programmers use to create, modify and extract data from the relational database, as well as control user access to the database. In addition to relational databases and SQL, an RDBMS like MySQL works with an operating system to implement a relational database in a computer's storage system, manages users, allows for network access and facilitates testing database integrity and creation of backups.
                  </p>
                  <p class="mb-4 mr-12">
                    Many of the world's largest and fastest-growing organizations including Facebook, Google, Adobe, Alcatel Lucent and Zappos rely on MySQL to save time and money powering their high-volume Web sites, business-critical systems and packaged software.
                  </p>
              </div>
              <div class="mr-8 mt-12">
                <button mat-raised-button color="primary" class="float-right" (click)="startInstallation()">GET START NOW</button>
              </div>
        </div>
    </ng-container>
    <ng-container *ngIf="startInstallationFlag">
      <div class="grid has-12-columns mysql-installation-section">
        <div class="column-start-1  row-span-1 column-end-9 left">
            <ng-container *ngIf="mysqlInstallationIndex == 0">
              <div class="console" id="console__body__id">
                <ng-container *ngFor="let message of finalMessages">
                  <kbd class="console__prompt" >
                    <div [innerHTML]="message.message">
    
                    </div>
                  </kbd>
                </ng-container>
                <div style="overflow-anchor: auto;height: 1px;"></div>
            </div>
            </ng-container>
            <ng-container *ngIf="mysqlInstallationIndex == 1">
                <div class="securing p-8">
                    <h3 class="pb-8">Securing the MySQL server deployment</h3>
                    <div class="grid has-12-columns">
                        <div class="column-start-1 column-end-7">
                          <form autocomplete="off" class="pr-12" [formGroup]="configGroup">
                            <mat-form-field class="w-full mb-4" appearance="outline" floatLabel="always">
                              <mat-label>USERNAME</mat-label>
                              <input matInput placeholder="" formControlName="username" color="#fff" type="text" id="mysqlUsername">
                              <!-- <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" type="button">
                                <mat-icon inline="true">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                              </button> -->
                              <mat-error *ngIf="configGroup.get('username').hasError('required')" class="mb-2">
                                Username is <strong>required</strong>
                              </mat-error>
                              
                          </mat-form-field>
                            <mat-form-field class="w-full" appearance="outline" floatLabel="always">
                                <mat-label>PASS WORD</mat-label>
                                <input matInput placeholder="" formControlName="password" color="#fff" [type]="hide ? 'password' : 'text'" id="mysqlPassword" (keyup.enter)="next()" #passwordInput>
                                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" type="button">
                                  <mat-icon inline="true">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-error *ngIf="configGroup.get('password').hasError('required')" class="mb-2">
                                  Password is <strong>required</strong>
                                </mat-error>
                                
                            </mat-form-field>
                            <div class="action-items flex flex-column mt-10">
                              <button mat-stroked-button color="primary" (click)="next()" [disabled]="formProcessing" class="w-full im-button" type="submit"> <div class="flex justify-between items-center"><span>{{ (processing)?"Processing..." : "CONTINUE" }}</span> <mat-icon>east</mat-icon></div></button>
                            </div>
                          </form>    
                        </div>
                        <div class="column-start-8 column-end-12">
                          <div class="password-policy">
                            <p>There are three levels of password validation policy:</p>
                            <ul>
                              <li><strong>LOW</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Length >= 8</li>
                              <li><strong>MEDIUM</strong> &nbsp;Length >= 8, numeric, mixed case, and special characters</li>
                              <li><strong>STRONG</strong> &nbsp;Length >= 8, numeric, mixed case, and special characters and dictionary</li>
                            </ul>
                          </div>
                        </div>
                    </div>
                  
                </div>
            </ng-container>
            <ng-container *ngIf="mysqlInstallationIndex == 2">
    
            </ng-container>
          
        </div>
        <div class="column-start-10  row-span-1 column-end-12 pl-4 right-side-nav">
            <p class="mt-4 mr-4 mb-4">
                MySQL, the most popular Open Source SQL database management system, is developed, distributed, and supported by Oracle Corporation.
            </p>
            <mat-vertical-stepper linear [selectedIndex]="mysqlInstallationIndex" #stepper>
                <mat-step label="Installing MySQL" state="installation" editable="false" [completed]="installationCompleted">
                  <p>MySql 5.7</p>
                </mat-step>
                <mat-step label="Securing MySQL server" state="config" editable="false" [completed]="configCompleted">
                  <p>Username and Password for Mysql</p>
                  
                </mat-step>
                <mat-step label="Complete" state="complete" editable="false">
                </mat-step>
              
                <!-- Icon overrides. -->
                <ng-template matStepperIcon="installation">
                  <mat-icon>downloading</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="config">
                  <mat-icon>settings_suggest</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="complete">
                    <mat-icon>done_all</mat-icon>
                  </ng-template>
              </mat-vertical-stepper>
        </div>
    </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="alreadyMysqlSetupDone">
    <iframe [src]="editorUrl" frameborder="0" style="width: 100%;height: calc(100vh - 57px);"></iframe>
  </ng-container>
</ng-container>


