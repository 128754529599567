import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@core/services/auth.service';
import { HttpService } from '@core/services/http.service';

@Component({
  selector: 'im-trial',
  templateUrl: './trial.component.html',
  styleUrls: ['./trial.component.scss']
})
export class TrialComponent implements OnInit {

  enable: boolean = true;

  constructor(public dialog: MatDialog, private http: HttpService) { }

  ngOnInit() {
    //this.checkSubscriptionExist();
  }

  book() {
    const dialogRef = this.dialog.open(BookComponent, {
      width: '60vw',
      minHeight: '50vh',
      data: {},
      panelClass: 'trail-section'
    });
    dialogRef.afterClosed().subscribe(result => {
        if (result && 'booked' in result && result.booked) {
          this.enable = false;
        }
    });
  }

  checkSubscriptionExist() {
    this.http.post('checkexist/user_course', {}).subscribe((res)=>{
      this.enable = (res.data.exist > 0)? false: true;
    });
  }

}


@Component({
  selector: 'im-book',
  templateUrl: './book.html',
})
export class BookComponent implements OnInit {

  secondFormGroup: FormGroup;

  loginUser: any;

  constructor(public dialogRef: MatDialogRef<BookComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _formBuilder: FormBuilder, private authService: AuthService, private http: HttpService) { }
    
    ngOnInit() {
      this.loginUser = this.authService.loginUser;
      this.secondFormGroup = this._formBuilder.group({
        name: [this.loginUser.name, Validators.required],
        email: [this.loginUser.email, Validators.required],
        mobile_number: [this.loginUser.mobile_number, Validators.required]
      });
    }

    save() {
        if (this.secondFormGroup.valid) {
          const data = {...this.secondFormGroup.value, 'title':'Free Trail Request', 'description':'Foundation to AI', 'request_type':'free_trail_request'};
          this.http.post('core/support', data).subscribe((res)=>{
              this.dialogRef.close({booked: true});
          }, (err) => {
           
          })
        }
    }

}