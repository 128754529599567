import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../../../core/services/http.service';
import { PaymentService } from '@modules/payment/payment.service';
import { DomSanitizer } from '@angular/platform-browser';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import { MatDrawer } from '@angular/material/sidenav';
import { AuthService } from '@core/services/auth.service';
import moment from 'moment';

@Component({
  selector: 'im-minikit-details',
  templateUrl: './minikit-details.component.html',
  styleUrls: ['./minikit-details.component.scss'],
})
export class MinikitDetailsComponent implements OnInit {
  courseSlug: string;
  course: any;
  processing: boolean = false;
  courseModules: any = [];

  IntroVideoUrl: any;

  key: string = '';

  @ViewChild(MatDrawer, { static: false }) sidenav: MatDrawer;

  selectedDay: any;

  todayDay: any;

  maxDay: any;

  errorProductKeyMessage = '';

  sidNavType = 1;

  timeSlots = {
    Wed: [
      {
        title: '5:00 PM',
        value: '17:00:00',
      },
    ],
    Sat: [
      {
        title: '5:00 PM',
        value: '17:00:00',
      },
    ],
  };

  activetimeSlot = [];

  selectedTimeSlot: any;

  userProductSessions: any = [];

  loginUser: any;

  packages = [];
  activePackage: any;

  couponNumber: string;

  isItFree: boolean = false;

  checkingCoupon: boolean = false;

  couponInfoApply: any;

  constructor(
    public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService,
    private paymentService: PaymentService,
    protected sanitizer: DomSanitizer,
    private auth: AuthService
  ) {
    this.route.params.subscribe((params) => {
      if (params.slug) {
        this.courseSlug = params.slug;
        this.getCourse();
      }
    });
  }

  ngOnInit() {
    this.loginUser = this.auth.loginUser;
    this.selectedDay = moment();
    this.todayDay = moment();
    this.maxDay = moment().add('days', 90);
  }

  openLockStatus(): void {
    const bottomSheetRef = this._bottomSheet.open(ChapterLockStatusSheet, {
      panelClass: 'im-sheet',
    });
    bottomSheetRef.afterDismissed().subscribe((event) => {
      if (event) {
        if (event.event == 'buyNow') {
          this.buyNow(this.course);
        }
      }
    });
  }

  downloadFile(url, filename = '') {
    if (filename.length === 0) filename = url.split('/').pop();
    const req = new XMLHttpRequest();
    req.open('GET', url, true);
    req.responseType = 'blob';
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: 'application/pdf',
      });

      const isIE = false || !!(<any>document).documentMode;
      if (isIE) {
        (<any>window).navigator.msSaveBlob(blob, filename);
      } else {
        const windowUrl = window.URL || (<any>window).webkitURL;
        const href = windowUrl.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('download', filename);
        a.setAttribute('href', href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  }

  getCourse() {
    this.processing = true;
    this.http.get('na/mini-kits/' + this.courseSlug).subscribe(
      (res) => {
        this.course = res.data;
        if (this.course.properties && this.course.properties.introVideoId) {
          this.IntroVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            'https://app.aitinkr.com/player/index.html?autoplay=false&autopause=true&muted=false&loop=false&url=https%3A%2F%2Fvimeo.com%2F' +
              this.course.properties.introVideoId +
              '&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true'
          );
        }

        this.course['cover_image_pb'] = this.sanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to bottom,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)), url(${this.course.cover_image_p})`
        );

        let activationQuery = this.route.snapshot.queryParamMap.get('ac');
        if (activationQuery && !this.course.user_course) {
          this.sidenav.open();
        }
        if (this.course.user_course) {
          this.getProductSessionsData();
        } else {
          this.getPackages();
        }
        this.getModules();

        //this.processing = false;
      },
      (err) => {
        this.processing = false;
      }
    );
  }

  getModules() {
    const that = this;
    let uri = 'na/mini-kits/' + this.course.id + '/modules';
    if (this.course.category == 'aitinker') {
      uri += '?nch=1';
    }
    this.http.get(uri).subscribe(
      (res) => {
        this.courseModules = res.data;
        this.processing = false;
        if (document.querySelector('.download_brochure a')) {
          setTimeout(() => {
            document.querySelector('.download_brochure a').addEventListener(
              'click',
              function () {
                that.downloadFile(
                  that.course.properties.brochure,
                  that.course.name + ' Brochure'
                );
                document
                  .querySelector('.download_brochure a')
                  .classList.add('loading');
                setTimeout(function () {
                  document
                    .querySelector('.download_brochure a')
                    .classList.remove('loading');
                }, 8000);
              },
              false
            );
          }, 100);
        }
      },
      (err) => {
        this.processing = false;
      }
    );
  }

  redirectDetails(module, chapter) {
    //if (this.course.category == 'aitinker') {
    this.router.navigate([
      'mini-kit',
      this.course.slug,
      module.slug,
      'nch',
      chapter.slug,
    ]);
    //}
  }

  buyNow(course: any) {
    this.course.packageType = 'course';
    const dialogRef = this.paymentService.initPayment(this.course);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.status === 'Success') {
        this.getCourse();
      }
    });
  }

  getPackages() {
    this.processing = true;
    let filter =
      'refId eq ' + this.course.id + " and ref eq 'mini-kit' and status eq 1";

    filter += ' and upgrade_package eq 0';

    this.http.get('na/packages?filter=' + filter).subscribe((res) => {
      this.packages = res.data;
      if (Object.keys(this.packages).length > 0) {
        this.activePackage = res.data[0];
        this.calculateTaxes();
      }
      if (
        'buy-now' in this.route.snapshot.queryParams &&
        this.route.snapshot.queryParams['buy-now'] == 1
      ) {
        this.initiatePayment();
      }
      this.processing = false;
    });
  }

  makeActivePackage(packageItem: any) {
    this.activePackage = packageItem;
    this.calculateTaxes();
  }

  initiatePayment() {
    if (!this.loginUser) {
      this.loginInitiateForBuy(this.sidenav);
      return false;
    }
    if (this.couponNumber) {
      window
        .open(
          'https://checkout.schoolforai.com/?d=aitinkr.com&type=product&refId=' +
            this.course.slug +
            '&coupon=' +
            this.couponNumber +
            '&pack=' +
            this.activePackage.id +
            '&c=' +
            this.auth.getToken(),
          '_blank'
        )
        .focus();
    } else {
      window
        .open(
          'https://checkout.schoolforai.com/?d=aitinkr.com&type=product&refId=' +
            this.course.slug +
            '&pack=' +
            this.activePackage.id +
            '&c=' +
            this.auth.getToken(),
          '_blank'
        )
        .focus();
    }
  }

  calculateTaxes() {
    if (this.activePackage.properties && this.activePackage.properties.mrp) {
      this.activePackage.subTotal = this.activePackage.properties.mrp;
      this.activePackage.total = this.activePackage.price;
      this.activePackage.discountAmount =
        this.activePackage.properties.mrp - this.activePackage.price;
      let temp = this.activePackage.total / 1.18;
      this.activePackage.taxes = Math.round(temp * 0.18);
    } else {
      this.activePackage.discountAmount =
        this.couponInfoApply && this.couponInfoApply.discounted_amount
          ? this.couponInfoApply.discounted_amount
          : 0;
      this.activePackage.shipping = 0;
      this.activePackage.subTotal = Math.round(this.activePackage.price / 1.18);
      let temp =
        this.activePackage.subTotal - this.activePackage.discountAmount;
      this.activePackage.taxes = Math.round(temp * 0.18);
      if (this.activePackage.properties.deposit) {
        this.activePackage.total =
          this.activePackage.subTotal +
          this.activePackage.taxes +
          this.activePackage.shipping +
          this.activePackage.properties.deposit -
          this.activePackage.discountAmount;
      } else {
        this.activePackage.total =
          this.activePackage.subTotal +
          this.activePackage.taxes +
          this.activePackage.shipping -
          this.activePackage.discountAmount;
      }
    }
  }

  courseIntro() {
    const dialogRef = this.dialog.open(CourseIntroModal, {
      width: '60vw',
      data: this.course,
      panelClass: 'courseIntroPopup',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  onKeyChange() {
    this.key = this.key.toUpperCase();
    this.key = this.key.replace(/-/g, '').slice(0, 19);
    const formattedKey = this.key.replace(/(\w{4})(?=\w)/g, '$1-');
    this.key = formattedKey;
  }

  validateActivationCode() {
    if (!this.key) return;
    this.http
      .post('user_products', {
        key: this.key,
        product_id: this.course.id,
      })
      .subscribe((res: any) => {
        if (res.success) {
          this.errorProductKeyMessage = '';
          window.location.reload();
        } else {
          this.key = '';
          this.errorProductKeyMessage = res.message;
        }
      });
  }

  getProductSessionsData() {
    this.http
      .get('user_product_events?filter=product_id eq ' + this.course.id)
      .subscribe((res: any) => {
        this.userProductSessions = res.data;
      });
  }
  onCalendarSelectedChange($event: any) {
    this.selectedDay = $event;
    let key = $event.format('ddd');
    if (this.userProductSessions.length >= 2) {
      this.activetimeSlot = [];
    } else {
      this.activetimeSlot = this.timeSlots[key];
    }
    //this.processing = true;
  }

  loginSuccess(user, drawer, sidnavClose = false) {
    this.loginUser = user;
    if (sidnavClose) {
      drawer.close();
      this.initiatePayment();
    }
  }

  loginInitiateForBuy(drawer) {
    this.sidNavType = 2;
    drawer.open();
  }

  appointmentDateSelectionFilter(date: any) {
    return date.format('ddd') == 'Wed' || date.format('ddd') == 'Sat';
  }

  checkSlotAlreadyBooked(slot) {
    let that = this;
    if (this.userProductSessions.length == 0) return null;
    let exist = this.userProductSessions.find(
      (se) =>
        se.event_time == slot.value &&
        se.event_date == that.selectedDay.format('YYYY-MM-DD')
    );
    if (exist) {
      return true;
    } else {
      return null;
    }
  }

  bookSlot() {
    if (!this.selectedTimeSlot) return;
    if (this.userProductSessions.length >= 1) return;
    this.http
      .post('user_course_events', {
        course_id: this.course.id,
        event_date: this.selectedDay.format('YYYY-MM-DD'),
        event_time: this.selectedTimeSlot.value,
      })
      .subscribe((res: any) => {
        this.userProductSessions.push(res.data);
        this.selectedTimeSlot = '';
        this.activetimeSlot = [];
      });
  }

  couponApply(couponNumber) {
    if (!couponNumber) return;
    if (this.checkingCoupon) return;
    this.couponNumber = couponNumber;
    this.checkingCoupon = true;
    this.http
      .post('search/coupon', {
        couponCode: couponNumber,
        course: this.course.id,
        package: this.activePackage.id,
      })
      .subscribe((res: any) => {
        this.checkingCoupon = false;
        if (res.success) {
          this.couponInfoApply = res.data;
          if (this.couponInfoApply.effected_price == 0) {
            this.isItFree = true;
          }
          this.calculateTaxes();
        } else {
          this.couponNumber = '';
        }
      });
  }
}

@Component({
  selector: 'im-chapter-lock',
  templateUrl: './chapter.lock.html',
})
export class ChapterLockStatusSheet {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<ChapterLockStatusSheet>
  ) {}

  buyNow(event: MouseEvent): void {
    this._bottomSheetRef.dismiss({ event: 'buyNow' });
    event.preventDefault();
  }
}

@Component({
  selector: 'course-intro',
  templateUrl: './course.intro.html',
})
export class CourseIntroModal implements OnInit {
  url: any;

  constructor(
    protected sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<CourseIntroModal>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://app.aitinkr.com/player/index.html?autoplay=false&autopause=true&muted=false&loop=false&url=https%3A%2F%2Fvimeo.com%2F' +
        this.data.properties.introVideoId +
        '&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true'
    );
  }
}
