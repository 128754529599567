import { Component, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { environment } from '@environment/environment';

@Component({
  selector: 'im-play-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss']
})
export class AudioComponent implements OnInit, OnDestroy, AfterViewInit {

  outputImg: any;
  outputLabel: any;
  labelContainer: any = [];
  @Input() game:any;

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    setTimeout(()=>{
      this.init();
    },-1);
  }


audioWave() {
    let paths = document.getElementsByTagName('path');
    let visualizer:any = document.getElementById('visualizer');
    let mask = visualizer.getElementById('mask');
    let path;
    let report = 0;
    
    var soundAllowed = function (stream) {
        (<any>window).persistAudioStream = stream;
        const audioContent = new AudioContext();
        const audioStream:any = audioContent.createMediaStreamSource( stream );
        const analyser = audioContent.createAnalyser();
        audioStream.connect(analyser);
        analyser.fftSize = 1024;

        let frequencyArray = new Uint8Array(analyser.frequencyBinCount);
        visualizer.setAttribute('viewBox', '0 0 255 255');
      
				//Through the frequencyArray has a length longer than 255, there seems to be no
        //significant data after this point. Not worth visualizing.
        for (var i = 0 ; i < 255; i++) {
            path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            path.setAttribute('stroke-dasharray', '4,1');
            mask.appendChild(path);
        }
        var doDraw = function () {
            requestAnimationFrame(doDraw);
            analyser.getByteFrequencyData(frequencyArray);
          	var adjustedLength;
            for (var i = 0 ; i < 255; i++) {
              	adjustedLength = Math.floor(frequencyArray[i]) - (Math.floor(frequencyArray[i]) % 1);
                paths[i].setAttribute('d', 'M '+ (i) +',255 l 0,-' + adjustedLength);
            }

        }
        doDraw();
    }

    var soundNotAllowed = function (error) {
        console.log(error);
    }
    navigator.getUserMedia({audio:true}, soundAllowed, soundNotAllowed);

};


  async createModel() {
    const checkpointURL = environment.storageUrl+'ai_games/models/'+this.game.key + '/model.json'; // model topology
    const metadataURL = environment.storageUrl+'ai_games/models/'+this.game.key + '/metadata.json'; // model metadata

    const recognizer = (<any>window).speechCommands.create(
        'BROWSER_FFT', // fourier transform type, not useful to change
        undefined, // speech commands vocabulary feature, not useful for your models
        checkpointURL,
        metadataURL);

    // check that model and metadata are loaded via HTTPS requests.
    await recognizer.ensureModelLoaded();

    return recognizer;
}

async init() {
    const recognizer:any = await this.createModel();
    this.audioWave();
    const classLabels = recognizer.wordLabels(); // get class labels
    //this.labelContainer = document.getElementById('label-container');
    for (let i = 0; i < classLabels.length; i++) {
      this.labelContainer.push({label:'', i: i, value:0});
    }

    this.outputImg = document.querySelector('#output-player-img');
    this.outputLabel = document.querySelector('#output-player-label');


    // listen() takes two arguments:
    // 1. A callback function that is invoked anytime a word is recognized.
    // 2. A configuration object with adjustable fields
    recognizer.listen(result => {
        const scores = result.scores; // probability of prediction for each class
        // render the probability scores per class
        for (let i = 0; i < classLabels.length; i++) {
            this.labelContainer[i].value = result.scores[i].toFixed(2);
            this.labelContainer[i].label = classLabels[i];
            if (result.scores[i].toFixed(2) > 0.5 && this.game.properties && this.game.properties.actions) {
              this.outputImg.src=environment.storageUrl+'ai_games/models/'+this.game.key+"/actions/"+this.game.properties.actions[classLabels[i]];
              if(this.game.properties.action_label) this.outputLabel.innerText = this.game.properties.action_label[classLabels[i]]
            }
        }
    }, {
        includeSpectrogram: true, // in case listen should return result.spectrogram
        probabilityThreshold: 0.75,
        invokeCallbackOnNoiseAndUnknown: true,
        overlapFactor: 0.50 // probably want between 0.5 and 0.75. More info in README
    });

  }

}
