import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'im-coder',
  templateUrl: './coder.component.html',
  styleUrls: ['./coder.component.scss']
})
export class CoderComponent implements OnInit {

  
  navLinks = [
    {
      path: '/coder/python',
      label: 'Python'
    },
    // {
    //   path: '/coder/mysql',
    //   label: 'MySQL'
    // },
  ];

  constructor() { }

  ngOnInit() {
  }

}
