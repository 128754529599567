import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth.component';
import { PasswordComponent } from './password/password.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { VerifyComponent } from './verify/verify.component';
import { ResetComponent } from './reset/reset.component';

import { AuthGuardService as AuthGuard } from '@core/services/guards/auth-guard.service';
import { RedirectComponent } from './redirect/redirect.component';
import { AuthWrapperComponent } from './auth-wrapper/auth-wrapper.component';


const routes: Routes = [
  {
    path: "auth", component: AuthWrapperComponent,  children: [
      { path: "start",  component: AuthComponent, data: {animation: 'AuthComponent'}  },
      { path: 'verify/:email', component: VerifyComponent},
      { path: 'password/:email', component: PasswordComponent, data: {animation: 'PasswordComponent'}},
      { path: 'reset/:email', component: ResetComponent},
      { path: 'onboarding', component: OnboardingComponent, canActivate: [AuthGuard]},
      { path: 'password',  redirectTo: '/auth/start'},
      { path: 'reset', redirectTo: '/auth/start'},
      { path: 'sso/:id', component: RedirectComponent},
      {path: "", redirectTo: '/auth/start', pathMatch: 'full'},

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoutesModule { }
