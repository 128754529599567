 /**
 * @license
 * Copyright IM All Rights Reserved.
 *
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { OtpFieldComponent } from './otp-field.component';

import { NumberOnlyDirective } from '@core/utillities/directives/number-only.directive';
import { KeysPipe } from '@core/utillities/pipes/keys.pipe';


@NgModule({
    declarations: [OtpFieldComponent, KeysPipe, NumberOnlyDirective],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule  
    ],
    exports: [OtpFieldComponent],
    providers: [KeysPipe]
  })
export class OtpFieldModule { }