import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommunityComponent } from './community.component';
import { ListComponent } from './list/list.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDividerModule} from '@angular/material/divider';
import {TextFieldModule} from '@angular/cdk/text-field';
import {MatMenuModule} from '@angular/material/menu';


@NgModule({
    declarations: [CommunityComponent, ListComponent, LeaderboardComponent],
    imports: [
      CommonModule,
      MatButtonModule,
      MatIconModule,
      FormsModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      MatInputModule,
      MatDividerModule,
      TextFieldModule,
      MatMenuModule
    ],
    exports: [ListComponent, LeaderboardComponent],
    entryComponents: []
  })
export class CommunityModule { }
  
