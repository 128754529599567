import { Routes } from '@angular/router';
import { NotesComponent } from './notes.component';
import { AuthGuardService as AuthGuard } from '@core/services/guards/auth-guard.service';
import { DetailsComponent } from './details/details.component';


export const noteRoutes: Routes = [
    { path: 'notes', component: NotesComponent, canActivate: [AuthGuard], children: [
        { path: ':id', component: DetailsComponent}
    ]}
];


