import { Component, OnInit, Input } from '@angular/core';
import Header from '@editorjs/header'; 
import List from '@editorjs/list'; 
import RawTool from '@editorjs/raw';
import SimpleImage from '@editorjs/simple-image';
import Checklist  from '@editorjs/checklist';
import Embed from '@editorjs/embed';
import Quote from '@editorjs/quote';
import Table from '@editorjs/table';
import ImageTool from '@editorjs/image';
import CodeTool from '@editorjs/code';
import LinkTool from '@editorjs/link';
import Marker from '@editorjs/marker';
import Warning from '@editorjs/warning';
import Paragraph from '@editorjs/paragraph';
import InlineCode from '@editorjs/inline-code';
import EditorJS from '@editorjs/editorjs';
import { environment } from '@environment/environment';

@Component({
  selector: 'im-ai-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss']
})
export class GuideComponent implements OnInit {

  @Input() model: any;

  editor: any;

  constructor() { }

  ngOnInit() {
    let tools =  {
      header: Header, 
      list: List,
      paragraph: {
       class: Paragraph,
     },
      //image: SimpleImage,
      checklist: Checklist,
      embed: {
        class: Embed,
        config: {
         services: {
           youtube: true,
           vimeo:  {
            regex: /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
            embedUrl: environment.playerUrl+'<%= remote_id %>',
            html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
            height: 300,
            width: 600,
            id: ([id]) => {
              const params = [
                'autoplay=true', 'autopause=true', 'muted=false', 'loop=false', `url=https%3A%2F%2Fvimeo.com%2F${id}`, 'poster=', 'time=true', 'progressBar=true', 'overlay=true', 'muteButton=true', 'fullscreenButton=true', 'style=light', 'quality=auto', 'playButton=true'
              ]; 
              return "?"+params.join('&');
            }
          },
           schoolforai: {
             regex: /https?:\/\/(?:s\.)?schoolforai.com\/static\/videos\/(\w+.(mp4))/,
             embedUrl: environment.playerUrl+'autoplay=true&autopause=false&muted=false&loop=true&url=https%3A%2F%2Fdev.schoolforai.com%2Fstatic%2Fvideos%2F<%= remote_id %>&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true',
             html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
             height: 300,
             width: 600,
           }
         }
        }
      },
      quote: Quote,
      table: Table,  
      raw: RawTool,
      code: CodeTool,
      linkTool: LinkTool,
      marker: Marker,
      warning: Warning, 
      inlineCode: {
         class: InlineCode,
         shortcut: 'CMD+SHIFT+M',
      },
      image: {
        class: ImageTool
      } 
    };
    this.editor = new EditorJS({ data: this.model.description, readOnly: true,  
      tools:{
        ...tools,
        twoColumns: {
          class: window['EditorJSLayout'].LayoutBlockTool,
          config: {
            EditorJS,
            editorJSConfig: {
              tools: tools,
              readOnly: true
            },
            enableLayoutEditing: true,
            enableLayoutSaving: true,
            readOnly: true,
            initialData: {
              itemContent: {
                1: {
                  blocks: [],
                },
                2: {
                  blocks: [],
                },
              },
              layout: {
                type: "container",
                id: "",
                className: "",
                style: "border: none;display:flex; padding:0",
                children: [
                  {
                    type: "item",
                    id: "",
                    className: "",
                    style: "border: none; padding:0",
                    itemContentId: "1",
                  },
                  {
                    type: "item",
                    id: "",
                    className: "",
                    style: "border: none;",
                    itemContentId: "2",
                  },
                ],
              },
            },
          },
          shortcut: "CMD+2",
          toolbox: {
            icon: `
              <svg xmlns='http://www.w3.org/2000/svg' width="16" height="16" viewBox='0 0 512 512'>
                <rect x='128' y='128' width='336' height='336' rx='57' ry='57' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/>
                <path d='M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/>
              </svg>
            `,
            title: "2 columns",
          },
        },
      },
    onReady: () => {
      var elements = document.querySelectorAll('[contenteditable=true]')
      elements.forEach(element => {
        element.setAttribute('contenteditable' , 'false');
      });
      window.addEventListener( "message",
          function (e) {
              if(e.data) {
                try {
                  let data = JSON.parse(e.data);
                  let iframe = document.querySelector('iframe[src="'+data.url+'"]');
                  if (iframe) {
                    iframe.parentElement.style.paddingTop = data.ratio+'%';
                  }
                } catch (error) {
                  
                }
              }
          });
    },
  });
  }

}
