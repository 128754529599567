import { Component, OnInit, OnChanges } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { HttpService } from '@core/services/http.service';

@Component({
  selector: 'im-public-page',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit, OnChanges {

  leftSide: any = false;
  rightSide: any = false;

  loginUser: any;

  constructor(private authService: AuthService, private http: HttpService) { }

  ngOnInit() {
    this.loginUser = this.authService.loginUser;

  }
  ngOnChanges() {
    this.loginUser = this.authService.loginUser;
  }

}
