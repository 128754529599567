import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'im-scratch',
  templateUrl: './scratch.component.html',
  styleUrls: ['./scratch.component.scss']
})
export class ScratchComponent implements OnInit {

  showHeader = false;

  navLinks = [
    {
      path: '/scratch',
      label: 'All'
    },
    {
      path: '/scratch/beginner',
      label: 'Beginner'
    },
    {
      path: '/scratch/intermediate',
      label: 'Intermediate'
    },
    {
      path: '/scratch/advanced',
      label: 'Advanced'
    },
    {
      path: '/scratch/my-workspace',
      label: 'My Workspace'
    },
   
  ];

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.router.events.subscribe((res)=>{
      this.checkHeader();
    })
     this.checkHeader();
  }

  checkHeader() {
    if(this.route.firstChild && (this.route.firstChild.routeConfig.path == "details/:id" || this.route.firstChild.routeConfig.path == "create")) {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
  }
}
