import { Routes } from '@angular/router';
import { CourseComponent } from './course.component';
import { AuthGuardService as AuthGuard } from '@core/services/guards/auth-guard.service';
import { ChapterComponent } from './chapter/chapter.component';
import { ListComponent } from './list/list.component';
import { FreeComponent } from './list/free/free.component';
import { AiTinkerComponent } from './list/aitinker/aitinker.component';
import { TopicComponent } from './topic/topic.component';
import { ProductListComponent } from './list/products/product-list.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { AllComponent } from './list/all/all.component';
import { MiniKitsComponent } from './list/mini-kits/mini-kits.component';
import { MinikitDetailsComponent } from './minikit-details/minikit-details.component';
import { MiniKitTopicComponent } from './mini-kit-topic/mini-kit-topic.component';

export const courseRoutes: Routes = [
  {
    path: 'our-offering',
    component: ListComponent,
    children: [
      { path: '', component: AiTinkerComponent },
      { path: 'bot-based-courses', component: ProductListComponent },
      { path: 'stage-courses', component: AiTinkerComponent },
      { path: 'short-courses', component: FreeComponent },
      { path: 'mini-kits', component: MiniKitsComponent },
    ],
  },
  { path: 'course/:slug', component: CourseComponent },
  { path: 'product/:slug', component: ProductDetailsComponent },
  { path: 'mini-kit/:slug', component: MinikitDetailsComponent },
  {
    path: 'mini-kit/:slug/:moduleSlug/nch/:topicSlug',
    component: MiniKitTopicComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'course/:slug/:moduleSlug/nch/:topicSlug',
    component: TopicComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'course/:slug/:moduleSlug/:chapterSlug',
    component: ChapterComponent,
    canActivate: [AuthGuard],
  },
];
