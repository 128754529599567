<ng-container *ngIf="!processing && courses.length == 0">
  <div class="empty-state flex items-center px-6 py-12 my-12 flex-column">
    <mat-icon class="border-radius-full">search</mat-icon>
    <p class="text-center mt-2 font-base color-secondary">
      No Courses available. <br />Please explore the by click on the below
      button.
    </p>
    <button
      mat-raised-button
      color="primary"
      class="mt-2"
      routerLink="/courses"
    >
      Explore
    </button>
  </div>
</ng-container>
<div class="grid has-4-columns xs:has-1-columns aitinker-section">
  <div
    class="column-start-1 column-end-3 xs:column-end-4 md:column-end-4 xl:column-end-3 mr-6 ml-4 xs:mr-4"
  >
    <p class="w-full mb-6 column-span-3 mt-8" style="line-height: 1.5rem">
      Select a suitable stage to start with as per your learning choice. We
      recommend you start with Stage 1 for a great foundation and proceed
      towards Robotics, IoT and beyond. You could also start with Stage 4
      directly i.e. ML and AI.
    </p>
    <div
      class="courses-list-section grid has-3-columns xs:has-1-columns row-gap-15 column-gap-15 xs:column-gap-0"
      *ngIf="!processing && courses.length > 0"
    >
      <ng-container *ngFor="let course of courses">
        <div class="item">
          <mat-card
            class="relative p-3 mat-card shadow-2 border-radius-lg course{{
              course.id
            }}-text course-border"
            style="min-height: 234px"
          >
            <mat-card-title class="font-xl mb-1 font-normal">
              <div
                class="flex justify-between course-card-title{{
                  course.properties.level
                }}"
                [attr.data-level]="course.properties.level"
                [ngClass]="{ 'course-card-title-group': course.group_course }"
              >
                <a
                  routerLink="/course/{{ course.slug }}"
                  class="flex items-center font-base"
                >
                  <div
                    class="shadow-1 border-radius-full mr-2"
                    style="width: 48px; height: 48px; background: azure"
                  >
                    <img
                      [src]="course.icon_p"
                      width="48"
                      style="width: 48px; height: 48px"
                      class="course-icon"
                    />
                  </div>

                  {{ course.name }}
                </a>
              </div>
            </mat-card-title>
            <mat-card-content>
              <!-- <ng-container *ngIf="course.user_course">  -->
              <div
                class="flex mb-1 items-center justify-evenly mt-4 mb-2"
                *ngIf="
                  course.properties.sessions && course.properties.engagement
                "
              >
                <div class="flex flex-column items-center justify-center">
                  <span
                    class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                    style="width: 32px; height: 32px"
                    >{{ course.properties.sessions }}</span
                  >
                  <span
                    class="color-primary highlight-item-title mt-2 font-semi-bold"
                    >Sessions</span
                  >
                </div>
                <div class="flex flex-column items-center justify-center">
                  <span
                    class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                    style="width: 32px; height: 32px"
                    >{{ course.properties.engagement }}</span
                  >
                  <span
                    class="color-primary highlight-item-title mt-2 font-semi-bold"
                    >Engagement</span
                  >
                </div>
              </div>

              <div class="flex mb-1 items-center justify-evenly mt-4 mb-2">
                <div class="flex flex-column items-center justify-center">
                  <span
                    class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                    style="width: 32px; height: 32px"
                    >{{ course.properties.stage }}</span
                  >
                  <span
                    class="color-primary highlight-item-title mt-2 font-semi-bold"
                    >Stage</span
                  >
                </div>
                <div class="flex flex-column items-center justify-center">
                  <span
                    class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                    style="width: 32px; height: 32px"
                    >{{ course.properties.engagement }}</span
                  >
                  <span
                    class="color-primary highlight-item-title mt-2 font-semi-bold"
                    >Engagement</span
                  >
                </div>
              </div>

              <!-- </ng-container> -->

              <p
                class="font-xs color-secondary line-clamp-2"
                *ngIf="!course.user_course"
              >
                {{ course.short_description }}
              </p>
            </mat-card-content>
            <mat-card-actions
              class="flex justify-between mat-card-actions"
              [ngClass]="{ 'flex-column': course.user_course }"
            >
              <ng-container *ngIf="!course.user_course">
                <button
                  mat-raised-button
                  color="primary"
                  class="letter-spacing-15 text-uppercase font-xss"
                  routerLink="/course/{{ course.slug }}"
                  style="height: 32px; line-height: 32px"
                >
                  Explore
                </button>
                <button
                  mat-stroked-button
                  class="letter-spacing-15 text-uppercase font-xss"
                  (click)="buyNow(course)"
                  style="height: 32px; line-height: 32px"
                  color="primary"
                >
                  Buy Now
                </button>
              </ng-container>
              <ng-container *ngIf="course.user_course">
                <div class="w-full">
                  <span class="float-right" style="margin-top: -1em">0%</span>
                  <mat-progress-bar
                    class="mt-4 w-full"
                    color="course{{ course.id }}"
                    mode="determinate"
                    value="0"
                    style="bottom: 0.75em"
                  ></mat-progress-bar>
                </div>
                <div class="w-full mt-2">
                  <span class="package_info opacity-background">{{
                    course.user_course?.package_properties?.name
                  }}</span>
                  <button
                    mat-mini-fab
                    class="float-right mat-elevation-z1"
                    routerLink="/course/{{ course.slug }}"
                    style="background: #ffbd32; color: #565050"
                  >
                    <mat-icon>east</mat-icon>
                  </button>
                </div>
              </ng-container>
            </mat-card-actions>
          </mat-card>
        </div>
      </ng-container>
    </div>
  </div>

  <div
    class="h-auto mt-8 pr-4 column-start-4 column-end-4 xs:display-none md:display-none xl:display-block"
  >
    <!-- <img
      src="assets/images/haas.jpeg"
      class="border-radius-lg"
      style="height: 98%; object-fit: contain; object-position: top"
    /> -->
    <div
      class="flex border-radius-lg flex-column py-6"
      style="line-height: 1.8rem; background: antiquewhite"
    >
      <h2 class="mb-2 pl-6">What will you learn?</h2>
      <ul class="pl-10 pr-4 font-xs">
        <li class="mb-2">
          A systematic multi-stage approach to engage students with emerging
          technologies. Students would master the topics and convert the
          knowledge into skill through project-based learning.
        </li>
        <li class="mb-2">
          Encouraging students to showcase their learning by solving real-life
          problems using emerging technologies such as AI.
        </li>
        <li class="mb-2">
          The pedagogy would reduce cognitive load and increase student
          engagement through online, offline, and self-learning modules.
        </li>
        <li>
          Students could select Kit and Non-kit based programs right from
          electronics, robotics, IoT, Data Science, and AI.
        </li>
      </ul>
    </div>
  </div>
</div>
