import { Component, OnInit, Inject, NgZone, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '@core/services/http.service';
import { AuthService } from '@core/services/auth.service';
import { PaymentService } from '../payment.service';

import { MatHorizontalStepper } from '@angular/material/stepper';


@Component({
  selector: 'im-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  loginDone: FormGroup;

  activePackage: any

  packages: any = {};

  loginUser: any;

  paymentStatus: String;

  paymentOrder: String;

  processing: boolean = false;

  currentStep = 0;

  isLinear = true;

  object = Object;

  checkingCoupon: boolean = false;

  couponInfoApply: any;

  packageCategoryTitles = {
    'hybrid_package': "Hybrid Packages",
    'e_learning': "E-learning Packages",
    'others': "Other Packages"
  }

  paymentData: any;

  couponNumber: string;

  isItFree: boolean = false;

  loginRequired: boolean = false;

  packageIndex = 0;

  @ViewChild("stepper", { static: false }) stepper: MatHorizontalStepper;


  constructor(public dialogRef: MatDialogRef<PackagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _formBuilder: FormBuilder, private http: HttpService, private authService: AuthService, private paymentService: PaymentService, private _ngZone: NgZone) { }

  ngOnInit() {
    this.loginUser = this.authService.loginUser;
    if(this.loginUser) {
        this.getProcess();
    } else {
      this.loginRequired  = true;
      this.firstFormGroup = this._formBuilder.group({});
      this.secondFormGroup = this._formBuilder.group({});
      this.loginDone = this._formBuilder.group({
        loinUser: ['', Validators.required]
      });
    }
   
  }

  loginSuccess(user) {
    this.loginUser = user;
    this.loginDone = this._formBuilder.group({
      loinUser: ['1', Validators.required]
    });
    this.getProcess();
    setTimeout(()=>{
      this.stepper.next();
      this.packageIndex = 1;
    },-1);
    
}

  getProcess() {
    this.getPackages();
    this.firstFormGroup = this._formBuilder.group({
      activePackage: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      name: [this.loginUser.name, Validators.required],
      address: ['', Validators.required],
      mobile_number: [this.loginUser.mobile_number, Validators.required],
      pincode: ['', Validators.required],
      city_district_town: ['', Validators.required],
      state: ['', Validators.required],
    });
    this.paymentService.paymentStatus.subscribe((res: any) => {
      this.paymentStatus = res.status;
      const data = { status: res.status };
      if (res.status === 'Success') {
        data['transaction_ref'] = res.response.razorpay_payment_id;
        data['razorpay'] = res.response;
      }
      this.http.put('payment/' + this.paymentOrder, data).subscribe((res: any) => {
        this.paymentService.courseRefresh.next({ course: this.data });
      })
    })
  }


  getPackages() {
    this.processing = true;
    let filter = "refId eq " + this.data.id + " and ref eq '" + this.data.packageType + "' and status eq 1";
    if (this.data.isItUpgrade) {
      filter += " and upgrade_package eq 1";
    } else {
      filter += " and upgrade_package eq 0";
    }
    this.http.get('packages?filter=' + filter).subscribe((res) => {
      res.data.forEach(item => {
        item['description_list'] = [];
        if (item.description) {
          item['description_list'] = item.description.split(/\n/);
        }
        if (item.properties.category) {
          if (!this.packages[item.properties.category]) this.packages[item.properties.category] = [];
          this.packages[item.properties.category].push(item);
        } else {
          if (!this.packages['other']) this.packages['other'] = [];
          this.packages['other'].push(item)
        }

      });
      //this.packages = res.data;
      if (Object.keys(this.packages).length > 0) {
        this.activePackage = res.data[0];
        this.selectPackage(this.activePackage);
      }
      this.processing = false;
    })
  }

  selectPackage(packageItem: any) {
    this.activePackage = packageItem;
    this.firstFormGroup.controls['activePackage'].setValue(packageItem);
  }

  saveBillingAddress() {
    if (this.secondFormGroup.valid) {
      this.authService.updateUserDetails(this.secondFormGroup.value);
    }
  }

  initiatePaymentGateway() {
    this.paymentStatus = 'Initialized';
    this.http.post('payment', { package: this.firstFormGroup.controls['activePackage'].value.id, ...this.secondFormGroup.value, couponNumber: this.couponNumber }).subscribe((res: any) => {
      this.paymentOrder = res.data.order_number;
      this.paymentData = res.data;
      if (this.paymentData.status == 'Success' && this.paymentData.price == 0) {
        this.paymentStatus = 'Success';
        this.paymentService.courseRefresh.next({ course: this.data });
      } else {
        this._ngZone.runOutsideAngular(() => {
          this.paymentService.payWithRazor(this.paymentData, this.loginUser);
        });
      }
    });
  }

  checkZipCode() {
    if (this.secondFormGroup.value['pincode'].length !== 6) return;
    this.http.post('extra/google_map/pincode', { pincode: this.secondFormGroup.value['pincode'] }).subscribe((res: any) => {
      console.log(res);
      if (res.city) {
        this.secondFormGroup.controls['city_district_town'].setValue(res.city);
      }
      if (res.state) {
        this.secondFormGroup.controls['state'].setValue(res.state);
      }
    });
  }

  close() {
    this.dialogRef.close({ status: this.paymentStatus });
  }

  stepChange($event) {
    this.currentStep = $event.selectedIndex;
  }

  onlyNumber(evt) {
    if (evt.which < 48 || evt.which > 57) {
      evt.preventDefault();
    }
  }

  couponApply(couponNumber) {
    if (!couponNumber) return;
    if (this.checkingCoupon) return;
    this.couponNumber = couponNumber;
    this.checkingCoupon = true;
    this.http.post('search/coupon', { couponCode: couponNumber, course: this.data.id, package: this.activePackage.id }).subscribe((res: any) => {
      this.checkingCoupon = false;
      if (res.success) {
        this.couponInfoApply = res.data;
        if (this.couponInfoApply.effected_price == 0) {
          this.isItFree = true;
        }
      } else {
        this.couponNumber = '';
      }
    })
  }

}
