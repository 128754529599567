import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';


import { CoderComponent } from './coder.component';
import { PythonComponent } from './python/python.component';
import { MysqlComponent } from './mysql/mysql.component';


@NgModule({
    declarations: [CoderComponent, PythonComponent, MysqlComponent, ],
    imports: [
      CommonModule,
      RouterModule,
      MatTabsModule,
      MatIconModule,
      MatStepperModule,
      MatButtonModule,
      MatFormFieldModule,
      MatInputModule,
      FormsModule,
      ReactiveFormsModule
    ]
  })
export class CoderModule { }