<div class="feed-list">
  <div class="container">

    <div class="main">

      <div class="main-container">

        <div class="timeline pt-4 w-full">

          <div class="timeline-right w-full">
            <div class="status box shadow-1 w-full">
              <div class="status-main">
                <img [src]="loginUser.avatar" class="status-img">
                <textarea class="status-textarea" placeholder="What's on your mind, {{loginUser.name}}?"
                  [(ngModel)]="content"></textarea>
              </div>
              <mat-divider></mat-divider>
              <div class="status-images">
                <input type="file" id="avatar" name="avatar" accept="image/png, image/jpeg" style="display: none;"
                  #streamImage (change)="uploadSource($event)">
                <div class="status-images-container">
                  <div class="mr-2 relative" *ngFor="let previewImage of previewImages;let pIi = index" style="width: 60px;
                  height: 60px;background-color: rgba(0,0,0,0.2);">
                    <img [src]="previewImage" width="60" height="60" class="image-item" />
                    <button mat-icon-button class="ml-2 cursor-pointer absolute right-0"
                      (click)="removeImage(previewImage, pIi)" style="width: 24px;height: 24px;top: -5px;">
                      <mat-icon style="font-size: 18px;">close</mat-icon>
                    </button>

                  </div>
                </div>
                <mat-divider *ngIf="previewImages.length > 0"></mat-divider>
              </div>
              <div class="status-actions">
                <button mat-stroked-button [matMenuTriggerFor]="menu" color="primary">
                  <ng-container *ngIf="privacy == 'PUBLIC'">
                    <div class="flex items-center">
                      <mat-icon class="mr-2">public</mat-icon>Public
                    </div>
                  </ng-container>
                  <ng-container *ngIf="privacy == 'PRIVATE'">
                    <div class="flex items-center">
                      <mat-icon class="mr-2">lock</mat-icon>Private
                    </div>
                  </ng-container>
                </button>
                <button mat-icon-button class="ml-2" (click)="streamImage.click()">
                  <mat-icon>image</mat-icon> Photo
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="privacy = 'PUBLIC'">
                    <div class="flex items-center">
                      <mat-icon class="mr-2">public</mat-icon>Public
                    </div>
                  </button>
                  <button mat-menu-item (click)="privacy = 'PRIVATE'">
                    <div class="flex items-center">
                      <mat-icon class="mr-2">lock</mat-icon>Private
                    </div>
                  </button>
                </mat-menu>
                <button class="status-share" (click)="create()">Share</button>
              </div>
            </div>
            <ng-container *ngFor="let post of feedList;let pi = index">
              <div class="album box shadow-1 w-full empty" *ngIf="!post.data">
                <div class="feed-loader">
                  <div class="feed-item">
                    <div class="animated-loading">
                      <div class="loading-masker header-top"></div>
                      <div class="loading-masker header-left"></div>
                      <div class="loading-masker header-right"></div>
                      <div class="loading-masker header-bottom"></div>
                      <div class="loading-masker subheader-left"></div>
                      <div class="loading-masker subheader-right"></div>
                      <div class="loading-masker subheader-bottom"></div>
                      <div class="loading-masker content-top"></div>
                      <div class="loading-masker content-first-end"></div>
                      <div class="loading-masker content-second-line"></div>
                      <div class="loading-masker content-second-end"></div>
                      <div class="loading-masker content-third-line"></div>
                      <div class="loading-masker content-third-end"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="album box shadow-1 w-full" *ngIf="post.data">
                <div class="status-main relative">
                  <img [src]="post.data.stream_info.user.profile_image" class="status-img" />
                  <div class="album-detail">
                    <div class="album-title"><strong>{{post.data.stream_info.user.name}}</strong></div>
                    <div class="album-date font-xss">{{moment(post.data.stream.created_at).fromNow()}}</div>
                  </div>
                  <ng-container *ngIf="post.data.stream_info.user.id == loginUser.id">
                    <button mat-icon-button (click)="delete(post, pi)" class="post_delete">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </ng-container>

                  <!-- <button class="intro-menu"></button> -->
                </div>
                <div class="album-content mt-2" [innerHtml]="'<pre>'+post.data.stream_info.content+'</pre>'"
                  *ngIf="post.data.stream_info.content">
                </div>
                <ng-container *ngIf="post.data.stream_info?.stream_link_details?.videoUrl">
                  <div style="padding-top: 56.25%; position: relative; overflow: hidden;"><iframe frameborder="0"
                      allowfullscreen="" scrolling="no" allow="autoplay;fullscreen"
                      [src]="post.data.stream_info?.stream_link_details?.videoUrl"
                      style="position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;"></iframe></div>

                </ng-container>

                <div class="album-photos" *ngIf="post.data.stream_info.medias?.length > 0">
                  <div class="album-right" *ngIf="post.data.stream_info.medias.length > 1">
                    <ng-container *ngFor="let image of post.data.stream_info.medias; let alpi = index">
                      <img src="{{image.name}}" alt="" class="album-photo" *ngIf="alpi > 0" />
                    </ng-container>

                  </div>
                  <div class="album-left">
                    <img src="{{post.data.stream_info.medias[0].name}}" alt="" class="album-photo" />
                  </div>

                </div>
                <mat-divider></mat-divider>
                <div class="album-actions">
                  <a href="javascript:void(0)" class="album-action" [ngClass]="{'active':post.data.stream.is_liked}"
                    (click)="like(post)">
                    <svg stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round"
                      stroke-linejoin="round" viewBox="0 0 24 24">
                      <path
                        d="M20.84 4.61a5.5 5.5 0 00-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 00-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 000-7.78z" />
                    </svg>
                    {{post.data.stream.like.count}}
                  </a>
                  <a href="javascript:void(0)" class="album-action">
                    <svg stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round"
                      stroke-linejoin="round" class="css-i6dzq1" viewBox="0 0 24 24">
                      <path d="M21 15a2 2 0 01-2 2H7l-4 4V5a2 2 0 012-2h14a2 2 0 012 2z" />
                    </svg>
                    {{post.data.stream.comment.count}}
                  </a>
                </div>
                <mat-divider></mat-divider>
                <div class="comment-box">
                  <div class="flex p-3 items-center">
                    <img [src]="loginUser.avatar" class="status-img comment-img">
                    <mat-form-field appearance="outline" class="w-full mr-2" style="font-size: 0.85em;">
                      <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                        placeholder="Write a comment..." #commentText
                        (keyup.enter)="comment(post, commentText)"></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div class="comments-list px-3 py-2" *ngIf="post.data.stream.comment.comments">
                  <div class="flex mb-2" *ngFor="let imcomment of post.data.stream.comment.comments; let ci = index">
                    <img [src]="imcomment.user.profile_image" class="status-img comment-img mt-4">
                    <div class="flex flex-column p-4 border-radius-lg w-full relative"
                      style="background: var(--grey-color);">
                      <div class="album-title flex items-center justify-between">
                        <strong>{{imcomment.user.name}}</strong>
                        <div class="album-date font-xss">
                          {{moment(imcomment.created_at).fromNow()}}
                          <ng-container *ngIf="imcomment.user.id == loginUser.id">
                            <button mat-icon-button (click)="deleteComment(post, imcomment, ci)" class="comment_delete">
                              <mat-icon>delete</mat-icon>
                            </button>
                          </ng-container>
                        </div>
                      </div>
                      <div>
                        {{imcomment.comment}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>