<form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
  <div style="display: flex; justify-content: space-between">
    <div class="namebox">
      <label for="firstName">First Name</label>
      <div>
        <input type="text" id="firstName" formControlName="firstName" />
      </div>
    </div>

    <div>
      <label for="lastName">Last Name</label>
      <div>
        <input type="text" id="lastName" formControlName="lastName" />
      </div>
    </div>
  </div>
  <div>
    <label for="email">Email</label>
    <div>
      <input type="text" id="email" formControlName="email" />
    </div>
  </div>

  <div>
    <label for="selectOption">Select Option</label>
    <div>
      <select id="selectOption" formControlName="selectOption">
        <option value="option1">Option 1</option>
        <option value="option2">Option 2</option>
        <!-- Add more options as needed -->
      </select>
    </div>
  </div>

  <div>
    <label for="textArea">Text Area</label>
    <div>
      <textarea id="textArea" formControlName="textArea"></textarea>
    </div>
  </div>

  <button type="submit" class="custom-button">Submit</button>
</form>
