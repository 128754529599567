<!-- <div class="im-bar-coder">
  <nav mat-tab-nav-bar>
    <a mat-tab-link class="letter-spacing-15" [active]="'true'"> Scratch </a>
  </nav>
  <img
    src="assets/images/logotag2.png"
    style="object-fit: contain"
    width="160"
    alt="SchoolForAI Logo"
    class="mr-4"
  />
</div> -->
<!-- <div class="mx-6 my-4 flex justify-between align-items-center">
  <h2 class="mat-color-primary font-3xl">AI with Block Coding</h2>
  <img
    src="assets/images/logotag2.png"
    style="object-fit: contain"
    width="160"
    alt="AITinkr Logo"
  />
</div>
<div class="mx-6 my-2">
  <mat-chip-list aria-label="Categories">
    <mat-chip selected class="cursor-pointer">All</mat-chip>
    <mat-chip class="cursor-pointer">Beginner</mat-chip>
    <mat-chip class="cursor-pointer">Intermediate</mat-chip>
    <mat-chip class="cursor-pointer">Advanced</mat-chip>
    <mat-chip class="cursor-pointer">My Workspace</mat-chip>
  </mat-chip-list>
</div> -->

<div
  class="grid has-4-columns xs:has-1-columns column-gap-15 mb-6 ml-6 mt-6 mr-6 scratch-items-section row-gap-15"
>
  <div class="item">
    <mat-card class="mat-card border-radius-lg" [style.background]="'#8b4324'">
      <mat-card-content>
        <div class="icon-info">
          <div class="border-radius-full">
            <img src="assets/images/block_icon.svg" width="32" height="32" />
          </div>
        </div>
        <h3 class="font-semi-bold mt-1" [style.color]="'#e4efff'">
          Create New Canvas
        </h3>
        <p class="mt-1 line-clamp-3 font-xs" [style.color]="'#ced5e0'">
          Create stories, games, and animations
        </p>
        <button
          mat-mini-fab
          class="mt-3 float-right mat-elevation-z1 absolute right-15-px bottom-15-px"
          routerLink="/scratch/create"
        >
          <mat-icon>east</mat-icon>
        </button>
      </mat-card-content>
    </mat-card>
  </div>
  <ng-container *ngFor="let item of data">
    <div class="item">
      <mat-card class="mat-card border-radius-lg">
        <mat-card-content>
          <div class="absolute right-10-px top-5-px">
            <mat-chip-list aria-label="model tags">
              <mat-chip color="primary" selected>{{
                item.properties?.tags
              }}</mat-chip>
            </mat-chip-list>
          </div>
          <div class="icon-info">
            <div class="border-radius-full">
              <img [src]="item.icon" width="32" height="32" />
            </div>
          </div>
          <h3 class="mt-1">{{ item.title }}</h3>
          <p class="mt-1 line-clamp-3 color-secondary font-xs">
            {{ item.short_description }}
          </p>
          <button
            mat-mini-fab
            class="mt-3 float-right mat-elevation-z1 absolute right-15-px bottom-15-px"
            routerLink="/scratch/details/{{ item.slug }}"
          >
            <mat-icon>east</mat-icon>
          </button>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>
</div>
