import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'; 

@Injectable({
  providedIn: 'root'
})
export class NoteService {

    activeNoteId = new Subject<any>();

    refreshNotes = new Subject<any>();

    updateNoteInfo = new Subject<any>();
    

}