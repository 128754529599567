<ng-container *ngIf="checkingUserModels">
  <div class="flex flex-column items-center justify-center h-screen relative">
    <mat-spinner strokeWidth="3"></mat-spinner>
    <img src="assets/images/icon.png" class="absolute" width="64" />
  </div>
</ng-container>
<ng-container *ngIf="!checkingUserModels">
  <div class="im-bar" *ngIf="activeModel == ''">
    <div class="flex justify-between items-center">
      <h3
        class="ml-6 letter-spacing-10 font-semi-bold flex items-center"
        style="color: #8b4324"
      >
        <mat-icon class="mr-2 material-symbols-outlined"
          >developer_guide</mat-icon
        >
        AI Labs
      </h3>

      <img
        src="assets/images/logotag2.png"
        style="object-fit: contain"
        width="160"
        alt="SchoolForAI Logo"
        class="mr-4"
      />
    </div>
    <nav mat-tab-nav-bar>
      <a
        mat-tab-link
        *ngFor="let link of navLinks"
        [routerLink]="link.path"
        routerLinkActive
        #rla="routerLinkActive"
        [active]="rla.isActive"
        class="letter-spacing-10"
        [routerLinkActiveOptions]="{ exact: false }"
      >
        {{ link.label }}
      </a>
    </nav>
  </div>

  <router-outlet> </router-outlet>
</ng-container>
