<ng-container *ngIf="processing">
  <div
    class="flex flex-column items-center justify-center relative"
    style="height: 100vh"
  >
    <mat-spinner strokeWidth="3"></mat-spinner>
    <img src="assets/images/icon.png" class="absolute" width="64" />
  </div>
</ng-container>

<iframe
  [src]="coderUrl"
  frameborder="0"
  class="scratch-wrapper"
  allow="fullscreen *;camera *; microphone *"
  id="scratch-iframe"
></iframe>
