import { Component, OnInit, AfterViewInit } from '@angular/core';
import { environment } from '@environment/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'im-scratch-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit, AfterViewInit {

  coderUrl: any;

  processing: boolean = true;

  constructor(private route: ActivatedRoute, private router: Router, protected sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.coderUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.scratchUrl);
    
  }

  ngAfterViewInit(): void {
    const that = this;
      (<any>document.querySelector('#scratch-iframe')).onload = () => {
        that.processing = false;
      };
  }

}
