<ng-container *ngIf="state == ''">
    <div class="flex flex-column justify-center w-full items-center xs:w-full xs:mt-4" style="min-height: 550px;">
        <mat-spinner strokeWidth="3"></mat-spinner>
    </div>
</ng-container>
<ng-container *ngIf="state == 'login'">
    <im-auth isItComonent="true" (loginCallback)="loginCallback($event)"></im-auth>
</ng-container>
<ng-container *ngIf="state == 'password'">
    <im-auth-password isItComonent="true" [compEmail]="email" (passwordCallback)="loginCallback($event)">
    </im-auth-password>
</ng-container>
<ng-container *ngIf="state == 'reset'">
    <im-auth-reset isItComonent="true" [compEmail]="email" (resetCallback)="loginCallback($event)"></im-auth-reset>
</ng-container>
<ng-container *ngIf="state == 'verify'">
    <im-auth-verify isItComonent="true" [compEmail]="email" (verifyCallback)="loginCallback($event)"></im-auth-verify>
</ng-container>