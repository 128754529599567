import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../../core/services/http.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'im-my',
  templateUrl: './my.component.html',
  styleUrls: ['./my.component.scss']
})
export class MyComponent implements OnInit {

  categoryModels: any;

  categorySlug: any;

  processing: boolean = false;

  customSort = ({data, sortBy}) => {
    const sortByObject = sortBy.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index
      }
    }, {})
    return data.sort((a, b) => {
      return sortByObject[a['properties']['tags']] - sortByObject[b['properties']['tags']]
    });
  }


  constructor(private http: HttpService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.categorySlug = this.route.snapshot.paramMap.get('slug');
    this.route.params.subscribe(params => {
      this.categorySlug = params.slug;
      this.getModels();
    });
    
  }

  getModels() {
    this.processing = true;
    this.http.get('ai_model_categories/'+this.categorySlug).subscribe((res)=>{
        this.categoryModels = res.data;
        this.categoryModels.ai_models = this.customSort({data: this.categoryModels.ai_models, sortBy: ['Basic', 'Intermediate', 'Advanced']});
        this.processing = false;
    });
  }

}
