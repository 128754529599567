import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpService } from '../../services/http.service';
import { environment } from '@environment/environment';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  @Input() field: any;

  @Input() parentGroup: any;

  @Input() control: any;

  @Output() fileSuccess= new EventEmitter<any>();
 
  sourceList =  [];

  previewImage: any;

  disabled : boolean = false;

  customPath: string;

  storageUrl = environment.storageUrl;

  process : boolean = false;

  constructor(private http: HttpService) { }

  excuteCondition(conditions, value:any) {
    conditions.forEach(conditionParam => {
      if (eval(conditionParam.condition)) {
          this[conditionParam.type] = true;
      } else {
          this[conditionParam.type] = false;
      }
    });
      
  }

  ngOnInit() {
    this.process = true;
    if (this.control.value) {
        if (this.field.multiple) {
          this.control.value.forEach(element => {
              this.sourceList.push({real_name: element, name: element})
          });
        } else {

        }
    }
    this.customPath = '/'+this.field.key;
    if (this.field.customPath) {
      this.customPath = '/'+this.field.key+'/'+this.parentGroup.value[this.field.customPath];
    }
    if (this.parentGroup && this.field.conditions) {
      this.excuteCondition(this.field.conditions, this.parentGroup.value)
      this.parentGroup.valueChanges.subscribe(value => {
        this.excuteCondition(this.field.conditions, value);
        if (this.field.customPath) {
          this.customPath = '/'+this.field.key+'/'+value[this.field.customPath];
        }
      });
    }
    this.process = false;
    
  }

   dropHandler(ev){
  	ev.preventDefault();

  	if (ev.dataTransfer.items) {
	    // Use DataTransferItemList interface to access the file(s)
	    for (var i = 0; i < ev.dataTransfer.items.length; i++) {
	      // If dropped items aren't files, reject them
	      if (ev.dataTransfer.items[i].kind === 'file') {
	        var file = ev.dataTransfer.items[i].getAsFile();
	        this.sourceList[i] = {name: file.name};
	        console.log('... file[' + i + '].name = ' + file.name);
	        this.upload(file, i);
	      }
	    }
	  } else {
	    // Use DataTransfer interface to access the file(s)
	    for (var i = 0; i < ev.dataTransfer.files.length; i++) {
	      console.log('... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
	    }
	  } 
  		// Pass event to removeDragData for cleanup
  	this.removeDragData(ev)
  }

  removeDragData(ev) {
		  console.log('Removing drag data');

		  if (ev.dataTransfer.items) {
		    // Use DataTransferItemList interface to remove the drag data
		    ev.dataTransfer.items.clear();
		  } else {
		    // Use DataTransfer interface to remove the drag data
		    ev.dataTransfer.clearData();
		  }
  }

  onDragOver(event) {
        event.stopPropagation();
        event.preventDefault();
  }

  uploadSource(e){
    for (var i = 0; i < e.target.files.length; i++) {
      this.sourceList.push({name: e.target.files[i].name});
      this.upload(e.target.files[i], (this.sourceList.length-1));
    }
  }

 upload(source, index){
   let that = this;
   this.sourceList[index]['uploading'] = true;
  	let ajaxData = new FormData();
  	ajaxData.append('file[0]',source);
    var reader = new FileReader();
    reader.onload = function(e:any) {
      that.previewImage = e.target.result;
    }
    reader.readAsDataURL(source);
    ajaxData.append('upload_path', this.field.upload_path+this.customPath);
    ajaxData.append('custom_attrs', '[]');
    ajaxData.append('random_names', 'true');
    this.http.upload('media/upload', ajaxData).subscribe((res:any) => {
        if(res.length == 1 && res[0].success) {
          if (this.field.multiple) {
            this.control.push(new FormControl(res[0].file_name));
          } else {
            this.control.setValue(res[0].file_name);
          }
          this.sourceList[index]['name'] = res[0].file_name;
          this.sourceList[index]['real_name'] = res[0].file_name;
          this.sourceList[index]['uploading'] = false;
          this.fileSuccess.emit(res[0]);
        }
    });
  }

  removeImage(source, i) {
    let ajaxData = {
        deleted_files : [
          {
            name:  source.real_name,
            storage_path: this.field.upload_path+this.customPath+'/'+source.real_name,
            type: 'file'
          }
        ]
    }
    this.http.upload('media/delete-file', ajaxData).subscribe((res:any) => {
      this.sourceList.splice(i,1);
      this.control.removeAt(i);
    })
  }

}
