<button class="game-create-close" routerLink="/ai-coder/teachable-machine">
	<mat-icon>cancel</mat-icon>
 </button>
 <div class="wrapper teachable-machine-create">
	<div class="intro">
	   <div class="intro__inner">
		  <h1 class="intro__title"><span class="hidden-text"></span><img
			 src="assets/images/Build_a_game.png" alt="" class="intro__title-image intro__title-image--desktop"><img src="assets/images/Build_a_game.png" alt="" class="intro__title-image intro__title-image--mobile"></h1>
		  <span class="a-desktop">
			 <p class="intro__text">Teach a machine using your camera,<span class="intro__text-break"></span> live in the browser. No coding required.</p>
			 <div class="intro__cta">
				<a href="#" class="button button--large button--color-blue" id="start-tutorial-button"> <span class="button__content">
				<span class="icon icon--speaker">
				</span>
				Let’s Go!</span>
				</a>
				<p class="wizard__launch-skip-paragraph display-none">or <a href="#" class="link wizard__launch-skip" id="skip-tutorial-button">skip the tutorial</a>
				</p>
				<p class="wizard__browser-warning">Sorry, your browser doesn’t support the latest technologies.
				   <br>Download <a class="link" href="https://www.google.com/chrome/browser/desktop/index.html" target="_blank">Google Chrome</a> to use this and other experiments.
				</p>
			 </div>
		  </span>
		  <span class="intro__content-mobile" id="is-compatible">
			 <p class="font__weight--semi-bold intro__content-mobile-header">Mobile version <br>is highly experimental.</p>
			 <p class="intro__content-mobile-body">If you’re new to machine learning, visit the site on a desktop for a helpful first-time tutorial and better performance.</p>
			 <p class="intro__content-mobile-ok">
				<a href="#" class="link font__weight--semi-bold intro__content-mobile-ok-link"
				   id="skip-tutorial-button-mobile">GOT IT</a>
			 </p>
		  </span>
		  <span class="intro__content-mobile-incompatible" id="is-not-compatible">
		  Sorry, looks like your browser or device doesn't support this experiment.
		  </span>
	   </div>
	</div>
	<div class="machine" id="machine">
	   <div class="machine__sections">
		<div id="learning-section" class="section learning section--learning">
			<a href="#" id="learning-condensed-button" class="learning-condensed-button"> <span class="arrow-line"></span>
			<span class="arrow-line"></span>
			<span class="arrow-line"></span>
			<span class="arrow-line"></span>
			</a>
			<h2 class="section__title section__title--learning"><span>Learning</span></h2>
			<div class="section__container">
			   <div id="green" class="learning__class learning__class--disabled learning__class--green">
				  <div class="examples">
					 <div class="machine__status examples__status"><span class="examples__counter">0</span> Images</div>
					 <div class="examples__wrapper">
						<img src="assets/teachable/close.svg" class="examples__close-icon"> <a href="#" class="link link--reset">Reset</a>
						<canvas class="examples__viewer"></canvas>
					 </div>
				  </div>
				  <div class="learning__class-column justify-center">
					
					 <a href="#" class="button button--record button--color-green"><span class="button__content button__content--small">Train <br>Bucket 1</span></a>
				  </div>
			   </div>
			   <div id="purple" class="learning__class learning__class--disabled learning__class--purple">
				  <div class="examples">
					 <div class="machine__status examples__status"><span class="examples__counter">0</span> Images</div>
					 <div class="examples__wrapper">
						<img src="assets/teachable/close.svg" class="examples__close-icon"> <a href="#" class="link link--reset">Reset</a>
						<canvas class="examples__viewer"></canvas>
					 </div>
				  </div>
				  <div class="learning__class-column justify-center">
					
					 <a href="#" class="button button--record button--color-purple"><span class="button__content button__content--small">Train <br>Bucket 2</span></a>
				  </div>
			   </div>
			   <div id="orange" class="learning__class learning__class--disabled learning__class--orange">
				  <div class="examples">
					 <div class="machine__status examples__status"><span class="examples__counter">0</span> Images</div>
					 <div class="examples__wrapper">
						<img src="assets/teachable/close.svg" class="examples__close-icon"> <a href="#" class="link link--reset">Reset</a>
						<canvas class="examples__viewer"></canvas>
					 </div>
				  </div>
				  <div class="learning__class-column justify-center">
					
					 <a href="#" class="button button--record button--color-orange"><span class="button__content button__content--small">Train <br>Bucket 3</span></a>
				  </div>
			   </div>
			</div>
		 </div>
		  <div class="wires wires--disabled wires--left">
			 <!-- Generator: Adobe Illustrator 19.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
			 <svg version="1.1" class="wires-svg" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 323 68.8" style="enable-background:new 0 0 323 68.8;" xml:space="preserve">
				<style type="text/css">
				   .st0{fill:none;stroke-miterlimit:10;}
				</style>
				<path class="st0 wire-base-green" d="M152.8,0c0,39.8-151.3,5.1-151.3,68.8" />
				<path class="st0 wire-base-purple" d="M170.2,0c0,39.8,151.3,5.1,151.3,68.8" />
				<line class="st0 wire-base-orange" x1="161.5" y1="0" x2="161.5" y2="68.8" />
				<path class="st0 wire-green" d="M152.8,0c0,39.8-151.3,5.1-151.3,68.8" />
				<path class="st0 wire-purple" d="M170.2,0c0,39.8,151.3,5.1,151.3,68.8" />
				<line class="st0 wire-orange" x1="161.5" y1="0" x2="161.5" y2="68.8" />
			 </svg>
		  </div>
		  <div id="input-section" class="section section--disabled input section--input">
			<h2 class="section__title"><span>Input</span></h2>
			<div class="section__container">
			   <div class="input__media"></div>
			   <a href="" class="input__media__flip" id="input__media__flip"></a>
			</div>
			<div id="im_confidence">
				<div class="learning__class-column">
					<div class="confidence green">
						<div class="machine__status confidence__status">Bucket 1</div>
						<div class="machine__meter">
						   <div class="machine__value machine__value--color-green">
							  <div class="machine__percentage machine__percentage--white">0%</div>
						   </div>
						</div>
					 </div>
					 <div class="confidence purple">
						<div class="machine__status confidence__status">Bucket 2</div>
						<div class="machine__meter">
						   <div class="machine__value machine__value--color-purple">
							  <div class="machine__percentage machine__percentage--white">0%</div>
						   </div>
						</div>
					 </div>
					 <div class="confidence orange">
						<div class="machine__status confidence__status">Bucket 3</div>
						<div class="machine__meter">
						   <div class="machine__value machine__value--color-orange">
							  <div class="machine__percentage machine__percentage--white">0%</div>
						   </div>
						</div>
					 </div>

				</div>
			</div>
		 </div>
		 
		  <div class="wires wires--disabled wires--right">
			 <!-- Generator: Adobe Illustrator 19.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
			 <svg version="1.1" class="wires-svg" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 323 77.6" style="enable-background:new 0 0 323 77.6;" xml:space="preserve">
				<filter id="blur-filter" x="-2" y="-2" width="100" height="100">
				   <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
				</filter>
				<style type="text/css">
				   .st0{fill:none;stroke:#CED1D2;stroke-width:3;stroke-miterlimit:10;}
				   .st1{fill:#F38759;}
				   .st2{fill:#C174AE;}
				   .st3{fill:#5EB84E;}
				</style>
				<path class="st0 wire--right-green" d="M75.1,69.4C75.1,29.3,1.5,64.2,1.5,0" />
				<line class="st0 wire--right-purple" x1="161.5" y1="69.4" x2="161.5" y2="0" />
				<path class="st0 wire--right-orange" d="M248.5,69.4c0-40.1,73-5.2,73-69.4" />
				<path class="st3 wire--right-bulb-green" d="M83.9,73.8v3.8H66.3v-3.8c0-2.4,1.9-4.3,4.3-4.3h9C82,69.4,83.9,71.4,83.9,73.8z" />
				<path class="st2 wire--right-bulb-purple" d="M170.3,73.8v3.8h-17.6v-3.8c0-2.4,1.9-4.3,4.3-4.3h9C168.4,69.4,170.3,71.4,170.3,73.8z" />
				<path class="st1 wire--right-bulb-orange" d="M257.3,73.8v3.8h-17.6v-3.8c0-2.4,1.9-4.3,4.3-4.3h9C255.4,69.4,257.3,71.4,257.3,73.8z" />
				<path class="st3 wire--right-bulb-green-glow" d="M83.9,73.8v3.8H66.3v-3.8c0-2.4,1.9-4.3,4.3-4.3h9C82,69.4,83.9,71.4,83.9,73.8z" />
				<path class="st2 wire--right-bulb-orange-glow" d="M170.3,73.8v3.8h-17.6v-3.8c0-2.4,1.9-4.3,4.3-4.3h9C168.4,69.4,170.3,71.4,170.3,73.8z" />
				<path class="st1 wire--right-bulb-purple-glow" d="M257.3,73.8v3.8h-17.6v-3.8c0-2.4,1.9-4.3,4.3-4.3h9C255.4,69.4,257.3,71.4,257.3,73.8z" />
			 </svg>
		  </div>
		  <div id="output-section" class="section section--disabled output section--output">
			 <h2 class="section__title"><span>Output</span></h2>
			 <div class="section__container">
				<div class="output__controls">
				   <div class="output__selector-wrapper">
					  <div id="GIFOutput" class="output_selector__option output_selector__option--selected">GIF</div>
					  <div id="SoundOutput" class="output_selector__option">Sound</div>
					  <div id="SpeechOutput" class="output_selector__option">Speech</div>
				   </div>
				</div>
				<div class="divider output__divider"></div>
				<div id="output-player" class="output__player"></div>
			 </div>
		  </div>
	   </div>
	</div>
	<div class="wizard__wrapper">
	   <div class="wizard" id="wizard">
		  <div class="wizard__inner">
			 <a href="#" class="wizard__sound-button">
				<div class="wizard__sound-icon wizard__sound-icon--on"></div>
			 </a>
			 <div class="wizard__text">
				<div class="wizard__text-inner"></div>
				<div class="wizard__timer">
				   <div class="wizard__timer-fill"></div>
				</div>
			 </div>
			 <a href="#" class="wizard__skip-button">Skip<span> Tutorial</span></a>
		  </div>
	   </div>
	</div>
	<p id="input__media__activate" class="input__media__activate"></p>
 </div>