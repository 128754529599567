import {
  Component,
  OnInit,
  ViewChildren,
  AfterViewInit,
  Inject,
} from '@angular/core';
import { HttpService } from '../../../core/services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environment/environment';
import moment from 'moment-timezone';
import { AuthService } from '@core/services/auth.service';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import RawTool from '@editorjs/raw';
import SimpleImage from '@editorjs/simple-image';
import Checklist from '@editorjs/checklist';
import Embed from '../../../core/ui/editor/embed';
import Quote from '@editorjs/quote';
import Table from '@editorjs/table';
import ImageTool from '@editorjs/image';
import CodeTool from '@editorjs/code';
import LinkTool from '@editorjs/link';
import Marker from '@editorjs/marker';
import Warning from '@editorjs/warning';
import Paragraph from '@editorjs/paragraph';
import InlineCode from '@editorjs/inline-code';
import EditorJS from '@editorjs/editorjs';
import AttachesTool from '../../../core/ui/editor/attaches';
import { PaymentService } from '@modules/payment/payment.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { OnlineComponent } from '../../schedule/online/online.component';
import { DomSanitizer } from '@angular/platform-browser';
import { IframeJoinComponent } from '../iframe-join/iframe-join.component';

import {
  MatBottomSheet,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';

@Component({
  selector: 'im-details',
  templateUrl: './details.component.html',
  styleUrls: [
    './details.component.scss',
    '../../../core/ui/editor/attaches/index.scss',
    '../../../core/ui/editor/embed/index.scss',
  ],
})
export class DetailsComponent implements OnInit {
  editor: any;
  event_id: any;
  processing: boolean = true;
  event: any;
  eventData: any;
  surveySubmitted = false;

  tabIndex = 0;

  selectedIndex = 0;

  startEnableJoin: boolean = false;

  days = [];
  currentDay = moment(new Date().getTime()).tz('Asia/Kolkata').startOf('day');
  //moment = moment;
  loginUser: any;

  joinStatus: boolean[] = [false, false, false];

  currentActiveDayIndex = -1;

  notEnrolled = true;

  matStepperIcons: any[];

  detailsShow = false;

  maticons = [
    'lightbulb',
    'event_available',
    'event_available',
    'event_available',
    'card_membership',
  ];

  eventStart = false;

  meetingWindow: any;

  weekBasedResources: any = {};

  weekBasedProjects = [];

  weekBasedAssignments = [];

  practicalEditors = {};

  selectedProjectIndex = 0;

  selectedAssignmentIndex = 0;

  selectedResourceIndex = 0;

  constructor(
    public dialog: MatDialog,
    private paymentService: PaymentService,
    private auth: AuthService,
    private http: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private _bottomSheet: MatBottomSheet
  ) {}

  ngOnInit() {
    this.loginUser = this.auth.loginUser;
    if ('step' in this.route.snapshot.queryParams) {
      this.selectedIndex = this.route.snapshot.queryParams.step;
    }
    if (this.route.snapshot.paramMap.get('event_slug')) {
      this.event_id = this.route.snapshot.paramMap.get('event_slug');
      this.getEvent();
    } else {
      //this.router.navigate(['/schedule']);
    }
    this.paymentService.courseRefresh.subscribe((res) => {
      //this.getCourse(res);
      window.location.reload();
    });
  }

  tabChange($event) {
    console.log($event);
    this.tabIndex = $event.selectedIndex;

    if (this.tabIndex == 0) {
      setTimeout(() => {
        this.processEditor();
      });
    }
    if (
      this.eventData &&
      this.tabIndex > 0 &&
      this.tabIndex <= this.eventData.weeks.length
    ) {
      if (this.weekBasedResources[this.tabIndex]) {
        this.http
          .get(
            'workshops/' +
              this.event.id +
              '/workshop_day_wise_notes?type=projects'
          )
          .subscribe((res) => {
            this.weekBasedProjects = res.data;
          });
        this.http
          .get(
            'workshops/' +
              this.event.id +
              '/workshop_day_wise_notes?type=assignments'
          )
          .subscribe((res) => {
            this.weekBasedAssignments = res.data;
          });
      }
    }
  }

  complete_step() {
    this.http
      .post(
        'user_event/' + this.event.user_event.id + '/event_program_user_track',
        { step: this.tabIndex }
      )
      .subscribe((res) => {
        this.event.user_event = res.data;
      });
  }

  surveySubmitStatus($event) {
    this.surveySubmitted = $event;
  }

  join() {
    if (this.startEnableJoin) {
      this._bottomSheet.open(IframeJoinComponent, {
        data: { link: this.event.link },
        panelClass: 'zoom-meeting-link-sheet',
        hasBackdrop: false,
      });

      // if (this.meetingWindow == null || this.meetingWindow.closed) {
      //   let height = window.screen.availHeight - 100;
      //   let width = window.screen.availWidth - 200;
      //   this.meetingWindow = window.open(
      //     this.event.link,
      //     this.event.name + ' - AITINKR',
      //     `directories=0,titlebar=0,toolbar=0,location=no,status=0,width=${width},height=${height},scrollbars=1, popup=yes, left=64`
      //   );
      // } else {
      //   this.meetingWindow.focus();
      // }
    }
    // let data: any = { 'type': 'day' };
    // data['day'] = this.tabIndex - 2;
    // this.http.post('user_event/' + this.event.user_event.id + '/event_program_user_track', data).subscribe((res) => {
    //   this.event.user_event = res.data;
    // });
    // let a = document.createElement('a');
    // a.target = '_blank';
    // a.href = this.event.link;
    // a.click();
    // const dialogRef = this.dialog.open(OnlineComponent, {
    //   width: 'calc(100vw - 84px)',
    //   maxWidth: 'calc(100vw - 84px)',
    //   minHeight: '100vh',
    //   data: this.event,
    //   panelClass: 'online-workshop-section',
    //   disableClose: true,
    //   hasBackdrop: false,
    // });
    // if (this.event.link.includes('meet.google.com')) {
    //   this.googleLink();
    //   return;
    // } else {
    //   let role = 0;
    //   if (this.loginUser.user_type == 'trainer') {
    //     role = 1;
    //   }
    //   const that = this;
    //   this.http
    //     .postExternal('https://zoom.cedlearn.com/', {
    //       meetingNumber: this.event.meetingId,
    //       role,
    //     })
    //     .subscribe((res) => {
    //       let meetingConfig = {
    //         mn: that.event.meetingId,
    //         name: that.loginUser.name,
    //         role,
    //         signature: res.signature,
    //         apiKey: res.api_key,
    //         pwd: that.event.link_pwd,
    //         email: '',
    //         //lu: this.b64EncodeUnicode(this.document.location.href)
    //       };

    //       const rootElement = document.getElementById('ZoomEmbeddedApp');
    //       const zmClient: any = (<any>window).ZoomMtgEmbedded.createClient();

    //       zmClient
    //         .init({
    //           debug: true,
    //           zoomAppRoot: rootElement,
    //           // assetPath: 'https://websdk.zoomdev.us/2.0.0/lib/av', //default
    //           webEndpoint: 'zoom.us',
    //           language: 'en-US',
    //           customize: {
    //             meetingInfo: [this.event.name],
    //             toolbar: {
    //               buttons: [
    //                 // {
    //                 //   text: "CustomizeButton",
    //                 //   className: "CustomizeButton",
    //                 //   onClick: () => {
    //                 //     console.log("click Customer Button");
    //                 //   },
    //                 // },
    //               ],
    //             },
    //           },
    //         })
    //         .then((e) => {
    //           console.log('success', e);
    //         })
    //         .catch((e) => {
    //           console.log('error', e);
    //         });

    //       // WebSDK Embedded join
    //       zmClient
    //         .join({
    //           sdkKey: meetingConfig.apiKey,
    //           signature: meetingConfig.signature,
    //           meetingNumber: meetingConfig.mn,
    //           userName: meetingConfig.name,
    //           password: meetingConfig.pwd,
    //           userEmail: meetingConfig.email,
    //         })
    //         .then((e) => {
    //           console.log('success', e);
    //         })
    //         .catch((e) => {
    //           console.log('error', e);
    //         });
    //     });
    // }
  }

  googleLink() {
    if (this.startEnableJoin) {
      if (this.meetingWindow == null || this.meetingWindow.closed) {
        this.meetingWindow = window.open(
          this.event.link,
          'Google Meet - SchoolforAI',
          'directories=0,titlebar=0,toolbar=0,location=0,status=0,width=650,height=350,scrollbars=1'
        );
      } else {
        this.meetingWindow.focus();
      }
    }
  }

  downloadCer() {}

  getEvent() {
    this.processing = true;
    this.http
      .getExternal(
        environment.storageUrl +
          'json/' +
          this.event_id +
          '.json' +
          '?t=' +
          new Date().getTime()
      )
      .subscribe((res) => {
        this.eventData = res;
        this.eventData.weeks.forEach((week, index) => {
          if ('resources_enable' in week)
            this.weekBasedResources[index + 1] = week.resources_enable;
          else {
            this.weekBasedResources[index + 1] = false;
          }
        });
      });
    let purl = 'na/workshops/';
    if (this.loginUser) {
      purl = 'workshops/';
    }
    this.http.get(purl + this.event_id).subscribe((res) => {
      this.event = res.data;
      if (this.event.user_event) {
        this.notEnrolled = false;

        if (
          moment
            .tz(this.event.event_start_date, 'Asia/Kolkata')
            .startOf('day')
            .diff(this.currentDay) > 0
        ) {
          this.eventStart = false;
          if (this.selectedIndex == 0) {
            setTimeout(() => {
              this.processEditor();
            });
          }
        } else {
          this.eventStart = true;
        }
        if (this.eventStart) {
          // this.selectedIndex = 1;
          // this.tabIndex = 1;
          let daysDiff = moment
            .tz(this.event.event_end_date, 'Asia/Kolkata')
            .diff(
              moment.tz(this.event.event_start_date, 'Asia/Kolkata'),
              'days'
            );
          for (let i = 0; i <= daysDiff; i++) {
            let temp = moment
              .tz(this.event.event_start_date, 'Asia/Kolkata')
              .add(i, 'days')
              .startOf('day');
            if (temp.diff(this.currentDay) == 0) {
              this.currentActiveDayIndex = i;
            }
            this.days.push(temp);
            let start_date_temp = temp.format('YYYY-MM-DD');
            if (
              moment(new Date().getTime())
                .tz('Asia/Kolkata')
                .diff(
                  moment.tz(
                    start_date_temp + ' ' + this.event.event_end_time,
                    'Asia/Kolkata'
                  )
                ) > 0
            ) {
              this.joinStatus[i] = false;
            } else {
              this.joinStatus[i] = true;
            }
          }
          let start_date = this.event.event_start_date;
          if (this.currentActiveDayIndex > -1) {
            start_date =
              this.days[this.currentActiveDayIndex].format('YYYY-MM-DD');
          }

          if (
            moment(new Date().getTime())
              .tz('Asia/Kolkata')
              .diff(
                moment.tz(
                  start_date + ' ' + this.event.event_start_time,
                  'Asia/Kolkata'
                )
              ) > -600000
          ) {
            this.startEnableJoin = true;
          } else {
            this.startEnableJoin = false;
            setTimeout(
              () => {
                this.startEnableJoin = true;
              },
              -moment(new Date().getTime())
                .tz('Asia/Kolkata')
                .diff(
                  moment.tz(
                    start_date + ' ' + this.event.event_start_time,
                    'Asia/Kolkata'
                  )
                )
            );
          }
          if ('step' in this.route.snapshot.queryParams) {
            this.selectedIndex = this.route.snapshot.queryParams.step;
            this.tabIndex = this.route.snapshot.queryParams.step;
          }
          this.processing = false;
          if (this.selectedIndex == 0) {
            setTimeout(() => {
              this.processEditor();
            });
          }
        }
      } else {
        this.notEnrolled = true;
      }
      this.processing = false;
      // if (this.selectedIndex == 0)
      //   this.processEditor();
    });
  }

  back(): void {
    this.router.navigate(['/events']);
  }

  openRecordedVideo(id) {
    const dialogRef = this.dialog.open(EVimeoModal, {
      width: '60vw',
      data: { id: id },
      panelClass: 'courseIntroPopup',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  mainTabChange($event) {
    this.selectedResourceIndex = $event.index;
    if ($event.index == 0) {
      this.processEditorPractical(
        'editorjsP' + this.weekBasedProjects[this.selectedProjectIndex].id,
        this.weekBasedProjects[this.selectedProjectIndex],
        'projects'
      );
    } else if ($event.index == 1) {
      this.processEditorPractical(
        'editorjsA' +
          this.weekBasedAssignments[this.selectedAssignmentIndex].id,
        this.weekBasedAssignments[this.selectedAssignmentIndex],
        'assignments'
      );
    }
  }

  ResourceItemTabChange($event, type) {
    if (type == 'projects') {
      this.selectedProjectIndex = $event.index;
      this.processEditorPractical(
        'editorjsP' + this.weekBasedProjects[this.selectedProjectIndex].id,
        this.weekBasedProjects[this.selectedProjectIndex],
        'projects'
      );
    } else if (type == 'assignments') {
      this.selectedAssignmentIndex = $event.index;
      this.processEditorPractical(
        'editorjsA' +
          this.weekBasedAssignments[this.selectedAssignmentIndex].id,
        this.weekBasedAssignments[this.selectedAssignmentIndex],
        'assignments'
      );
    }
  }

  openResources(drawer) {
    drawer.toggle();
    this.selectedProjectIndex = 0;
    this.selectedAssignmentIndex = 0;
    this.processEditorPractical(
      'editorjsP' + this.weekBasedProjects[this.selectedProjectIndex].id,
      this.weekBasedProjects[this.selectedProjectIndex],
      'projects'
    );
  }

  processEditor() {
    const that = this;

    const editorTools = {
      header: Header,
      list: List,
      paragraph: {
        class: Paragraph,
      },
      //image: SimpleImage,
      checklist: Checklist,
      embed: {
        class: Embed,
        config: {
          services: {
            youtube: true,
            vimeo: {
              regex:
                /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
              embedUrl: environment.playerUrl + '<%= remote_id %>',
              html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allow='autoplay;fullscreen' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
              height: 300,
              width: 600,
              id: ([id]) => {
                const params = [
                  'autoplay=false',
                  'autopause=true',
                  'muted=false',
                  'loop=false',
                  `url=https%3A%2F%2Fvimeo.com%2F${id}`,
                  'poster=',
                  'time=true',
                  'progressBar=true',
                  'overlay=true',
                  'muteButton=true',
                  'fullscreenButton=true',
                  'style=light',
                  'quality=auto',
                  'playButton=true',
                  'pip=true',
                ];
                return '?' + params.join('&');
              },
            },
            schoolforai: {
              regex:
                /https?:\/\/(?:s\.)?schoolforai.com\/static\/videos\/(\w+.(mp4))/,
              embedUrl:
                environment.playerUrl +
                '?autoplay=false&autopause=false&muted=false&loop=true&url=https%3A%2F%2Fdev.schoolforai.com%2Fstatic%2Fvideos%2F<%= remote_id %>&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true',
              html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
              height: 300,
              width: 600,
            },
          },
        },
      },
      attaches: {
        class: AttachesTool,
        config: {
          //   endpoint: environment.mediaUrl+'/upload',
          //   field: 'file[0]',
          //   additionalRequestData: {
          //     upload_path: '/content',
          //     custom_attrs: [],
          //     random_names: false,
          //     modify_output: true
          //   },
          //   additionalRequestHeaders: {
          //    Authorization: `Bearer ${this.authService.getToken()}`
          //  },
        },
      },
      quote: Quote,
      table: Table,
      raw: RawTool,
      code: (<any>window).editorjsCodeflask,
      linkTool: LinkTool,
      marker: Marker,
      warning: Warning,
      inlineCode: {
        class: InlineCode,
        shortcut: 'CMD+SHIFT+M',
      },
      image: {
        class: ImageTool,
      },
    };

    this.editor = new EditorJS({
      data: this.event.welcome_intro,
      readOnly: true,
      tools: {
        ...editorTools,
        twoColumns: {
          class: window['EditorJSLayout'].LayoutBlockTool,
          config: {
            EditorJS,
            editorJSConfig: {
              tools: editorTools,
              readOnly: true,
            },
            enableLayoutEditing: true,
            enableLayoutSaving: true,
            readOnly: true,
            initialData: {
              itemContent: {
                1: {
                  blocks: [],
                },
                2: {
                  blocks: [],
                },
              },
              layout: {
                type: 'container',
                id: '',
                className: '',
                style: 'border: none;display:flex; padding:0',
                children: [
                  {
                    type: 'item',
                    id: '',
                    className: '',
                    style: 'border: none; padding:0',
                    itemContentId: '1',
                  },
                  {
                    type: 'item',
                    id: '',
                    className: '',
                    style: 'border: none;',
                    itemContentId: '2',
                  },
                ],
              },
            },
          },
          shortcut: 'CMD+2',
          toolbox: {
            icon: `
                <svg xmlns='http://www.w3.org/2000/svg' width="16" height="16" viewBox='0 0 512 512'>
                  <rect x='128' y='128' width='336' height='336' rx='57' ry='57' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/>
                  <path d='M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/>
                </svg>
              `,
            title: '2 columns',
          },
        },
      },
      onChange: () => {},
      onReady: () => {
        window.addEventListener('message', function (e) {
          if (e.data) {
            try {
              let data = JSON.parse(e.data);
              console.log(data);
              let iframe = document.querySelector(
                'iframe[src="' + data.url + '"]'
              );
              if (iframe) {
                if (data.ratio) {
                  iframe.parentElement.style.paddingTop = data.ratio + '%';
                } else if (data.event && data.event == 'pip') {
                  //data.player.pause();
                }
              }
            } catch (error) {}
          }
        });
      },
    });
  }

  processEditorPractical(id, practical, type) {
    if (!(type in this.practicalEditors)) {
      this.practicalEditors[type] = {};
    }
    console.log(this.practicalEditors);
    if (
      id in this.practicalEditors[type] &&
      this.practicalEditors[type][id] &&
      'render' in this.practicalEditors[type][id]
    ) {
      if ('clear' in this.practicalEditors[type][id])
        this.practicalEditors[type][id].clear();
      this.practicalEditors[type][id].render(practical.description);
      setTimeout(() => {
        document
          .querySelectorAll('.image-tool__image-picture')
          .forEach((element) => {
            element.addEventListener('click', ($event: any) => {
              console.log($event);
              const imagePopup = this._bottomSheet.open(ImageZoom, {
                data: { source: $event.srcElement.currentSrc },
              });
            });
          });
      }, 800);
      return false;
    }
    const that = this;
    const editorTools = {
      header: Header,
      list: List,
      paragraph: {
        class: Paragraph,
      },
      //image: SimpleImage,
      checklist: Checklist,
      embed: {
        class: Embed,
        config: {
          services: {
            youtube: true,
            vimeo: {
              regex:
                /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
              embedUrl: environment.playerUrl + '<%= remote_id %>',
              html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allow='autoplay;fullscreen' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
              height: 300,
              width: 600,
              id: ([id]) => {
                const params = [
                  'autoplay=false',
                  'autopause=true',
                  'muted=false',
                  'loop=false',
                  `url=https%3A%2F%2Fvimeo.com%2F${id}`,
                  'poster=',
                  'time=true',
                  'progressBar=true',
                  'overlay=true',
                  'muteButton=true',
                  'fullscreenButton=true',
                  'style=light',
                  'quality=auto',
                  'playButton=true',
                  'pip=true',
                ];
                return '?' + params.join('&');
              },
            },
            schoolforai: {
              regex:
                /https?:\/\/(?:s\.)?schoolforai.com\/static\/videos\/(\w+.(mp4))/,
              embedUrl:
                environment.playerUrl +
                '?autoplay=false&autopause=false&muted=false&loop=true&url=https%3A%2F%2Fdev.schoolforai.com%2Fstatic%2Fvideos%2F<%= remote_id %>&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true',
              html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
              height: 300,
              width: 600,
            },
          },
        },
      },
      attaches: {
        class: AttachesTool,
        config: {
          //   endpoint: environment.mediaUrl+'/upload',
          //   field: 'file[0]',
          //   additionalRequestData: {
          //     upload_path: '/content',
          //     custom_attrs: [],
          //     random_names: false,
          //     modify_output: true
          //   },
          //   additionalRequestHeaders: {
          //    Authorization: `Bearer ${this.authService.getToken()}`
          //  },
        },
      },
      quote: Quote,
      table: Table,
      raw: RawTool,
      code: (<any>window).editorjsCodeflask,
      linkTool: LinkTool,
      marker: Marker,
      warning: Warning,
      inlineCode: {
        class: InlineCode,
        shortcut: 'CMD+SHIFT+M',
      },
      image: {
        class: ImageTool,
      },
    };
    this.practicalEditors[type][id] = new EditorJS({
      holder: id,
      data: practical.description,
      readOnly: true,
      tools: {
        ...editorTools,
        twoColumns: {
          class: window['EditorJSLayout'].LayoutBlockTool,
          config: {
            EditorJS,
            editorJSConfig: {
              tools: editorTools,
              readOnly: true,
            },
            enableLayoutEditing: true,
            enableLayoutSaving: true,
            readOnly: true,
            initialData: {
              itemContent: {
                1: {
                  blocks: [],
                },
                2: {
                  blocks: [],
                },
              },
              layout: {
                type: 'container',
                id: '',
                className: '',
                style: 'border: none;display:flex; padding:0',
                children: [
                  {
                    type: 'item',
                    id: '',
                    className: '',
                    style: 'border: none; padding:0',
                    itemContentId: '1',
                  },
                  {
                    type: 'item',
                    id: '',
                    className: '',
                    style: 'border: none;',
                    itemContentId: '2',
                  },
                ],
              },
            },
          },
          shortcut: 'CMD+2',
          toolbox: {
            icon: `
                <svg xmlns='http://www.w3.org/2000/svg' width="16" height="16" viewBox='0 0 512 512'>
                  <rect x='128' y='128' width='336' height='336' rx='57' ry='57' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/>
                  <path d='M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/>
                </svg>
              `,
            title: '2 columns',
          },
        },
      },
      onChange: () => {},
      onReady: () => {
        var elements = document.querySelectorAll('[contenteditable=true]');
        elements.forEach((element) => {
          element.setAttribute('contenteditable', 'false');
        });
        setTimeout(() => {
          document
            .querySelectorAll('.image-tool__image-picture')
            .forEach((element) => {
              element.addEventListener('click', ($event: any) => {
                console.log($event);
                const imagePopup = this._bottomSheet.open(ImageZoom, {
                  data: { source: $event.srcElement.currentSrc },
                });
              });
            });
        }, 800);

        window.addEventListener('message', function (e) {
          if (e.data) {
            try {
              let data = JSON.parse(e.data);
              console.log(data);
              let iframe = document.querySelector(
                'iframe[src="' + data.url + '"]'
              );
              if (iframe) {
                if (data.ratio) {
                  iframe.parentElement.style.paddingTop = data.ratio + '%';
                } else if (data.event && data.event == 'pip') {
                  //data.player.pause();
                }
              }
            } catch (error) {}
          }
        });
      },
    });
  }
}

@Component({
  selector: 'e-vimeo-modal',
  templateUrl: 'vimeo.html',
})
export class EVimeoModal implements OnInit {
  url: any;

  constructor(
    protected sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<EVimeoModal>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    window.addEventListener('message', function (e) {
      if (e.data) {
        try {
          let data = JSON.parse(e.data);
          let iframe = document.querySelector('#im-event-recorded-video');
          if (iframe) {
            if (data.ratio) {
              (<any>iframe).style.paddingTop = data.ratio + '%';
            }
          }
        } catch (error) {}
      }
    });
  }

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
      environment.playerUrl +
        '?autoplay=false&autopause=true&muted=false&loop=false&url=https%3A%2F%2Fvimeo.com%2F' +
        this.data.id +
        '&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true'
    );
  }
}

@Component({
  selector: 'image-zoom',
  templateUrl: 'image-zoom.html',
})
export class ImageZoom {
  source: any = '';

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<ImageZoom>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data
  ) {
    this.source = this.data.source;
  }
}
