import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '@core/services/http.service';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'im-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  secondFormGroup: FormGroup;

  hide: boolean = true;

  newHide: boolean = true;

  confirmHide: boolean = true;

  processing: boolean = false;

  showForm: boolean = true;

  constructor(private _formBuilder: FormBuilder, private http: HttpService, private authService: AuthService) { }

  ngOnInit() {
    this.secondFormGroup = this._formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmNewPassword: ['', Validators.required]
    });
  }

  process(){
    if(this.secondFormGroup.valid){
      if (this.secondFormGroup.get('currentPassword').value === this.secondFormGroup.get('newPassword').value) {
        this.secondFormGroup.controls['newPassword'].setErrors({'incorrect': true});  
        return;
      }
      if (this.secondFormGroup.get('newPassword').value !== this.secondFormGroup.get('confirmNewPassword').value) {
        this.secondFormGroup.controls['confirmNewPassword'].setErrors({'incorrect': true});  
        return;
      }
      this.processing = true;
      this.http.put('user/update',{...this.secondFormGroup.value}).subscribe((res)=>{
        if(res && res.success){
          this.authService.setToken(res.data.accessToken);
          this.secondFormGroup.reset();
          this.showForm = false;
          setTimeout(() => this.showForm = true, 0);
        } else {
          this.secondFormGroup.controls['currentPassword'].setErrors({'incorrect': true});
          this.processing = false;
        }
      }, (err) => {
          this.processing = false;
      })
    }
  }

}
