<ng-container *ngIf="!processing && courses.length == 0">
  <div class="empty-state flex items-center px-6 py-12 my-12 flex-column">
    <mat-icon class="border-radius-full">search</mat-icon>
    <p class="text-center mt-2 font-base color-secondary">
      No Courses available. <br />Please explore the by click on the below
      button.
    </p>
    <button
      mat-raised-button
      color="primary"
      class="mt-2"
      routerLink="/courses"
    >
      Explore
    </button>
  </div>
</ng-container>

<div class="grid has-4-columns p-4">
  <div
    class="column-start-1 column-end-3 xs:column-end-4 md:column-end-4 xl:column-end-3 mr-6 xs:mr-0"
  >
    <div class="w-full mb-6 column-span-3 mt-4">
      <mat-chip-list aria-label="Short Course Category">
        <mat-chip
          [selected]="activeFilter == 'all'"
          class="text-uppercase letter-spacing-10 font-xsss"
          (click)="filetrList('all')"
          style="cursor: pointer"
          >All</mat-chip
        >
        <mat-chip
          [selected]="activeFilter == 'Must for All'"
          class="text-uppercase letter-spacing-10 font-xsss"
          (click)="filetrList('Must for All')"
          style="cursor: pointer"
          >Must for All
        </mat-chip>
        <mat-chip
          [selected]="activeFilter == 'Age 10-13'"
          class="text-uppercase letter-spacing-10 font-xsss"
          (click)="filetrList('Age 10-13')"
          style="cursor: pointer"
          >Age 10 - 13
        </mat-chip>
        <mat-chip
          [selected]="activeFilter == 'Age 13+'"
          class="text-uppercase letter-spacing-10 font-xsss"
          (click)="filetrList('Age 13+')"
          style="cursor: pointer"
          >Age 13+
        </mat-chip>
      </mat-chip-list>
    </div>
    <div
      class="courses-list-section grid has-3-columns xs:has-1-columns row-gap-15 column-gap-15 xs:column-gap-0"
      *ngIf="!processing && courses.length > 0"
    >
      <ng-container *ngFor="let course of courses">
        <div class="item">
          <mat-card
            class="relative p-3 mat-card shadow-2 border-radius-lg course{{
              course.id
            }}-text course-border"
            style="min-height: 204px"
          >
            <mat-card-title class="font-xl mb-1 font-normal">
              <div
                class="flex justify-between course-card-title{{
                  course.properties.level
                }}"
                [attr.data-level]="course.properties.level"
                [ngClass]="{ 'course-card-title-group': course.group_course }"
              >
                <a
                  routerLink="/course/{{ course.slug }}"
                  class="flex items-center font-base"
                  ><img
                    [src]="course.icon_p"
                    width="32"
                    class="mr-1 course-icon"
                  />
                  {{ course.name }}</a
                >
              </div>
            </mat-card-title>
            <mat-card-content>
              <!-- <ng-container *ngIf="course.user_course">  -->
              <div class="flex mb-1 items-center justify-evenly mt-4 mb-2">
                <div class="flex flex-column items-center justify-center">
                  <span
                    class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                    style="width: 32px; height: 32px"
                    >{{ course.properties.sessions }}</span
                  >
                  <span
                    class="color-primary highlight-item-title mt-2 font-semi-bold"
                    >Sessions</span
                  >
                </div>
                <div class="flex flex-column items-center justify-center">
                  <span
                    class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                    style="width: 32px; height: 32px"
                    >{{ course.properties.engagement }}</span
                  >
                  <span
                    class="color-primary highlight-item-title mt-2 font-semi-bold"
                    >Engagement</span
                  >
                </div>
              </div>
              <!-- </ng-container> -->

              <p
                class="font-xs color-secondary line-clamp-2"
                *ngIf="!course.user_course"
              >
                {{ course.short_description }}
              </p>
            </mat-card-content>
            <mat-card-actions
              class="flex justify-between mat-card-actions"
              [ngClass]="{ 'flex-column': course.user_course }"
            >
              <ng-container *ngIf="!course.user_course">
                <button
                  mat-raised-button
                  color="primary"
                  class="letter-spacing-15 text-uppercase font-xss"
                  routerLink="/course/{{ course.slug }}"
                  style="height: 32px; line-height: 32px"
                >
                  Explore
                </button>
                <!-- <button
                  mat-stroked-button
                  class="letter-spacing-15 text-uppercase font-xss"
                  (click)="buyNow(course)"
                  style="height: 32px; line-height: 32px"
                  color="primary"
                >
                  Buy Now
                </button> -->
              </ng-container>
              <ng-container *ngIf="course.user_course">
                <div class="w-full">
                  <span class="float-right" style="margin-top: -1em">0%</span>
                  <mat-progress-bar
                    class="mt-4 w-full"
                    color="course{{ course.id }}"
                    mode="determinate"
                    value="0"
                    style="bottom: 0.75em"
                  ></mat-progress-bar>
                </div>
                <div class="w-full mt-2">
                  <span class="package_info opacity-background">{{
                    course.user_course?.package_properties?.name
                  }}</span>
                  <button
                    mat-mini-fab
                    class="float-right mat-elevation-z1"
                    routerLink="/course/{{ course.slug }}"
                    style="background: #ffbd32; color: #565050"
                  >
                    <mat-icon>east</mat-icon>
                  </button>
                </div>
              </ng-container>
            </mat-card-actions>
          </mat-card>
        </div>
      </ng-container>
    </div>
  </div>
  <div
    class="h-auto mt-6 column-start-4 column-end-4 xs:display-none md:display-none xl:display-block"
  >
    <div
      class="flex border-radius-lg flex-column py-6"
      style="line-height: 1.8rem; background: antiquewhite"
    >
      <h2 class="mb-2 pl-6">Future-ready Short Courses</h2>
      <p class="pl-6 pr-4 font-xs">
        Not yet clear on the AI path or Not ready to commit to AI and Data
        Science? No Worries. Try our short courses to gentle lean into the world
        of AI. Give your child a taste of the emerging technologies. You could
        customize your learning by picking the courses of your choice and build
        your career path. If you are not clear on it, you could always connect
        our academic counsellors to draft a learning plan that would suit your
        goals. We guarantee skills and fun throughout the process.
      </p>
    </div>
  </div>
</div>
