import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '@environment/environment';
import { IMStorage } from '../utillities/storage';

import { IMSnackBarService } from '../utillities/snack-bar';
import { timeStamp } from 'console';


class IMOptions {
  showErrorMessage?:boolean = true;
  showSuccessMessage?: boolean = true;
}

let httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient, private snackbar: IMSnackBarService) { }

  /** GET heroes from the server */
  get (url: string): Observable<any> {
    let final_url;
    final_url = environment.ApiUrl + url;
    this.processHeaders(); 
    return this.http.get<any>(final_url, httpOptions)
      .pipe(
        tap(data => this.log(data)),
        catchError(err => this.handleError(err,'url', {}))
      );
  }

    /** GET heroes from the server */
    getExternal (url: string): Observable<any> {
      this.processHeaders(); 
      return this.http.get<any>(url, httpOptions)
        .pipe(
          tap(data => this.log(data)),
          catchError(err => this.handleError(err,'url', {}))
        );
    }

   /** POST: add a new data to the server */
  post (url: string,  data: any, options?: IMOptions): Observable<any> {
    let final_url;
    final_url = environment.ApiUrl + url;
    this.processHeaders(); 
    return this.http.post<any>(final_url, data, httpOptions).pipe(
      tap((data: any) => this.log(data, options)),
      catchError(err => this.handleError<any>(err, 'add', options))
    );
  }


   /** POST: add a new data to the server */
   postExternal (url: string,  data: any, options?: IMOptions): Observable<any> {
    this.processHeaders(); 
    return this.http.post<any>(url, data, httpOptions).pipe(
      tap((data: any) => this.log(data, options)),
      catchError(err => this.handleError<any>(err, 'add', options))
    );
  }


   /** POST: add a new data to the server */
  put (url: string,  data: any): Observable<any> {
    let final_url;
    final_url = environment.ApiUrl + url;
    this.processHeaders(); 
    return this.http.put<any>(final_url, data, httpOptions).pipe(
      tap((data: any) => this.log(data)),
      catchError(err => this.handleError<any>(err, 'add'))
    );
  }

   /** POST: add a new data to the server */
   upload (url: string,  data: any, options?: IMOptions): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({'Accept': 'application/json' }),
      reportProgress: true,
    };
    let final_url;
    final_url = environment.ApiUrl + url;
    this.processHeaders(); 
    return this.http.post<any>(final_url, data, httpOptions).pipe(
      tap((data: any) => this.log(data, options)),
      catchError(err => this.handleError<any>(err, 'add', options))
    );
  }


  processHeaders(){
    // if(IMStorage.get('u')){
    //      const userSession = IMStorage.get('u'); 
    //       httpOptions = {
    //         headers: new HttpHeaders({
    //           'user_id':userSession.id,
    //         })
    //       };
    //   }
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   */
  private handleError<T> (error: HttpErrorResponse, operation = 'operation', options?: IMOptions) {
    if(error.status === 401){
        IMStorage.set('u','');
        window.location.reload();
    } else {
      if(options && !options.showErrorMessage) {

      } else{
        if  (error.error && error.error.message){
          this.snackbar.open(error.error.message, 'error');
        } else {
          this.snackbar.open('Someting happend please try again', 'error');
        }
      }
      
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }



  /** Log a HeroService message with the MessageService */
  private log(data: any, options?: IMOptions) {
    if (options) {
        if(!options.showErrorMessage && !options.showSuccessMessage) {
          
        } else {
          this.__sowSnackBar(data);
        }
    } else {
      this.__sowSnackBar(data);
    }
    
  }

  private __sowSnackBar(data) {
    if(data.error_message){
      this.snackbar.open(data.error_message, 'error');
    }
    else if(data.error){
      this.snackbar.open(data.message, 'error');
    } else if (data.success && data.message) {
      this.snackbar.open(data.message, 'success')
    }
  }

}
