import { Component, OnInit, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { HttpService } from '../../../core/services/http.service';
import { QuizQuestion } from '../model/QuizQuestion';

import Header from '@editorjs/header'; 
import List from '@editorjs/list'; 
import RawTool from '@editorjs/raw';
import Checklist  from '@editorjs/checklist';
import Quote from '@editorjs/quote';
import ImageTool from '@editorjs/image';
import CodeTool from '@editorjs/code';
import LinkTool from '@editorjs/link';
import Marker from '@editorjs/marker';
import Warning from '@editorjs/warning';
import Paragraph from '@editorjs/paragraph';
import InlineCode from '@editorjs/inline-code';
import EditorJS from '@editorjs/editorjs';

@Component({
  selector: 'codelab-question-container',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
  @Input() answer: string;
  @Input() formGroup: FormGroup;
  @Output() question: QuizQuestion;
  totalQuestions: number;
  completionTime: number;
  correctAnswersCount = 0;

  questionID = 0;
  currentQuestion = 0;
  questionIndex: number;
  correctAnswer: boolean;
  hasAnswer: boolean;
  disabled: boolean;
  quizIsOver: boolean;
  progressValue: number;
  timeLeft: number;
  timePerQuestion = 0;
  interval: any;
  elapsedTime: number;
  elapsedTimes = [];
  blueBorder = '2px solid #007aff';
  stateData: any;

  activeTopic: any;

  displayTimer: string;

  allQuestions: QuizQuestion[] = [];

  editor: any;

  // allQuestions: QuizQuestion[] = [
  //   {
  //     questionId: 1,
  //     questionText: 'What is the objective of dependency injection?',
  //     options: [
  //       { optionValue: '1', optionText: 'Pass the service to the client.' },
  //       { optionValue: '2', optionText: 'Allow the client to find service.' },
  //       { optionValue: '3', optionText: 'Allow the client to build service.' },
  //       { optionValue: '4', optionText: 'Give the client part service.' }
  //     ],
  //     answer: '1',
  //     explanation: 'a service gets passed to the client during DI',
  //     selectedOption: ''
  //   },
  //   {
  //     questionId: 2,
  //     questionText: 'Which of the following benefit from dependency injection?',
  //     options: [
  //       { optionValue: '1', optionText: 'Programming' },
  //       { optionValue: '2', optionText: 'Testability' },
  //       { optionValue: '3', optionText: 'Software design' },
  //       { optionValue: '4', optionText: 'All of the above.' },
  //     ],
  //     answer: '4',
  //     explanation: 'DI simplifies both programming and testing as well as being a popular design pattern',
  //     selectedOption: ''
  //   },
  //   {
  //     questionId: 3,
  //     questionText: 'Which of the following is the first step in setting up dependency injection?',
  //     options: [
  //       { optionValue: '1', optionText: 'Require in the component.' },
  //       { optionValue: '2', optionText: 'Provide in the module.' },
  //       { optionValue: '3', optionText: 'Mark dependency as @Injectable().' },
  //       { optionValue: '4', optionText: 'Declare an object.' }
  //     ],
  //     answer: '3',
  //     explanation: 'the first step is marking the class as @Injectable()',
  //     selectedOption: ''
  //   },
  //   {
  //     questionId: 4,
  //     questionText: 'In which of the following does dependency injection occur?',
  //     options: [
  //       { optionValue: '1', optionText: '@Injectable()' },
  //       { optionValue: '2', optionText: 'constructor' },
  //       { optionValue: '3', optionText: 'function' },
  //       { optionValue: '4', optionText: 'NgModule' },
  //     ],
  //     answer: '2',
  //     explanation: 'object instantiations are taken care of by the constructor in Angular',
  //     selectedOption: ''
  //   }
  // ];

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpService) {
    this.route.paramMap.subscribe(params => {
      this.stateData = history.state;
      if (!this.stateData || !this.stateData.course) {
        this.router.navigate(['../../intro'], {relativeTo: this.route});
      }
      this.setQuestionID(+params.get('questionId'));  // get the question ID and store it
      this.question = this.getQuestion;
    });
  }

  ngOnInit() {
    this.stateData.questions.forEach(element => {
        let temp = {
              questionId: parseInt(element.order),
              questionText: element.name,
              options: [],
              answer: element.answer,
              explanation: '',
              selectedOption: '',
              answerObject: {},
              selectedOptionObject: {}
            };
        if(element.type == 'radio') {
          Object.keys(element.options).forEach(optionKey => {
              if (optionKey == temp.answer){
                temp.answerObject = {optionValue: optionKey, optionText: element.options[optionKey]};
              }
              temp.options.push({optionValue: optionKey, optionText: element.options[optionKey]});
          });
        }
        this.allQuestions.push(temp);    
    });
    //this.activeTopic = this.router.getCurrentNavigation().extras.state.topic;
    this.question = this.getQuestion;
    this.totalQuestions = this.allQuestions.length;
    this.timeLeft = this.timePerQuestion;
    this.progressValue = 100 * (this.currentQuestion / this.totalQuestions);
    this.countdown();

  }

  displayNextQuestion() {
    //this.resetTimer();
    this.increaseProgressValue();

    this.questionIndex = this.questionID++;

    if (typeof document.getElementById('question') !== 'undefined' && this.getQuestionID() <= this.totalQuestions) {
      //document.getElementById('question').innerHTML = this.allQuestions[this.questionIndex]['questionText'];
      //document.getElementById('question').style.border = this.blueBorder;
    } else {
      this.navigateToResults();
    }
  }

  /* displayPreviousQuestion() {
    this.resetTimer();
    this.decreaseProgressValue();

    this.questionIndex = this.questionID--;

    if (typeof document.getElementById('question') !== 'undefined' && this.getQuestionID() <= this.totalQuestions) {
      document.getElementById('question').innerHTML = this.allQuestions[this.questionIndex]['questionText'];
      document.getElementById('question').style.border = this.blueBorder;
    } else {
      this.navigateToResults();
    }
  } */

  navigateToNextQuestion(): void {
    this.router.navigate(['../', this.getQuestionID() + 1], {relativeTo: this.route, state: {...this.stateData}});
    this.hasAnswer = false;
    this.displayNextQuestion();
  }

  /* navigateToPreviousQuestion(): void {
    this.router.navigate(['/question', this.getQuestionID() - 1]);
    this.displayPreviousQuestion();
  } */

  navigateToResults(): void {
    const data = {
      topic_id : this.stateData.topic.id,
      module_id: this.stateData.module.id,
      course_id: this.stateData.course.id,
      chapter_id: this.stateData.chapter.id,
      properties: {              
        totalQuestions: this.totalQuestions,
        correctAnswersCount: this.correctAnswersCount,
        completionTime: this.completionTime,
        allQuestions: this.allQuestions,
      },
      status: 2
    }
    this.insertQuiz(data).subscribe(res => {
          this.activateNextTopic(res);
    })
  }

  insertQuiz(data) {
    return this.http.post('user_quiz', data, {showErrorMessage: false});
  }

  activateNextTopic(quizRes) {
    if (this.stateData.topic.user_course_topic && this.stateData.topic.user_course_topic.is_completed == 0) {
      this.http.post('user_course_topic', {id: this.stateData.topic.user_course_topic.id, is_completed: 1, current_topic:0}, {showErrorMessage: false}).subscribe((res)=>{
        this.stateData.topic.user_course_topic.is_completed = 1;
        if(res.data.nextTopic) {
          this.router.navigate(['../../results/'+btoa(quizRes.data.id)], { state:
            {
              totalQuestions: this.totalQuestions,
              correctAnswersCount: this.correctAnswersCount,
              completionTime: this.completionTime,
              allQuestions: this.allQuestions,
              nextTopic: res.data.nextTopic,
              ...this.stateData
            },
            relativeTo: this.route
          });
        } else if (res.data.course_completed) {
          this.router.navigate(['../../results/'+btoa(quizRes.data.id)], { state:
            {
              totalQuestions: this.totalQuestions,
              correctAnswersCount: this.correctAnswersCount,
              completionTime: this.completionTime,
              allQuestions: this.allQuestions,
              nextTopic: res.data.nextTopic,
              courseCompleted: true,
              ...this.stateData
            },
            relativeTo: this.route
          });
        }
        
        // this.http.get('user_course_topic?filter="course_id eq '+this.stateData.course.id+' and status eq 0 and is_completed eq 0"&limit=1&order_by=id').subscribe((res)=>{
        //   nextTopic['user_course_topic'] = res.data;
        //   this.http.post('user_course_topic', {id: nextTopic.user_course_topic.id, status: 1, current_topic: 1}, {showErrorMessage: false}).subscribe((res)=>{
        //     this.router.navigate(['../../results'], { state:
        //       {
        //         totalQuestions: this.totalQuestions,
        //         correctAnswersCount: this.correctAnswersCount,
        //         completionTime: this.completionTime,
        //         allQuestions: this.allQuestions,
        //         nextTopic: nextTopic,
        //         ...this.stateData
        //       },
        //       relativeTo: this.route
        //     });
        //   });
        // }); 
      });
    } else {
      this.router.navigate(['../../results/'+btoa(quizRes.data.id)], { state:
        {
          totalQuestions: this.totalQuestions,
          correctAnswersCount: this.correctAnswersCount,
          completionTime: this.completionTime,
          allQuestions: this.allQuestions,
          ...this.stateData
        },
        relativeTo: this.route
      });
    }
    
  }

  // checks whether the question is valid and is answered correctly
  checkIfAnsweredCorrectly() {
    if (this.isThereAnotherQuestion() && this.isCorrectAnswer()) {
      this.incrementCorrectAnswersCount();
      this.correctAnswer = true;
      this.hasAnswer = true;
      this.disabled = false;

      //this.elapsedTime = Math.ceil(this.timePerQuestion - this.timeLeft);
      if (this.getQuestionID() < this.totalQuestions) {
        //this.elapsedTimes = [...this.elapsedTimes, this.elapsedTime];
      } else {
        //this.elapsedTimes = [...this.elapsedTimes, 0];
        //this.completionTime = this.calculateTotalElapsedTime(this.elapsedTimes);
        this.completionTime = this.timeLeft;
      }

      //this.quizDelay(1000);

      if (this.getQuestionID() < this.totalQuestions) {
        this.navigateToNextQuestion();
      } else {
        this.navigateToResults();
      }
    }
  }

  incrementCorrectAnswersCount() {
    if (this.questionID <= this.totalQuestions && this.isCorrectAnswer()) {
      if (this.correctAnswersCount === this.totalQuestions) {
        return this.correctAnswersCount;
      } else {
        this.correctAnswer = true;
        //this.hasAnswer = true;
        return this.correctAnswersCount++;
      }
    } else {
      this.correctAnswer = false;
      //this.hasAnswer = false;
    }
  }

  increaseProgressValue() {
    this.progressValue = parseFloat((100 * (this.getQuestionID() + 1) / this.totalQuestions).toFixed(1));
  }

  /* decreaseProgressValue() {
    this.progressValue = parseFloat((100 * (this.getQuestionID() - 1) / this.totalQuestions).toFixed(1));
  } */

  calculateTotalElapsedTime(elapsedTimes) {
    return this.completionTime = elapsedTimes.reduce((acc, cur) => acc + cur, 0);
  }

  /****************  public API  ***************/
  getQuestionID() {
    return this.questionID;
  }

  setQuestionID(id: number) {
    return this.questionID = id;
  }

  isThereAnotherQuestion(): boolean {
    return this.questionID <= this.allQuestions.length;
  }

  isFinalQuestion(): boolean {
    return this.currentQuestion === this.totalQuestions;
  }

  isCorrectAnswer(): boolean {
    const selectedOptionObject = this.question.options.find(item => item.optionValue == this.question.selectedOption);
    this.question.selectedOptionObject = selectedOptionObject;
    return this.question.selectedOption === this.question.answer;
  }

  get getQuestion(): QuizQuestion {
    let question =  this.allQuestions.filter(
      question => question.questionId === this.questionID
    )[0];
      this.currentQuestion = this.questionID;
      console.log(this.currentQuestion);
    if (question && question.questionText) {
      if (this.editor && this.editor.render) {
        this.editor.render(question.questionText);
      } else {
        this.editor = new EditorJS({ data: question.questionText, readOnly: true, holder: 'question',  tools:{
          header: Header, 
          list: List,
          paragraph: {
           class: Paragraph,
           inlineToolbar: true,
         },
          //image: SimpleImage,
          checklist: Checklist,
          
          quote: Quote,
          raw: RawTool,
          code: CodeTool,
          linkTool: LinkTool,
          marker: Marker,
          warning: Warning, 
          inlineCode: {
             class: InlineCode,
             shortcut: 'CMD+SHIFT+M',
          },
          image: {
            class: ImageTool
          } 
        },
        onReady: () => {  
          },
        });
      }  
    }
   

  return question;

  }

  // countdown clock
  private countdown() {
    if (this.questionID <= this.totalQuestions) {
      this.interval = setInterval(() => {
        //if (this.timeLeft > 0) {
          if (this.question.selectedOption) {
            this.hasAnswer = true;
          } else {
            this.hasAnswer = false;
          }
          if(this.isFinalQuestion() && this.quizIsOver) {
              clearInterval(this.interval);
              return;
          }
          this.timeLeft++;
          this.checkIfAnsweredCorrectly();

          this.completionTime = this.timeLeft;

          // if (this.correctAnswersCount <= this.totalQuestions) {
          //   this.calculateTotalElapsedTime(this.elapsedTimes);
          // }
          // if (this.timeLeft === 0 && !this.isFinalQuestion()) {
          //   //this.navigateToNextQuestion();
          // }
          // if (this.timeLeft === 0 && this.isFinalQuestion()) {
          //   //this.navigateToResults();
          // }
          if (this.isFinalQuestion() && this.hasAnswer === true) {
            this.navigateToResults();
            this.quizIsOver = true;
          }

          this.displayTimer = this.transformTimer(this.timeLeft);

          // disable the next button until an option has been selected
          this.question.selectedOption === '' ? this.disabled = true : this.disabled = false;
        //}
      }, 1000);
    }
  }

  private resetTimer() {
    this.timeLeft = this.timePerQuestion;
  }

  quizDelay(milliseconds) {
    const start = new Date().getTime();
    let counter = 0;
    let end = 0;

    while (counter < milliseconds) {
      end = new Date().getTime();
      counter = end - start;
    }
  }

  transformTimer(value: number): string {
    let minutes: any = Math.floor(value / 60);
    let seconds: any = value - minutes * 60;
    if (minutes < 10) {
      minutes = '0'+minutes
    }
    if (seconds < 10) {
      seconds = '0'+seconds
    }
    return minutes + ':' + seconds;
  }
}
