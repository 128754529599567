import { Component, OnInit } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { PaymentService } from '@modules/payment/payment.service';

@Component({
  selector: 'im-my',
  templateUrl: './my.component.html',
  styleUrls: ['./my.component.scss']
})
export class MyComponent implements OnInit {

  courses: any = [];

  processing: boolean = false;

  constructor(private http: HttpService, private paymentService: PaymentService) { }

  ngOnInit() {
    this.processing = true;
    this.getCourses();
  }

  getCourses() {
    this.http.get('user/my/user_courses').subscribe((res) => {
      this.courses = res.data;
      this.processing = false;
    })
  }

  buyNow(course: any) {
    course.packageType = 'course';
    const dialogRef = this.paymentService.initPayment(course);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'Success') {
        this.getCourses();
      }
    });
  }

  upgrade(course: any) {
    course.packageType = 'course';
    const dialogRef = this.paymentService.initPayment(course, true);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'Success') {
        this.getCourses();
      }
    });
  }

}
