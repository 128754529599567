import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {take} from 'rxjs/operators';

@Component({
  selector: 'im-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  @ViewChild('autosize', {static: false}) autosize: CdkTextareaAutosize;

 

  constructor(private _ngZone: NgZone) { }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  ngOnInit() {
  }

  index(el) {
    if (!el) return -1;
    var i = 0;
    while (el = el.previousElementSibling) {
      i++;
    }
    return i;
  }

  changeFeedback(ele, value) {
    const that = this;
    let li:any = document.querySelectorAll('.rating ul li')[value-1];
     let   ul = li.closest('ul'),
        rating = ul.closest('.rating'),
        last = ul.querySelector('.current');

    if(!rating.classList.contains('animate-left') && !rating.classList.contains('animate-right')) {

        if(last) last.classList.remove('current');
        Array.prototype.forEach.call(ul.children, function(el, i) {
            let current = el;
            current.classList.toggle('active', that.index(li) > that.index(current));
        });

        if(last) rating.classList.add(that.index(li) > that.index(last) ? 'animate-right' : 'animate-left');
        rating.style.setProperty('--x', li.offsetLeft + 'px');

        li.classList.add('move-to');
        if(last) last.classList.add('move-from');

        setTimeout(() => {
            li.classList.add('current');
            li.classList.remove('move-to');
            if(last) last.classList.remove('move-from');
            rating.classList.remove('animate-left');
            rating.classList.remove('animate-right');
            
        }, 800);

    }

  }


}
