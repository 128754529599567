<div class="im-bar-profile">
  <div class="flex justify-between items-center">
    <h2 class="ml-6 mb-2">My Profile</h2>
    <button mat-button color="primary" (click)="logout()" class="mr-6">
      <mat-icon class="mr-1">power_settings_new</mat-icon> Logout
    </button>
  </div>
  <nav mat-tab-nav-bar >
    <a mat-tab-link
       *ngFor="let link of navLinks"
       [routerLink]="link.path"
       routerLinkActive #rla="routerLinkActive"
       [active]="rla.isActive" class="letter-spacing-15" [routerLinkActiveOptions]="{exact:
        true}"> 
      {{link.label}}
    </a>
  </nav>
</div>

  <div class="overflow-y-auto display-block">
    <router-outlet></router-outlet>
  </div>
