import { Injectable, NgZone } from '@angular/core';
import { PackagesComponent } from './packages/packages.component';
import { WindowRefService } from '@core/services/window-ref.service';
import {MatDialog} from '@angular/material/dialog';
import { Subject ,  Observable } from 'rxjs'; 
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  dialogRef: any;

  paymentStatus= new Subject<any>();
  
  courseRefresh = new Subject<any>();

  constructor(public dialog: MatDialog, private winRef: WindowRefService, private _ngZone: NgZone) { }

  initPayment(course: any, isItUpgrade = false): any {
    const dialogRef = this.dialog.open(PackagesComponent, {
      width: '100vw',
      maxWidth: '70vw',
      //minHeight: '80vh',
      data: {...course, isItUpgrade},
      panelClass: 'packages-section'
    });
    return dialogRef;
  }

  payWithRazor(data: any, loginUser) {
    const that = this;
    let amount = parseInt(data.price)*100;
    const options: any = {
      key: environment.razorpay,
      amount: amount, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: data.name, // company name or product name
      description: data.description,  // product description
      image: './assets/images/logo.png', // company logo or product image
      order_id: data.razorpay_order_id, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a'
      },
      prefill: {
        name: loginUser.name,
        email: loginUser.email,
        contact: loginUser.mobile_number
      }
    };
    options.handler = ((response, error) => {
      options.response = response;
      this._ngZone.run(() => { 
        that.paymentStatus.next({status:'Success', data, response});
      });
      console.log(response);
      console.log(options);
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      this._ngZone.run(() => { 
        that.paymentStatus.next({status:'Canceled', data});
      });
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }
}
