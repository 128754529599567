<div style="display: flex;flex-direction: column;align-items: center;justify-content: center;padding: 10px;">
    <img src="assets/images/cancel.svg" style="width: 5rem;margin-bottom: 12px;">
    <h1 mat-dialog-title style="margin-bottom: 12px;">Are you sure?</h1>
    <div mat-dialog-content style="text-align: center;">
        <p style="line-height: 1.5;">
            Do you really want to delete these note? <br /> This process cannot be undone.
        </p>
    </div>
    <div mat-dialog-actions style="margin-top: 2rem;">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button color="primary" (click)="delete()">Delete</button>
    </div>
</div>


