import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../core/services/http.service';
import { AuthService } from '@core/services/auth.service';
import { ActivatedRoute , Router} from '@angular/router';

@Component({
  selector: 'im-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpService, private authService: AuthService) { }

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('id')) {
        const token = atob(this.route.snapshot.paramMap.get('id'));
        this.http.post('exist/demo_logins', {identity: token}, {showErrorMessage: false, showSuccessMessage: false}).subscribe(res=>{
             if(res.data){
                this.authService.setToken(res.data.token);
                this.authService.redirectUrl();
             }else {
              this.router.navigate(['auth','']);
             }
        })
       
    } else {
      this.router.navigate(['auth','']);
    }
  }

}
