<div class="editor__head flex justify-between" *ngIf="editorView">
    <div class="flex justify-between">
        <div>
            <button mat-icon-button matTooltip="Update the note" [disabled]="!activeNote" (click)="save()">
                <mat-icon class="material-icons-outlined">save</mat-icon>
            </button>
            <button mat-icon-button matTooltip="Delete the note" [disabled]="!activeNote" (click)="delete()"><img
                    src="assets/images/trash.svg" /></button>
            <mat-divider [vertical]="true"></mat-divider>
        </div>
        <div>

        </div>
    </div>
    <div class="mr-12" *ngIf="loginUser.user_type == 'trainer'">
        <button mat-icon-button [disabled]="!activeNote" (click)="publish()">
            <mat-icon>send</mat-icon> Publish
        </button>
    </div>
    <!-- <button mat-icon-button matTooltip="Download the note" [disabled]="!activeNote"><img
            src="assets/images/cloud-download.svg" /></button>
    <button mat-icon-button matTooltip="Copy code" (click)="copy()" [disabled]="!activeNote"><img
            src="assets/images/clippy.svg" /></button>
    <mat-divider [vertical]="true"></mat-divider> -->
</div>
<div>
    <div id="noteeditor">
        <ng-container *ngIf="activeNote">
            <textarea placeholder="Add title" class="note-title" rows="1" [(ngModel)]="title"
                (keydown)="titleKeydownHandler($event)" [readonly]="!editorView"></textarea>
        </ng-container>
    </div>
</div>