import { Route, RouterModule } from '@angular/router';
import { IntroductionComponent } from './introduction/introduction.component';
import { QuestionComponent } from './question/question.component';
import { ResultsComponent } from './results/results.component';

export const quizRoutes: Route[] = [
    { path: 'quiz/:slug/:moduleSlug/:chapterSlug/:topicSlug', children: [ 
            
    { path: 'intro', component: IntroductionComponent, pathMatch: 'full' },
    { path: 'question', component: QuestionComponent, pathMatch: 'full' },
    { path: 'question/:questionId', component: QuestionComponent, pathMatch: 'full' },
    { path: 'results/:quizId', component: ResultsComponent, pathMatch: 'full' },
    { path: '', redirectTo: 'intro', pathMatch: 'full' }] }
 
];