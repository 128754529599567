import { Component, OnInit, Inject, HostBinding } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '@core/services/auth.service';
import { UrlSerializer, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '@environment/environment';
import {
  DOCUMENT
} from '@angular/common';
import { HttpService } from '@core/services/http.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FeedbackComponent } from '../../../themes/default/feedback/feedback.component';

@Component({
  selector: 'im-schedule-online',
  templateUrl: './online.component.html',
  styleUrls: ['./online.component.scss']
})
export class OnlineComponent implements OnInit {

  link: any;

  loginUser: any;

  isItSchedulePage: boolean = false;

  isItDashboardPage: boolean = false;

  @HostBinding('class.minimize') minimize: boolean = false;

  constructor(public dialogRef: MatDialogRef<OnlineComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, protected sanitizer: DomSanitizer, private authService: AuthService, private router: Router, @Inject(DOCUMENT) private document, private serializer: UrlSerializer, private http: HttpService, private _bottomSheet: MatBottomSheet) { }

  ngOnInit() {
    this.loginUser = this.authService.loginUser;
    let role = 0;
    if (this.loginUser.user_type == 'trainer') {
      role = 1;
    }
    if (this.document.location.href.includes('schedule')) {
      this.isItSchedulePage = true;
    } else {
      this.isItDashboardPage = true;
    }
    this.http.post('extra/zoom/signature', { 'meeting_number': this.data.meetingId, role }).subscribe((res) => {
      let meetingConfig = {
        mn: this.data.meetingId,
        name: this.loginUser.name,
        role,
        signature: res.data.signature,
        apiKey: res.data.api_key,
        pwd: this.data.link_pwd,
        email: '',
        lu: this.b64EncodeUnicode(this.document.location.href)
      }
      const tree = this.router.createUrlTree(['/'], {
        queryParams: meetingConfig
      });
      this.link = this.sanitizer.bypassSecurityTrustResourceUrl(environment.zoomUrl + 'meeting.html' + this.serializer.serialize(tree).replace('/?', '?'));
      const that = this
      window.addEventListener("message",
        function (e) {
          if (e.data) {
            try {
              let data = JSON.parse(e.data);
              if (data.closeZoom) {
                that.link = '';
                document.body.classList.remove('online-section-minimize');
                that.dialogRef.close();
                //that._bottomSheet.open(FeedbackComponent);
              }
            } catch (error) {

            }
          }
        });
      // var rootElement = document.getElementById('ZoomEmbeddedApp');
      // var zmClient: any = (<any>window).ZoomMtgEmbedded.createClient();

      // zmClient.init({
      //   debug: true,
      //   zoomAppRoot: rootElement,
      //   // assetPath: 'https://websdk.zoomdev.us/2.0.0/lib/av', //default
      //   //webEndpoint: meetingConfig.webEndpoint,
      //   language: 'en-US',
      //   customize: {
      //     meetingInfo: ['topic', 'host', 'mn', 'pwd', 'telPwd', 'invite', 'participant', 'dc', 'enctype'],
      //     toolbar: {
      //       buttons: [
      //         // {
      //         //   text: 'CustomizeButton',
      //         //   className: 'CustomizeButton',
      //         //   onClick: () => {
      //         //     console.log('click Customer Button');
      //         //   }
      //         // }
      //       ]
      //     }
      //   }
      // }).then((e) => {
      //   console.log('success', e);
      // }).catch((e) => {
      //   console.log('error', e);
      // });

      // // WebSDK Embedded join 
      // zmClient.join({
      //   apiKey: meetingConfig.apiKey,
      //   signature: meetingConfig.signature,
      //   meetingNumber: meetingConfig.mn,
      //   userName: meetingConfig.name,
      //   password: meetingConfig.pwd,
      //   userEmail: meetingConfig.email,
      // }).then((e) => {
      //   console.log('success', e);
      // }).catch((e) => {
      //   console.log('error', e);
      // });

    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (event.url.includes('schedule') && this.isItSchedulePage) {

      }
      else if (event.url.includes('dashboard') && this.isItDashboardPage) {

      }
      else {
        this.minimize = true;
        document.body.className += ' online-section-minimize';
      }
    });
  }

  maximize() {
    this.minimize = false;
    document.body.classList.remove('online-section-minimize');
  }

  b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(
        match,
        p1
      ) {
        return String.fromCharCode(Number("0x" + p1));
      })
    );
  }

}
