import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'im-product-redirect',
  templateUrl: './product-redirect.component.html',
  styleUrls: ['../page/page.component.scss'],
})
export class ProductRedirectComponent implements OnInit {
  navLinks = [
    {
      label: 'Dashboard',
      icon: 'assets/images/widgets.svg',
      iconS: 'assets/images/widgets.svg',
      path: '/dashboard',
    },
    {
      label: 'Our Offering',
      icon: 'assets/images/our-offering.svg',
      iconS: 'assets/images/our-offering.svg',
      path: '/our-offering',
    },
    {
      label: 'Schedule',
      icon: 'assets/images/event_note.svg',
      iconS: 'assets/images/event_note.svg',
      path: '/schedule',
    },
    {
      label: 'Coder',
      icon: 'assets/images/coder.svg',
      iconS: 'assets/images/coder.svg',
      path: '/coder',
    },
    {
      label: 'AI with Block Coding',
      icon: 'assets/images/block_icon.svg',
      iconS: 'assets/images/block_icon.svg',
      path: '/scratch',
    },
    {
      label: 'AI Labs',
      icon: 'assets/images/ai.png',
      iconS: 'assets/images/ai.png',
      path: '/ai-coder',
    },
    {
      label: 'Notes',
      icon: 'assets/images/note.svg',
      iconS: 'assets/images/note.svg',
      path: '/notes',
    },
    {
      label: 'Community',
      icon: 'assets/images/question_answer.svg',
      iconS: 'assets/images/question_answer.svg',
      path: '/community',
    },
  ];

  scQueryParam: boolean = false;

  loginUser: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {
    this.authService.userProfileUpdated.subscribe((res) => {
      this.loginUser = res;
    });
  }

  ngOnInit(): void {
    console.log(this.router);
    if (this.router.url) {
      this.router.navigate(['product', this.router.url.replace('/', '')], {
        queryParams: { ac: 1 },
      });
    }
  }
}
