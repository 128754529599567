import { Component, OnInit } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { environment } from '@environment/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'im-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  data; any = [
  ];

  constructor(private http: HttpService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.http.getExternal(environment.storageUrl + 'scratch/data.json?t='+new Date().getTime()).subscribe((res) => {
        if (this.route.snapshot.data.type) {
            let result = res.filter((item) => item.properties.tags == this.route.snapshot.data.type);
            this.data = result;
        } else {
          this.data = res;
        }
    });
  }

}
