import { Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from '@core/services/guards/auth-guard.service';
import { EventComponent } from './event/event.component';
import { DetailsComponent } from './details/details.component';
import { ListComponent } from './list/list.component';

export const eventRoutes: Routes = [
  {
    path: 'events',
    component: EventComponent,
    children: [
      {
        path: '',
        component: ListComponent,
      },
    ],
  },
  {
    path: 'event',
    component: EventComponent,
    children: [
      {
        path: ':event_slug',
        component: DetailsComponent,
      },
    ],
  },
  {
    path: 'event/auth',
    component: EventComponent,
    children: [
      {
        path: ':event_slug',
        component: DetailsComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];
