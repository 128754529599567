<ng-container *ngIf="processing">
	<div class="flex flex-column items-center justify-center relative" style="height: 70vh;">
		<mat-spinner strokeWidth="3"></mat-spinner> <img src="assets/images/icon.png" class="absolute" width="64"> </div>
</ng-container>
<ng-container *ngIf="!processing && categoryModels?.ai_models.length">
      <div class="models-section flex mx-8 flex-wrap mt-6">
        <ng-container *ngFor="let model of categoryModels.ai_models">
          <div class="item">
            <mat-card class="mat-card border-radius-lg ">
              <mat-card-content>
                <div class="absolute right-10-px top-10-px">
                  <mat-chip-list aria-label="model tags">
                    <mat-chip color="primary" selected>{{model.properties.tags}}</mat-chip>
                  </mat-chip-list>
                </div>
                <div class="icon-info"> 
                  <div class="border-radius-full"><img [src]="model.icon_p" width="32" height="32" /></div>
                </div>
                <h3>{{model.name}}</h3>
                <p class="mt-1 line-clamp-3 color-secondary font-xs">{{model.short_description}}</p>
                <button mat-mini-fab class="mt-3 float-right mat-elevation-z1" routerLink="../../{{model.slug}}">
                  <mat-icon>east</mat-icon>
                </button>
              </mat-card-content>
            </mat-card>
          </div>
        </ng-container>
      </div>
</ng-container>



<div class="md:grid 
has-1-columns 
justify-items-stretch" *ngIf="!processing && !categoryModels?.ai_models.length">
<div class="empty-state flex items-center px-6 py-12 my-12 flex-column" >
    <mat-icon class="border-radius-full">search</mat-icon>
    <p class="text-center mt-2 font-base font-medium">
      You didn't started any AI models. <br />Please explore the by click on the below button.
    </p>
    <button mat-raised-button color="primary" class="mt-2" routerLink="../../all">Explore</button>
</div>

</div>