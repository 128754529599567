import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../../core/services/http.service';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { V } from '@angular/cdk/keycodes';

@Component({
  selector: 'im-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss']
})
export class GamesComponent implements OnInit {

  games: any = [];
  moment = moment;

  processing: boolean = false;

  constructor(private http: HttpService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.getModels();
  }

  getModels() {
    this.processing = true;
    this.http.get('ai_games?order_by=sequence&order=asc&fields=id,slug,name,properties,properties,is_system_game,user_id,views,cover_image,created_at').subscribe((res)=>{
        this.games = res.data;
        this.processing = false;
    });
  }

}
