import { Component, OnInit } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { PaymentService } from '@modules/payment/payment.service';
import moment from 'moment';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'im-events-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  products: any = [];

  productList: any = [];

  oldProductsList: any = [];

  processing: boolean = false;

  activeFilter = 'all';

  loginUser: any;

  constructor(
    private http: HttpService,
    private paymentService: PaymentService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.loginUser = this.auth.loginUser;
    this.processing = true;
    this.getProducts();
  }

  getProducts() {
    let purl = 'na/workshops';
    if (this.loginUser) {
      purl = 'workshops';
    }
    this.http
      .get(
        purl +
          "?order_by=id&order=desc&filter=is_it_paid eq 1 and status eq 1 and event_end_date gt '" +
          moment().format('YYYY-MM-DD') +
          "'"
      )
      .subscribe((res) => {
        this.products = res.data;
        this.productList = [...this.products];
        this.processing = false;
      });

    this.http
      .get(
        purl +
          "?order_by=id&order=desc&filter=is_it_paid eq 1 and status eq 1 and event_end_date lt '" +
          moment().format('YYYY-MM-DD') +
          "'"
      )
      .subscribe((res) => {
        this.oldProductsList = res.data;
        this.processing = false;
      });
  }

  buyNow(course: any) {
    course.packageType = 'course';
    const dialogRef = this.paymentService.initPayment(course);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.status === 'Success') {
        this.getProducts();
      }
    });
  }

  filetrList(searchTerm: any) {
    this.activeFilter = searchTerm;
    if (searchTerm == 'all') {
      this.productList = [...this.products];
    } else {
      this.productList = this.products.filter(
        (product) => product.properties.tag_line == searchTerm
      );
    }
  }
}
