import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../../../core/services/http.service';
import { environment } from '@environment/environment';
import { AuthService } from '@core/services/auth.service';
import { FormControl, Validators } from '@angular/forms';
import { jsPDF } from 'jspdf';
import 'svg2pdf.js';
@Component({
  selector: 'im-event-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {

  @Input() event: any;

  loginUser: any;


  nameFormControl = new FormControl('', [
    Validators.required
  ]);

  certificate: any;

  processing = true;

  exist: boolean = false;


  constructor(private auth: AuthService, private http: HttpService) { }

  ngOnInit() {
    this.loginUser = this.auth.loginUser;
    this.nameFormControl.setValue(this.loginUser.name);
    this.processing = true;
    this.generateCertificate();
  }

  generateCertificate() {
    this.http.post('user_certificate', { user_event_id: this.event.user_event.id, name: this.loginUser.name }, { showSuccessMessage: false }).subscribe((res) => {
      if (res.success == true) {
        this.exist = true;
        this.certificate = res.data;
      } else {
        this.exist = false;
      }
      this.processing = false;
    });
  }

  downloadCer() {
    const element = document.getElementById('certificate');
    //element.setAttribute("transform", "rotate(90)");
    const doc = new jsPDF('l');
    doc
      .svg(element)
      .then(() => {
        // save the created pdf
        doc.save(this.event.name + ' - Certification.pdf')
      })
  }

}
