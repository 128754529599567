import { environment } from '@environment/environment';

import { Subject ,  Observable } from 'rxjs'; 


export namespace IMStorage {

	let storageSessionSub= new Subject<any>();

	let storageLocalSub= new Subject<any>();

	let cookie_domain = '.'+environment.baseUrl;

	declare function escape(s:string): string;

	declare function unescape(s:string): string;

	export function watchSessionStorage(): Observable<any> {
    		return storageSessionSub.asObservable();
 	 }

 	 export function watchLocalStorage(): Observable<any> {
    		return storageLocalSub.asObservable();
 	 }

 	 export function setSession(key : string, value: any){
		if (sessionStorage) {
			sessionStorage.setItem(key, btoa(JSON.stringify(value)));
			storageSessionSub.next(key);
		}
	}

	export function getSession(key : string): any{
		if (sessionStorage) {
			let exist = sessionStorage.getItem(key);
			if(exist) return JSON.parse(atob(exist));
		}
	}


	export function set(key : string, value: any){
	    setCookie({name: key, value: btoa(JSON.stringify(value)), maxAge: 1});
		if (localStorage) {
			localStorage.setItem(key, btoa(JSON.stringify(value)));
			storageLocalSub.next(key);
		}
	}

	export function get(key : string) : any{
		if (localStorage) {
			let exist = localStorage.getItem(key);
			if(exist) return JSON.parse(atob(exist));
			else {
				let check_in_cookie = getCookie(key);
				//console.log(JSON.parse(atob(check_in_cookie)));
				if(check_in_cookie) return JSON.parse(atob(check_in_cookie));
				else return false;
			}
		}else{
			let check_in_cookie = getCookie(key);
		    if(check_in_cookie) return JSON.parse(atob(check_in_cookie));
			else return false;
		}	
	}

	export function remove(key?: string) {
		if(key){
			if (localStorage) {
				localStorage.removeItem(key);
				storageLocalSub.next(key);
			}
			deleteCookie(key);
		}else{
			if (localStorage) {
				localStorage.clear();
				storageLocalSub.next();
			}
			deleteAllCookies();
		}	
	}
	export function removeLocalStorage(key?: string) {
		if(key){
			if (localStorage) localStorage.removeItem(key);
		}else{
			if (localStorage) localStorage.clear();
		}	
	}

	export function getCookie(name: any) {
	  var value = "; " + document.cookie;
	  var parts = value.split("; " + name + "=");
	  if (parts.length == 2) return unescape(parts.pop().split(";").shift());
	}

	export function setCookie(params: any) {
	    var name            = params.name,
	        value           = params.value,
	        expireDays      = params.days,
	        expireHours     = params.hours,
	        expireMinutes   = params.minutes,
	        expireSeconds   = params.seconds;

	    if(!params.maxAge){    
		    var expireDate = new Date();
		    if (expireDays) {
		        expireDate.setDate(expireDate.getDate() + expireDays);
		    }
		    if (expireHours) {
		        expireDate.setHours(expireDate.getHours() + expireHours);
		    }
		    if (expireMinutes) {
		        expireDate.setMinutes(expireDate.getMinutes() + expireMinutes);
		    }
		    if (expireSeconds) {
		        expireDate.setSeconds(expireDate.getSeconds() + expireSeconds);
		    }

	    	document.cookie = name +"="+ escape(value) +
	        ";domain="+ cookie_domain +
	        ";path=/"+
	        ";expires="+expireDate.toUTCString();

	    }else{

	    	document.cookie = name +"="+ escape(value) +
	        ";domain="+ cookie_domain +
	        ";path=/"+
	        ";max-age="+31536000;
	    }
	}

	export function deleteCookie(name: string) {
	    setCookie({name: name, value: "", seconds: 1});
	}

	export function deleteAllCookies() {
		let cookies = document.cookie.split(";");
		for (var i = 0; i < cookies.length; i++) {
				let cookie = cookies[i];
		        let eqPos = cookie.indexOf("=");
		        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
				setCookie({name: name, value: "", seconds: 1});
		}
	}

}