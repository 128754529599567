import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'im-auth-comp',
  templateUrl: './comp.component.html',
  styleUrls: ['./comp.component.scss']
})
export class CompComponent implements OnInit {

  @Input() isItComonent = false;

  @Output() onSuccess = new EventEmitter<any>();

  email: string = '';

  state: string = 'login';

  constructor(private authService: AuthService) { }

  async loginCallback(event: any) {
      if (event.next == 'done') {
          this.state = '';
          const user = await this.authService.me();
          this.onSuccess.emit(user);
      }
      this.state = event.next;
      if(event.email) this.email = event.email;
      else {
        this.email = '';
      }
  }

  ngOnInit() {
  }

}
