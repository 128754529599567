<div class="grid 
    has-12-columns 
    has-1-rows
    justify-items-stretch h-screen relative playzone" *ngIf="game">
    <div class="result column-start-1  row-span-1 column-end-12 overflow-hidden">

        <div class="flex px-8 py-1 items-center header justify-between">
        <div class="flex items-center">
          <button mat-icon-button class="mr-2" (click)="back()"><mat-icon>keyboard_backspace</mat-icon></button>
          <h2>{{game.name}}</h2>
        </div>
        <!-- <div class="icon-info"> 
          <div class="border-radius-full"><img [src]="model.icon_p" width="32" height="32" /></div>
        </div> -->
      </div>
      <div class="flex w-full items-center justify-center" style="height: calc(70vh - 50px);">
        <div class="p-4 w-1/3 " style="display: flex;">
            <div class="shadow-1 h-3/5 p-4 border-radius-lg w-full">
                <h2>Introduction</h2>
                <p class="mt-2" style="overflow: hidden;height: 196px !important;">
                 {{game.short_description}}
                </p>
            </div>
        </div>
        <div class="p-4 w-2/5" style="display: flex;flex-direction: column;">
          <im-ai-play [game]="game"></im-ai-play>
        </div>
        <div class="w-1/4 p-4" style="display: flex;flex-direction: column;">
          <div id="output-section" class="section output section--output">
            <h2 class="section__title mb-4"><span>Output</span></h2>
            <div class="section__container">
              <!-- <div class="output__controls">
                <div class="output__selector-wrapper">
                  <div id="GIFOutput" class="output_selector__option output_selector__option--selected">GIF</div>
                </div>
              </div> -->
              <!-- <div class="divider output__divider"></div> -->
              <div id="output-player" class="output__player shadow-1">
                  <img src="" id="output-player-img" class="output__player_img" alt>
                  <div id="output-player-label" class="output__player_label" [ngStyle]="{background:game.properties.color}">

                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [ngStyle]="{'color': '#fff','background-color': game.properties.color, 'width': '100%', 'height':'30vh'}" class="flex">
        <div id="plygame-notes" class="overflow-y-auto w-1/2" ></div>
        <div id="plygame-notes-application" class="overflow-y-auto w-1/2"></div>
      </div>

     

    </div>


</div>
