import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth.component';
import { RoutesModule } from './routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PasswordComponent } from './password/password.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { AuthAvatarModule } from '@core/ui/auth-avatar';
import { OtpFieldModule } from '@core/ui/otp-field';


import { AuthGuardService } from '@core/services/guards/auth-guard.service';
import { UserCheckGuardService } from '@core/services/guards/user-check.service';
import { RoleGuardService } from '@core/services/guards/role-guard.service';


//import { SwiperModule } from 'ngx-swiper-wrapper';
//import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
//import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';


/*const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  autoplay: {
    delay: 5000,
  }
};*/

import { JwtModule, JWT_OPTIONS } from "@auth0/angular-jwt";
import { VerifyComponent } from './verify/verify.component';
import { ResetComponent } from './reset/reset.component';
import { RedirectComponent } from './redirect/redirect.component';
import { CompComponent } from './comp/comp.component';
import { AuthWrapperComponent } from './auth-wrapper/auth-wrapper.component';

export function tokenGetter() {
  return "SOME_TOKEN";
}

export function getAuthScheme(request) {
  return "Bearer ";
}

export function jwtOptionsFactory() {
  return {
    tokenGetter,
    authScheme: getAuthScheme,
  };
}



@NgModule({
  declarations: [AuthComponent, PasswordComponent, OnboardingComponent, VerifyComponent, ResetComponent, RedirectComponent, CompComponent, AuthWrapperComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatProgressBarModule,
    RoutesModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
      },
    }),
    AuthAvatarModule,
    OtpFieldModule,
    //SwiperModule,
    MatProgressSpinnerModule,
    MatCheckboxModule
  ],
  providers: [
    // {
    //   provide: SWIPER_CONFIG,
    //   useValue: DEFAULT_SWIPER_CONFIG
    // },
    AuthGuardService,
    UserCheckGuardService,
    RoleGuardService
  ],
  exports: [CompComponent]
})
export class AuthModule { }
