import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { AboutComponent } from './about/about.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TransactionsComponent } from './transactions/transactions.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IntegrationsComponent } from './integrations/integrations.component';
import { MatCardModule } from '@angular/material/card';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { CommunityModule } from '../community';
import { TimelineComponent } from './profile-page/timeline/timeline.component';
import { SummaryComponent } from './profile-page/summary/summary.component';
import { PublicComponent } from './public/public.component';
import { PSummaryComponent } from './public/summary/summary.component';
import { MyComponent } from './my/my.component';

@NgModule({
  declarations: [
    MyComponent,
    PSummaryComponent,
    ProfileComponent,
    ChangePasswordComponent,
    AboutComponent,
    TransactionsComponent,
    IntegrationsComponent,
    ProfilePageComponent,
    TimelineComponent,
    SummaryComponent,
    PublicComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTabsModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatCardModule,
    CommunityModule,
  ],
})
export class ProfileModule {}
