import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'im-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  navLinks = [
    {
      path: '/profile/edit',
      label: 'About',
    },
    {
      path: '/profile/edit/my-courses',
      label: 'My Courses',
    },
    {
      path: '/profile/edit/change-password',
      label: 'Change Password',
    },
  ];

  loginUser: any;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.loginUser = this.authService.loginUser;
    if (this.loginUser.user_type == 'student') {
      this.navLinks.push({
        path: '/profile/edit/transactions',
        label: 'Transactions',
      });
    } else if (this.loginUser.user_type == 'trainer') {
      this.navLinks.push({
        path: '/profile/edit/integrations',
        label: 'Links',
      });
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['auth']);
  }
}
