import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultModule } from '../../themes/default/default.module';
import { HttpClientModule } from '@angular/common/http';

import { AuthModule, ProfileModule } from '../../modules';

import { IMSnackBarModule } from '@core/utillities/snack-bar';


import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from '@core/services/interceptors/token.interceptor';
import { NotFoundComponent } from './not-found/not-found.component';

import {MatButtonModule} from '@angular/material/button';


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IMSnackBarModule,
    AuthModule,
    ProfileModule,
    DefaultModule,
    MatButtonModule
  ],
  providers: [ {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
