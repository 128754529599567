<div class="im-bar-courses">
  <div class="flex justify-between items-center">
    <h3
      class="ml-6 letter-spacing-10 font-semi-bold flex items-center"
      style="color: #06669f"
    >
      <mat-icon class="mr-2 material-symbols-outlined">shopping_cart</mat-icon
      >OUR OFFERING
    </h3>
    <img
      src="assets/images/logotag2.png"
      style="object-fit: contain"
      width="160"
      alt="SchoolForAI Logo"
      class="mr-4"
    />
  </div>
  <nav mat-tab-nav-bar>
    <a
      mat-tab-link
      *ngFor="let link of navLinks"
      [routerLink]="link.path"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
      class="letter-spacing-10"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      {{ link.label }}
    </a>
  </nav>
</div>

<div class="overflow-y-auto display-block" style="height: calc(100vh - 95px)">
  <router-outlet></router-outlet>
</div>
