import { Component, OnInit } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { PaymentService } from '@modules/payment/payment.service';

@Component({
  selector: 'im-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {

  courses: any;

  processing: boolean = true;

  subscriptionExist: boolean = false;

  constructor(private http: HttpService, private paymentService: PaymentService) { }

  ngOnInit() {
    this.checkSubscriptionExist()
    this.getCourses();
  }

  getCourses() {
    this.http.get('course?fields=id,name,icon,properties,short_description,slug,status,cover_image').subscribe((res)=>{
        this.courses = res.data;
    })
  }

  checkSubscriptionExist() {
    this.http.post('checkexist/user_course', {}).subscribe((res)=>{
      this.subscriptionExist = (res.data.exist > 0) ? true: false;
      this.processing = false;
    });
  }

  buyNow(course:any) {
    course.packageType = 'course';
    const dialogRef = this.paymentService.initPayment(course);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'Success') {
        this.getCourses();
      }
    });
  }

  upgrade(course:any) {
    course.packageType = 'course';
    const dialogRef = this.paymentService.initPayment(course, true);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'Success') {
        this.getCourses();
      }
    });
  }

}
