<div class="schedule-section">
  <mat-sidenav-container autosize>
    <mat-sidenav-content>
      <div class=" 
              grid 
              has-12-columns 
              has-1-rows
              column-gap-25 
              lg:column-gap-15 
              justify-items-stretch h-screen mobile-h-screen">
        <div class="main column-start-1  row-span-1 column-end-8 xs:column-end-12">
          <router-outlet></router-outlet>
        </div>
        <div class="main column-start-9  row-span-1 column-end-12 courses-section xs:display-none">
          <div class="py-8 px-8 my-3 mx-12 notes border-radius-lg" *ngIf="loginUser.user_type == 'trainer'">
            <h2 class="text-center mb-2 letter-spacing-10">About SchoolForAI</h2>
            <p class="font-base line-height-15rem">
              SchoolforAI is India’s First AI & Data Science focused online mentor-led, self-paced learning platform for
              students of Grade 7 & above with unique hybrid-learning pedagogy. We are here to democratize AI and our
              curriculum is AI and Data Science focused. Hybrid learning is a new way to learn the emerging technologies
              on a proven platform where the strengths of both mentor-led training and video-based learning are
              leveraged. It encapsulates experiential learning where learners play around the interactive practical
              learning modules, block and text coding techniques along with 1-on-1 interaction with the mentor. We
              ensure that the students master the basics and challenge themselves towards solving real-life problems
              using AI technologies. We empower schools and colleges to incorporate AI curriculum through teacher
              training and setting up AI labs.
            </p>
          </div>

          <ng-container *ngIf="!processing &&  loginUser.user_type == 'student'">
            <mat-accordion [multi]="true">
              <mat-expansion-panel expanded="true" hideToggle
                class="start-practicing mx-12 my-4 xs:display-none mb-2 mat-elevation-z0" disabled>
                <div class="start-practicing-empty">
                  <div class="start-practicing-empty-wrapper">
                    <div class="text-center mb-3">
                      <img src="assets/images/1.webp" style="width: 65%;" />
                      <h2 class="letter-spacing-15 text-uppercase">CODER</h2>
                      <p class="font-sm mt-2 mx-4" style="color: rgb(95 99 104 / 60%)">Practice
                        your coding skills in
                        Python
                        and MySQL
                        supported by code snippets </p>
                    </div>
                    <button mat-raised-button color="primary" class="letter-spacing-15 text-uppercase mb-6"
                      routerLink="/coder">GO
                      CODING</button>

                  </div>
                </div>

              </mat-expansion-panel>
            </mat-accordion>

          </ng-container>




        </div>
      </div>
    </mat-sidenav-content>
    <mat-sidenav #drawer mode="over" position="end" class="w-1/3 xs:w-full" (closedStart)="closedStart()">
      <im-schedule-details [activeEvent]="activeEvent" [drawer]="drawer"></im-schedule-details>
    </mat-sidenav>

  </mat-sidenav-container>
</div>