import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../../core/services/http.service';

@Component({
  selector: 'im-all',
  templateUrl: './all.component.html',
  styleUrls: ['./all.component.scss']
})
export class AllComponent implements OnInit {


  modelCategories: any = [];

  processing: boolean = true;

  customSort = ({data, sortBy}) => {
    const sortByObject = sortBy.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index
      }
    }, {})
    return data.sort((a, b) => {
      return sortByObject[a['properties']['tags']] - sortByObject[b['properties']['tags']]
    });
  }

  constructor(private http: HttpService) { }

  ngOnInit() {
    this.getModels();
  }

  getModels(){
    this.http.get('ai_model_categories?order_by=order').subscribe((res)=>{
        this.modelCategories = res.data;
        this.modelCategories.forEach(data => {
           data.ai_models = this.customSort({data: data.ai_models, sortBy: ['Basic', 'Intermediate', 'Advanced']});
        });
        this.processing = false;
    });
  }

}
