import { Component, OnInit, Inject, HostBinding } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { UrlSerializer, Router, NavigationEnd } from '@angular/router';
import { environment } from '@environment/environment';
import {
  DOCUMENT
} from '@angular/common';

@Component({
  selector: 'im-picture-video',
  templateUrl: './picture-video.component.html',
  styleUrls: ['./picture-video.component.scss']
})
export class PictureVideoComponent implements OnInit {

  link: any;

  @HostBinding('class.minimize') minimize: boolean = true;

  constructor(protected sanitizer: DomSanitizer, private dialogRef: MatDialogRef<PictureVideoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
      this.link = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url+'&time=false&progressBar=false&pip=false&autoplay=true');
  }

  close() {
    this.dialogRef.close();
  }

}
