<div class="h-screen mx-auto my-auto lg:w-32vw xs:w-full md:w-full">
  <div
    class="flex flex-column p-12 justify-center"
    [ngClass]="{ 'im-login-center': isItComonent }"
  >
    <div class="mb-4 flex flex-column justify-center items-center">
      <h3 class="mb-4">Verification</h3>
      <p class="px-4 py-1 xs:text-center">
        We sent the otp to the below email address
      </p>
      <p>
        <strong>{{ email }}</strong>
      </p>
      <a
        class="pt-2 text-right cursor-pointer mat-color-primary font-sm"
        (click)="resendOtp()"
        >Didn't Received Otp? Try Again</a
      >
    </div>
    <form>
      <div class="mb-4">
        <im-otp-field
          class="text-center"
          (onInputChange)="otpChange($event)"
          [config]="otpConfig"
        ></im-otp-field>
        <mat-error class="text-center display-block pt-2 font-xs">
          <ng-container *ngIf="otpError && !otp"
            ><strong>Please enter a otp</strong></ng-container
          >
          <ng-container *ngIf="otpError && otp"
            ><strong>Please enter a valid otp</strong></ng-container
          >
          &nbsp;
        </mat-error>
      </div>

      <mat-form-field class="w-full" appearance="outline" floatLabel="always">
        <mat-label>Name</mat-label>
        <input
          matInput
          placeholder="Your name"
          [formControl]="nameFormControl"
          color="#fff"
          [type]="'text'"
          id="authName"
          required
        />
        <mat-icon matSuffix>{{ "person" }}</mat-icon>

        <mat-error
          *ngIf="passwordFormControl.hasError('required')"
          class="mb-2"
        >
          Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field
        class="w-full mt-4"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>PASSWORD</mat-label>
        <input
          matInput
          placeholder="Set the password for your account"
          [formControl]="passwordFormControl"
          color="#fff"
          [type]="hide ? 'password' : 'text'"
          id="authPassword"
          required
        />
        <button
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>

        <mat-error
          *ngIf="passwordFormControl.hasError('required')"
          class="mb-2"
        >
          Password is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field
        class="w-full mt-4"
        appearance="outline"
        floatLabel="always"
      >
        <mat-label>Referral Code</mat-label>
        <input
          matInput
          placeholder=""
          [formControl]="referalControl"
          color="#fff"
          type="text"
          id="authReferal"
          (keyup.enter)="next(termsCheckbox.checked)"
        />
        <mat-icon matSuffix>scatter_plot</mat-icon>
      </mat-form-field>

      <div class="action-items flex flex-column pt-6">
        <div class="mb-4 font-xs font-light">
          <mat-checkbox class="ml-4" color="primary" #termsCheckbox>
            <p class="ml-2 mr-4">
              By registering here, I agree to AITINKR
              <a
                href="https://aitinkr.com/terms-and-conditions"
                target="_blank"
                class="mat-color-primary"
                >Terms & Conditions</a
              >
              and
              <a
                href="https://aitinkr.com/privacy-policy"
                target="_blank"
                class="mat-color-primary"
                >Privacy Policy</a
              >
            </p>
          </mat-checkbox>
          <mat-error *ngIf="termsCheckError" class="ml-4 mt-2"
            >Please accept the terms and conditions</mat-error
          >
        </div>
        <button
          mat-stroked-button
          color="primary"
          (click)="next(termsCheckbox.checked)"
          [disabled]="processing"
          class="w-full im-button"
        >
          <div class="flex justify-between items-center">
            <span>{{ processing ? "Processing..." : "CONTINUE" }}</span>
            <mat-icon>east</mat-icon>
          </div>
        </button>
      </div>
    </form>
  </div>
</div>
