import { Component, OnInit, ViewChild, ElementRef, Input, Output,  EventEmitter  } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute , Router} from '@angular/router';
import { HttpService } from '@core/services/http.service';

@Component({
  selector: 'im-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  @Input() isItComonent = false;
  @Output() loginCallback = new EventEmitter<any>();

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email
  ]);

  @ViewChild('emailAddressInput') private emailAddressInput: ElementRef;

  processing: boolean = false;

  showView: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpService) { }

  ngOnInit() {
    this.showView = true;
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.emailAddressInput.nativeElement.focus();
    }, 200)
  }

  next(){
    if(this.emailFormControl.valid){
      this.processing = true;
      this.http.post('exist/user',{email:this.emailFormControl.value}, {showErrorMessage: false}).subscribe((res)=>{
        if(res && res.success){
          if (this.isItComonent){
              this.loginCallback.emit({next: 'password', email: this.emailFormControl.value});
          } else {
            this.router.navigate(['auth/password', btoa(this.emailFormControl.value)], {queryParamsHandling: 'preserve'});
          }
        } else {
          this.processing = false;
        }
      }, (err) => {
        if (err.status === 404) {
          if (this.isItComonent){
            this.loginCallback.emit({next: 'verify', email: this.emailFormControl.value});
          } else {
            this.router.navigate(['auth/verify', btoa(this.emailFormControl.value)], {queryParamsHandling: 'preserve'});
          }
        } else {
          this.processing = false;
        }
      })
    }
  }

}
