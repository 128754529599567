<div class="profile-page">
    <div class="container">

        <div class="main">

            <div class="main-container">
                <div class="profile">

                    <img src="assets/images/Cover1.png" alt="" class="profile-cover">
                    <div class="profile-menu">
                        <a class="profile-menu-link" routerLink="about" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact:
                  true}">About</a>
                        <a class="profile-menu-link" routerLink="feed" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact:
                  true}">Timeline</a>
                        <!-- <a class="profile-menu-link" routerLink="edit" >Edit Profile</a> -->
                    </div>
                </div>
                <div class="timeline mr-8">
                    <div class="timeline-right">
                        <router-outlet></router-outlet>
                    </div>
                    <div class="timeline-left">
                        <div class="intro box shadow-1" style="position: absolute;top: -30%;z-index: 2;">
                            <div class="profile-avatar">
                                <img [src]="loginUser.avatar" alt="" class="profile-img">
                            </div>
                            <div class="profile-name">{{loginUser.name}}</div>
                            <div class="info" z>
                                <div class="mb-4 color-secondary">
                                    <ng-container *ngIf="loginUser.about">
                                        {{loginUser.about}}
                                    </ng-container>
                                    <ng-container *ngIf="!loginUser.about">
                                        <div class="font-xs">
                                            Write about yourself. This is your personal space to express your thoughts
                                            and plans. You could even share your profile with your peers.

                                        </div>
                                    </ng-container>
                                </div>
                                <div class="info-item" *ngIf="loginUser.school">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 503.889 503.889"
                                        fill="currentColor">
                                        <path
                                            d="M453.727 114.266H345.151V70.515c0-20.832-16.948-37.779-37.78-37.779H196.517c-20.832 0-37.78 16.947-37.78 37.779v43.751H50.162C22.502 114.266 0 136.769 0 164.428v256.563c0 27.659 22.502 50.161 50.162 50.161h403.565c27.659 0 50.162-22.502 50.162-50.161V164.428c0-27.659-22.503-50.162-50.162-50.162zm-262.99-43.751a5.786 5.786 0 015.78-5.779h110.854a5.786 5.786 0 015.78 5.779v43.751H190.737zM32 164.428c0-10.015 8.147-18.162 18.162-18.162h403.565c10.014 0 18.162 8.147 18.162 18.162v23.681c0 22.212-14.894 42.061-36.22 48.27l-167.345 48.723a58.482 58.482 0 01-32.76 0L68.22 236.378C46.894 230.169 32 210.321 32 188.109zm421.727 274.725H50.162c-10.014 0-18.162-8.147-18.162-18.161V253.258c8.063 6.232 17.254 10.927 27.274 13.845 184.859 53.822 175.358 52.341 192.67 52.341 17.541 0 7.595 1.544 192.67-52.341 10.021-2.918 19.212-7.613 27.274-13.845v167.733c.001 10.014-8.147 18.162-18.161 18.162z" />
                                    </svg>
                                    School at <a href="javascript:void(0)">{{loginUser.school}}</a>
                                </div>
                                <div class="info-item mt-2" *ngIf="loginUser.grade">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 503.889 503.889"
                                        fill="currentColor">
                                        <path
                                            d="M453.727 114.266H345.151V70.515c0-20.832-16.948-37.779-37.78-37.779H196.517c-20.832 0-37.78 16.947-37.78 37.779v43.751H50.162C22.502 114.266 0 136.769 0 164.428v256.563c0 27.659 22.502 50.161 50.162 50.161h403.565c27.659 0 50.162-22.502 50.162-50.161V164.428c0-27.659-22.503-50.162-50.162-50.162zm-262.99-43.751a5.786 5.786 0 015.78-5.779h110.854a5.786 5.786 0 015.78 5.779v43.751H190.737zM32 164.428c0-10.015 8.147-18.162 18.162-18.162h403.565c10.014 0 18.162 8.147 18.162 18.162v23.681c0 22.212-14.894 42.061-36.22 48.27l-167.345 48.723a58.482 58.482 0 01-32.76 0L68.22 236.378C46.894 230.169 32 210.321 32 188.109zm421.727 274.725H50.162c-10.014 0-18.162-8.147-18.162-18.161V253.258c8.063 6.232 17.254 10.927 27.274 13.845 184.859 53.822 175.358 52.341 192.67 52.341 17.541 0 7.595 1.544 192.67-52.341 10.021-2.918 19.212-7.613 27.274-13.845v167.733c.001 10.014-8.147 18.162-18.161 18.162z" />
                                    </svg>
                                    Grade <a href="javascript:void(0)">{{loginUser.grade}}</a>
                                </div>

                                <div class="im-badges">
                                    <div class="badge red">
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px"
                                            width="100px" height="120px" viewBox="0 0 216 232">
                                            <path fill="#2B2B2B"
                                                d="M207,0C171.827,0.001,43.875,0.004,9.003,0c-5.619-0.001-9,3.514-9,9c0,28.23-0.006,151.375,0,169    c0.005,13.875,94.499,54,107.999,54S216,191.57,216,178V9C216,3.298,212.732,0,207,0z" />
                                        </svg>
                                        <p class="title">BRUTEFORCE EXPERT</p>
                                        <p class="subtitle">CTF 365</p>
                                    </div>

                                    <div class="badge green">
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px"
                                            width="100px" height="120px" viewBox="0 0 216 232">
                                            <path fill="#2B2B2B"
                                                d="M207,0C171.827,0.001,43.875,0.004,9.003,0c-5.619-0.001-9,3.514-9,9c0,28.23-0.006,151.375,0,169    c0.005,13.875,94.499,54,107.999,54S216,191.57,216,178V9C216,3.298,212.732,0,207,0z" />
                                        </svg>
                                        <p class="title">SQLi<br />MASTER</p>
                                        <p class="subtitle">CTF 365</p>
                                    </div>

                                    <div class="badge gray">
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px"
                                            width="100px" height="120px" viewBox="0 0 216 232">
                                            <path fill="#2B2B2B"
                                                d="M207,0C171.827,0.001,43.875,0.004,9.003,0c-5.619-0.001-9,3.514-9,9c0,28.23-0.006,151.375,0,169    c0.005,13.875,94.499,54,107.999,54S216,191.57,216,178V9C216,3.298,212.732,0,207,0z" />
                                        </svg>
                                        <p class="title">SNIFFER COMMANDER</p>
                                        <p class="subtitle">CTF 365</p>
                                    </div>

                                    <div class="badge yellow">
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px"
                                            width="100px" height="120px" viewBox="0 0 216 232">
                                            <path fill="#2B2B2B"
                                                d="M207,0C171.827,0.001,43.875,0.004,9.003,0c-5.619-0.001-9,3.514-9,9c0,28.23-0.006,151.375,0,169    c0.005,13.875,94.499,54,107.999,54S216,191.57,216,178V9C216,3.298,212.732,0,207,0z" />
                                        </svg>
                                        <p class="title">SCRIPT<br />EXPERT</p>
                                        <p class="subtitle">CTF 365</p>
                                    </div>

                                    <div class="badge blue">
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px"
                                            width="100px" height="120px" viewBox="0 0 216 232">
                                            <path fill="#2B2B2B"
                                                d="M207,0C171.827,0.001,43.875,0.004,9.003,0c-5.619-0.001-9,3.514-9,9c0,28.23-0.006,151.375,0,169    c0.005,13.875,94.499,54,107.999,54S216,191.57,216,178V9C216,3.298,212.732,0,207,0z" />
                                        </svg>
                                        <p class="title">CRYPTO MASTER</p>
                                        <p class="subtitle">CTF 365</p>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="overlay" (click)="rightSide = false; leftSide = false"
            [ngClass]="{ 'active': rightSide || leftSide }"></div>
    </div>
</div>