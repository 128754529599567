<mat-drawer-container hasBackdrop="false">
  <mat-drawer #drawer mode="over" position="end">
    <ng-container *ngIf="sidNavType == 1">
      <ng-container *ngIf="!loginUser">
        <div class="auth-wrapper-product xs:w-screen">
          <button
            mat-icon-button
            color="warn"
            class="absolute right-2-rem top-1-rem cursor-pointer"
            (click)="drawer.close()"
            style="z-index: 2"
          >
            <mat-icon>close</mat-icon>
          </button>
          <im-auth-comp
            isItComonent="true"
            (onSuccess)="loginSuccess($event, drawer, false)"
          ></im-auth-comp>
        </div>
      </ng-container>

      <div class="activation-code-wrapper xs:w-auto" *ngIf="loginUser">
        <button
          mat-icon-button
          color="warn"
          class="absolute right-2-rem top-1-rem cursor-pointer"
          (click)="drawer.close()"
          style="z-index: 2"
        >
          <mat-icon>close</mat-icon>
        </button>
        <div class="screen">
          <div class="flex flex-column">
            <h3 class="title poppins-font font-2xl">Enter your Given Key</h3>
            <p class="mt-2 line-height-15rem opacity-75">
              We thank you for this purchase and assure you of our committed
              support. <b>Enter</b> the 16-digit Activation code to unlock
              course and learning videos. Book your FREE Trainer Sessions.
            </p>
          </div>

          <div class="form-container mt-6">
            <div class="form-title mb-2">
              <b class="letter-spacing-15">ACTIVATION CODE</b>
            </div>
            <form (ngSubmit)="validateActivationCode()">
              <div style="margin-right: 1rem">
                <input
                  id="activation-code"
                  name="activation-code"
                  type="text"
                  placeholder="XXXX-XXXX-XXXX-XXXX"
                  required
                  class="input"
                  [(ngModel)]="key"
                  (ngModelChange)="onKeyChange()"
                  maxlength="19"
                />
                <span
                  *ngIf="errorProductKeyMessage"
                  class="mat-error mt-2 flex font-semi-bold"
                  >{{ errorProductKeyMessage }}</span
                >
              </div>
              <div>
                <button
                  type="submit"
                  class="button"
                  [disabled]="!key && key.length != 16"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="sidNavType == 2">
      <div style="width: 33vw" class="auth-wrapper-product">
        <button
          mat-icon-button
          color="warn"
          class="absolute right-2-rem top-1-rem cursor-pointer"
          (click)="drawer.close()"
          style="z-index: 2"
        >
          <mat-icon>close</mat-icon>
        </button>
        <im-auth-comp
          isItComonent="true"
          (onSuccess)="loginSuccess($event, drawer, true)"
        ></im-auth-comp>
      </div>
    </ng-container>
  </mat-drawer>
  <mat-drawer-content>
    <div
      class="md:grid has-12-columns has-1-rows justify-items-stretch h-screen"
      *ngIf="!processing"
    >
      <div
        class="main-course-details column-start-1 row-span-1 column-end-8 pb-2 xxl:column-end-8"
      >
        <div
          class="w-full cover-image bg-no-repeat course-header"
          [style.background-image]="course.cover_image_pb"
        >
          <!-- <div class="play" *ngIf="course.properties.courseIntroVideo">
        <button mat-icon-button><img src="assets/images/play.gif" /></button>
      </div> -->
          <div
            class="flex top-0 justify-end course-level-wrapper"
            *ngIf="course.group_course == 1"
          >
            <div
              class="flex course-level flex-column cursor-pointer"
              [ngClass]="{ active: course.properties.level == 1 }"
              routerLink="../foundation-for-ai"
            >
              <span>STEP</span><span class="level-1">1</span>
            </div>
            <div
              class="flex course-level flex-column cursor-pointer"
              [ngClass]="{ active: course.properties.level == 2 }"
              routerLink="../data-science"
            >
              <span>STEP</span><span class="level-2">2</span>
            </div>
            <div
              class="flex course-level flex-column cursor-pointer"
              [ngClass]="{ active: course.properties.level == 3 }"
              routerLink="../artificial-intelligence"
            >
              <span>STEP</span><span class="level-3">3</span>
            </div>
          </div>
          <!-- <div class="content flex top-0 justify-end course-level flex-column">
              <span>LEVEL</span><span class="level-{{course.properties.level}}">{{course.properties.level}}</span>
          </div> -->
          <div
            class="content flex justify-content-space-between align-items-center"
          >
            <!-- <button
          mat-icon-button
          class="icon"
          aria-label="Course Intro"
          class="course_intro"
          (click)="courseIntro()"
        >
          <mat-icon>play_circle_outline</mat-icon>
        </button> -->
            <div class="">
              <span class="title-tag" *ngIf="course.properties.tag">{{
                course.properties.tag
              }}</span>
              <h1>{{ course.name }}</h1>
              <div
                class="flex mt-1 course-more-info"
                *ngIf="course.category == 'trainer'"
              >
                <span class="font-xss"
                  >{{ course.properties.duration }}
                  {{ course.properties.durationUnit }}</span
                >
                <span class="dot-separator">.</span>
                <span class="font-xss"
                  >{{ course.properties.sessions }} Sessions</span
                >
                <span class="dot-separator">.</span>
                <span class="font-xss"
                  >{{ course.properties.projectSessions }}Projects</span
                >
              </div>
              <div
                class="flex mt-1 course-more-info"
                *ngIf="course.category == 'videos'"
              >
                <span class="font-xss"
                  >{{ course.properties.sessions }} Sessions</span
                >
                <span class="dot-separator">.</span>
                <span class="font-xss"
                  >{{ course.properties.engagement }}Engagement</span
                >
              </div>
            </div>
            <div class="flex xs:flex-column xs:items-end">
              <button
                mat-raised-button
                class="letter-spacing-15 text-uppercase flex mr-4 xs:mr-0 xs:mb-4"
                color="primary"
                (click)="courseIntro()"
              >
                <mat-icon>play_circle_outline</mat-icon>
                <span class="ml-1">Watch Now</span>
              </button>
              <!-- <ng-container *ngIf="!course.user_course">
                <ng-container *ngIf="course.id > 3">
                  <button
                    mat-raised-button
                    class="letter-spacing-15 text-uppercase flex"
                    color="primary"
                    (click)="buyNow(course)"
                  >
                    Buy Now
                  </button>
                </ng-container>
                <ng-container *ngIf="course.id <= 3">
                  <button
                    mat-stroked-button
                    class="letter-spacing-15 text-uppercase flex"
                    color="course{{ course.id }}-button"
                    (click)="buyNow(course)"
                  >
                    Buy Now
                  </button>
                </ng-container>
              </ng-container> -->
            </div>

            <!-- <ng-container *ngIf="course.user_course">
          <div class="w-1/4">
            <h5 class="mb-2">Course Progress:</h5>
            <mat-progress-bar
              class=""
              color="course{{ course.id }}"
              mode="determinate"
              value="0"
            ></mat-progress-bar>
          </div>
        </ng-container> -->
          </div>
        </div>
        <div class="course-content px-4 pt-4">
          <p style="line-height: 1.8em">
            {{ course.description || course.short_description }}
          </p>

          <div class="chapters-section mb-12">
            <ng-container *ngIf="course.category == 'aitinker'">
              <div class="mt-4 aitinker-index" *ngIf="courseModules.length > 0">
                <mat-accordion>
                  <ng-container
                    *ngFor="let module of courseModules; let step = index"
                  >
                    <mat-expansion-panel [expanded]="step === 0">
                      <mat-expansion-panel-header>
                        <div class="flex items-center">
                          <!-- <div class="module-icon" *ngIf="module.icon_p">
                        <img [src]="module.icon_p" width="24" height="24" />
                      </div> -->
                          <button
                            mat-icon-button
                            class="icon"
                            aria-label="Module icon"
                            *ngIf="module.icon_p"
                            type="button"
                          >
                            <img [src]="module.icon_p" width="24" height="24" />
                          </button>
                          <span class="font-sm letter-spacing-10">
                            {{ module.name }}</span
                          >
                        </div>
                        <!-- <span class="im-spacer"></span> -->
                      </mat-expansion-panel-header>
                      <div class="topics">
                        <p
                          class="px-4 pb-2 font-light color-secondary"
                          *ngIf="module.short_description"
                        >
                          {{ module.short_description }}
                        </p>
                        <ul>
                          <li
                            *ngFor="let chapter of module.chapter"
                            class="flex"
                          >
                            <div
                              *ngIf="
                                !chapter?.properties?.is_it_free &&
                                !course.user_course
                              "
                            >
                              <ng-container
                                *ngIf="
                                  course.access &&
                                    course.access?.chap &&
                                    course.access.chap.includes(chapter.id);
                                  else lockedChapter
                                "
                              >
                                <button
                                  mat-icon-button
                                  class="icon lock-icon"
                                  aria-label="Chapter is Unlocked"
                                >
                                  <mat-icon>play_circle_outline</mat-icon>
                                </button>
                                <a
                                  class="cursor-pointer"
                                  (click)="redirectDetails(module, chapter)"
                                >
                                  {{ chapter.title || chapter.name }}</a
                                >
                              </ng-container>
                              <ng-template #lockedChapter>
                                <button
                                  mat-icon-button
                                  class="icon lock-icon"
                                  aria-label="Chapter is Locked"
                                  (click)="openLockStatus()"
                                >
                                  <mat-icon>lock_outline</mat-icon>
                                </button>
                                {{ chapter.title || chapter.name }}
                              </ng-template>
                            </div>
                            <div
                              *ngIf="
                                chapter?.properties?.is_it_free ||
                                course?.user_course
                              "
                            >
                              <button
                                mat-icon-button
                                class="icon lock-icon"
                                aria-label="Chapter is Unlocked"
                              >
                                <mat-icon>play_circle_outline</mat-icon>
                              </button>
                              <a
                                class="cursor-pointer"
                                (click)="redirectDetails(module, chapter)"
                              >
                                {{ chapter.title || chapter.name }}</a
                              >
                            </div>
                            <span class="im-spacer"></span>
                          </li>
                        </ul>
                      </div>
                    </mat-expansion-panel>
                  </ng-container>
                </mat-accordion>
              </div>
            </ng-container>
            <ng-container *ngIf="course.category != 'aitinker'">
              <ng-container *ngFor="let module of courseModules">
                <div class="mt-4">
                  <mat-toolbar class="course{{ course.id }}">
                    <div class="flex items-center">
                      <div class="module-icon" *ngIf="module.icon_p">
                        <img [src]="module.icon_p" width="24" height="24" />
                      </div>
                      <span class="font-sm letter-spacing-10">
                        {{ module.name }}</span
                      >
                    </div>
                    <!-- <span class="im-spacer"></span> -->
                    <!-- <button mat-icon-button class="icon" aria-label="Module icon" *ngIf="module.icon_p">
                      <img [src]="module.icon_p" width="24" height="24"/>
                    </button> -->
                  </mat-toolbar>
                  <div
                    class="topics py-4 border border-width-1 border-color-whitesmoke"
                  >
                    <p
                      class="px-4 pb-2 font-light color-secondary"
                      *ngIf="module.short_description"
                    >
                      {{ module.short_description }}
                    </p>
                    <ul>
                      <li *ngFor="let chapter of module.chapter" class="flex">
                        <div
                          *ngIf="
                            (!chapter?.properties?.is_it_free &&
                              !course.user_course) ||
                            (course?.user_course?.properties?.chapterStatus &&
                              course?.user_course?.properties?.chapterStatus[
                                chapter.id
                              ]?.lock == 1)
                          "
                        >
                          <ng-container
                            *ngIf="
                              course.access &&
                                course.access?.chap &&
                                course.access.chap.includes(chapter.id);
                              else lockedChapter
                            "
                          >
                            <button
                              mat-icon-button
                              class="icon lock-icon"
                              aria-label="Chapter is Unlocked"
                            >
                              <mat-icon>play_circle_outline</mat-icon>
                            </button>
                            <a
                              class="cursor-pointer"
                              (click)="redirectDetails(module, chapter)"
                            >
                              {{ chapter.title || chapter.name }}</a
                            >
                          </ng-container>
                          <ng-template #lockedChapter>
                            <button
                              mat-icon-button
                              class="icon lock-icon"
                              aria-label="Chapter is Locked"
                              (click)="openLockStatus()"
                            >
                              <mat-icon>lock_outline</mat-icon>
                            </button>
                            {{ chapter.title || chapter.name }}
                          </ng-template>
                        </div>
                        <div
                          *ngIf="
                            chapter?.properties?.is_it_free ||
                            (course?.user_course?.properties?.chapterStatus &&
                              course?.user_course?.properties?.chapterStatus[
                                chapter.id
                              ]?.lock == 0)
                          "
                        >
                          <button
                            mat-icon-button
                            class="icon lock-icon"
                            aria-label="Chapter is Unlocked"
                          >
                            <mat-icon>play_circle_outline</mat-icon>
                          </button>
                          <a
                            class="cursor-pointer"
                            (click)="redirectDetails(module, chapter)"
                          >
                            {{ chapter.title || chapter.name }}</a
                          >
                        </div>
                        <span class="im-spacer"></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>

      <div
        class="buy-section column-start-9 row-span-1 column-end-12"
        style="background: white"
      >
        <ng-container *ngIf="course.category == 'aitinker'">
          <div
            class="relative"
            style="border-left: 1px solid aliceblue; display: unset"
          >
            <div
              class="sticky h-screen top-0 w-full"
              style="display: inline-grid"
            >
              <ng-container *ngIf="!course.user_course">
                <section
                  aria-labelledby="summary-heading "
                  class="w-full flex flex-column h-full"
                >
                  <ul
                    role="list"
                    class="overflow-y-auto list-unstyled h-full payment-summary"
                    *ngIf="activePackage"
                  >
                    <li class="flex flex-column h-full">
                      <div
                        class="flex justify-center"
                        style="background: #f9fafc; height: 240px"
                      >
                        <img
                          [src]="activePackage.properties.image"
                          alt=""
                          class="border-radius-lg h-full"
                        />
                      </div>
                      <div class="flex pl-5">
                        <div
                          *ngFor="let package of packages"
                          class="package-category"
                          [ngClass]="{
                            active:
                              activePackage && package.id == activePackage.id
                          }"
                          (click)="makeActivePackage(package)"
                        >
                          <span>{{ package.name }}</span>
                        </div>
                      </div>

                      <div
                        class="flex flex-column justify-between py-4"
                        style="border-bottom: 1px solid #e5e7eb"
                      >
                        <div class="text-sm font-medium">
                          <div class="flex justify-between items-center px-6">
                            <p
                              class="text-uppercase letter-spacing-10 mat-color-primary font-medium"
                            >
                              MAKE IT YOURS
                            </p>

                            <div class="flex justify-end items-center">
                              <ng-container
                                *ngIf="
                                  !couponInfoApply?.effected_price && !isItFree
                                "
                              >
                                <ng-container *ngIf="checkingCoupon">
                                  <mat-spinner
                                    strokeWidth="3"
                                    diameter="50"
                                  ></mat-spinner>
                                </ng-container>
                                <ng-container *ngIf="!checkingCoupon">
                                  <mat-form-field
                                    appearance="outline"
                                    class="mr-4"
                                    style="width: 10vw"
                                  >
                                    <mat-label>Coupon Code</mat-label>
                                    <input
                                      matInput
                                      placeholder=""
                                      #coupon
                                      class="text-uppercase"
                                      style="letter-spacing: 3px"
                                      (keyup.enter)="couponApply(coupon.value)"
                                    />
                                    <mat-icon
                                      matSuffix
                                      class="material-icons-outlined"
                                      >local_offer</mat-icon
                                    >
                                  </mat-form-field>
                                  <button
                                    type="button"
                                    mat-flat-button
                                    color="accent"
                                    (click)="couponApply(coupon.value)"
                                    style="
                                      letter-spacing: 0.2em;
                                      font-size: 0.7rem;
                                      height: 32px;
                                      line-height: 32px;
                                    "
                                  >
                                    APPLY
                                  </button>
                                </ng-container>
                              </ng-container>
                              <ng-container
                                *ngIf="
                                  couponInfoApply?.effected_price || isItFree
                                "
                              >
                                <div class="flex items-center justify-between">
                                  <div class="flex items-center mr-4">
                                    <mat-icon
                                      matSuffix
                                      class="material-icons-outlined mr-2"
                                      color="accent"
                                      >local_offer</mat-icon
                                    >
                                    <div class="flex flex-column">
                                      <span
                                        >YAY! You saved
                                        {{
                                          " " +
                                            (activePackage.price -
                                              couponInfoApply.effected_price)
                                            | currency
                                              : "INR"
                                              : "symbol"
                                              : "2.0-0"
                                        }}</span
                                      >
                                      <span class="font-xs font-light"
                                        >Coupon Applied</span
                                      >
                                    </div>
                                  </div>

                                  <button
                                    (click)="
                                      couponInfoApply = ''; couponNumber = ''
                                    "
                                    mat-icon-button
                                  >
                                    <mat-icon
                                      class="material-icons-outlined"
                                      style="color: #525252"
                                      >highlight_off</mat-icon
                                    >
                                  </button>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="px-6 py-4 line-height-2rem"
                        *ngIf="activePackage"
                        style="border-bottom: 1px solid #e5e7eb"
                      >
                        <dl
                          class="mt-0 space-y-6 text-sm font-medium text-gray-500"
                        >
                          <div class="flex justify-between">
                            <dt>Subtotal</dt>
                            <dd class="text-gray-900">
                              <sup>₹</sup>{{ activePackage.subTotal }}
                            </dd>
                          </div>
                          <div class="flex justify-between">
                            <dt class="flex">Discount</dt>
                            <dd class="text-gray-900">
                              -<sup>₹</sup>{{ activePackage.discountAmount }}
                            </dd>
                          </div>
                          <div class="flex justify-between">
                            <dt>Shipping</dt>
                            <dd class="text-gray-900 tracking-[.25em]">
                              <sup *ngIf="activePackage.shipping">₹</sup
                              >{{
                                activePackage.shipping == 0
                                  ? "FREE"
                                  : activePackage.shipping
                              }}
                            </dd>
                          </div>

                          <div class="flex justify-between">
                            <dt>GST</dt>
                            <dd class="text-gray-900">
                              <sup>₹</sup>{{ activePackage.taxes }}
                            </dd>
                          </div>
                        </dl>
                      </div>
                      <div
                        class="px-6 py-4"
                        style="border-bottom: 1px solid #e5e7eb"
                        *ngIf="
                          activePackage && activePackage.properties?.deposit
                        "
                      >
                        <div class="flex items-center justify-between">
                          <dt class="font-base">Deposit</dt>
                          <dd class="font-base">
                            <sup>₹</sup>{{ activePackage.properties?.deposit }}
                          </dd>
                        </div>
                      </div>
                      <div class="px-6 py-4" *ngIf="activePackage">
                        <div class="flex items-center justify-between">
                          <dt class="font-base">Total</dt>
                          <dd class="font-base">
                            <sup>₹</sup>{{ activePackage.total }}
                          </dd>
                        </div>
                      </div>
                      <div>
                        <ng-container *ngIf="loginUser">
                          <button
                            mat-raised-button
                            class="letter-spacing-15 text-uppercase float-right w-1/3 mr-3"
                            color="primary"
                            (click)="initiatePayment()"
                          >
                            Buy Now
                          </button>
                        </ng-container>
                        <ng-container *ngIf="!loginUser">
                          <button
                            mat-raised-button
                            class="letter-spacing-15 text-uppercase float-right w-1/3 mr-3"
                            color="primary"
                            (click)="loginInitiateForBuy(drawer)"
                          >
                            Buy Now
                          </button>
                        </ng-container>
                      </div>
                    </li>
                  </ul>
                </section>
                <div
                  class="flex justify-between items-center w-full absolute bottom-0"
                  style="background: aliceblue; padding: 1rem"
                >
                  <div class="flex flex-column w-full items-center">
                    <p class="mb-2 font-sm">
                      Already Purchased <b>{{ course.name }}</b
                      >?
                    </p>
                    <a
                      class="text-uppercase letter-spacing-5 mat-color-primary poppins-font cursor-pointer font-lg"
                      (click)="sidNavType = 1; drawer.open()"
                    >
                      Enter Activation Code
                    </a>
                  </div>
                </div>
              </ng-container>

              <div
                class="flex flex-column mt-2 mr-2 px-6"
                *ngIf="course.user_course"
              >
                <img
                  src="/assets/images/congrats.png"
                  width="180"
                  class="self-center"
                />
                <div class="flex justify-between items-center">
                  <p class="opacity-75 w-3/5 font-xs">
                    I personally thank you for this purchase and assure you of
                    our committed support.
                  </p>

                  <div class="flex flex-column">
                    <span class="mb-1">For Support :</span>
                    <a
                      class="flex items-center"
                      target="_blank"
                      href="https://wa.me/9949296431"
                    >
                      <span class="mr-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16"
                          width="24px"
                          height="24px"
                          style="fill: #00a884"
                        >
                          <path
                            d="M 7.5 1 C 3.914063 1 1 3.914063 1 7.5 C 1 8.796875 1.417969 9.984375 2.070313 11 L 0.953125 14.121094 L 4.429688 13.140625 C 5.351563 13.644531 6.375 14 7.5 14 C 11.085938 14 14 11.085938 14 7.5 C 14 3.914063 11.085938 1 7.5 1 Z M 7.5 2 C 10.542969 2 13 4.457031 13 7.5 C 13 10.542969 10.542969 13 7.5 13 C 6.457031 13 5.492188 12.707031 4.65625 12.203125 L 4.472656 12.089844 L 2.546875 12.628906 L 3.171875 10.894531 L 3.019531 10.683594 C 2.378906 9.78125 2 8.6875 2 7.5 C 2 4.457031 4.457031 2 7.5 2 Z M 5.0625 4 C 4.949219 4 4.761719 4.046875 4.601563 4.234375 C 4.445313 4.417969 4 4.867188 4 5.78125 C 4 6.691406 4.617188 7.574219 4.703125 7.699219 C 4.789063 7.824219 5.917969 9.695313 7.644531 10.5 C 8.054688 10.691406 8.375 10.804688 8.625 10.890625 C 9.039063 11.03125 9.414063 11.011719 9.710938 10.964844 C 10.042969 10.910156 10.730469 10.515625 10.875 10.082031 C 11.015625 9.648438 11.019531 9.277344 10.972656 9.199219 C 10.933594 9.121094 10.816406 9.074219 10.644531 8.984375 C 10.472656 8.890625 9.625 8.441406 9.46875 8.382813 C 9.3125 8.316406 9.195313 8.289063 9.078125 8.472656 C 8.964844 8.660156 8.636719 9.078125 8.535156 9.199219 C 8.433594 9.324219 8.335938 9.339844 8.164063 9.246094 C 7.988281 9.152344 7.433594 8.957031 6.777344 8.324219 C 6.265625 7.832031 5.917969 7.226563 5.820313 7.039063 C 5.71875 6.851563 5.808594 6.753906 5.894531 6.664063 C 5.972656 6.578125 6.066406 6.445313 6.152344 6.335938 C 6.238281 6.230469 6.265625 6.148438 6.324219 6.027344 C 6.382813 5.902344 6.355469 5.792969 6.3125 5.703125 C 6.265625 5.609375 5.921875 4.695313 5.78125 4.324219 C 5.640625 3.964844 5.496094 4.011719 5.390625 4.007813 C 5.292969 4 5.175781 4 5.0625 4 Z"
                          />
                        </svg>
                      </span>
                      <span class="font-semi-bold">+91-9949296431</span>
                    </a>
                  </div>
                </div>

                <h3
                  style="color: #4073b9; letter-spacing: 2px; font-weight: 600"
                  class="mb-2 mt-4"
                >
                  BOOK A FREE SESSIONS
                </h3>
                <div
                  class="w-full border border-radius-md px-2 mb-2 py-1"
                  style="border-color: #4073b966"
                >
                  <div style="opacity: 1; transform: none" class="flex">
                    <div class="mt-1 w-1/3">
                      <img
                        alt="Suresh Kadari"
                        class="border-radius-full"
                        src="https://schoolforai.in/images/sureshk.png"
                      />
                    </div>
                    <div
                      class="flex items-start justify-start flex-column font-sm ml-4"
                    >
                      <h1 class="font-base font-semi-bold mt-1">
                        Suresh Kadari
                      </h1>

                      <p class="color-secondary font-xs">
                        <!-- Book me and I will never give you up. AITINKR will never
                        let you down. Innovation & Ethics are our lifelines. -->
                        Schedule your free training session by selecting from
                        the available dates. We asure timely support for your
                        learning.
                      </p>
                      <div class="flex my-0">
                        <div
                          class="flex items-start justify-start font-sm my-2"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="relative z-20 mr-2 mt-[2px] h-4 w-4 flex-shrink-0 rtl:ml-2"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <polyline points="12 6 12 12 16 14"></polyline>
                          </svg>
                          <div
                            class="relative z-10 max-w-full break-words font-xs"
                          >
                            45 mins max
                          </div>
                        </div>
                        <div
                          class="flex items-start justify-start font-sm my-2 ml-3"
                        >
                          <div class="relative z-10 max-w-full break-words">
                            <div
                              class="text-default mr-6 flex w-full flex-col space-y-4 text-sm mr-2"
                            >
                              <div
                                class="flex flex-row items-center text-sm font-medium"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="text-default h-4 w-4 ltr:mr-[10px] rtl:ml-[10px]"
                                >
                                  <path
                                    d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
                                  ></path>
                                  <path
                                    d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
                                  ></path>
                                </svg>
                                <p
                                  class="line-clamp-1 ml-2 font-xs"
                                  data-state="closed"
                                >
                                  Link meeting
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex">
                  <div class="w-1/2">
                    <mat-calendar
                      (selectedChange)="onCalendarSelectedChange($event)"
                      [selected]="selectedDay"
                      startView="month"
                      class="w-full"
                      [startAt]="selectedDay"
                      [minDate]="todayDay"
                      [maxDate]="maxDay"
                      [dateFilter]="appointmentDateSelectionFilter"
                    ></mat-calendar>
                  </div>
                  <div class="relative w-1/2">
                    <div class="flex justify-end mr-2">
                      <div class="text-right flex items-center">
                        <span class="color-secondary mr-3"
                          >Available Free Sessions</span
                        >
                        <h1 style="color: #4073b9" class="font-5xl">
                          {{ 2 - userProductSessions.length }}
                        </h1>
                      </div>
                    </div>
                    <div
                      class="flex items-center justify-center"
                      *ngIf="activetimeSlot.length == 0"
                      style="height: 80%"
                    >
                      <p class="mat-color-primary">No Timeslots Available</p>
                    </div>
                    <div
                      class="flex flex-column ml-6 mr-12 p-4 border-radius-lg justify-between"
                      *ngIf="activetimeSlot.length > 0"
                      style="
                        background-color: white;
                        width: max-content;
                        height: 30vh !important;
                      "
                    >
                      <div>
                        <h3 class="mb-4">
                          {{ selectedDay.format("MMM Do, dddd") }}
                        </h3>
                        <ul class="list-unstyled">
                          <li
                            *ngFor="let slot of activetimeSlot"
                            class="border-radius-lg border p-4 cursor-pointer text-center"
                            style="width: 10vw"
                            (click)="selectedTimeSlot = slot"
                            [ngClass]="{
                              'timeslot-active':
                                selectedTimeSlot?.value == slot.value
                            }"
                            [attr.disabled]="checkSlotAlreadyBooked(slot)"
                          >
                            {{ slot.title }}
                          </li>
                        </ul>
                      </div>
                      <button
                        class="mat-focus-indicator letter-spacing-10 text-uppercase font-xss mat-raised-button mat-button-base mat-primary cursor-pointer"
                        [disabled]="!selectedTimeSlot"
                        (click)="bookSlot()"
                        style="background-color: #4073b9"
                      >
                        Confirm the slot
                      </button>
                    </div>
                  </div>
                </div>
                <div class="flex flex-column mt-2 pl-4">
                  <h4 class="letter-spacing-10 font-light mat-color-primary">
                    YOUR SESSIONS
                  </h4>
                  <div
                    *ngFor="let schedule of userProductSessions"
                    class="flex mt-4 px-0 cursor-pointer items-center"
                  >
                    <div
                      class="mr-4 course1-text course-border-top border-radius-lg shadow-1 flex items-center flex-column"
                      style="min-width: 3.2rem; border: 0"
                    >
                      <div
                        style="
                          font-size: 11px;
                          border-radius: 8px 8px 0 0;
                          background-color: currentColor;
                          width: 100%;
                        "
                        class="text-center"
                      >
                        <span style="color: #fff"
                          >{{ schedule.event_date | date : "MMM" }}
                          {{ schedule.event_date | date : "dd" }}
                        </span>
                      </div>
                      <div
                        class="flex flex-column items-center"
                        style="padding: 3px 2px 0 2px"
                      >
                        <p class="font-bolder text-center">
                          {{
                            schedule.event_date +
                              "
                                                    " +
                              schedule.event_time | date : "h:mm"
                          }}
                        </p>
                        <p class="font-xs time-prefix">
                          {{
                            schedule.event_date +
                              "
                                                    " +
                              schedule.event_time | date : "a"
                          }}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p class="font-medium">{{ course.name }}</p>
                      <p class="font-xs line-clamp-1 font-light mt-1">
                        Your learning and play bot
                      </p>
                    </div>
                  </div>
                  <div *ngIf="userProductSessions.length == 0">
                    <div
                      class="empty-state flex items-center flex-column justify-center mt-12"
                    >
                      <mat-icon
                        class="border-radius-full"
                        style="height: 50px; width: 50px; font-size: 32px"
                        >search</mat-icon
                      >
                      <p class="text-center mt-2 color-secondary">
                        No schedules found.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="course.category != 'aitinker'">
          <ng-container *ngIf="course.user_course">
            <div class="co-ordinator">
              <div class="card">
                <div class="additional mat-course{{ course.id }}-button">
                  <div class="user-card">
                    <img src="assets/images/2222.png" />
                  </div>
                  <div class="more-info">
                    <h2>Chandramouli Logisa</h2>
                    <div class="stats">
                      <div>
                        <div class="title">Email</div>
                        <div class="value">
                          <a href="mailto:support@schoolforai.com"
                            >support@schoolforai.com</a
                          >
                        </div>
                      </div>

                      <div>
                        <div class="title">Phone</div>
                        <div class="value infinity">
                          <a href="tel:+91-9949296431">+91-9949296431</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="general">
                  <h2>Chandramouli Logisa</h2>
                  <p class="font-xs mt-1 color-secondary">
                    Dynamic and aspiring data scientist with experiencing in
                    guiding students towards their learning goals.
                  </p>
                  <span class="more">Mouse over the card for more info</span>
                </div>
              </div>
            </div>

            <mat-card
              class="ask-query mt-6 mat-elevation-z0"
              style="background: #e8f2ff"
            >
              <mat-card-header>
                <mat-card-title class="font-base mb-1"
                  >Ask your Question</mat-card-title
                >
              </mat-card-header>
              <mat-card-content>
                <p class="color-secondary font-xss">
                  Dynamic and aspiring data scientist with experiencing in
                  guiding students towards their learning goals.
                </p>
                <div class="mt-3 flex">
                  <form class="w-full">
                    <mat-form-field
                      appearance="outline"
                      class="w-full"
                      color="white"
                    >
                      <textarea
                        matInput
                        placeholder=""
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="2"
                      ></textarea>
                    </mat-form-field>
                    <div class="float-left">
                      <button mat-raised-button color="primary">Submit</button>
                    </div>
                  </form>
                </div>
              </mat-card-content>
            </mat-card>
          </ng-container>

          <ng-container *ngIf="!course.user_course">
            <div
              class="relative"
              style="border-left: 1px solid aliceblue; display: unset"
            >
              <div
                class="sticky h-screen top-0 w-full"
                style="display: inline-grid"
              >
                <div
                  class="md:grid has-1-columns has-3-rows column-gap-20 my-4 mx-6"
                >
                  <ng-container *ngIf="course.properties.key_features">
                    <mat-card
                      class="mat-card border-radius-lg course-border mb-3 xxl:mb-4 mat-elevation-z3"
                    >
                      <mat-card-title
                        class="font-lg mb-0 course-highlights-item key_features"
                      >
                        <span class="ml-6">Key Features</span>
                      </mat-card-title>
                      <mat-card-content>
                        <ul
                          class="color-primary pl-8 pt-3 line-height-15rem font-xs"
                        >
                          <li
                            *ngFor="let item of course.properties.key_features"
                            class="list-unstyled flex align-items mb-1"
                          >
                            <mat-icon
                              class="mat-icon material-icons-round material-icons mr-2 course{{
                                course.id
                              }}-text"
                              >done </mat-icon
                            >{{ item }}
                          </li>
                        </ul>
                      </mat-card-content>
                    </mat-card>
                  </ng-container>

                  <ng-container *ngIf="course.properties.takeaways">
                    <mat-card
                      class="mat-card border-radius-lg course-border mb-3 xxl:mb-4 mat-elevation-z3"
                    >
                      <mat-card-title
                        class="font-lg mb-0 course-highlights-item takeaways"
                      >
                        <span class="ml-6">Take aways</span>
                      </mat-card-title>
                      <mat-card-content>
                        <ul
                          class="color-primary pl-8 pt-3 line-height-15rem font-xs"
                        >
                          <li
                            *ngFor="let item of course.properties.takeaways"
                            class="list-unstyled flex align-items mb-1"
                          >
                            <mat-icon
                              class="mat-icon material-icons-round material-icons mr-2 course{{
                                course.id
                              }}-text"
                              >done </mat-icon
                            >{{ item }}
                          </li>
                        </ul>
                      </mat-card-content>
                    </mat-card>
                  </ng-container>

                  <ng-container *ngIf="course.properties.outcomes">
                    <mat-card
                      class="mat-card border-radius-lg course-border mb-3 xxl:mb-4 mat-elevation-z3"
                    >
                      <mat-card-title
                        class="font-lg mb-0 course-highlights-item outcomes"
                      >
                        <span class="ml-6">Outcomes</span>
                      </mat-card-title>
                      <mat-card-content>
                        <ul
                          class="color-primary pl-8 pt-3 line-height-15rem font-xs"
                        >
                          <li
                            *ngFor="let item of course.properties.outcomes"
                            class="list-unstyled flex align-items mb-1"
                          >
                            <mat-icon
                              class="mat-icon material-icons-round material-icons mr-2 course{{
                                course.id
                              }}-text"
                              >done </mat-icon
                            >{{ item }}
                          </li>
                        </ul>
                      </mat-card-content>
                    </mat-card>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
