import { Component, OnInit } from '@angular/core';
import { HttpService } from '@core/services/http.service';

@Component({
  selector: 'im-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit {

  usersList:any = [];

  constructor(private http: HttpService) { }

  ngOnInit() {
    this.list();
  }


  list() {
    this.http.get('user_rank?order_by=rank&order=asc&limit=9').subscribe((res)=>{
      this.usersList=res.data;
    });
  }

}
