import { Component, OnInit } from '@angular/core';
import { HttpService } from '@core/services/http.service';

@Component({
  selector: 'im-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {

  transactions = [];

  processing = true;

  activeTransaction:any;

  constructor(private http: HttpService) { }

  ngOnInit() {
    this.processing = true;
    this.http.get('user/my/transactions').subscribe((res)=>{
        this.transactions = res.data;
        this.processing = false;
    });
  }

  active(transaction) {
    this.activeTransaction = transaction;
  }

}
