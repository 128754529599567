import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@core/ui/calendar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTreeModule } from '@angular/material/tree';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { EventComponent } from './event/event.component';
import { DetailsComponent, EVimeoModal } from './details/details.component';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CertificateComponent } from './certificate/certificate.component';
import { PayComponent } from './pay/pay.component';
import { PaymentModule } from '@modules/payment';
import { ListComponent } from './list/list.component';
import { IframeJoinComponent } from './iframe-join/iframe-join.component';
@NgModule({
  declarations: [
    EVimeoModal,
    EventComponent,
    DetailsComponent,
    CertificateComponent,
    PayComponent,
    ListComponent,
    IframeJoinComponent,
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatCardModule,
    MatIconModule,
    MatDialogModule,
    MatSidenavModule,
    MatButtonModule,
    RouterModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTreeModule,
    MatToolbarModule,
    MatBottomSheetModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    PaymentModule,
  ],
  exports: [],
  entryComponents: [EVimeoModal, IframeJoinComponent],
})
export class EventModule {}
