import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'im-ai-play',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.scss']
})
export class PlayComponent implements OnInit {

  @Input() game:any;


  constructor() { 
  }

  ngOnInit() {
  }

 

}
