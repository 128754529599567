<div class="book-free-online mb-4 xs:h-auto" *ngIf="enable">
  <div class="book-free-online-wrapper xs:flex-column xs:text-center xs:p-2">
    <div class="w-1/2 xs:w-full">
      <h2 class="letter-spacing-15 text-uppercase">WELCOME TO AITINKR</h2>
      <p class="font-sm mt-2 color-secondary-white">
        Ease your way into the world of AI through easy to learn step-by-step
        courses built around project based learning to turn you into the best.
      </p>
    </div>
    <div>
      <button
        mat-raised-button
        color="primary"
        class="letter-spacing-15 text-uppercase xs:mt-3 mr-4"
        routerLink="/event/ai-robotics-summer-camp-2024"
      >
        Summer Camp'24
      </button>
      <!-- <button mat-raised-button color="primary" class="letter-spacing-15 text-uppercase xs:mt-3" (click)="book()">Book a
        FREE Trail</button> -->
    </div>
  </div>
</div>
